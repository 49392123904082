import { Modal } from 'react-bootstrap';
import styled from 'styled-components';


export const StyledModal = styled(Modal)`
  
  .modal-dialog {
    
    @media (min-width: 576px) {
      max-width: 642px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }

  }

  .modal-content{
    width: max-content;
  }

  .modal-header {
   
    .close{
      color: #CC0000;
      font-weight: 100;
      font-size: 38px;
      opacity: 1;
    }

    border: 0;
    padding: 2.41rem 2rem 1rem 2rem;
    .modal-title {
    font-family: "Poppins";
    font-size: 1.50rem;
    font-weight: 600;
    }
  }

  .modal-body{
    align-items:left;
    display:flex;
    justify-content: center;
    padding: 1rem 2rem 2rem 1rem;
    p{
      font-family: "Open Sans";
      font-size: 1rem;
      margin-left: 1rem;
      margin-bottom: 2rem;
    }
  }


  .container-buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    margin-top: 1.5rem;
    

    button {
      &:first-child{
        border: none;
      }
      
      padding: 18px 60px;
      width: 305px;
    }
  }
`;
