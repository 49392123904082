import styled, { css } from 'styled-components';
import * as Styled from '../Content/CustomTable/styles'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 0 0 16px;
    height: 100vh;
    margin-bottom: 40px;

  ${Styled.Container} {
    & + div {
      margin-top: 40px;
    }
  }

    .contentmain__header {
      width: 100%;
      border-bottom: 1px solid var(--linecolor-gray);
      display: flex;
      min-height: 72px;
    }

    .contentmain__header__text {
      padding: 16px 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      display: flex;
      align-items: center;
      letter-spacing: -1px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--gray-50);
    }

    .contentmain__header__text__active {
      font-weight: 600;
    }

    .contentmain__header__icon {
      margin: auto 0;
      color: var(--gray-100);
      font-size: 21px;
      line-height: 20px;
      padding: 0 10px;
    }
    .BE070:before {
      color: var(--gray-100);
    }

    .contentmain__lastupdate {
      float: right;
      padding: 24px 80px 16px 0;

      .contentmain__lastupdate__text {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: right;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--gray-200);
      }
    }

  `}
`;
