import React from 'react';

import { Container } from './styles';

export default function ToggleButton({
  groupName,
  value,
  text,
  onChange,
  checked,
}) {
  return (
    <Container>
      <input
        type="radio"
        name={groupName}
        value={value}
        onChange={onChange}
        id={value}
        checked={checked}
      />
      <label className="btn-radio" htmlFor={value}>
        {text}
      </label>
    </Container>
  );
}
