import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';

export default function PartnersParityContent({
  ordenedListPartners,
  searchParams,
}) {
  return (
    <>
      <S.contentMainPartnersParity>
        <div className="content__box__partner">
          {ordenedListPartners
            .filter(item => {
              return searchParams.toLowerCase() === ''
                ? item
                : item.partnerName.toLowerCase().includes(searchParams);
            })
            .map(item => (
              <Link
                key={item.partnerIdentifier}
                to={`/partners-parity/${item.partnerIdentifier}`}
              >
                <div
                  className="content__item__partner"
                  id={item.partnerIdentifier}
                >
                  <div>
                    <img src={item.imageUrl} alt="Imagem Parceiro" />
                    <span>{item.partnerName}</span>
                  </div>
                  <span className="contentmain__header__icon icoArrow esfera-icon BE060" />
                </div>
              </Link>
            ))}
        </div>
      </S.contentMainPartnersParity>
    </>
  );
}
