import React from 'react';
import Button from '../../Button';
import * as S from './styles';

export default function HistoryCampaignTableHead({ headTableData }) {
  return (
    <S.Container>
      <tr>
        {headTableData.map(item => (
          <th>
            <S.WrapperHeader>
              {item.title}
              {!!item.hasIcoSort && (
                <Button>
                  <span
                    aria-hidden="true"
                    className="icoArrow esfera-icon BE390"
                  />
                </Button>
              )}
            </S.WrapperHeader>
          </th>
        ))}
      </tr>
    </S.Container>
  );
}
