import styled, { css } from 'styled-components';
import { Navbar } from 'react-bootstrap';
import * as StyledCustomSelect from './SiteSelector/styles';

export const Container = styled.aside`
  ${({ theme, isSideMenuMobileOpen }) => css`
    width: 15%;
    height: calc(100% - 56px);
    margin-top: 56px;
    padding: 0 8px;
    z-index: ${theme.layers.menu};
    background: ${theme.colors.lighterSky};

    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    transform: translateX(-200px);
    transition: transform 0.15s ease-in-out;

    @media (min-width: 992px) {
      transform: translateX(0);
    }

    @media (max-width: 992px) {
      width: 200px;
    }

    ${isSideMenuMobileOpen ? (
      css`
        transform: translateX(0);
      `
    ) : (
      css`
        transform: translateX(-200px);
      `
    )}

    ${StyledCustomSelect.Container} {
      margin-top: 16px;
    }
  `}
`;

export const StyledNavbar = styled(Navbar)`
  margin-top: 20px;
  padding: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;


