import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Checkbox from '../../../Checkbox';
import TextField from '../../../TextField';
import { Content, DivInput } from './styles';

export default function ValidityContent({
  beginDate,
  endDate,
  populatePayloadRequest,
}) {
  const [optionChecked, setOptionChecked] = useState('');
  const beginningDate = moment(beginDate, 'DD-MM-YYYY hh:mm:ss').format(
    'YYYY-MM-DD',
  );
  const endingDate = moment(endDate, 'DD-MM-YYYY hh:mm:ss').format(
    'YYYY-MM-DD',
  );
  const beginningHour = moment(beginDate, 'DD-MM-YYYY hh:mm:ss').format(
    'HH:mm:ss',
  );
  const endingHour = moment(endDate, 'DD-MM-YYYY hh:mm:ss').format('HH:mm:ss');
  const [newValidityValues, setNewValidityValues] = useState({
    promoStartDate: moment(beginDate, 'DD-MM-YYYY hh:mm:ss').format(
      'DD-MM-YYYY',
    ),
    promoEndDate: moment(endDate, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY'),
    startTime: `${beginningHour}`,
    endTime: `${endingHour}`,
  });
  const [validationFields, setValidationFields] = useState({
    promoStartDate: {
      isValid: !!(
        beginningDate &&
        beginningDate.length >= 1 &&
        beginningDate !== 'Invalid date'
      ),
      isRequired: true,
      minLength: 1,
    },
    startTime: {
      isValid: !!(
        beginningHour &&
        beginningHour.length >= 1 &&
        beginningHour !== 'Invalid date'
      ),
      isRequired: true,
      minLength: 1,
    },
    promoEndDate: {
      isValid: !!(
        endingDate &&
        endingDate.length >= 1 &&
        endingDate !== 'Invalid date'
      ),
      isRequired: true,
      minLength: 1,
    },
    endTime: {
      isValid: !!(
        endingHour &&
        endingHour.length >= 1 &&
        endingHour !== 'Invalid date'
      ),
      isRequired: true,
      minLength: 1,
    },
  });

  const selectCheckedOrNot = e => {
    const checked = e;
    const promoEndValid = { ...validationFields.promoEndDate };

    promoEndValid.isValid = !!(
      newValidityValues.promoEndDate &&
      newValidityValues.promoEndDate.length >= 1 &&
      newValidityValues.promoEndDate !== 'Invalid date Invalid date'
    );
    const endTimeValid = { ...validationFields.endTime };
    endTimeValid.isValid = !!(
      newValidityValues.promoEndDate &&
      newValidityValues.promoEndDate.length >= 1 &&
      newValidityValues.promoEndDate !== 'Invalid date Invalid date'
    );

    const endDateRequired = {
      promoEndDate: { ...promoEndValid, isRequired: !checked.target.checked },
      endTime: { ...endTimeValid, isRequired: !checked.target.checked },
    };
    setValidationFields({ ...validationFields, ...endDateRequired });
    setOptionChecked(!!checked.target.checked);
    setNewValidityValues({
      ...newValidityValues,
      ...{ promoEndDate: null, endDateRequired: !!checked.target.checked },
    });
  };

  const setIsUntouchable = e => {
    const { target } = e;
    const { value, id } = target;

    const validationField = {};
    validationField[id] = {
      ...validationFields[id],
      isRequired: !!(
        value &&
        value.length >= validationFields[id].minLength &&
        value !== 'Invalid date'
      ),
      isValid: !!(
        value &&
        value.length >= validationFields[id].minLength &&
        value !== 'Invalid date'
      ),
    };

    setValidationFields({ ...validationFields, ...validationField });
  };

  const populateValidityFields = e => {
    const { target } = e;
    let { value } = target;
    const key = target.name;

    if (key === 'promoStartDate' || key === 'promoEndDate') {
      value = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }

    const newObject = {};
    newObject[key] = value;

    setNewValidityValues({ ...newValidityValues, ...newObject });
  };

  useEffect(() => {
    populatePayloadRequest(newValidityValues, validationFields);
  }, [newValidityValues]);

  return (
    <Content>
      <DivInput>
        <TextField
          id="Data de início"
          idName="promoStartDate"
          label="Data de início"
          type="date"
          defaultValue={beginningDate}
          className={
            validationFields.promoStartDate.isValid ||
            !validationFields.promoStartDate.isRequired
              ? ''
              : 'invalid-input'
          }
          onChange={e => {
            setIsUntouchable(e);
            populateValidityFields(e);
          }}
        />
        <TextField
          id="Hora de início"
          idName="startTime"
          label="Hora de início"
          type="time"
          className={
            validationFields.startTime.isValid ||
            !validationFields.startTime.isRequired
              ? ''
              : 'invalid-input'
          }
          onChange={e => {
            setIsUntouchable(e);
            populateValidityFields(e);
          }}
          defaultValue={beginningHour}
        />
      </DivInput>
      <DivInput>
        <TextField
          id="Data de término"
          idName="promoEndDate"
          label="Data de término"
          type="date"
          defaultValue={endingDate}
          className={
            validationFields.promoEndDate.isValid ||
            !validationFields.promoEndDate.isRequired
              ? ''
              : 'invalid-input'
          }
          onChange={e => {
            setIsUntouchable(e);
            populateValidityFields(!optionChecked ? e : null);
          }}
          disabled={optionChecked}
        />
        <TextField
          id="Hora de término"
          idName="endTime"
          label="Hora de término"
          type="time"
          defaultValue={endingHour}
          className={
            validationFields.endTime.isValid ||
            !validationFields.endTime.isRequired
              ? ''
              : 'invalid-input'
          }
          onChange={e => {
            setIsUntouchable(e);
            populateValidityFields(!optionChecked ? e : null);
          }}
          disabled={optionChecked}
        />
      </DivInput>
      <Checkbox
        id="notDefinedEnd"
        name="notDefinedEnd"
        label="Não definir data de término"
        type="checkbox"
        onClick={e => {
          selectCheckedOrNot(e);
        }}
      />
    </Content>
  );
}
