import styled from "styled-components";

export const DivInput = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;

  .invalid-input {
    border-color: red;
  }

  @media (min-width: 992px) {
    display: grid;
    grid-template: 1fr / 1fr 2fr 1fr;
    gap: 12px;

    div:last-child {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
`

export const Subtitle = styled.h4`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
`

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 33px;
`

export const DivRadioButton = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
`
