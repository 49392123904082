import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import { StyledModal, StyledForm } from './styles';
import {
  handleGetRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';

export default function ModalHistoryFilter({
  isOpenModal,
  handleCloseModal,
  handleFilter,
  currentFilter,
}) {
  const { keycloak } = useKeycloak();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectParticipants, setSelectParticipants] = useState('');
  const FULL_CLUB_URL = `${window.SERVICES_DOMAIN}${window.CLUB_TYPES}`;
  const [participants, setParticipants] = useState([]);

  const getListParticipants = () => {
    const reqOptions = {
      method: 'GET',
      headers: initialHeadersData(keycloak.token),
      data: {},
    };

    handleGetRequest(FULL_CLUB_URL, reqOptions)
      .then(({ data }) => {
        if (data) {
          setParticipants(
            data.messages.map(e =>
              Object.freeze({
                clubName: e,
                selected: false,
              }),
            ),
          );
          setSelectParticipants(data.messages[0]);
        }
      })
      .catch(error => {
        console.warn(`Error at listOrders request = ${error}`);
      });
  };

  const cancelModal = () => {
    handleCloseModal(false);
  };

  const handleStartDate = e => {
    setStartDate(e.target.value);
  };

  const handleEndDate = e => {
    setEndDate(e.target.value);
  };

  const handleSelectParticipants = e => {
    setSelectParticipants(e.target.value);
  };

  const applyFilter = e => {
    e.preventDefault();
    const filter = currentFilter;
    filter.pageNumber = 0;
    filter.pageCount = 6;

    delete filter.dateInit;
    delete filter.dateEnd;

    if (startDate)
      filter.dateInit = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
    if (endDate) filter.dateEnd = moment(endDate).format('YYYY-MM-DDT23:59:59');

    if (selectParticipants) filter.clubName = selectParticipants;
    handleFilter(filter);
    cancelModal();
  };

  const isFilterValid = () => {
    const isValid = startDate && endDate && selectParticipants;
    return isValid;
  };

  useEffect(() => {
    getListParticipants();
  }, []);

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <StyledModal.Header closeButton>
        <h4 className="modal-title" id="myModalLabel">
          Filtrar por período
        </h4>
      </StyledModal.Header>
      <div className="modal-body">
        <div className="modal-body-form">
          <span>Prazo</span>
          <div className="row">
            <div className="ap-text-field">
              <StyledForm.Control
                onChange={handleStartDate}
                className="ap-text-field-input"
                type="date"
                value={startDate}
              />
              <label htmlFor="meuInput" className="ap-text-field-label">
                Inicio{' '}
              </label>
            </div>
            <div className="ap-text-field">
              <StyledForm.Control
                onChange={handleEndDate}
                className="ap-text-field-input"
                type="date"
                value={endDate}
              />
              <label htmlFor="meuInputTwo" className="ap-text-field-label">
                Fim{' '}
              </label>
            </div>
          </div>
        </div>
        <div className="modal-body-form">
          <span>Clube</span>
          <div className="row">
            <div className="ap-text-field">
              <div className="select-container">
                <select
                  className="ap-text-field-select"
                  value={selectParticipants}
                  onChange={handleSelectParticipants}
                >
                  {participants?.map(club => (
                    <option key={club.clubName} value={club.clubName}>
                      {club.clubName}
                    </option>
                  ))}
                </select>
                <span className="ic_system_arrow_down esfera-icon arrow-icon" />
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={cancelModal}
          className="ap-button ap-button-secondary"
        >
          Cancelar
        </button>
        <button
          type="button"
          className="ap-button ap-button-primary"
          onClick={applyFilter}
          disabled={!isFilterValid()}
        >
          Confirmar
        </button>
      </div>
    </StyledModal>
  );
}
