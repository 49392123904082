import React, { useState } from 'react';
import moment from 'moment';

import constants from '../../../config';

import ModalApproveOrder from '../ModalApproveOrder';
import ModalRefusedOrder from '../ModalRefusedOrder';
import ModalOrderDetails from '../ModalOrderDetails';

import confirmedImg from '../../../assets/img/ic-check.png';
import cancelledImg from '../../../assets/img/ic-cancelled.png';

import { StyledTr, WrapperButton } from './styles';

export default function EsferaItem({
  order,
  userPermissions,
  handleShowMessageAction,
}) {
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [isOpenModalRefused, setIsOpenModalRefused] = useState(false);
  const [isOpenModalDetails, setIsOpenModalDetails] = useState(false);

  const handleShowMdlConfirmOrder = event => {
    event.stopPropagation();
    setIsOpenModalDetails(false);
    setIsOpenModalConfirm(true);
  };

  const handleCloseMdlConfirmOrder = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalConfirm(false);
  };

  const handleShowMdlRefusedOrder = event => {
    event.stopPropagation();
    setIsOpenModalDetails(false);
    setIsOpenModalRefused(true);
  };

  const handleCloseMdlRefusedOrder = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalRefused(false);
  };

  const handleShowMdlOrderDetails = () => setIsOpenModalDetails(true);
  const handleCloseMdlOrderDetails = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalDetails(false);
  };

  return (
    <>
      <StyledTr onClick={handleShowMdlOrderDetails}>
        <td>{order.orderId}</td>
        <td>{order.profile.document}</td>
        <td>{moment(order.submittedDate).format('DD/MM/YYYY HH:mm')}</td>
        <td>
          {order.products.length > 0 ? (
            <>
              {order.products.map(product => (
                <span key={Math.random()}>
                  {product.name} {`\n`}
                </span>
              ))}
            </>
          ) : (
            `-`
          )}
        </td>
        <td>
          {order.products.length > 0 ? (
            <>
              {order.products.map(product => (
                <span key={Math.random()}>
                  {product.skuId} {'\n'}
                </span>
              ))}
            </>
          ) : (
            `-`
          )}
        </td>
        <td>
          <span>{order.amount}</span>
        </td>

        <td>
          {(order.status === constants.OCC_STATUS_LABEL.SETTLED && (
            <WrapperButton>
              <img src={confirmedImg} alt="" />
              <span className="confirm">Confirmado</span>
            </WrapperButton>
          )) ||
            ((order.status === constants.OCC_STATUS_LABEL.REFUSED ||
              order.status === constants.OCC_STATUS_LABEL.CANCELLED) && (
              <WrapperButton>
                <img src={cancelledImg} alt="" />
                <span className="recused">Recusado</span>
              </WrapperButton>
            )) || (
              <WrapperButton>
                {userPermissions.checkUiPermissionsFn(
                  'ESFERA.MANUAL_ORDERS.UPDATE',
                  userPermissions.userGroups,
                ) ? (
                  <>
                    <button
                      type="button"
                      className="btn-action"
                      onClick={handleShowMdlConfirmOrder}
                    >
                      <span className="confirm">Confirmar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-action"
                      onClick={handleShowMdlRefusedOrder}
                    >
                      <span className="recused">Recusar</span>
                    </button>
                  </>
                ) : null}
              </WrapperButton>
            )}
        </td>
      </StyledTr>

      <ModalApproveOrder
        order={order}
        isOpenModal={isOpenModalConfirm}
        handleCloseModal={handleCloseMdlConfirmOrder}
        userPermissions={userPermissions}
      />

      <ModalOrderDetails
        order={order}
        isOpenModal={isOpenModalDetails}
        handleShowMdlConfirm={handleShowMdlConfirmOrder}
        handleShowMdlRefused={handleShowMdlRefusedOrder}
        handleCloseModal={handleCloseMdlOrderDetails}
      />

      <ModalRefusedOrder
        order={order}
        isOpenModal={isOpenModalRefused}
        handleCloseModal={handleCloseMdlRefusedOrder}
        userPermissions={userPermissions}
      />
    </>
  );
}
