import styled, { css } from 'styled-components';
import * as Styled from '../../components/Nucleo/Content/CustomTable/styles'
export const Container = styled.div`
    height: 100vh;
    display: block;
    float: right;
    width: 85%;
    margin-top: 4%;
    padding: 2rem 1rem;
  ${({ theme }) => css`
    padding: 0 16px;
    height: 100%;
  ${Styled.Container} {
    & + div {
      margin-top: 40px;
    }
  }
  `}
`;
export const AirCampaignHeaderContentStyle = styled.div`
  .contentmain__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 72px;
    border-bottom: 1px solid #DADADA;
  }
  .contentmain__header button {
    max-height: 40px;
  }
  .contentmain__header__title {
    display: flex;
  }
  .contentmain__header__text {
    padding: 16px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
  }
  .contentmain__header__text__active {
    font-weight: 600;
  }
  .contentmain__header__icon {
    margin: auto 0;
    color: var(--gray-100);
    font-size: 21px;
    line-height: 20px;
    padding: 0 10px;
  }
  .BE070:before {
    color: var(--gray-100);
  }
  .contentmain__lastupdate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 0;
  }
  .contentmain__lastupdate__text {
    font-size: 14px;
    line-height: 16px;
    color: #767676;
    padding-bottom: 20px;
  }
`
export const AirCampaignHeaderText = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textSize.small};
    max-width: 542px;
    margin-top: 20px;
    margin-bottom: 20px;
  `}
`;