import styled from 'styled-components';

export const Container = styled.main`
  height: 100vh;
  display: block;
  float: right;
  width: 85%;
  margin-top: 4%;
  padding: 2rem 1rem;

  .toast-compasso {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }
  .toast-compasso-success {
    background-color: rgba(55,231,145,.8);
  }
  .toast-compasso-error {
    background-color: var(--boston);
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 440px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h2 {
      font-size: 22px;
      color: var(--gray-50);
      font-weight: 700;

      @media (max-width: 440px) {
        margin-bottom: 16px;
      }
    }
  }
`;
