import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Button from '../../Button';
import Loading from '../../Loading';

import { StyledForm } from './styles';

export default function OrdersPmaFilterDate({ currentFilter, handleFilter }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [showLoading, setShowLoading] = useState(false);
  const handleStartDate = e => {
    setStartDate(e.target.value);
  };

  const handleEndDate = e => {
    setEndDate(e.target.value);
  };

  const clearFilter = () => {
    setShowLoading(true);
    const filter = currentFilter;
    filter.pageNumber = 0;
    filter.pageCount = 20;

    delete filter.startDate;
    delete filter.endDate;

    setStartDate('');
    setEndDate('');

    if (startDate) filter.startAt = '2023-01-01T13:00:00';
    if (endDate)
      filter.endAt = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');

    handleFilter(filter);
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  };

  const applyFilter = e => {
    e.preventDefault();
    setShowLoading(true);
    const filter = currentFilter;
    filter.pageNumber = 0;
    filter.pageCount = 20;

    delete filter.startAt;
    delete filter.endAt;

    if (startDate)
      filter.startAt = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
    if (endDate) filter.endAt = moment(endDate).format('YYYY-MM-DDT23:59:59');

    handleFilter(filter);
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  };

  const isFilterValid = () => {
    const isValid = startDate && endDate;
    return isValid;
  };

  useEffect(() => {
    setStartDate('');
    setEndDate('');
  }, [currentFilter]);

  return (
    <div className="table-content">
      <StyledForm>
        <div className="form-content">
          <StyledForm.Group className="form-group period-inputs">
            <StyledForm.Label>Período:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleStartDate}
              type="date"
              value={startDate}
            />
            <StyledForm.Control
              onChange={handleEndDate}
              type="date"
              value={endDate}
            />
          </StyledForm.Group>
        </div>

        <div className="form-actions">
          <Button
            type="submit"
            typeOfButton="primary"
            onClick={applyFilter}
            disabled={!isFilterValid()}
          >
            Aplicar Filtros
          </Button>
          <button
            onClick={clearFilter}
            type="button"
            className="btn-clear-filter"
            disabled={!isFilterValid()}
          >
            Limpar Filtros
          </button>
        </div>
      </StyledForm>
      <Loading showLoading={showLoading} />
    </div>
  );
}
