import styled, {
  css
} from 'styled-components';

export const Container = styled.div `
  flex: 1;
  height: 100vh;
  display: contents;

 @media (min-width: 992px) {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
 }


 ${props => props.isSideMenuMobileOpen && css`
   &::before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: 5;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
   `}
`;
