import styled from "styled-components";

export const SFinancialContent = styled.div`
  padding: 40px 0;

  .invalid-input {
    border-color: red;
  }
`

export const Subtitle = styled.h4`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
`

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 16px;
`
export const Alert = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 289px;
  padding: 18px;
  background: rgba(51, 102, 255, 0.1);
  border-radius: 4px;
  gap: 0.5rem;
  margin: 21px 0 38px 0;

  p {
    margin-bottom: 0px;
  }

  .ic_filled_circle_attention {
    font-size: 24px;
    color: #3366FF;
    border-radius: 50%;
  }
`

export const DivCheckBox = styled.div`
  display: flex;
  max-width: 324px;
  gap: 1rem;

  .checkbox {
    background: #DEEDF2;
    border: 1px solid #9BC3D3;
    border-radius: 4px;
    padding: 18px;
    max-width: 167px;
  }
`

export const DivRadioButton = styled.div`
  display: flex;
  gap: 2rem;
`

export const DivInput = styled.div`
  display: grid;
  gap: 18px;
  margin-top: 16px;
  grid-template-columns: repeat(2, 162px);
`
