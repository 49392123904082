import React from 'react';
import * as S from './styles';

export default function TableHead({
  headTableData,
  bodyTableDataLength,
  msgDefaultNoData,
  sortFunction,
}) {
  console.log('[bodyTableDataLength]', bodyTableDataLength);
  return (
    <S.Container>
      {bodyTableDataLength ? (
        <tr>
          {headTableData.map(item => (
            <th key={item.title}>
              <S.WrapperHeader>
                {item.title}
                {!!item.hasIcoSort && (
                  <button
                    type="button"
                    aria-label="arrow"
                    onClick={() => sortFunction(item)}
                  >
                    <span className="icoArrow esfera-icon BE390" />
                  </button>
                )}
              </S.WrapperHeader>
            </th>
          ))}
        </tr>
      ) : (
        <tr>
          <td>{msgDefaultNoData}</td>
        </tr>
      )}
    </S.Container>
  );
}
