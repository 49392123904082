import styled, { css } from 'styled-components';

export const Container = styled.button`
  ${({  isActive }) => css`
    border: 0;
    outline: 0;
    background: transparent;
    font-family: "Roboto";
    font-size: 14px;
    color: var(--gray-100);
    transition: color 0.3s;

    .icoArrow {
      font-size: 14px;
      line-height: 21px;
      position: relative; //
      top: 2px; //

      &::before {
        color: var(--gray-100);
      }
    }

    &:hover {
      color: var(--boston);

      .icoArrow {
        &::before {
          color: var(--boston);
        }
      }
    }

    ${isActive && css`
        color: var(--boston);
    `}
  `}
`;
