import React, { useEffect, useState, useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Header from './Header';
import Navbar from './Navbar';
import Ruler from './Ruler';
import FromTo from './FromTo';
import {
  handleGetRequest,
  initialHeadersData,
  handlePutRequest,
  handlePostRequest,
  handleDeleteRequest,
} from '../../../services/GenericMethods';
import ModalConfirmSave from './ModalConfirmSave';
import ModalError from './ModalError';
import ModalCodeAdded from './ModalCodeAdded';
import ModalCodeDelete from './ModalCodeDelete';

const MENU = {
  RULER: { id: 'RULER', value: 'Régua' },
  FROMTO: { id: 'FROMTO', value: 'De/Para' },
};

export default function SelectedPartner({
  userHasPermission,
  selectedPartner,
  setSelectedPartner,
}) {
  const STATUS_URL = `${
    window.SERVICES_DOMAIN + window.STATUS_RULES_TRACKING_GET
  }?partnerCode=${selectedPartner.code}&page=0&size=300`;

  const RULES_URL = `${
    window.SERVICES_DOMAIN + window.RULES_TRACKING_GET
  }?partnerCode=${selectedPartner.code}&page=0&size=10`;

  const UPDATE_RULES_URL = `${
    window.SERVICES_DOMAIN + window.RULES_TRACKING_PUT
  }`;

  const SAVE_NEW_STATUS_URL =
    window.SERVICES_DOMAIN + window.STATUS_RULES_TRACKING_POST;

  const DELETE_STATUS_URL = `${
    window.SERVICES_DOMAIN + window.STATUS_RULES_TRACKING_DELETE
  }`;

  const { keycloak } = useKeycloak();

  const [selectedMenuItem, setSelectedMenuItem] = useState(MENU.RULER.id);
  const [statusResponse, setStatusResponse] = useState({});
  const [cachedRules, setCachedRules] = useState([]);
  const [modifiedRules, setModifiedRules] = useState([]);
  const [newPartnerCodes, setNewPartnerCodes] = useState([]);
  const [deletePartnerCodes, setDeletePartnerCodes] = useState([]);
  const [isOpenConfirmSaveModal, setIsOpenConfirmSaveModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isOpenCodeAddedModal, setIsOpenCodeAddedModal] = useState(false);
  const [isOpenCodeDeleteModal, setIsOpenCodeDeleteModal] = useState(false);

  const goToSelectPartner = () => {
    setSelectedPartner({});
  };

  const fetchStatus = isMounted => {
    const reqOptions = {
      headers: initialHeadersData(keycloak.token),
      data: {},
    };
    handleGetRequest(STATUS_URL, reqOptions)
      .then(response => {
        if (!isMounted || !response || !response.data) return;
        setStatusResponse(response.data.body);
      })
      .catch(error => {
        console.warn(`Error at request = ${error}`);
      });
  };

  const fetchRules = isMounted => {
    const reqOptions = {
      headers: initialHeadersData(keycloak.token),
      data: {},
    };
    handleGetRequest(RULES_URL, reqOptions)
      .then(response => {
        if (!isMounted || !response || !response.data) return;
        setCachedRules(response.data.body.rules.content);
      })
      .catch(error => {
        console.warn(`Error at request = ${error}`);
      });
  };

  useEffect(() => {
    let isMounted = true;
    fetchStatus(isMounted);
    fetchRules(isMounted);
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChanges = () => {
    if (deletePartnerCodes.length) {
      setIsOpenCodeDeleteModal(true);
    } else {
      setIsOpenConfirmSaveModal(true);
    }
  };

  const updateRules = () => {
    const putOptions = {
      data: modifiedRules,
      headers: initialHeadersData(keycloak.token),
    };
    setShowLoading(true);
    handlePutRequest(UPDATE_RULES_URL, putOptions)
      .then(() => {
        setModifiedRules([]);
        fetchRules(true);
      })
      .catch(error => {
        console.warn(`Error at request = ${error}`);
        setIsOpenErrorModal(true);
      })
      .finally(() => {
        setShowLoading(false);
        setIsOpenConfirmSaveModal(false);
      });
  };

  const saveNewStatus = () => {
    const options = {
      data: newPartnerCodes,
      headers: initialHeadersData(keycloak.token),
    };
    setShowLoading(true);
    handlePostRequest(SAVE_NEW_STATUS_URL, options)
      .then(() => {
        setIsOpenCodeAddedModal(true);
        fetchStatus(true);
      })
      .catch(error => {
        console.warn(`Error at request = ${error}`);
        setIsOpenErrorModal(true);
      })
      .finally(() => {
        setShowLoading(false);
        setIsOpenConfirmSaveModal(false);
      });
  };

  const deleteStatus = () => {
    const idsForDelete = deletePartnerCodes.map(partnerCode => partnerCode.id);
    const options = {
      data: { ids: idsForDelete },
      headers: initialHeadersData(keycloak.token),
    };
    setShowLoading(true);
    handleDeleteRequest(DELETE_STATUS_URL, options)
      .then(() => {
        setDeletePartnerCodes([]);
      })
      .catch(error => {
        console.warn(`Error at request = ${error}`);
        setIsOpenErrorModal(true);
      })
      .finally(() => {
        setShowLoading(false);
        setIsOpenCodeDeleteModal(false);
      });
  };

  const handleModalAction = () => {
    if (modifiedRules.length) {
      updateRules();
    }
    if (newPartnerCodes.length) {
      saveNewStatus();
    }
    if (deletePartnerCodes.length) {
      deleteStatus();
    }
  };

  const handleCloseModalAddedCode = () => {
    setIsOpenCodeAddedModal(false);
    setNewPartnerCodes([]);
  };

  const isToDisableSaveButton = useMemo(() => {
    if (
      modifiedRules.length ||
      newPartnerCodes.length ||
      deletePartnerCodes.length
    ) {
      return false;
    }
    return true;
  }, [modifiedRules, newPartnerCodes, deletePartnerCodes]);

  return (
    <>
      <ModalError
        isOpenModal={isOpenErrorModal}
        handleCloseModal={() => setIsOpenErrorModal(false)}
      />
      <ModalConfirmSave
        isOpenModal={isOpenConfirmSaveModal}
        handleSaveModal={handleModalAction}
        showLoading={showLoading}
        handleCloseModal={() => setIsOpenConfirmSaveModal(false)}
      />
      <ModalCodeAdded
        isOpenModal={isOpenCodeAddedModal}
        handleCloseModal={() => handleCloseModalAddedCode()}
        newPartnerCodes={newPartnerCodes}
      />
      <ModalCodeDelete
        isOpenModal={isOpenCodeDeleteModal}
        handleCloseModal={() => setIsOpenCodeDeleteModal(false)}
        showLoading={showLoading}
        deletePartnerCodes={deletePartnerCodes}
        handleDelete={handleModalAction}
      />
      <Header
        selectedPartner={selectedPartner}
        goToSelectPartner={goToSelectPartner}
        statusResponse={statusResponse}
      />
      <Navbar
        isToDisableSaveButton={isToDisableSaveButton}
        userHasPermission={userHasPermission}
        menu={MENU}
        handleSaveUpdate={handleChanges}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
      />
      <Ruler
        isVisible={selectedMenuItem === MENU.RULER.id}
        attendentUser={keycloak.userInfo.name}
        cachedRules={cachedRules}
        modifiedRules={modifiedRules}
        setModifiedRules={setModifiedRules}
      />
      <FromTo
        isVisible={selectedMenuItem === MENU.FROMTO.id}
        userHasPermission={userHasPermission}
        attendentUser={keycloak.userInfo.name}
        statusResponse={statusResponse}
        selectedPartner={selectedPartner}
        newPartnerCodes={newPartnerCodes}
        setNewPartnerCodes={setNewPartnerCodes}
        deletePartnerCodes={deletePartnerCodes}
        setDeletePartnerCodes={setDeletePartnerCodes}
      />
    </>
  );
}
