import React, { useState, useEffect } from 'react';
import CancelParity from '../CancelParity';
import * as S from './styles';
import icEsfera from '../../../assets/ic-esfera.png';
import RegisterParityModal from '../../RegisterParityModal';

export default function Parity({
  plan,
  deleteParity,
  populateToastMessages,
  userPermissions,
  saveParity,
  permissionEditParity,
  partnerParities,
  updateParities,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState();

  const getAllSelectedClubsFromPartner = () => {
    const selectedClubs = [];
    if (Array.isArray(partnerParities)) {
      partnerParities.forEach(parityItem => {
        if (parityItem && Array.isArray(parityItem.clubs)) {
          parityItem.clubs.forEach(club => {
            if (club && club.selected) {
              selectedClubs.push(club.clubName);
            }
          });
        }
      });
    }

    return selectedClubs;
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const handleOpenEditModal = () => {
    setModalVisible(true);
  };

  const handleOpenModal = () => {
    setCheckboxDisabled(true);
    setModalVisible(true);
  };
  const handleCloseEditModal = () => {
    setModalVisible(false);
  };

  const handleDeleteParity = parityId => {
    deleteParity(parityId);
    updateParities(parityId);
  };

  useEffect(() => {
    if (modalVisible) {
      getAllSelectedClubsFromPartner();
    }
  }, [modalVisible]);

  return (
    <>
      <S.ParityFracion key={plan.id}>
        <S.TypeParity>
          <div className="title-parity-fracion">
            {plan.clubs.filter(club => club.selected).length} Clubes
            selecionados
          </div>
        </S.TypeParity>
        <S.Parity>
          <div className="icon_parity">
            <img src={icEsfera} alt="icon-esfera" />
          </div>
          <div className="title-parity-default">Pontos | {plan.points}</div>
        </S.Parity>
        <S.Parity>
          <div className="title-parity-default">Milhas | {plan.miles}</div>
        </S.Parity>
        <div className="icons-crud">
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.PARITY_CONTROL.UPDATE',
            userPermissions.userGroups,
          ) && permissionEditParity ? (
            <>
              <button
                aria-label="edit"
                type="button"
                onClick={handleOpenEditModal}
                className="parity__crud__edit"
              >
                <span
                  style={{ color: 'red' }}
                  className=" icon-update  esfera-icon ic_functional_edit"
                />
              </button>
              <button
                aria-label="delete"
                type="button"
                onClick={handleOpenDeleteModal}
                className="parity__crud__delete"
              >
                <span
                  style={{ color: 'red' }}
                  className="icon-delete esfera-icon ic_system_clouse"
                />
              </button>
            </>
          ) : (
            <button
              aria-label="edit"
              type="button"
              onClick={handleOpenModal}
              className="parity__crud__edit"
            >
              <span
                style={{ color: 'red' }}
                className="icon-delete esfera-icon ic_functional_password_show"
              />
            </button>
          )}
        </div>
        {modalVisible && (
          <RegisterParityModal
            userPermissions={userPermissions}
            mode={permissionEditParity ? 'edit' : 'view'}
            isOpen={modalVisible}
            onClose={handleCloseEditModal}
            onSave={saveParity}
            parity={plan}
            populateToastMessages={populateToastMessages}
            checkboxDisabled={checkboxDisabled}
            parities={partnerParities}
          />
        )}

        {deleteModalVisible && (
          <CancelParity
            isOpenModal={deleteModalVisible}
            handleCloseModal={handleCloseDeleteModal}
            method="delete"
            parityId={plan.id}
            handleDeleteParity={handleDeleteParity}
            populateToastMessages={populateToastMessages}
          />
        )}
      </S.ParityFracion>
    </>
  );
}
