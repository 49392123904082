import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Button from '../../Button';
import Loading from '../../Loading';
import constants from '../../../config';
import { StyledModal, WrapperButtons } from './styles';
import {
  handlePutRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';

import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalInactiveRule({
  rule,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const [showLoading, setShowLoading] = useState(false);
  const FULL_URL = window.SERVICES_DOMAIN + window.CPP_RULE_INACTIVE;

  const handleInactiveCppRule = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.CPP_RULES.DELETE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'PUT',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };

      handlePutRequest(`${FULL_URL}/${rule.id}`, reqOptions)
        .then(() => {
          setShowLoading(false);
          handleCloseModal('success', 'Sucesso!', `Regra de CPP Inativa!.`);
        })
        .catch(error => {
          setShowLoading(false);
          console.error(`
            Error at handleInactiveCppRule request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
    handleCloseModal();
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Inativação</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div>
          <p>Deseja realmente inativar a seguinte regra: </p>
          <span>
            <strong>Tipo</strong>: {constants.CPP_RULE_TYPES[rule.ruleType]}
          </span>
          <span>
            <strong>Identificador</strong>: {rule.ruleId}
          </span>
          <span>
            <strong>Site ID</strong>: {rule.siteId}
          </span>
        </div>

        <WrapperButtons>
          <Button typeOfButton="secondary" onClick={handleInactiveCppRule}>
            Sim
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Não
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
