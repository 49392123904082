import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  .contentmain__parity__title {
    padding: 16px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
    text-align: left;
  }
`;

export const TypeParity = styled.div`
  background-color: #deebf2;
  border-radius: 8px;
  height: 56px;
  width: 280px;

  align-self: center;
  display: flex;
  justify-content: center;
  margin-left: 1%;
  align-items: center;

  .icon_default_parity {
    width: 20%;
    text-align: center;
  }
  .title-parity-default {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    width: 280px;
    heigth: 56px;
    padding-left: 4%;
    text-align: left;
  }
`;
export const DefaultParity = styled.div`
  height: 100px;
  display: flex;
  width: 100%;
  background-color: #f5f9fb;
  color: #767676;

  .container-buttons-parity {
    display: flex;
    align-items: center;
    margin-left: 50px;
    .icon_parity img {
      width: 25px;
      border-radius: 30px;
      margin-right: 18px;
    }
    .action-buttons {
      align-items: center;
      margin-right: 20px;

      &.selected {
        border-radius: 8px;
      }

      .parity-input {
        width: 100%;
        background-color: white;
      }

      .points {
        .parity-value {
          width: 40%;
        }
        input{
          margin-left: 5px;
        }
      }

      .milhas {
        margin-left: 25px;
        .parity-value {
          width: 50%;
        }
        input {
          margin-left: -10px;
        }
      }

      .points,
      .milhas {
        background-color: white;
        color: #000;
        display: flex;
        align-items: center;
        width: 184px;
        height: 56px;
        padding: 16px 5px 16px 12px;
        border-radius: 8px;
        margin-right: 10px;
        text-align: left;

        span {
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          color: #808080;
          width: 50%;
        }

        input {
          width: 100%;
          border: transparent;
        }

        .value-parity {
          width: 50%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

export const NoDefaultParity = styled.div`
  height: 100px;
  display: flex;
  width: 100%;
  background-color: #f5f9fb;
  border-radius: 8px;

  .no-default-paritie {
    margin-left: 5%;
    align-self: center;
  }
`;

export const ParityFracion = styled.div`
  height: 100px;
  display: flex;
  width: 100%;
  background-color: #f5f9fb;
  position: relative;
  .icons-crud {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  .icon-update,
  .icon-delete {
    color: #cc0000;
    font-size: 30px;
    font-family: Open Sans;
    margin-right: 10px;
  }
  .parity__crud__edit,
  .parity__crud__delete {
    background: transparent;
    border: transparent;
    padding-bottom: 35px;
  }
`;

export const Parity = styled.div`
  width: 184px;
  height: 56px;
  margin-left: 3%;
  background-color: #deebf2;
  border-radius: 8px;
  background-color: white;

  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;

  .icon_parity {
    text-align: right;
    width: 20%;

    img {
      width: 25px;
      border-radius: 30px;
    }
  }
  .title-parity-default {
    padding-left: 20px;
    text-align: left;
    color: #767676;
  }
  .value-parity-default {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #222222;
  }
`;

export const ButtonAdd = styled.div`
  width: 352px;
  height:68px;
  margin-top: 2%;
  cursor: pointer;
  display: flex;
  padding: 10px 22px;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: var(--white);
  align-items: center;
  transition: all 0.2s linear;
  

  icon_add_parity {
    width: 10%;
    text-align: center;
    margin-right: 10px;
    border: 1px dashed gray;
    border-radius: 4px;
  }
  }
`;

export const IconParidade = styled.div`
  width: 10%;
  text-align: center;
  border: 1px dashed black;
  border-radius: 4px;
`;
export const TextParidade = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  width: 155px;
  padding-left: 16px;
  white-space: nowrap;
`;
