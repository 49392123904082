import styled, { css } from 'styled-components';


export const StyledTr = styled.tr`
  ${({ theme, isInactive }) => css`

    background: ${isInactive ? theme.colors.sky : theme.colors.white};

    td {
      font-family: ${theme.fonts.family.roboto};
      font-size: ${theme.fonts.textSize.small};
      color: ${theme.colors.darkGrey};
      line-height: 16px;

      &.emphasis {
        color: ${theme.colors.mediumGrey};
        font-weight: ${theme.fonts.bold};
      }

      .status {
        width: fit-content;
        padding: 4px 6px;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: ${theme.fonts.textSize.xsmall};
        font-weight: ${theme.fonts.semibold};

        &.inactive {
          color: ${theme.colors.mediumGrey};
          background: ${theme.colors.lighterGrey};
        }

        &.barred {
          color: ${theme.colors.suportDarkError};
          background: ${theme.colors.suportLightError};
        }

        &.review {
          color: ${theme.colors.suportDarkWarning};
          background: ${theme.colors.suportLightWarning};
        }

        &.authorize {
          color: ${theme.colors.suportDarkSuccess};
          background: ${theme.colors.suportLightSuccess};
        }
      }
    }

    .container-actions {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;

      .iconEdit {
        font-size: 24px;

        &::before {
          color:  ${isInactive ? theme.colors.boston : theme.colors.darkerGrey};
        }
      }

      .iconMoreInfo {
        font-size: 24px;

        &::before {
          color:  ${isInactive ? theme.colors.boston : theme.colors.darkerGrey};
        }
      }

      > svg {
        cursor: pointer;
        font-size: 18px;
      }
    }
  `}
`;
