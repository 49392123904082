export default {
  FRAUD_CHECK: 'Análise Manual',
  REFUSED: 'Barrar Pedido',
  APPROVED: 'Liberar Pedido',

  FRAUD_CHECK_LABEL: 'FRAUD_CHECK',
  REFUSED_LABEL: 'REFUSED',
  APPROVED_LABEL: 'APPROVED',

  BLACKLIST_TYPES: {
    SKU: 'Sku',
    PRODUCT: 'Produto',
    SELLER_ID: 'Seller Id',
  },

  CASHPLUSPOINT_RULE_TYPES: {
    SKU: 'Sku',
    PRODUCT_TYPE: 'ProductType',
    CATEGORY: 'Categoria',
    SELLER: 'Seller Id',
    PRODUCT_TYPE_AND_SELLER: 'ProductType,Seller Id'
  },

  CPP_RULE_TYPES: {
    SKU: 'Sku',
    CATEGORY: 'Categoria',
    SELLER: 'Seller Id',
    SELLER_AND_CATEGORY: "Seller Id,Categoria"
  },

  CPP_SITE_ID: {
    ESFERA: 'ESFERA',
    SANTANDER: 'SHOP SANTANDER'
  },

  OCC_PRODUCT_TYPES: {
    physicalProductType: 'Físico',
    cashBackProductType: 'Desconto em Fatura',
    digitalProductType: 'Vale-compra e Voucher',
    giftCardProductType: 'Vale-compra e Voucher',
    transferProductType: 'Transferência para Milhas',
    digitalTransferProductType: 'Vale-compra e Voucher',
  },

  OCC_STATUS_VALUE: {
    INITIAL: 'Pendente',
    SETTLED: 'Confirmado',
    REFUSED: 'Recusado',
    CANCELLED: 'Cancelado'
  },

  OCC_TRANSFER_STATUS_VALUE: {
    INITIAL: 'Pendente',
    APPROVED: 'Confirmado',
    REFUSED: 'Recusado'
  },

  OCC_PHYSICAL_STATUS_VALUE: {
    SETTLED: 'Confirmado',
    CANCELLED_PARTNER: 'Cancelado pelo Parceiro',
    REFUSED: 'Não Confirmado',
    CANCELLED_PARTIAL: 'Cancelado Parcialmente',
    NO_PENDING_ACTIONS: 'Entregue',
    PENDING_MERCHANDISING_ACTION: 'em Análise',
    INITIAL: 'Realizado',
    SHIPPED: 'em Rota',
    CANCELLED: 'Cancelado',
    PENDING_SHIPPING: 'na Transportadora'
  },

  OCC_STATUS_LABEL: {
    APPROVED: 'APPROVED',
    INITIAL: 'INITIAL',
    SETTLED: 'SETTLED',
    REFUSED: 'REFUSED',
    CANCELLED: 'CANCELLED'
  },

  ORDERS_PMA_STATUS_REPROCESS: {
    PMA_USER_CREATION: 'PMA_USER_CREATION',
    PMA_ACCRUAL: 'PMA_ACCRUAL',
    PMA_MANUAL_ORDER: 'PMA_MANUAL_ORDER',
    PMA_REDEMPTION: 'PMA_REDEMPTION',
    PMA_PROCESSING: 'PMA_PROCESSING',
  },

  TRANSACTIONS_PMA_STATUS_REPROCESS: {
    PMA_CREATION: 'PMA_CREATION',
    PMA_LINE_CREATION: 'PMA_LINE_CREATION',
    PMA_REDEMPTION: 'PMA_REDEMPTION',
  },

  DEFAULT_PERMISSIONS_GROUP: {
    ONBOARDING: {
      RULES: {
        SEE_MAIN_MENU: ['onboarding_admin', 'onboarding_read'],
        SEE_MENU: ['onboarding_admin', 'onboarding_read'],
        CREATE: ['onboarding_admin'],
        READ: ['onboarding_admin', 'onboarding_read'],
        UPDATE: ['onboarding_admin', 'onboarding_update'],
        DELETE: ['onboarding_admin'],
      }
    },
    AWIN: {
      ORDERS: {
        SEE_MAIN_MENU: ['awin_admin', 'awin_read'],
        SEE_MENU: ['awin_admin', 'awin_read'],
        CREATE: ['awin_admin'],
        READ: ['awin_admin', 'awin_read'],
        UPDATE: ['awin_admin'],
        DELETE: ['awin_admin'],
      }
    },
    ANTIFRAUD: {
      RULES: {
        SEE_MAIN_MENU: ['admin', 'atendimento', 'backoffice', 'default'],
        SEE_MENU: ['admin', 'atendimento', 'backoffice', 'default'],
        CREATE: ['admin'],
        READ: ['admin', 'atendimento', 'backoffice', 'default'],
        UPDATE: ['admin'],
        DELETE: ['admin'],
      },
      ORDERS: {
        SEE_MAIN_MENU: ['admin', 'atendimento', 'backoffice', 'default'],
        SEE_MENU: ['admin', 'atendimento', 'backoffice', 'default'],
        CREATE: ['admin'],
        READ: ['admin', 'atendimento', 'backoffice', 'default'],
        UPDATE: ['admin', 'backoffice'],
        DELETE: ['admin'],
      }
    },
    ESFERA: {
      INCENTIVE_ENGINE: {
        SEE_MAIN_MENU: ['admin', 'cashback_incentive_read'],
        SEE_MENU: ['admin', 'cashback_incentive_read'],
        CREATE: ['admin'],
        READ: ['admin', 'cashback_incentive_read'],
        UPDATE: ['admin'],
        DELETE: ['admin'],
      },
      BLACKLIST: {
        SEE_MAIN_MENU: ['admin', 'loja_esfera_admin', 'loja_esfera'],
        SEE_MENU: ['admin', 'loja_esfera_admin', 'loja_esfera'],
        READ: ['admin', 'loja_esfera_admin', 'loja_esfera'],
        CREATE: ['admin','loja_esfera_admin'],
        DELETE: ['admin','loja_esfera_admin']
      },
      TRACKING: {
        SEE_MENU: ['admin', 'tracking_rules'],
        READ: ['admin', 'tracking_rules'],
        UPDATE: ['admin','tracking_rules'],
        DELETE: ['admin','tracking_rules']
      },
      MANUAL_ORDERS: {
        SEE_MAIN_MENU: ['admin', 'loja_esfera_admin', 'loja_esfera'],
        SEE_MENU: ['admin', 'loja_esfera_admin', 'loja_esfera'],
        READ: ['admin', 'loja_esfera_admin', 'loja_esfera'],
        UPDATE: ['admin','loja_esfera_admin']
      },
      MANUAL_TRANSFER_ORDERS: {
        SEE_MAIN_MENU: ['admin', 'manual_transfer_orders_admin', 'manual_transfer_orders'],
        SEE_MENU: ['admin', 'manual_transfer_orders_admin', 'manual_transfer_orders'],
        READ: ['admin', 'manual_transfer_orders_admin', 'manual_transfer_orders'],
        UPDATE: ['admin', 'manual_transfer_orders_admin']
      },
      COUPONS: {
        SEE_MAIN_MENU: ['admin', 'coupon_core_admin', 'coupon_core_search', 'coupon_core_create', 'coupon_core_delete', 'coupon_core_update'],
        SEE_MENU: ['admin', 'coupon_core_admin', 'coupon_core_search', 'coupon_core_create', 'coupon_core_delete', 'coupon_core_update'],
        CREATE: ['admin', 'coupon_core_admin', 'coupon_core_create'],
        READ: ['admin', 'coupon_core_admin', 'coupon_core_search', 'coupon_core_create', 'coupon_core_delete', 'coupon_core_update'],
        UPDATE: ['admin', 'coupon_core_admin', 'coupon_core_update'],
        DELETE: ['admin', 'coupon_core_admin', 'coupon_core_delete'],
      },
      MILES_CAMPAIGN: {
        SEE_MAIN_MENU: ['admin', 'miles_campaign_admin', 'miles_campaign_visualization', 'miles_campaign_create', 'miles_campaign_delete', 'miles_campaign_update'],
        SEE_MENU: ['admin', 'miles_campaign_admin', 'miles_campaign_visualization', 'miles_campaign_create', 'miles_campaign_delete', 'miles_campaign_update'],
        CREATE: ['admin', 'miles_campaign_admin', 'miles_campaign_create'],
        READ: ['admin', 'miles_campaign_admin', 'miles_campaign_visualization', 'miles_campaign_create', 'miles_campaign_delete', 'miles_campaign_update'],
        UPDATE: ['admin', 'miles_campaign_admin', 'miles_campaign_update'],
        DELETE: ['admin', 'miles_campaign_admin', 'miles_campaign_delete'],
      },
      RELEASE_CASHBACK: {
        SEE_MENU: ['release_cashback_menu'],
        READ: ['release_cashback_read'],
        UPDATE: ['release_cashback_update'],
      },
      PARITY_CONTROL: {
        SEE_MAIN_MENU: ['admin', 'parity_control_approver', 'parity_control_admin', 'parity_control_visualization'],
        SEE_MENU: ['admin', 'parity_control_approver', 'parity_control_admin', 'parity_control_visualization'],
        CREATE: ['admin', 'parity_control_approver','parity_control_admin'],
        READ: ['admin', 'parity_control_approver', 'parity_control_admin', 'parity_control_visualization'],
        UPDATE: ['admin', 'parity_control_approver','parity_control_admin'],
        DELETE: ['admin', 'parity_control_approver','parity_control_admin'],
        APPROVER: ['admin', 'parity_control_approver'],
      },
      CPP_RULES: {
        SEE_MAIN_MENU: ['admin', 'cpp_rule_admin', 'cpp_rule_visualization'],
        SEE_MENU: ['admin', 'cpp_rule_admin', 'cpp_rule_visualization'],
        READ: ['admin', 'cpp_rule_admin', 'cpp_rule_visualization'],
        CREATE: ['admin','cpp_rule_admin'],
        DELETE: ['admin','cpp_rule_admin']
      },
      CASHPLUSPOINT_RULES: {
        SEE_MAIN_MENU: ['admin','cash_plus_point_rule_admin', 'cash_plus_point_rule_visualization'],
        SEE_MENU: ['admin','cash_plus_point_rule_admin', 'cash_plus_point_rule_visualization'],
        READ: ['admin', 'cash_plus_point_rule_admin', 'cash_plus_point_rule_visualization'],
        CREATE: ['admin','cash_plus_point_rule_admin'],
        DELETE: ['admin','cash_plus_point_rule_admin']
      },
    },
  },
}
