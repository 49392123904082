import styled, { css } from 'styled-components';

export const contentHistoryContent = styled.div`
  .ap-content-section-campaigns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px 40px;
  }
  .ap-campaign {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    border-radius: 8px;
    border: 1px solid #767676;
    min-width: 350px;
  }
  .ap-campaign-info {
    display: block;
  }
  .ap-campaign-info-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #262626;
    margin-top: 5px;
    display: block;
  }
  .ap-campaign-info-title-pending {
    position: relative;
    display: inline-block;
    padding: 3px 5px;
    background-color: #DEEBF2;
    border-radius: 20px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 700;
    color: #262626;
  }
  .ap-campaign-info-date {
    font-size: 12px;
    line-height: 16px;
    color: #4d4d4d;
  }
  .ap-campaign-actions {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .ap-campaign-actions-btn {
    all: unset;
  }
  .esfera-icon {
    font-size: 25px;
    font-weight: 500;
    cursor: pointer;
    color: #cc0000;
  }
`;
