import React from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../Button';
import { StyledModal } from './styles';
import Loading from '../../../Loading';

export default function ModalConfirmSave({
  isOpenModal,
  handleCloseModal,
  handleSaveModal,
  showLoading,
}) {
  return (
    <>
      <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
        <Loading showLoading={showLoading} />

        <StyledModal.Header closeButton>
          <StyledModal.Title>Salvar Status?</StyledModal.Title>
        </StyledModal.Header>

        <StyledModal.Body>
          <Form>
            <div className="container-buttons">
              <Button
                type="button"
                typeOfButton="primary"
                onClick={handleSaveModal}
              >
                Salvar
              </Button>
              <Button
                type="button"
                typeOfButton="secondary"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </StyledModal.Body>
      </StyledModal>
    </>
  );
}
