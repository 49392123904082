import styled from 'styled-components';

export const Container = styled.div `
  display: flex;
  width: 100%;

  .footer__box {
    position: absolute;
    bottom: 2%;
    padding: 10px;
    border-top: 1px solid var(--linecolor-gray);
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    margin: 0 auto;

    .footer__text {
      height: 21.58px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      display: flex;
      align-items: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--gray-100);
      padding-left: 8px;
    }
  }
`;
