import React from 'react';
import { SearchStyle } from './styles';

export default function PartnerSearch({ id, label, onChangeSearchParams }) {
  const handleSearchParams = e => {
    if (e.key === 'Enter') {
      onChangeSearchParams(e.target.value);
    }
  };

  return (
    <SearchStyle>
      <div className="form-container">
        <input
          id={id}
          className="form-input"
          placeholder={label}
          onKeyDown={handleSearchParams}
        />
        <span className="icon-button esfera-icon BD290" />
      </div>
    </SearchStyle>
  );
}
