import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import TransactionsItemTable from '../TransactionsItemTable';
import * as S from './styles';
import {
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import Pag from '../../Nucleo/Content/CustomTable/Pag';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export const headTableDataFileItem = [
  {
    title: 'Número Pedido',
    hasIcoSort: false,
  },
  {
    title: 'Campanha',
    hasIcoSort: false,
  },
  {
    title: 'CommerceItemID',
    hasIcoSort: false,
  },
  {
    title: 'CPF/CNPJ',
    hasIcoSort: false,
  },
  {
    title: 'Cliente',
    hasIcoSort: false,
  },
  {
    title: 'Conta',
    hasIcoSort: false,
  },
  {
    title: 'Dígito Conta',
    hasIcoSort: false,
  },
  {
    title: 'Data Criação',
    hasIcoSort: false,
  },
  {
    title: 'Data Atualização',
    hasIcoSort: false,
  },
  {
    title: 'Valor Cashback',
    hasIcoSort: false,
  },
  {
    title: 'Status da Transação',
    hasIcoSort: false,
  },
];

export default function TransactionsItem({
  fileItemId,
  userPermissions,
  setIsRefreshTransaction,
  isRefreshTransaction,
  populateToastMessages,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = `${window.SERVICES_DOMAIN + window.TRANSACTION_ITEM_URL}`;
  const [fileItemData, setFileItemData] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentFilter, setCurrentFilter] = useState({
    pageNumber: 0,
    pageCount: 50,
    fileId: fileItemId,
  });

  const getFileTransactions = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.INCENTIVE_ENGINE.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: currentFilter,
      };

      handlePostRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response.status === 204) {
            const error = 'Não existem transações para este arquivo';
            const errorMessage = formatErrorMessageToToast(error, keycloak);
            populateToastMessages(
              'error',
              'Erro',
              errorMessage.messagePTBR,
              'main',
            );
          } else if (response && response.data) {
            setFileItemData(response.data);
            setCurrentPage(response.data.currentPageNumber);
            setTotalPages(response.data.totalPages);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setFileItemData([]);
          }
        })
        .catch(error => {
          console.log(`Error at list file transactions = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const handlePageChange = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    setCurrentFilter(filter);
    getFileTransactions();
  };

  useEffect(() => {
    getFileTransactions();
    setIsRefreshTransaction(false);
  }, [setIsRefreshTransaction, isRefreshTransaction]);

  return (
    <>
      <S.ContainerTr>
        <S.ContainerTd colSpan={6}>
          {fileItemData && fileItemData.items.length > 0 && (
            <TransactionsItemTable
              headTableData={headTableDataFileItem}
              bodyTableData={fileItemData.items}
            />
          )}
        </S.ContainerTd>
      </S.ContainerTr>
      <S.ContainerTr>
        <S.ContainerTd colSpan={6}>
          {totalPages > 0 ? (
            <Pag
              currentPage={currentPage}
              totalPages={totalPages}
              onClickFnPage={handlePageChange}
            />
          ) : null}
        </S.ContainerTd>
      </S.ContainerTr>
    </>
  );
}
