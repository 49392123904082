import styled, { css } from 'styled-components';
import * as Styled from '../../components/Nucleo/Content/CustomTable/styles';
export const Container = styled.div`
  height: 100vh;
  display: block;
  float: right;
  width: 85%;
  margin-top: 4%;
  padding: 2rem 1rem;
  ${({ theme }) => css`
    padding: 0 16px;
    height: 100%;
    ${Styled.Container} {
      & + div {
        margin-top: 40px;
      }
    }
  `}
  .table-content > main > .loading-content-compass {
    padding-right: 12%;
    padding-top: 7%;
  }
  .table-content > main {
    width: 84%;
  }
`;
export const listCampaignHeader = styled.div`
  .content__header__list__partner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
  }
  .contentmain__header {
    width: 100%;
    align-items: center;
    min-height: 72px;
    border-bottom: 1px solid #dadada;
  }
  .contentmain__header__title {
    display: flex;
    justify-content: space-between;
  }
  .contentmain__header__update {
    display: flex;
    grid-gap: 8px;

    .contentmain__header__icon {
      margin: 0 auto;
      align-self: center;
      font-size: 25px;
    }
    .contentmain__header__text {
      color: #cc0000;
      font-size: 18px;
      font-family: Open Sans;
      text-decoration: underline;
    }
  }
  .contentmain__header__text {
    padding: 16px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
  }
  .contentmain__text-info {
    margin-top: 15px;
    margin-bottom: 45px;
  }
  button {
    border: none;
    background: none;
  }
  .content__title_partner {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
    align-self: center;
    width: 25%;
  }
`;
export const listFilterNavigationHeader = styled.div`
  .ap-content-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    min-height: 530px;
  }
  .ap-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .ap-tab-item {
    padding: 0 16px;
    color: #4d4d4d;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    position: relative;
    padding-bottom: 8px;
    cursor:pointer;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: -4px;
      background: transparent;
    }

    &.active {
      color: #c00;

      &::after {
        background: #c00;
      }
    }
  }
  .ap-tab-count{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #4D4D4D;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
  }
  .ap-tab-item.active {
    color: #c00;
    .ap-tab-count{
      background-color: #c00;
    }
  }
  .tab-visible{
    display:block;
  }
  .tab-visible.notActive{
    display:none;
  }
  .ap-tab-filter {
    display: flex;
    align-items: center;
    gap: 32px;
  }
  .ap-tab-apply {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .ap-tab-apply-filtered {
    background: #f2f2f2;
    padding: 2px 8px;
    color: #262626;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
  }
  .ap-tab-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .ap-tab-actions-btn {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #262626;
    cursor: pointer;
  }
  .esfera-icon {
    font-size: 22px;
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
    vertical-align: middle;
  }
  .BD390:before {
    color: #262626;
  }
  .BD020:before {
    color: #262626;
  }
`;
export const listFilterNavigationBody = styled.div`
  .ap-campaign-info-date {
    font-size: 12px;
    line-height: 16px;
    color: #4d4d4d;
  }
  .ap-campaign-actions {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .ap-campaign-actions-btn {
    all: unset;
  } 
  .esfera-icon {
    font-size: 25px;
    font-weight: 500;
    cursor: pointer;
    color:#CC0000;
  }
  .message-alert{
    display: flex;
    font-size: 14px;
  }
`;
export const listFilterNavigationFooter = styled.div`
.ap-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 50px 0px 30px 0px;
}
.ap-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 64px;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.ap-button-secondary {
  border-color: #c00;
  color: #c00;
  background: #fff;
}
.ap-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #808080;
    color: #262626;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    position: relative;
  }
}
.ap-button-primary {
  border-color: #c00;
  color: #fff;
  background: #c00;
}
.ap-pagination-next, .ap-pagination-prev, .ap-pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #808080;
  color: #262626;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}
.ap-pagination-next.active, .ap-pagination-prev.active, .ap-pagination-item.active {
  border-color: #c00;
  color: #c00;
}
.ap-pagination-next:disabled, .ap-pagination-prev:disabled, .ap-pagination-item:disabled {
  background: #deebf2;
  border-color: #deebf2;
  color: #9bc0d3;
}
`;