import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import * as S from './styles';

export default function CampaingHistoryContent({ listCampaing, permission }) {
  const history = useHistory();
  const { partnerId } = useParams();
  const handleDisableCampaing = selectId => {
    let campaingSelectEdit = [];
    if (listCampaing.content.length) {
      campaingSelectEdit = listCampaing.content.filter(
        campaing => campaing.id === selectId,
      );
    }
    history.push({
      pathname: `/campaign/${partnerId}`,
      state: { campaingSelectEdit, extraParam: 'disable' },
    });
  };
  const handleEditCampaing = selectId => {
    let campaingSelectEdit = [];
    if (listCampaing.content.length) {
      campaingSelectEdit = listCampaing.content.filter(
        campaing => campaing.id === selectId,
      );
    }
    history.push({
      pathname: `/campaign/${partnerId}`,
      state: { campaingSelectEdit, extraParam: 'edit' },
    });
  };
  const handleApprovedCampaing = selectId => {
    let campaingSelectEdit = [];
    if (listCampaing.content.length) {
      campaingSelectEdit = listCampaing.content.filter(
        campaing => campaing.id === selectId,
      );
    }
    history.push({
      pathname: `/campaign/${partnerId}`,
      state: { campaingSelectEdit, extraParam: 'approved' },
    });
  };
  const handleCopyCampaing = selectId => {
    let campaingSelectEdit = [];
    if (listCampaing.content.length) {
      campaingSelectEdit = listCampaing.content.filter(
        campaing => campaing.id === selectId,
      );
    }
    history.push({
      pathname: `/campaign/${partnerId}`,
      state: { campaingSelectEdit, extraParam: 'copy' },
    });
  };
  const handleViewCampaing = selectId => {
    let campaingSelectEdit = [];
    if (listCampaing.content.length) {
      campaingSelectEdit = listCampaing.content.filter(
        campaing => campaing.id === selectId,
      );
    }
    history.push({
      pathname: `/campaign/${partnerId}`,
      state: { campaingSelectEdit, extraParam: 'view' },
    });
  };
  return (
    <S.contentHistoryContent>
      <div className="ap-content-section-campaigns">
        {listCampaing &&
          listCampaing.content &&
          listCampaing.content.map(item => (
            <div className="ap-campaign" key={item.id}>
              <div className="ap-campaign-info" id={item.id}>
                {item.status === 'NEED_APPROVAL' && (
                  <span className="ap-campaign-info-title-pending">
                    Aguardando Aprovação
                  </span>
                )}
                <span className="ap-campaign-info-title">
                  {item.campaignName}
                </span>
                <span className="ap-campaign-info-date">
                  De:{' '}
                  {`${moment(item.dateInit, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                    'DD/MM/YYYY',
                  )}`}{' '}
                  a{' '}
                  {`${moment(item.dateEnd, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                    'DD/MM/YYYY',
                  )}`}
                </span>
              </div>

              {item.status === 'ACTIVE' &&
                permission.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  permission.userGroups,
                ) && (
                  <div className="ap-campaign-actions">
                    <button
                      label="edit"
                      type="button"
                      className="ap-campaign-actions-btn ic_functional_password_show esfera-icon"
                      onClick={() => handleDisableCampaing(item.id)}
                    />
                  </div>
                )}
              {item.status === 'ACTIVE' &&
                !permission.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  permission.userGroups,
                ) && (
                  <div className="ap-campaign-actions">
                    <button
                      label="view"
                      type="button"
                      className="ap-campaign-actions-btn ic_functional_password_show esfera-icon"
                      onClick={() => handleViewCampaing(item.id)}
                    />
                  </div>
                )}
              {item.status === 'NEED_APPROVAL' && (
                <div className="ap-campaign-actions">
                  <button
                    label="aprove"
                    type="button"
                    className="ap-campaign-actions-btn ic_system_arrow_right esfera-icon"
                    onClick={() => handleApprovedCampaing(item.id)}
                  />
                </div>
              )}
              {item.status === 'SCHEDULED' && (
                <div className="ap-campaign-actions">
                  <button
                    label="copy"
                    type="button"
                    className="ap-campaign-actions-btn BC220 esfera-icon"
                    onClick={() => handleCopyCampaing(item.id)}
                  />
                  <button
                    label="edit"
                    type="button"
                    className="ap-campaign-actions-btn BD270 esfera-icon"
                    onClick={() => handleEditCampaing(item.id)}
                  />
                </div>
              )}
              {(item.status === 'FINISHED' || item.status === 'CANCELED') &&
                permission.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  permission.userGroups,
                ) && (
                  <div className="ap-campaign-actions">
                    <button
                      label="copy"
                      type="button"
                      className="ap-campaign-actions-btn BC220 esfera-icon"
                      onClick={() => handleCopyCampaing(item.id)}
                    />
                    <button
                      label="view"
                      type="button"
                      className="ap-campaign-actions-btn ic_functional_password_show esfera-icon"
                      onClick={() => handleViewCampaing(item.id)}
                    />
                  </div>
                )}
              {(item.status === 'FINISHED' || item.status === 'CANCELED') &&
                !permission.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  permission.userGroups,
                ) && (
                  <div className="ap-campaign-actions">
                    <button
                      label="view"
                      type="button"
                      className="ap-campaign-actions-btn ic_functional_password_show esfera-icon"
                      onClick={() => handleViewCampaing(item.id)}
                    />
                  </div>
                )}
            </div>
          ))}
      </div>
    </S.contentHistoryContent>
  );
}
