import React, { useState } from 'react';

import ModalConfirmOrder from '../ModalConfirmOrder';
import ModalCancelOrder from '../ModalCancelOrder';

import confirmedImg from '../../../assets/img/ic-check.png';
import cancelledImg from '../../../assets/img/ic-cancelled.png';

import { StyledTr } from './styles';

export default function OrderItem({
  order,
  userPermissions,
  handleShowMessageAction,
}) {
  const [isOpenModalConfirmOrder, setIsOpenModalConfirmOrder] = useState(false);
  const [isOpenModalCancelOrder, setIsOpenModalCancelOrder] = useState(false);

  const handleShowMdlConfirmOrder = () => setIsOpenModalConfirmOrder(true);
  const handleCloseMdlConfirmOrder = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalConfirmOrder(false);
  };

  const handleShowMdlCancelOrder = () => setIsOpenModalCancelOrder(true);
  const handleCloseMdlCancelOrder = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalCancelOrder(false);
  };

  return (
    <StyledTr>
      <td>{order.orderId}</td>
      <td>{order.document}</td>
      <td>{order.date}</td>
      <td>{order.rules}</td>
      <td>{order.salesforceNumber}</td>
      <td>{order.siteId}</td>
      <td>{order.user}</td>
      <td>
        {(order.status === 'FRAUD_CHECK_APPROVED' && (
          <div className="container-actions">
            <img src={confirmedImg} alt="" />
            <span className="confirm">Confirmado</span>
          </div>
        )) ||
          (order.status === 'FRAUD_CHECK_REFUSED' && (
            <div className="container-actions">
              <img src={cancelledImg} alt="" />
              <span className="cancelled">Cancelado</span>
            </div>
          )) || (
            <div className="container-actions">
              {userPermissions.checkUiPermissionsFn(
                'ANTIFRAUD.ORDERS.UPDATE',
                userPermissions.userGroups,
              ) ? (
                <>
                  <button
                    type="button"
                    className="btn-action"
                    onClick={handleShowMdlConfirmOrder}
                  >
                    <span className="confirm">Confirmar</span>
                  </button>

                  <button
                    type="button"
                    className="btn-action"
                    onClick={handleShowMdlCancelOrder}
                  >
                    <span className="cancelled">Cancelar</span>
                  </button>
                </>
              ) : null}
            </div>
          )}
      </td>

      <ModalConfirmOrder
        order={order}
        isOpenModal={isOpenModalConfirmOrder}
        handleCloseModal={handleCloseMdlConfirmOrder}
        userPermissions={userPermissions}
      />

      <ModalCancelOrder
        order={order}
        isOpenModal={isOpenModalCancelOrder}
        handleCloseModal={handleCloseMdlCancelOrder}
        userPermissions={userPermissions}
      />
    </StyledTr>
  );
}
