import styled from 'styled-components';

export const StyledForm = styled.div`
  margin-top: 1.5rem;
  display: flex;
  width: 880px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &.hidden {
    display: none;
  }
`;

export const ContainerAccordion = styled.div`
  width: 432px;
  margin: 16px 0;
`
