import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import Button from '../../Button';
import { StyledModal } from './styles';

export default function CancelParity({
  method,
  parityId,
  handleCloseModal,
  isOpenModal,
  handleDeleteParity,
}) {
  const handleSubmit = e => {
    e.preventDefault();
  };

  const handleDisableParity = () => {
    if (method === 'delete') {
      handleDeleteParity(parityId);
      handleCloseModal();
    }
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Excluir paridade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <p>Tem certeza que deseja excluir esta paridade cadastrada?</p>
          <div className="container-buttons">
            <Button typeOfButton="secondary" onClick={handleCloseModal}>
              Cancelar
            </Button>
            <Button type="submit" onClick={handleDisableParity}>
              Excluir
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  );
}
