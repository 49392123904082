import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Button from '../../Button';
import Loading from '../../Loading';

import { StyledModal } from './styles';
import {
  handlePutRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalCancelOrder({
  order,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.CANCEL_ORDER_URL;
  const [showLoading, setShowLoading] = useState(false);

  const handleCancelOrder = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ANTIFRAUD.ORDERS.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'PUT',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };

      handlePutRequest(
        `${FULL_URL}/${order.siteId}/${order.orderId}`,
        reqOptions,
      )
        .then(() => {
          setShowLoading(false);
          handleCloseModal(
            'success',
            'Sucesso!',
            `Pedido cancelado com sucesso.`,
          );
        })
        .catch(error => {
          setShowLoading(false);
          console.error('Error at cancelOrder request', error);
          console.error(`Error at cancelOrder request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    } else {
      handleCloseModal();
    }
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Cancelamento</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div>
          <p>Deseja realmente cancelar o pedido abaixo: </p>
          <span>
            <strong>Pedido: </strong>
            {order.orderId}
          </span>
        </div>

        <div className="container-buttons">
          <Button typeOfButton="secondary" onClick={handleCancelOrder}>
            Sim
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Não
          </Button>
        </div>
      </StyledModal.Body>
    </StyledModal>
  );
}
