import styled from 'styled-components';

import { FormCheck } from 'react-bootstrap';

export const StyledTr = styled.tr`

  background: ${props => props.isInactive ? "var(--gray-500)" : 'var(--white)'};

  .rules-number {
    display: flex;
    align-items: center;

    > img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  .status {
    text-transform: uppercase;
    font-weight: 700;

    &.inactive {
      color: var(--gray-200);
    }

    &.barred {
      color: var(--boston);
    }

    &.review {
      color: var(--gray-50);
    }
  }

  .container-actions {
    display: flex;
    align-items: center;

    > svg {
      cursor: pointer;
      font-size: 18px;
    }
  }
` ;

export const StyledSwitch = styled(FormCheck)`
    margin-left: 10px;

    .custom-control-input:checked~.custom-control-label::before {
      background: var(--boston);
      border-color: var(--boston);
    }
`;
