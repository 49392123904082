import React from 'react';
import { Container } from './styles';

export default function Button({
  children,
  typeOfButton,
  type,
  onClick,
  disabled,
}) {
  return (
    <Container
      type={type}
      typeOfButton={typeOfButton}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Container>
  );
}
