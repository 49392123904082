/* eslint-disable import/no-anonymous-default-export */

export default {
  fonts: {
    family: {
      roboto: 'Roboto',
      poppins: 'Poppins',
      opensans: 'Open Sans'
    },
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,

    textSize: {
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xlarge: '20px',
      xxlarge: '24px',
    },
    titleSize: {
      xsmall: '16px',
      small: '20px',
      medium: '24px',
      large: '32px',
      xlarge: '36px',
      xxlarge: '48px',
    }
  },

  colors: {
    santander: '#EC0000',
    boston: '#CC0000',
    ruby: '#990000',
    sky: '#DEEDF2',
    mediumSky: '#CEDEE7',
    darkSky: '#9BC3D3',
    lighterSky: '#F5F9FB',
    white: '#FFFFFF',
    lighterGrey: '#F0F0F0',
    lightGrey: '#CCCCCC',
    mediumGrey: '#767676',
    darkGrey: '#444444',
    darkerGrey: '#222222',
    black: '#000000',
    yellow: '#ffcc33',
    green: '#63ba68',
    turquoise: '#1bb3bc',
    blue: '#3366ff',
    purple: '#9e3667',
    suportDarkSuccess: '#3A8340',
    suportLightSuccess: '#F0F8F0',
    suportDarkError: '#A63449',
    suportLightError: '#FCDBE1',
    suportDarkWarning: '#BD6402',
    suportLightWarning: '#FEF2E5',

  },
  // São as camadas que serão utilizada no z-index
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },

  breakpoints: {
    mobile: '576px',
    tablet: '768px',
    desktop: '992px',
  }
}






