import React, { useEffect, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import CustomTable from '../../components/Nucleo/Content/CustomTable';
import * as S from '../../components/Nucleo/Layout/styles';
import {
  handleGetRequest,
  initialPaginationData,
} from '../../services/GenericMethods';
import constants from '../../config';
import Content from '../../components/Nucleo/Content';

// mocks
const linkDataTable1 = {
  title: 'Analisar pedidos',
  link: '/orders',
};
const linkDataTable2 = {
  title: 'Gerenciar regras',
  link: '/rules',
};

const headTableData = [
  {
    title: 'Pedido',
    code: 'orderId',
    sort: false,
    fn: 'getOrders',
    hasIcoSort: true,
  },
  {
    title: 'CPF/CNPJ',
    code: 'customerId',
    sort: false,
    fn: 'getOrders',
    hasIcoSort: true,
  },
  {
    title: 'Cliente',
    code: 'attendentUser',
    sort: false,
    fn: 'getOrders',
    hasIcoSort: true,
  },
  {
    title: 'Data da transação',
    code: 'createDate',
    sort: false,
    fn: 'getOrders',
    hasIcoSort: true,
  },
  {
    title: 'Regras Ativadas',
    hasIcoSort: false,
  },
  {
    title: 'Chamado Sales Force',
    hasIcoSort: false,
  },
];

// mock table2
const titleTable2 = 'Regras';
const headTableData2 = [
  {
    title: 'Código',
    code: 'id',
    sort: false,
    fn: 'getRules',
    hasIcoSort: true,
  },
  {
    title: 'Usuário',
    code: 'user',
    sort: false,
    fn: 'getRules',
    hasIcoSort: true,
  },
  {
    title: 'Cadastro',
    code: 'createDate',
    sort: false,
    fn: 'getRules',
    hasIcoSort: true,
  },
  {
    title: 'Atualização',
    code: 'updateDate',
    sort: false,
    fn: 'getRules',
    hasIcoSort: true,
  },
  {
    title: 'Critério',
    code: 'action',
    sort: false,
    fn: 'getRules',
    hasIcoSort: true,
  },
];

const linkTable2 = {
  path: '/rules',
  textLink: 'Gerenciar regras',
};

export default function Prevention({ userPermissions }) {
  const { keycloak, initialized } = useKeycloak();
  const [user, setUser] = useState(false);
  console.log(user);

  const FULL_URL_RULES = window.SERVICES_DOMAIN + window.ANTIFRAUD_RULES_URL;
  const [rulesFormatted, setRulesFormatted] = useState([]);
  const [currentPageRules, setCurrentPageRules] = useState();
  const [totalPagesRules, setTotalPagesRules] = useState(0);
  const [isFirstPageRules, setIsFirstPageRules] = useState(false);
  const [isLastPageRules, setIsLastPageRules] = useState(false);
  const [sortRules, setSortRules] = useState({ field: null, order: 'asc' });

  const [ordersFormatted, setOrdersFormatted] = useState([]);
  const FULL_URL = window.SERVICES_DOMAIN + window.LIST_ORDERS_URL;
  const options = initialPaginationData('GET', 0, keycloak.token);
  const [actualDate, setActualDate] = useState(moment());
  const [getOrderExecuted, setGetOrderExecuted] = useState(moment());
  const [lastGetOrderExecuted, setLastGetOrderExecuted] = useState(0);
  const [sortOrders, setSortOrders] = useState({ field: null, order: 'asc' });
  const [resumeTableData, setResumeTableData] = useState([
    {
      title: 'Pendentes',
      resultNumber: 0,
      classStyle: 'primaryType',
    },
  ]);

  const handleLogout = () => {
    keycloak.logout();
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak
        .loadUserInfo()
        .then(userInfo => {
          setUser(userInfo);
        })
        .catch(error => {
          console.error(`Error keycloak ${error}`);
          handleLogout();
        });
    }
  }, [keycloak.authenticated, initialized]);

  const getOrders = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ANTIFRAUD.ORDERS.READ',
        userPermissions.userGroups,
      )
    ) {
      const params = inputParams;
      params.params.preview = true;
      params.params.pageCount = 5;

      setOrdersFormatted([]);
      handleGetRequest(FULL_URL, params)
        .then(response => {
          setGetOrderExecuted(moment());
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            setResumeTableData([
              {
                title: 'Pendentes',
                resultNumber: response.data.totalElements,
                classStyle: 'primaryType',
              },
            ]);
            const orders = response.data.content;
            setOrdersFormatted(
              orders.map(order => {
                const { sentinelResponse } = order;

                return {
                  code: order.orderId ? order.orderId : '-',
                  rulesActived:
                    sentinelResponse.rules.length > 0
                      ? sentinelResponse.rules.toString()
                      : '-',
                  userName: order.username ? order.username : '-',
                  document: order.customerId ? order.customerId : '-',
                  createdAt: moment(order.createDate).format(
                    'DD/MM/YYYY - hh:mm:ss',
                  ),
                  idSalesForce: order.salesforceNumber
                    ? order.salesforceNumber
                    : '-',
                };
              }),
            );
          } else {
            setOrdersFormatted([]);
          }
          console.warn(`Response listOrders = ${response}`);
        })
        .catch(error => {
          console.error(`Error at listOrders request = ${error}`);
        });
    }
  };

  const getRules = (actualPage, sortParams) => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ANTIFRAUD.RULES.READ',
        userPermissions.userGroups,
      )
    ) {
      const optionsRequest = initialPaginationData(
        'GET',
        actualPage,
        keycloak.token,
      );
      if (sortParams) {
        optionsRequest.params.sort = sortParams;
      }
      optionsRequest.params.pageCount = 5;

      setRulesFormatted([]);
      handleGetRequest(FULL_URL_RULES, optionsRequest)
        .then(response => {
          const responseFormatted = response.data.content.map(res => {
            let ruleConfig;
            if (res.action === 'FRAUD_CHECK') {
              ruleConfig = 'review';
            } else if (res.action === 'REFUSED') {
              ruleConfig = 'barred';
            } else {
              ruleConfig = 'inactive';
            }

            return {
              code: res.id,
              userName: res.user,
              createdAt: moment(res.createDate).format('DD/MM/YYYY - hh:mm:ss'),
              updatedAt: moment(res.updateDate).format('DD/MM/YYYY - hh:mm:ss'),
              statusClassName: ruleConfig,
              status: constants[res.action],
            };
          });

          setRulesFormatted(responseFormatted);
          setCurrentPageRules(response.data.number);
          setTotalPagesRules(response.data.totalPages);
          setIsFirstPageRules(response.data.first);
          setIsLastPageRules(response.data.last);
        })
        .catch(error => {
          console.error(`Error at listRules request = ${error}`);
        });
    }
  };

  useEffect(() => {
    getOrders(options);
    getRules(0);
  }, []);

  const calculateLastGetOrder = () => {
    setTimeout(() => {
      setActualDate(moment());
      const duration = moment.duration(getOrderExecuted.diff(actualDate));
      setLastGetOrderExecuted(Math.abs(parseInt(duration.asMinutes(), 10)));
    }, 60000);
  };

  const setSortOrder = sortParams => {
    if (!sortParams) return;

    if (sortParams.fn === 'getOrders') {
      let sortProp = false;
      if (sortOrders.field === null || sortOrders.field !== sortParams.code) {
        setSortOrders({ field: sortParams.code, order: sortOrders.order });
        sortProp = `${sortParams.code},${sortOrders.order}`;
      } else if (sortOrders.field === sortParams.code) {
        if (sortOrders.order === 'asc') {
          setSortOrders({ field: sortParams.code, order: 'desc' });
          sortProp = `${sortParams.code},desc`;
        } else {
          setSortOrders({ field: sortParams.code, order: 'asc' });
          sortProp = `${sortParams.code},asc`;
        }
      }

      options.params.sort = sortProp;
      getOrders(options);
    } else {
      let sortProp = false;
      if (sortRules.field === null || sortRules.field !== sortParams.code) {
        setSortRules({ field: sortParams.code, order: sortOrders.order });
        sortProp = `${sortParams.code},${sortRules.order}`;
      } else if (sortRules.field === sortParams.code) {
        if (sortRules.order === 'asc') {
          setSortRules({ field: sortParams.code, order: 'desc' });
          sortProp = `${sortParams.code},desc`;
        } else {
          setSortRules({ field: sortParams.code, order: 'asc' });
          sortProp = `${sortParams.code},asc`;
        }
      }

      getRules(0, sortProp);
    }
  };

  useEffect(() => {
    calculateLastGetOrder();
  }, [actualDate]);

  return (
    <Content>
      <S.Container>
        <div className="contentmain__header">
          <text className="contentmain__header__text">Prevenção</text>
          <span className="contentmain__header__icon icoArrow esfera-icon BE070" />
          <text className="contentmain__header__text contentmain__header__text__active">
            Regras Antifraude
          </text>
        </div>
        <div className="contentmain__lastupdate">
          <text className="contentmain__lastupdate__text">
            Última atualização: há {lastGetOrderExecuted} minutos
          </text>
        </div>

        {userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.ORDERS.READ',
          userPermissions.userGroups,
        ) ? (
          <CustomTable
            msgDefaultNoData="No momento não há nenhum pedido pendente de análise."
            linkData={linkDataTable1}
            resumeTableData={resumeTableData}
            headTableData={headTableData}
            bodyTableData={ordersFormatted}
            userPermissions={userPermissions}
            sortFunction={setSortOrder}
          />
        ) : null}

        {userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.RULES.READ',
          userPermissions.userGroups,
        ) ? (
          <CustomTable
            msgDefaultNoData="No momento não há regras cadastradas."
            linkData={linkDataTable2}
            headTableData={headTableData2}
            titleTable={titleTable2}
            bodyTableData={rulesFormatted}
            hasPagination
            linkTable={linkTable2}
            userPermissions={userPermissions}
            currentPage={currentPageRules}
            totalPages={totalPagesRules}
            isFirstPage={isFirstPageRules}
            isLastPage={isLastPageRules}
            onClickFnPage={getRules}
            sortFunction={setSortOrder}
          />
        ) : null}
      </S.Container>
    </Content>
  );
}
