import styled from 'styled-components';

export const FilterStyle = styled.div`
    self-align: center;
    position: relative;

    .BE090 {
        position: absolute;
        right: 20px;
        top: 35%;
        font-size: 20px;
        cursor: pointer;
    }

    .content__filter__partners {
        span {
            font-size: 18px;
            font-weight: 700;
            margin-right: 15px;
        }
        select {
            appearance: none;
            height: 56px;
            border: 1px solid #cccccc;
            font-family: 'Open Sans';
            font-size: 16px;
            font-weight: 600;
            border-radius: 10px;
            padding-left: 15px;
            width: 320px;
            
        }
    }
    .content__filter__partners::after {
        content: "";
        color: #c00;
    }

`;