import React, { useState } from 'react';
import moment from 'moment';

import constants from '../../../config';

import ModalApproveOrder from '../ModalApproveOrder';
import ModalRefusedOrder from '../ModalRefusedOrder';

import confirmedImg from '../../../assets/img/ic-check.png';
import cancelledImg from '../../../assets/img/ic-cancelled.png';

import { StyledTr, WrapperButton } from './styles';

export default function EsferaItem({
  order,
  userPermissions,
  handleShowMessageAction,
}) {
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [isOpenModalRefused, setIsOpenModalRefused] = useState(false);

  const handleShowMdlConfirmOrder = event => {
    event.stopPropagation();
    setIsOpenModalConfirm(true);
  };

  const handleCloseMdlConfirmOrder = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalConfirm(false);
  };

  const handleShowMdlRefusedOrder = event => {
    event.stopPropagation();
    setIsOpenModalRefused(true);
  };

  const handleCloseMdlRefusedOrder = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalRefused(false);
  };

  return (
    <>
      <StyledTr>
        <td>{order.orderId}</td>
        <td>{order.documentId}</td>
        <td>{moment(order.orderSubmitedDate).format('DD/MM/YYYY HH:mm')}</td>
        <td>{moment(order.campaignDate).format('DD/MM/YYYY HH:mm')}</td>
        <td>{order.product}</td>
        <td>
          <span>{order.value}</span>
        </td>

        <td>
          {(order.status === constants.OCC_STATUS_LABEL.APPROVED && (
            <WrapperButton>
              <img src={confirmedImg} alt="" />
              <span className="confirm">Confirmado</span>
            </WrapperButton>
          )) ||
            (order.status === constants.OCC_STATUS_LABEL.REFUSED && (
              <WrapperButton>
                <img src={cancelledImg} alt="" />
                <span className="recused">Recusado</span>
              </WrapperButton>
            )) || (
              <WrapperButton>
                {userPermissions.checkUiPermissionsFn(
                  'ESFERA.MANUAL_TRANSFER_ORDERS.UPDATE',
                  userPermissions.userGroups,
                ) ? (
                  <>
                    <button
                      type="button"
                      className="btn-action"
                      onClick={handleShowMdlConfirmOrder}
                    >
                      <span className="confirm">Confirmar</span>
                    </button>

                    <button
                      type="button"
                      className="btn-action"
                      onClick={handleShowMdlRefusedOrder}
                    >
                      <span className="recused">Recusar</span>
                    </button>
                  </>
                ) : null}
              </WrapperButton>
            )}
        </td>
      </StyledTr>

      <ModalApproveOrder
        order={order}
        isOpenModal={isOpenModalConfirm}
        handleCloseModal={handleCloseMdlConfirmOrder}
        userPermissions={userPermissions}
      />

      <ModalRefusedOrder
        order={order}
        isOpenModal={isOpenModalRefused}
        handleCloseModal={handleCloseMdlRefusedOrder}
        userPermissions={userPermissions}
      />
    </>
  );
}
