import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Form, Modal } from 'react-bootstrap';
import Button from '../../Button';
import { StyledModal } from './styles';
import {
  handleDeleteRequest,
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function CancelCampaign({
  method,
  campaignId,
  handleCloseModal,
  isOpenModal,
  setIsModifiedFuture,
  setIsRefreshHistory,
  userPermissions,
  setIsSwitchOn,
  populateToastMessages,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.AIRCAMPAIGN_URL;

  const disableCampaign = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
      };
      handlePostRequest(`${FULL_URL}/${campaignId}/disable`, reqOptions)
        .then(response => {
          if (response && response.data) {
            handleCloseModal();
            setIsSwitchOn(false);
            setIsModifiedFuture(true);
            setIsRefreshHistory(true);
            populateToastMessages(
              'success',
              'Sucesso!',
              'Campanha desativada com sucesso!',
              'main',
            );
          }
        })
        .catch(error => {
          console.warn(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const deleteCampaign = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.DELETE',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'DELETE',
        headers: initialHeadersData(keycloak.token),
      };
      handleDeleteRequest(`${FULL_URL}/${campaignId}`, reqOptions)
        .then(response => {
          if (response && response.data) {
            handleCloseModal();
            setIsModifiedFuture(true);
            setIsRefreshHistory(true);
            populateToastMessages(
              'success',
              'Sucesso!',
              'Campanha removida com sucesso!',
              'main',
            );
          }
        })
        .catch(error => {
          console.warn(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  const handleDisableCampaign = () => {
    if (method === 'disable') {
      disableCampaign(campaignId);
    } else if (method === 'delete') {
      deleteCampaign(campaignId);
    }
  };
  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {method === 'disable' ? 'Desativação' : 'Exclusão'} de campanha
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <p>
            Você está prestes a {method === 'disable' ? 'desativar' : 'excluir'}{' '}
            esta campanha. Deseja continuar?
          </p>
          <div className="container-buttons">
            <Button
              type="submit"
              typeOfButton="secondary"
              onClick={handleCloseModal}
            >
              Não quero {method === 'disable' ? 'cancelar' : 'excluir'}
            </Button>
            <Button typeOfButton="primary" onClick={handleDisableCampaign}>
              {method === 'disable' ? 'Desativar' : 'Excluir'} campanha
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  );
}
