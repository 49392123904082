import React from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../Button';

import { StyledModal } from './styles';

export default function ModalError({ isOpenModal, handleCloseModal }) {
  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>Erro</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <div className="container-buttons">
            <Button
              type="button"
              typeOfButton="primary"
              onClick={handleCloseModal}
            >
              Retornar
            </Button>
          </div>
        </Form>
      </StyledModal.Body>
    </StyledModal>
  );
}
