import styled from 'styled-components';

export const SCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    position: relative;
    cursor: pointer;
    visibility: hidden;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    margin-bottom: 22px;
    z-index: 1;
  }

  input:before {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid #cc0000;
    border-radius: 4px;
    visibility: visible;
  }

  input:checked:before {
    content: '';
    background-color: #cc0000;
  }

  input:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    left: 9px;
    visibility: visible;
  }

  input:disabled {
    cursor: not-allowed;
  }

  input:checked + label,
  input:not(:checked) + label {
    position: relative;
  }

  label {
    margin-bottom: 0px;
  }

  .checkbox__text {
    font-size: 16px;
    padding-left: 24px;
  }

  .esfera-icon:before {
    font-size: 20px;
  }

  input[type='checkbox'] {
    border-color: ${({ disabled }) => (disabled ? '#808080' : '#ccc')};

    &:checked:disabled {
      border-color: #808080;
    }
  }
`;
