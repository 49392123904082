import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import {
  handleGetRequest,
  handlePutRequest,
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import iconArrow from '../../../assets/iconArrow.png';
import BoxOption from '../../BoxOption';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import chevronLeft from '../../../assets/airCampaign/chevron-left.svg';
import trash from '../../../assets/airCampaign/trash.svg';
import {
  InputName,
  StyledModal,
  DivCheckBox,
  AddCupom,
  DivCodigosCampanha,
  DivCupom,
  CupomText,
} from './styles';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function EditCampaign({
  campaignId,
  campaignSeller,
  campaignName,
  campaignStartDate,
  campaignStartTime,
  campaignEndDate,
  campaignEndTime,
  campaingMilesCampaignRules,
  handleCloseModal,
  isOpenModal,
  setIsModifiedFuture,
  setIsModifiedAirCampaign,
  userPermissions,
  populateToastMessages,
}) {
  const { keycloak } = useKeycloak();
  let FULL_URL = `${window.SERVICES_DOMAIN + window.AIRCAMPAIGN_URL}`;
  const FULL_CLUB_URL = `${window.SERVICES_DOMAIN + window.CLUB_TYPES}`;
  const handleSubmit = e => {
    e.preventDefault();
  };
  if (campaignId) {
    FULL_URL = `${
      window.SERVICES_DOMAIN + window.AIRCAMPAIGN_URL
    }/${campaignId}`;
  }

  const nameSeller = sellerId => {
    switch (sellerId) {
      case 'dsml':
        return 'Smiles';
      case 'dazl':
        return 'Tudo Azul';
      case 'dmtl':
        return 'Latam';
      default:
        return 'Nenhum parceiro selecionado';
    }
  };
  const idSeller = sellerName => {
    switch (sellerName) {
      case 'Smiles':
        return 'dsml';
      case 'Tudo Azul':
        return 'dazl';
      case 'Latam':
        return 'dmtl';
      default:
        return null;
    }
  };
  const formatParticipatingClubsToArray = value => {
    if (!value) return [];
    const participatingClubsFormatted = value.map(object => {
      return {
        ...object,
        participatingClubs: object.participatingClubs.split(','),
        id: !campaignId ? undefined : object.id,
      };
    });

    return participatingClubsFormatted;
  };
  const [openOptions, setOpenOptions] = useState(false);
  const [inputName, setInputName] = useState(nameSeller(campaignSeller || ''));
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [clubTypesData, setClubTypesData] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [selectedClubsList, setSelectedClubsList] = useState(
    formatParticipatingClubsToArray(campaingMilesCampaignRules) || [],
  );
  const [step2FieldsHasErrors, setStep2FieldsHasErrors] = useState(false);
  const [sellerIdSelected, setSellerIdSelected] = useState(
    campaignSeller || '',
  );
  const [valueCampaignName, setValueCampaignName] = useState(
    campaignName || '',
  );
  const [valueCampaignStartDate, setValueCampaignStartDate] = useState(
    campaignStartDate || '',
  );
  const [valueCampaignStartTime, setValueCampaignStartTime] = useState(
    campaignStartTime || '',
  );
  const [valueCampaignEndDate, setValueCampaignEndDate] = useState(
    campaignEndDate || '',
  );
  const [valueCampaignEndTime, setValueCampaignEndTime] = useState(
    campaignEndTime || '',
  );
  const [valuePromotionCode, setValuePromotionCode] = useState('');
  const [step, setStep] = useState(1);
  const defaultFields = {
    campaignSeller: {
      isValid: !!campaignId,
      isRequired: true,
      minLength: 1,
    },
    valueCampaignName: {
      isValid: !!campaignId,
      isRequired: true,
      minLength: 1,
    },
    valueCampaignStartDate: {
      isValid: !!campaignId,
      isRequired: true,
      isValidDate: null,
      isValidInterval: null,
      minLength: 1,
    },
    valueCampaignStartTime: {
      isValid: !!campaignId,
      isRequired: true,
      isValidDate: null,
      isValidInterval: null,
      minLength: 1,
    },
    valueCampaignEndDate: {
      isValid: !!campaignId,
      isRequired: true,
      isValidDate: true,
      isValidInterval: true,
      minLength: 1,
    },
    valueCampaignEndTime: {
      isValid: !!campaignId,
      isRequired: true,
      isValidDate: true,
      isValidInterval: true,
      minLength: 1,
    },
  };

  const [validationFields, setValidationFields] = useState(defaultFields);
  const refreshValidateFields = (
    objectName,
    isValidDate,
    isValidInterval,
    isRequired,
    isValid,
  ) => {
    setValidationFields(prevState => ({
      ...prevState,
      [objectName]: {
        ...prevState[objectName],
        isValidDate,
        isValidInterval,
        isRequired,
        isValid,
      },
    }));
  };

  const getPermissionTypeRequest = () => {
    if (campaignId) return 'ESFERA.MILES_CAMPAIGN.UPDATE';
    return 'ESFERA.MILES_CAMPAIGN.CREATE';
  };

  const verifyClubsAlreadySelected = (
    response,
    formattedParticipatingClubsToarray,
  ) => {
    if (!response.data.messages) return [];
    const clubs = response.data.messages;
    const selectedList = campaignId
      ? selectedClubsList
      : formattedParticipatingClubsToarray;
    selectedList.forEach(alreadySelectedClubs => {
      alreadySelectedClubs.participatingClubs.forEach(item => {
        const index = clubs.indexOf(item);
        if (index !== -1) {
          clubs.splice(index, 1);
        }
      });
    });
    return clubs;
  };

  const getClubTypes = formattedParticipatingClubsToarray => {
    if (
      userPermissions.checkUiPermissionsFn(
        getPermissionTypeRequest(),
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };

      handleGetRequest(FULL_CLUB_URL, reqOptions)
        .then(response => {
          if (response) {
            setClubTypesData(
              verifyClubsAlreadySelected(
                response,
                formattedParticipatingClubsToarray,
              ),
            );
          }
        })
        .catch(error => {
          console.warn(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal();
          setStep(1);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const getLastParticipatingClubs = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        getPermissionTypeRequest(),
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };
      const FULL_URL_HISTORY = `${
        window.SERVICES_DOMAIN + window.AIRCAMPAIGN_URL
      }/seller/${sellerIdSelected}/history?page=0&size=1&sort=endDate,DESC`;
      handleGetRequest(FULL_URL_HISTORY, reqOptions)
        .then(response => {
          if (
            response &&
            response.data &&
            response.data.content &&
            response.data.content[0] &&
            response.data.content[0].milesCampaignRules !== undefined &&
            response.data.content[0].milesCampaignRules !== null &&
            response.data.content[0].milesCampaignRules !== ''
          ) {
            const formattedParticipatingClubsToarray =
              formatParticipatingClubsToArray(
                response.data.content[0].milesCampaignRules,
              );
            setSelectedClubsList(formattedParticipatingClubsToarray);

            getClubTypes(formattedParticipatingClubsToarray);
          } else {
            getClubTypes([]);
          }
        })
        .catch(error => {
          console.error(
            `Error at list history promotionCodes request = ${error}`,
          );
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal();
          setStep(1);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  function validateFieldCampaignName() {
    const isValidInterval = undefined;
    const isValidDate = undefined;
    let isRequired = false;
    let isValid = true;
    if (valueCampaignName.length > 1) {
      isValid = true;
      isRequired = false;
    } else {
      isValid = true;
      isRequired = true;
    }
    refreshValidateFields(
      'valueCampaignName',
      isValidDate,
      isValidInterval,
      isRequired,
      isValid,
    );
  }
  function validateFieldSeller() {
    const isValidInterval = undefined;
    const isValidDate = undefined;
    let isRequired = false;
    let isValid = true;
    if (inputName !== 'Nenhum parceiro selecionado') {
      isRequired = false;
      isValid = true;
    } else {
      isRequired = true;
      isValid = false;
    }
    refreshValidateFields(
      'campaignSeller',
      isValidDate,
      isValidInterval,
      isRequired,
      isValid,
    );
  }

  function validateFieldStartDate() {
    let isValidInterval = true;
    let isValidDate = true;
    const isRequired = !valueCampaignStartDate;
    const isValid = !!(
      valueCampaignStartDate && valueCampaignStartDate.length > 1
    );
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const inputDate = moment(valueCampaignStartDate).format('YYYY-MM-DD');
    if (valueCampaignStartDate) {
      isValidDate = moment(inputDate).isSameOrAfter(currentDate);
      if (valueCampaignEndDate) {
        isValidInterval =
          moment(inputDate).isSameOrBefore(valueCampaignEndDate);
      }
    }
    refreshValidateFields(
      'valueCampaignStartDate',
      isValidDate,
      isValidInterval,
      isRequired,
      isValid,
    );
  }

  function validateFieldStartTime() {
    const isValidInterval = true;
    let isValidDate = true;
    const isRequired = !valueCampaignStartTime;
    const isValid = !!(
      valueCampaignStartTime && valueCampaignStartTime.length > 1
    );
    const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm');
    if (valueCampaignStartDate && valueCampaignStartTime) {
      const concateDate = moment(
        `${valueCampaignStartDate} ${valueCampaignStartTime}`,
      ).format('YYYY-MM-DD HH:mm');
      isValidDate = moment(concateDate).isSameOrAfter(currentDate);
    }
    refreshValidateFields(
      'valueCampaignStartTime',
      isValidDate,
      isValidInterval,
      isRequired,
      isValid,
    );
  }

  function validateFieldEndDate() {
    const isValidInterval = true;
    let isValidDate = true;
    const isRequired = !valueCampaignEndDate;
    const isValid = !!(valueCampaignEndDate && valueCampaignEndDate.length > 1);
    if (valueCampaignEndDate) {
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      const inputDate = moment(valueCampaignEndDate).format('YYYY-MM-DD');
      isValidDate = moment(inputDate).isSameOrAfter(currentDate);
      if (valueCampaignStartDate) {
        isValidDate =
          isValidDate &&
          moment(inputDate).isSameOrAfter(valueCampaignStartDate);
      }
    }
    refreshValidateFields(
      'valueCampaignEndDate',
      isValidDate,
      isValidInterval,
      isRequired,
      isValid,
    );
  }

  function validateFieldEndTime() {
    const isValidInterval = true;
    let isValidDate = true;
    const isRequired = !valueCampaignEndTime;
    const isValid = !!(valueCampaignEndTime && valueCampaignEndTime.length > 1);
    const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm');
    let startDate = null;
    if (valueCampaignEndDate && valueCampaignStartTime) {
      const concateDate = moment(
        `${valueCampaignEndDate} ${valueCampaignEndTime}`,
      ).format('YYYY-MM-DD HH:mm');

      if (valueCampaignStartDate && valueCampaignStartTime) {
        startDate = moment(
          `${valueCampaignStartDate} ${valueCampaignStartTime}`,
        ).format('YYYY-MM-DD HH:mm');
      }
      if (moment(concateDate).isBefore(currentDate)) {
        isValidDate =
          moment(concateDate).isSameOrAfter(currentDate) &&
          moment(concateDate).isAfter(startDate);
      } else {
        isValidDate = moment(concateDate).isAfter(startDate);
      }
    }
    refreshValidateFields(
      'valueCampaignEndTime',
      isValidDate,
      isValidInterval,
      isRequired,
      isValid,
    );
  }

  useEffect(() => {
    validateFieldSeller();
    validateFieldCampaignName();
    validateFieldStartDate();
    validateFieldStartTime();
    validateFieldEndDate();
    validateFieldEndTime();
  }, [
    sellerIdSelected,
    valueCampaignName,
    valueCampaignEndDate,
    valueCampaignEndTime,
    valueCampaignStartDate,
    valueCampaignStartTime,
  ]);

  const validatePromotionCodeText = event => {
    const promotionCodeAlreadyInUse = selectedClubsList.some(
      item => item.promotionCode === event.target.value,
    );
    if (promotionCodeAlreadyInUse) setStep2FieldsHasErrors(true);

    const { value } = event.target;

    if (value) {
      setValuePromotionCode(value);
    }
  };

  const handleResetForm = () => {
    setInputName('Nenhum parceiro selecionado');
    setStep(1);
    setSellerIdSelected('');
    setValueCampaignName('');
    setValueCampaignStartDate('');
    setValueCampaignStartTime('');
    setValueCampaignEndDate('');
    setValueCampaignEndTime('');
    setValuePromotionCode('');
    setIsDisabledSubmit(true);
    setSelectedClubsList([]);
    setSelectedClubs([]);
    setValidationFields(defaultFields);
  };
  const handleCampaignName = event => {
    event.preventDefault();
    setValueCampaignName(event.target.value);
  };
  const handleCampaignStartDate = event => {
    event.preventDefault();
    setValueCampaignStartDate(event.target.value);
  };
  const handleCampaignStartTime = event => {
    event.preventDefault();
    setValueCampaignStartTime(event.target.value);
  };
  const handleCampaignEndDate = event => {
    event.preventDefault();
    setValueCampaignEndDate(event.target.value);
  };
  const handleCampaignEndTime = event => {
    event.preventDefault();
    setValueCampaignEndTime(event.target.value);
  };
  const handlePromotionCode = event => {
    event.preventDefault();
    if (selectedClubs && event.target.value) setStep2FieldsHasErrors(false);
    validatePromotionCodeText(event);
  };
  const changeName = name => {
    setInputName(name);
    setSellerIdSelected(idSeller(name));
    setOpenOptions(!openOptions);
  };
  const formatParticipatingClubsToRequest = value => {
    const participatingClubsFormatted = value.map(object => {
      return {
        ...object,
        participatingClubs: object.participatingClubs.join(','),
      };
    });
    return participatingClubsFormatted;
  };
  const handleContinueModal = () => {
    if (!campaignId) getLastParticipatingClubs();
    else getClubTypes();

    setStep(2);
  };
  const handleReturnModal = () => {
    setStep(1);
    setSelectedClubs([]);
    if (!campaignId) setSelectedClubsList([]);
  };
  const handlePostForm = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.CREATE',
        userPermissions.userGroups,
      )
    ) {
      const formRequest = {
        campaignName: valueCampaignName,
        startDate: `${valueCampaignStartDate}T${valueCampaignStartTime}`,
        endDate: `${valueCampaignEndDate}T${valueCampaignEndTime}`,
        seller: sellerIdSelected,
        milesCampaignRules:
          formatParticipatingClubsToRequest(selectedClubsList),
      };
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: formRequest,
      };
      handlePostRequest(`${FULL_URL}`, reqOptions)
        .then(response => {
          if (response && response.data) {
            setIsModifiedAirCampaign(true);
            handleCloseModal();
            handleResetForm();
            populateToastMessages(
              'success',
              'Sucesso!',
              'Campanha cadastrada com sucesso!',
              'main',
            );
          }
        })
        .catch(error => {
          console.warn(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          setStep(1);
          setSelectedClubs([]);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const handlePutForm = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      const formRequest = {
        campaignName: valueCampaignName,
        startDate: `${valueCampaignStartDate}T${valueCampaignStartTime}`,
        endDate: `${valueCampaignEndDate}T${valueCampaignEndTime}`,
        seller: sellerIdSelected,
        milesCampaignRules:
          formatParticipatingClubsToRequest(selectedClubsList),
      };
      const reqOptions = {
        method: 'PUT',
        headers: initialHeadersData(keycloak.token),
        data: formRequest,
      };
      handlePutRequest(`${FULL_URL}`, reqOptions)
        .then(response => {
          if (response && response.data) {
            setIsModifiedFuture(true);
            handleCloseModal();
            setSelectedClubs([]);
            setStep(1);
            populateToastMessages(
              'success',
              'Sucesso!',
              'Campanha atualizada com sucesso!',
              'main',
            );
          }
        })
        .catch(error => {
          console.warn(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          setStep(1);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const handleSubmitForm = () => {
    if (campaignId) handlePutForm();
    else handlePostForm();
  };

  const handleOptions = () => {
    setOpenOptions(!openOptions);
  };

  const cancelCreate = () => {
    if (!campaignId) handleResetForm();
    handleCloseModal();
    setStep(1);
  };

  const handleAddSelectedClubList = () => {
    if (valuePromotionCode.trim() === '' || selectedClubs.length === 0) {
      setStep2FieldsHasErrors(true);
      return;
    }

    const promotionCodeAlreadyInUse = selectedClubsList.some(
      item => item.promotionCode === valuePromotionCode,
    );
    if (promotionCodeAlreadyInUse) {
      setStep2FieldsHasErrors(true);
      return;
    }

    const campaignObject = {
      promotionCode: valuePromotionCode,
      participatingClubs: selectedClubs,
    };

    setSelectedClubsList([...selectedClubsList, campaignObject]);

    const availableClubsWithoutSelected = clubTypesData.filter(
      availableItem => !selectedClubs.includes(availableItem),
    );
    setClubTypesData(availableClubsWithoutSelected);

    setValuePromotionCode('');
    setSelectedClubs([]);

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      const checkboxElement = checkbox;
      checkboxElement.checked = false;
    });
  };

  const firstModalTitle = () => {
    if (step === 1) {
      if (campaignId) {
        return 'Editar campanha';
      }
      return 'Cadastrar nova campanha';
    }
    return '';
  };

  const handleRemovePromotionFromList = promotionCode => {
    const removedItem = selectedClubsList.find(
      item => item.promotionCode === promotionCode,
    );

    setClubTypesData([...clubTypesData, ...removedItem.participatingClubs]);

    const updatedFinalItems = selectedClubsList.filter(
      item => item.promotionCode !== promotionCode,
    );
    setSelectedClubsList(updatedFinalItems);
  };

  useEffect(() => {
    if (
      validationFields.campaignSeller.isRequired ||
      validationFields.valueCampaignEndDate.isRequired ||
      validationFields.valueCampaignEndTime.isRequired ||
      validationFields.valueCampaignName.isRequired ||
      validationFields.valueCampaignStartDate.isRequired ||
      validationFields.valueCampaignStartTime.isRequired ||
      !validationFields.valueCampaignEndTime.isValidDate ||
      !validationFields.valueCampaignEndDate.isValidDate ||
      !validationFields.valueCampaignStartDate.isValidDate ||
      !validationFields.valueCampaignStartTime.isValidDate
    )
      setIsDisabledSubmit(true);
    else setIsDisabledSubmit(false);
  }, [validationFields]);

  useEffect(() => {
    if (!isOpenModal) cancelCreate();
  }, [isOpenModal]);

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {firstModalTitle()}
          {step === 2 && (
            <>
              <img
                aria-hidden="true"
                onClick={handleReturnModal}
                cursor="pointer"
                src={chevronLeft}
                alt="continue"
              />
              Códigos de promoção da campanha
            </>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              <Form.Group>
                <Form.Label>Escolha um parceiro</Form.Label>
                <div className="container-name">
                  <InputName onClick={() => handleOptions()}>
                    <p>{inputName}</p>
                    <img src={iconArrow} alt="arrow" />
                  </InputName>
                  <BoxOption
                    display={openOptions}
                    setInputName={changeName}
                    items={[
                      'Nenhum parceiro selecionado',
                      'Smiles',
                      'Tudo Azul',
                    ]}
                    className="invalid-input"
                    name="campaignSeller"
                  />
                  {(!validationFields.campaignSeller.isValid ||
                    validationFields.campaignSeller.isRequired) && (
                    <span className="invalid-text">
                      Escolha um parceiro para continuar.
                    </span>
                  )}
                </div>

                <Form.Label>Nome da campanha</Form.Label>
                <Form.Control
                  as="input"
                  className={
                    !validationFields.valueCampaignName.isValid ||
                    validationFields.valueCampaignName.isRequired
                      ? 'invalid-input'
                      : ''
                  }
                  placeholder="Ex.: Campanha aérea 1"
                  onChange={handleCampaignName}
                  value={valueCampaignName}
                  name="valueCampaignName"
                />
                {(!validationFields.valueCampaignName.isValid ||
                  validationFields.valueCampaignName.isRequired) && (
                  <span className="invalid-text">
                    Escolha um nome para campanha.
                  </span>
                )}
              </Form.Group>

              <div className="container-date-hour">
                <Form.Group>
                  <Form.Label>Data Inicial</Form.Label>
                  <Form.Control
                    as="input"
                    className={
                      !validationFields.valueCampaignStartDate.isValid ||
                      validationFields.valueCampaignStartDate.isRequired
                        ? 'invalid-input input-date-hour'
                        : 'input-date-hour'
                    }
                    type="date"
                    placeholder="Ex.: Campanha aérea 1"
                    onChange={handleCampaignStartDate}
                    value={valueCampaignStartDate}
                    name="valueCampaignStartDate"
                  />
                  {(!validationFields.valueCampaignStartDate.isValid ||
                    validationFields.valueCampaignStartDate.isRequired) && (
                    <span className="invalid-text">
                      Escolha uma data inicial.
                    </span>
                  )}
                  {((!validationFields.valueCampaignEndDate.isValidDate &&
                    !validationFields.valueCampaignEndDate.isRequired) ||
                    (!validationFields.valueCampaignStartDate.isValidDate &&
                      !validationFields.valueCampaignStartDate.isRequired)) && (
                    <span className="invalid-text">
                      Intervalo de datas incorreto.
                    </span>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Horário inicial</Form.Label>
                  <Form.Control
                    as="input"
                    className={
                      !validationFields.valueCampaignStartTime.isValid ||
                      validationFields.valueCampaignStartTime.isRequired
                        ? 'invalid-input input-date-hour'
                        : 'input-date-hour'
                    }
                    type="time"
                    placeholder="00:00:00"
                    onChange={handleCampaignStartTime}
                    value={valueCampaignStartTime}
                    name="valueCampaignStartTime"
                  />
                  {(!validationFields.valueCampaignStartTime.isValid ||
                    validationFields.valueCampaignStartTime.isRequired) && (
                    <span className="invalid-text">
                      Escolha um horário inicial.
                    </span>
                  )}
                  {((!validationFields.valueCampaignStartTime.isValidDate &&
                    !validationFields.valueCampaignStartTime.isRequired) ||
                    (!validationFields.valueCampaignEndTime.isValidDate &&
                      !validationFields.valueCampaignEndTime.isRequired)) && (
                    <span className="invalid-text">
                      Intervalo de horários incorreto.
                    </span>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Data Final</Form.Label>
                  <Form.Control
                    as="input"
                    className={
                      !validationFields.valueCampaignEndDate.isValid ||
                      validationFields.valueCampaignEndDate.isRequired
                        ? 'invalid-input input-date-hour'
                        : 'input-date-hour'
                    }
                    type="date"
                    placeholder="Ex.: Campanha aérea 1"
                    onChange={handleCampaignEndDate}
                    value={valueCampaignEndDate}
                    name="valueCampaignEndDate"
                  />
                  {(!validationFields.valueCampaignEndDate.isValid ||
                    validationFields.valueCampaignEndDate.isRequired) && (
                    <span className="invalid-text">
                      Escolha uma data final.
                    </span>
                  )}
                  {!validationFields.valueCampaignEndDate.isValidDate &&
                    !validationFields.valueCampaignEndDate.isRequired && (
                      <span className="invalid-text">
                        Intervalo de datas incorreto.
                      </span>
                    )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Horário Final</Form.Label>
                  <Form.Control
                    as="input"
                    className={
                      !validationFields.valueCampaignEndTime.isValid ||
                      validationFields.valueCampaignEndTime.isRequired
                        ? 'invalid-input input-date-hour'
                        : 'input-date-hour'
                    }
                    type="time"
                    placeholder="00:00:00"
                    onChange={handleCampaignEndTime}
                    value={valueCampaignEndTime}
                    name="valueCampaignEndTime"
                  />
                  {(!validationFields.valueCampaignEndTime.isValid ||
                    validationFields.valueCampaignEndTime.isRequired) && (
                    <span className="invalid-text">
                      Escolha um horário final.
                    </span>
                  )}
                  {!validationFields.valueCampaignEndTime.isValidDate &&
                    !validationFields.valueCampaignEndTime.isRequired && (
                      <span className="invalid-text">
                        Intervalo de horários incorreto.
                      </span>
                    )}
                </Form.Group>
              </div>

              <div className="container-buttons">
                <Button
                  type="submit"
                  typeOfButton="secondary"
                  onClick={cancelCreate}
                >
                  Cancelar
                </Button>
                <Button
                  typeOfButton="primary"
                  onClick={handleContinueModal}
                  disabled={isDisabledSubmit}
                >
                  Continuar
                </Button>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <CupomText>Adicione os códigos de promoção</CupomText>

              <Form.Group>
                <Form.Label>Código da campanha</Form.Label>
                <Form.Control
                  as="input"
                  className={step2FieldsHasErrors ? 'invalid-input' : ''}
                  placeholder="Defina um código de promoção para adicioná-lo"
                  onChange={handlePromotionCode}
                  value={valuePromotionCode}
                  maxLength={60}
                  type="text"
                />
                {step2FieldsHasErrors && (
                  <span className="invalid-text">
                    Digite um Código de Promoção, que não esteja sendo utlizado,
                    e selecione ao menos um Clube.
                  </span>
                )}
              </Form.Group>

              <CupomText>
                Vincule um ou mais planos Esfera a este código de promoção
              </CupomText>

              <DivCheckBox>
                {clubTypesData.map(item => (
                  <>
                    <Checkbox
                      id={item}
                      name=""
                      label={item}
                      onChange={e => {
                        if (e.target.checked) {
                          setSelectedClubs(prevSelectedItems => [
                            ...prevSelectedItems,
                            item,
                          ]);
                          if (valuePromotionCode)
                            setStep2FieldsHasErrors(false);
                        } else {
                          setSelectedClubs(prevSelectedItems =>
                            prevSelectedItems.filter(
                              selectedItem => selectedItem !== item,
                            ),
                          );
                        }
                      }}
                    />
                  </>
                ))}
              </DivCheckBox>

              <AddCupom type="button" onClick={handleAddSelectedClubList}>
                Adicionar Código
              </AddCupom>
              {selectedClubsList.length === 0 && (
                <CupomText>
                  <strong className="invalid-text">
                    Você deve definir pelo menos um Código de campanha e Clube
                  </strong>
                </CupomText>
              )}

              {selectedClubsList.length !== 0 && (
                <>
                  <CupomText>Códigos cadastrados nessa campanha</CupomText>

                  <DivCodigosCampanha>
                    {selectedClubsList.map(addedPromotionCode => (
                      <li
                        id={addedPromotionCode.promotionCode}
                        alt="Clubes selecionados"
                      >
                        <DivCupom>
                          <strong>{addedPromotionCode.promotionCode}</strong>
                          <span>
                            {addedPromotionCode.participatingClubs.toString()}
                          </span>
                        </DivCupom>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-bottom">
                              Remover código de promoção
                            </Tooltip>
                          }
                        >
                          <button
                            type="button"
                            onClick={() =>
                              handleRemovePromotionFromList(
                                addedPromotionCode.promotionCode,
                              )
                            }
                          >
                            <img src={trash} cursor="pointer" alt="Excluir" />
                          </button>
                        </OverlayTrigger>
                      </li>
                    ))}
                  </DivCodigosCampanha>
                </>
              )}

              <div className="container-buttons">
                <Button
                  type="submit"
                  typeOfButton="secondary"
                  onClick={cancelCreate}
                >
                  Cancelar
                </Button>
                <Button
                  typeOfButton="primary"
                  onClick={handleSubmitForm}
                  disabled={selectedClubsList.length === 0}
                >
                  Salvar
                </Button>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </StyledModal>
  );
}
