import {
  createGlobalStyle
} from 'styled-components';

import "@fontsource/roboto";
import "@fontsource/poppins";
import "@fontsource/open-sans";

export default createGlobalStyle `
  :root {
    --boston: #CC0000;
    --ruby: #990000;
    --white: #FFFFFF;
    --black: #000000;
    --sky: #DEEDF2;
    --mediumSky: #CEDEE7;
    --darkSky: #9BC3D3;
    --lighterSky: #F5F9FB;

    --gray-50: #222222;
    --gray-100: #444444;
    --gray-200: #767676;
    --gray-300: #CCCCCC;
    --gray-500: #f0f0f0;

    --red-50: #FBE4E4;

    --green-50: #16a48b;

    --gray-table: #F6F6F6;
    --gray-header-bottom: #ECECEC;
    --blue-light: #F5F9FB;
    --background-link-active: #F0D8D8;
    --linecolor-gray: #DADADA;

    --suportDarkSuccess: #3A8340;
    --suportLightSuccess: #F0F8F0;
    --suportDarkError: #A63449;
    --suportLightError: #FCDBE1;
    --suportDarkWarning: #BD6402;
    --suportLightWarning: #FEF2E5;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html {

    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
  }

  body, input, textarea, button {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
    margin: 0;
  }

  button {
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .modal-backdrop {
    width: 100%;
    height: 100%;
  }
`;
