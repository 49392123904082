import React, { useContext, useState } from 'react';
import {
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from 'react-bootstrap';
import {
  IconArrowDown,
  IconArrowUp,
  IconArrowRight,
  IconClose,
} from '../../../../../assets/icons';
import {
  CollapseText,
  Flex,
  InputCode,
  CodeContainer,
  Container,
} from './styles';

export default function StatusAccordion({
  userHasPermission,
  selectedPartner,
  addPartnerCode,
  removePartnerCode,
}) {
  const [inputCode, setInputCode] = useState('');

  const handleAddPartnerCode = () => {
    if (!inputCode) return;
    const newPartnerCode = {
      ...selectedPartner,
      tempId: Math.floor(Math.random(300) * 3000),
      statusPartnerCode: inputCode,
    };
    delete newPartnerCode.statusPartnerCodes;
    addPartnerCode(newPartnerCode, selectedPartner.statusEsfera);
    setInputCode('');
  };

  const handleRemovePartnerCode = partnerCode => {
    removePartnerCode(partnerCode, selectedPartner.statusEsfera);
  };

  return (
    <Container>
      <Accordion>
        <Card className="CustomCard">
          <Card.Header className="CustomCard__Header">
            <ContextAwareToggle
              eventKey={`accordion-${selectedPartner.statusEsfera}`}
            >
              Pedido {selectedPartner.statusDescription}
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse
            eventKey={`accordion-${selectedPartner.statusEsfera}`}
          >
            <Card.Body className="CustomCard__Body">
              <hr />
              <Flex justifyContent="space-between">
                <span>Códigos parceiro:</span>
                <Flex mb="10px" position="relative">
                  <InputCode
                    placeholder="Digite o código"
                    type="text"
                    value={inputCode}
                    onChange={event => setInputCode(event.target.value)}
                  />
                  <Flex position="absolute" right="0px">
                    <div
                      role="button"
                      tabIndex="0"
                      aria-hidden="true"
                      onClick={() => {
                        handleAddPartnerCode();
                      }}
                    >
                      <IconArrowRight />
                    </div>
                  </Flex>
                </Flex>
              </Flex>
              <Flex display="inline-flex" flexWrap="wrap" gap="16px">
                {selectedPartner.statusPartnerCodes.map(partnerCode => (
                  <Code
                    key={partnerCode.id}
                    userHasPermission={userHasPermission}
                    partnerCode={partnerCode}
                    handleRemovePartnerCode={handleRemovePartnerCode}
                  />
                ))}
              </Flex>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Container>
  );
}

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={decoratedOnClick}
        aria-hidden="true"
      >
        <CollapseText>{children}</CollapseText>
        {isCurrentEventKey ? <IconArrowUp /> : <IconArrowDown />}
      </div>
    </>
  );
}

function Code({ userHasPermission, partnerCode, handleRemovePartnerCode }) {
  return (
    <CodeContainer>
      <span>{partnerCode.code}</span>
      {userHasPermission('ESFERA.TRACKING.DELETE') && (
        <div
          onClick={() => {
            handleRemovePartnerCode(partnerCode);
          }}
          role="button"
          tabIndex="0"
          aria-hidden="true"
        >
          <IconClose />
        </div>
      )}
    </CodeContainer>
  );
}
