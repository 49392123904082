import React from 'react';
import useMenuMobile from '../../../hooks/useMenuMobile';

import { Container } from './styles';

export default function Content({ children }) {
  const { isOpenMenuMobile } = useMenuMobile();

  return (
    <Container isSideMenuMobileOpen={isOpenMenuMobile}>{children}</Container>
  );
}
