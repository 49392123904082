import styled from 'styled-components';

export const Container = styled.main`
  height: 100vh;
  display: block;
  float: right;
  width: 85%;
  margin-top: 4%;
  padding: 2rem 1rem;

  h2 {
    font-size: 22px;
    color: var(--gray-50);
    font-weight: 700;
  }

`;
