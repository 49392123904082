import styled, { css } from 'styled-components';
import * as Styled from '../../components/Nucleo/Content/CustomTable/styles';
export const Container = styled.div`
  height: 100vh;
  display: block;
  float: right;
  width: 85%;
  margin-top: 4%;
  padding: 2rem 1rem;
  ${({ theme }) => css`
    padding: 0 16px;
    height: 100%;
    ${Styled.Container} {
      & + div {
        margin-top: 40px;
      }
    }
  `}
  .table-content > main > .loading-content-compass {
    padding-right: 12%;
    padding-top: 7%;
  }
  .table-content > main {
    width: 84%;
  }
`;
export const listHeaderPartnersParity = styled.div`
  .content__header__list__partner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
  }
  .contentmain__header {
    width: 100%;
    align-items: center;
    min-height: 72px;
    border-bottom: 1px solid #DADADA;
  }
  .contentmain__header__title {
    display: flex;
    justify-content: space-between;
  }
  .contentmain__header__update {
    display: flex;
    grid-gap: 8px;
    
    .contentmain__header__icon {
      margin: 0 auto;
      align-self: center;
      font-size: 25px;
    }
    .contentmain__header__text{
      color: #CC0000;
      font-size: 18px;
      font-family: Open Sans;
      text-decoration: underline;
    }
  }
  .contentmain__header__text {
    padding: 16px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
  }
  .contentmain__text-info {
    margin-top: 15px;
    margin-bottom: 45px;
  }
  button {
    border: none;
    background: none;
  }
  .content__title_partner {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 700;
    align-self: center;
    width: 25%;  
  }
`;
export const LoadingComponent = styled.main`
  position: absolute;
  width: 100%;
  height: 100%;
  visibility:hidden;
  .loading-box-compass {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #eee;
    z-index: 11;
    opacity: .9;
    visibility: visible;
  }
 
  .loading-content-compass {
    z-index: 12;
    position: relative;
    padding-top: 25%;
    visibility: visible;
  }
 
`;