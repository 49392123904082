import React, { useState, useEffect } from 'react';
import moment from 'moment';
import TransactionsItem from '../TransactionsItem';
import * as S from './styles';

export default function TransactionsTableBody({
  item,
  userPermissions,
  isModifiedTransactionFile,
  populateToastMessages,
}) {
  const [isRefreshTransaction, setIsRefreshTransaction] = useState(false);
  const [classActive, setClassActive] = useState(false);
  const [viewTable, setViewTable] = useState(false);
  const [currentFileId, setCurrentFileId] = useState('');

  const handleViewTable = () => {
    setViewTable(!viewTable);
    setClassActive(!classActive);
  };

  const closeViewTable = () => {
    setViewTable(false);
    setClassActive(false);
  };

  useEffect(() => {
    closeViewTable();
  }, [isModifiedTransactionFile]);
  return (
    <>
      <S.StyledTr>
        <td className="partner">
          <span
            aria-hidden="true"
            className={`esfera-icon BE040 ${classActive ? 'active' : ''}`}
            onClick={() => {
              setCurrentFileId(item.fileId);
              handleViewTable();
            }}
          />
          {item.fileName ? item.fileName : '---'}
        </td>
        <td>
          {item.createDate
            ? moment(item.createDate).format('DD/MM/YYYY HH:mm')
            : '---'}
        </td>
        <td>{item.qtdTransactions ? item.qtdTransactions : '---'}</td>
        <td>
          {item.totalCashbackFileDouble
            ? `R$ ${Number(item.totalCashbackFileDouble)
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`
            : 'R$ 0,00'}
        </td>
        <td>
          {item.totalCashbackPendingDouble
            ? `R$ ${Number(item.totalCashbackPendingDouble)
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`
            : 'R$ 0,00'}
        </td>
        <td>{item.status ? item.status : '---'}</td>
      </S.StyledTr>

      {viewTable && (
        <>
          <TransactionsItem
            fileItemId={currentFileId}
            userPermissions={userPermissions}
            setIsRefreshTransaction={setIsRefreshTransaction}
            isRefreshTransaction={isRefreshTransaction}
            populateToastMessages={populateToastMessages}
          />
        </>
      )}
    </>
  );
}
