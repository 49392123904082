import React, { useMemo } from 'react';
import moment from 'moment';
import { IconBack } from '../../../../assets/icons';
import {
  Flex,
  BackButton,
  SelectedCardTitle,
  SelectedCardSubtitle,
  SelectedCardLastChange,
} from './styles';

export default function Header({
  selectedPartner,
  goToSelectPartner,
  statusResponse,
}) {
  const registeredCodes = useMemo(() => {
    if (!statusResponse || !statusResponse.trackingStatus) {
      return 0;
    }
    const { content: codes } = statusResponse.trackingStatus;
    return codes.filter(code => code.needEnrichment === false).length;
  }, [statusResponse]);

  return (
    <>
      <Flex maxW="1024px" mt="23px" mb="23px" justifyContent="end">
        <BackButton onClick={() => goToSelectPartner()}>
          Voltar <IconBack />
        </BackButton>
      </Flex>
      {statusResponse && (
        <Flex
          direction="column"
          maxW="1024px"
          p="13px 16px 11px 14px"
          bg="#f0f0f0b3"
          bRadius="4px"
          mb="26px"
        >
          <Flex justifyContent="space-between">
            <SelectedCardTitle>{selectedPartner.description}</SelectedCardTitle>
            <SelectedCardSubtitle>
              Códigos do parceiro cadastrados:{' '}
              <strong>{registeredCodes}</strong>
            </SelectedCardSubtitle>
          </Flex>
          <hr />

          <SelectedCardLastChange>
            Última alteração:
            {moment(statusResponse.latestUpdateDate).format('DD/MM/YYYY')}
          </SelectedCardLastChange>
        </Flex>
      )}
    </>
  );
}
