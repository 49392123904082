import React from 'react';
import moment from 'moment';

import { StyledTable } from './styles';

export default function HistoryRuleTable({ rules }) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Valor Minimo %</th>
          <th>Posição</th>
          <th>Site</th>
          <th>Situação</th>
          <th>Usuário</th>
          <th>Criação</th>
          <th>Alteração</th>
        </tr>
      </thead>

      <tbody>
        {rules.map(rule => (
          <tr key={rule.id}>
            <td>{rule.ruleName}</td>
            <td>{rule.minPercentageValue}</td>
            <td>{rule.ruleOrder}</td>
            <td>{rule.siteId}</td>
            <td>{rule.active ? 'Ativa' : 'Inativa'}</td>
            <td>{rule.user}</td>
            <td>{moment(rule.createdAt).format('DD/MM/YYYY HH:mm')}</td>
            <td>{moment(rule.updatedAt).format('DD/MM/YYYY HH:mm')}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}
