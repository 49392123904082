import React, { useEffect, useState } from 'react';
import RegisterParityModal from '../../RegisterParityModal';
import * as S from './styles';
import icEsfera from '../../../assets/ic-esfera.png';
import Parity from '../Parity';

export default function RegisterParity({
  populateToastMessages,
  selectedCampaignEdit,
  userPermissions,
  setParity,
  parities,
  defaultParity,
  defaultPointsFunction,
  defaultMilesFunction,
  editParity,
  permissionEditParity,
  selectedClubs,
  newPartner,
}) {
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [plans, setPlans] = useState([]);
  const [disableInput, setDisableInput] = useState(true);
  const [partnerParities, setPartnerParities] = useState(parities);

  const updateParitiesAfterDeletion = parityId => {
    const newParities = partnerParities.map(parity => {
      if (parity.id === parityId) {
        return {
          ...parity,
          clubs: parity.clubs.map(club => ({ ...club, selected: false })),
        };
      }
      return parity;
    });

    setPartnerParities(newParities);
  };

  const selectedParitiesDefault = selectedCampaignEdit?.parities?.find(
    parity => parity.type === 'DEFAULT',
  );

  const handleOpenModal = () => {
    setModalAddVisible(true);
  };
  const handleCloseModal = () => {
    setModalAddVisible(false);
  };
  const handleSaveParity = newParity => {
    const currentParity = plans.find(e => {
      return e.id === newParity.id;
    });

    if (currentParity) {
      const [...copyPlans] = plans;
      const updatedParity = copyPlans.find(e => {
        return e.id === currentParity.id;
      });

      updatedParity.clubs = newParity.clubs;
      updatedParity.miles = newParity.miles;
      updatedParity.points = newParity.points;

      setPlans(copyPlans);
      setParity(copyPlans);
    } else {
      // create new parity
      setPlans([...plans, newParity]);
      setParity([...plans, newParity]);
    }
  };
  const handleDeleteParity = parityId => {
    let arrayPlans = [...plans];

    const parity = arrayPlans.find(plan => plan.id === parityId);

    // if have parity.type, this parity come from database
    if (parity.type) {
      parity.removeParity = true;
    } else {
      // if not, this parity was created only in front
      arrayPlans = arrayPlans.filter(plan => plan.id !== parityId);
    }

    setPlans(arrayPlans);
    setParity(arrayPlans);
  };
  const changeDefaultPoints = points => {
    defaultPointsFunction(points);
  };
  const changeDefaultMiles = miles => {
    defaultMilesFunction(miles);
  };

  const selectedDefaultParity = selectedParitiesDefault || defaultParity;

  useEffect(() => {
    setPlans(parities || []);
  }, [parities]);

  useEffect(() => {
    if (editParity) {
      setPlans([...plans, ...editParity]);
    }
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      setDisableInput(false);
    }
    if (selectedCampaignEdit && selectedCampaignEdit.status === 'ACTIVE') {
      setDisableInput(true);
    }
  }, [editParity]);
  return (
    <S.Container>
      <>
        <div className="contentmain__parity">
          <div className="contentmain__parity__title">Paridades</div>
        </div>
        <S.Container>
          {selectedDefaultParity !== undefined ||
          selectedDefaultParity !== null ? (
            <>
              <S.DefaultParity>
                <S.TypeParity>
                  <div className="icon_default_parity">
                    <span
                      style={{ color: 'red' }}
                      className="linkIcon esfera-icon BE200"
                    />
                  </div>
                  <div className="title-parity-default">Paridade padrão</div>
                </S.TypeParity>
                <div className="container-buttons-parity">
                  <div className="action-buttons">
                    <div className="points">
                      <div className="icon_parity">
                        <img src={icEsfera} alt="icon-esfera" />
                      </div>
                      <span>Pontos | </span>
                      <div className="parity-value">
                        <input
                          type="number"
                          className="parity-input"
                          defaultValue={selectedDefaultParity?.points}
                          disabled={disableInput}
                          onChange={e => changeDefaultPoints(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="action-buttons">
                    <div className="milhas">
                      <span>Milhas | </span>
                      <div className="parity-value">
                        <input
                          type="number"
                          className="parity-input"
                          defaultValue={selectedDefaultParity?.miles}
                          disabled={disableInput}
                          onChange={e => changeDefaultMiles(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </S.DefaultParity>
            </>
          ) : (
            <>
              <S.NoDefaultParity>
                <div className="no-default-paritie">
                  <span>Não há paridade padrão cadastrada.</span>
                </div>
              </S.NoDefaultParity>
            </>
          )}
        </S.Container>
        <S.Container>
          {plans
            .filter(e => !e.removeParity && e.type !== 'DEFAULT')
            .map(plan => (
              <div key={plan.id} style={{ marginBottom: '20px' }}>
                <Parity
                  defaultParity={defaultParity}
                  plan={plan}
                  saveParity={handleSaveParity}
                  deleteParity={handleDeleteParity}
                  populateToastMessages={populateToastMessages}
                  userPermissions={userPermissions}
                  permissionEditParity={permissionEditParity}
                  partnerParities={parities}
                  updateParities={updateParitiesAfterDeletion}
                />
              </div>
            ))}
        </S.Container>
      </>
      <S.Container>
        {userPermissions.checkUiPermissionsFn(
          'ESFERA.PARITY_CONTROL.CREATE',
          userPermissions.userGroups,
        ) &&
          permissionEditParity &&
          !newPartner && (
            <>
              <S.ButtonAdd
                onClick={handleOpenModal}
                userPermissions={userPermissions}
              >
                <S.IconParidade>
                  <span
                    style={{ color: 'red' }}
                    className="linkIcon esfera-icon ic_system_circle_add"
                  />
                </S.IconParidade>
                <S.TextParidade>Cadastrar paridade</S.TextParidade>
              </S.ButtonAdd>
            </>
          )}
      </S.Container>
      {modalAddVisible && (
        <RegisterParityModal
          userPermissions={userPermissions}
          mode="create"
          defaultParity={defaultParity}
          isOpen={modalAddVisible}
          onClose={handleCloseModal}
          onSave={handleSaveParity}
          parities={parities}
          selectedClubs={selectedClubs}
        />
      )}
    </S.Container>
  );
}
