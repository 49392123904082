import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 8px;
  height: 69px;
  min-width: 220px;
  width: fill-available;
  max-width: 400px;
  gap: 8px;
  cursor: pointer;

  button {
    height: 40px !important;
  }
`;

export const FileName = styled.span`
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
