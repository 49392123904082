import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../components/Button';
import ToastCustom from '../../components/Toast';

import ModalAddRule from '../../components/CppRulePage/ModalAddRule';
import CppRuleTable from '../../components/CppRulePage/CppRuleTable';
import CppRuleFilter from '../../components/CppRulePage/CppRuleFilter';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';

import { Container, CppHeaderContentStyle } from './styles';

import {
  handleGetRequest,
  initialPaginationData,
} from '../../services/GenericMethods';

import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

export default function CppRules({ userPermissions }) {
  const [currentFilter, setCurrentFilter] = useState([]);
  const [isOpenModalAddRule, setIsOpenModalAddRule] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [cppRules, setCppRules] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const { keycloak } = useKeycloak();
  const options = initialPaginationData('GET', currentPage, keycloak.token);
  const FULL_URL = window.SERVICES_DOMAIN + window.CPP_RULE_URL;

  const getCppRules = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.CPP_RULES.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const { content } = response.data;
            setCppRules(content);

            setCurrentPage(response.data.number);
            setTotalPages(response.data.totalPages);
            setIsFirstPage(response.data.first);
            setIsLastPage(response.data.last);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setIsFirstPage(false);
            setIsLastPage(false);
            setCppRules([]);
          }
        })
        .catch(error => {
          console.error(`Error at getCppRules request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const handleShowMdlAddRule = () => setIsOpenModalAddRule(true);
  const handleCloseMdlAddRule = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }

    setIsOpenModalAddRule(false);
  };
  const handleShowMessageAction = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }
  };

  const handlePageChange = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getCppRules(options);
  };

  const handleFilter = inputParams => {
    options.params = inputParams;
    setCurrentFilter(inputParams);
    getCppRules(options);
  };

  const setOptionParams = () => {
    if (currentFilter.length !== 0) {
      options.params = currentFilter;
    }
  };

  useEffect(() => {
    setOptionParams();
    getCppRules(options);
    setCurrentFilter(options.params);
  }, [toastList]);

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
      <CppHeaderContentStyle>
        <header className="contentmain__header">
          <div className="contentmain__header__title">
            <text className="contentmain__header__text">Regras Especiais</text>
            <span className="contentmain__header__icon icoArrow esfera-icon BE070" />
            <text className="contentmain__header__text contentmain__header__text__active">
              Valor diferenciado de CPP
            </text>
          </div>

          {userPermissions.checkUiPermissionsFn(
            'ESFERA.CPP_RULES.CREATE',
            userPermissions.userGroups,
          ) ? (
            <Button typeOfButton="primary" onClick={handleShowMdlAddRule}>
              Adicionar Regra
            </Button>
          ) : null}
        </header>
      </CppHeaderContentStyle>
      <CppRuleFilter
        currentFilter={currentFilter}
        handleFilter={handleFilter}
      />
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.CPP_RULES.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {cppRules.length > 0 ? (
            <CppRuleTable
              rules={cppRules}
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPages > 0 ? (
        <Pag
          currentPage={currentPage}
          totalPages={totalPages}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          onClickFnPage={handlePageChange}
        />
      ) : null}

      <ModalAddRule
        isOpenModal={isOpenModalAddRule}
        handleCloseModal={handleCloseMdlAddRule}
        userPermissions={userPermissions}
      />
    </Container>
  );
}
