import React from 'react';
import { SearchCouponStyle } from './styles';

export default function SearchCoupon({
  id,
  label,
  searchParams,
  onChangeSearchParams,
}) {
  console.log('[SearchCoupon][searchParams]', searchParams);
  const handleSearchParams = e => {
    if (e.key === 'Enter') {
      onChangeSearchParams(e.target.value);
    }
  };
  return (
    <SearchCouponStyle>
      <div className="form-container">
        <input className="form-input" onKeyDown={handleSearchParams} />
        <label htmlFor={id} className="form-label">
          {label}
        </label>
        <span className="icon-button esfera-icon ic_functional_search" />
      </div>
    </SearchCouponStyle>
  );
}
