import styled from "styled-components";

export const Content = styled.div`
  padding-top: 32px;
`

export const DivInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
  margin-top: 24px;

  .invalid-input {
    border-color: red;
  }

  @media (min-width: 992px) {
    grid-template-columns: 184px 168px;
  }
`
