import styled from 'styled-components';

export const SearchStyle = styled.div`  
   width: 334px;
  .form-container {
    position: relative;
    height: 56px;
  }
  .form-container svg {
    position: absolute;
    right: 14px;
    top: 10px;
    color: #767676;
    z-index: 9;
    cursor: pointer;
    font-size: 20px;
  }
  input::placeholder {
    font-size: 16px;
  }
  .form-input {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #262626;
    border: none;
    border-radius: 20px;
    outline: none;
    padding: 10px 15px 10px 50px;
    background: #F5F9FB;
    font-weight: 600;
  }

  .form-label {
    font-family: 'Open Sans';
    position: absolute;
    left: 0.8rem;
    top: 0.5rem;
    padding: 0 0.25rem;
    color: #767676;
    font-size: 0.87rem;
    background-color: #F5F9FB;
    transition: 0.3s;
  }
  .BD290:before {
    color: #262626;
  }
  .BD290 {
    position: absolute;
    left: 10px;
    top: 25%;
    font-size: 30px;
  }
`