import React from 'react';
import { SCheckbox } from './styles';

export default function Checkbox({
  id,
  idName,
  label,
  className,
  iconClassName,
  onClick,
  checked,
  onChange,
  disabled,
}) {
  return (
    <SCheckbox disabled={disabled} className={className}>
      <input
        type="checkbox"
        name={idName}
        id={id}
        aria-checked="true"
        onClick={onClick}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id} className="checkbox__text">
        {label}
      </label>
      {!iconClassName && (
        <span className={`icon-button esfera-icon ${{ ...iconClassName }}`} />
      )}
    </SCheckbox>
  );
}
