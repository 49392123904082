import styled, { css } from 'styled-components';
import { Table as TableBootstrap } from "react-bootstrap";

export const Container = styled.main`
  .border_emailage{
    border-top: 1px solid #DADADA;
  }

  .contentmain__header__text {
    padding: 16px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
  }

  .subtitle {
    font-size: 19px;
  }
`;

export const tableTest = styled.main`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.lightGrey};
    border-radius: 24px;
    background: ${theme.colors.white};
    margin-top: 15px;

    .table-responsive {
      border-radius: inherit;
    }
  `}
`;

export const Table = styled(TableBootstrap)`
  margin-bottom: 0;
`;
