import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../Button';
// eslint-disable-next-line import/named
import {
  handleGetRequest,
  handlePutRequest,
} from '../../services/GenericMethods';
import constants from '../../config';

import { StyledModal } from './styles';
import { formatErrorMessageToToast } from '../../helpers/GlobalFunctions';

export default function EditModalRules({
  isOpenModal,
  handleCloseModal,
  ruleNumber,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.ANTIFRAUD_RULES_URL;

  const [originalRule, setOriginalRule] = useState({
    loading: false,
    ruleData: {
      action: null,
      activated: null,
    },
  });

  const [action, setAction] = useState('');
  const [activated, setActivated] = useState('');

  useEffect(() => {
    setOriginalRule({
      loading: true,
      ruleData: {
        action: null,
        activated: null,
      },
    });

    const getRuleOption = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`,
      },
    };

    handleGetRequest(`${FULL_URL}/${ruleNumber}`, getRuleOption)
      .then(response => {
        setOriginalRule({
          loading: true,
          ruleData: {
            action: response.data.action,
            activated: response.data.active,
          },
        });

        setAction(response.data.action);
        setActivated(response.data.active);
      })
      .catch(error => {
        console.error(`Error at createRule request = ${error}`);
        handleCloseModal(
          'warning',
          'Falha',
          `Falha ao consultar a regra número ${ruleNumber}!`,
        );
      });
  }, [isOpenModal]);

  const { ruleData } = originalRule;

  const handleEditRule = () => {
    if (!ruleNumber || !action || !activated)
      console.error('error to createRule!');

    let activatedPost = false;
    if (activated === 'true' || activated === true) {
      activatedPost = true;
    }
    const createPutOption = {
      method: 'PUT',
      data: {
        action,
        activated: activatedPost,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`,
      },
    };
    handlePutRequest(`${FULL_URL}/${ruleNumber}`, createPutOption)
      .then(() => {
        handleCloseModal('success', 'Sucesso!', 'Regra alterada com sucesso!');
      })
      .catch(error => {
        console.error(`Error at createRule request = ${error}`);
        console.error(error);
        const errorMessage = formatErrorMessageToToast(error, keycloak);
        handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
      });
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>Editar Regra</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Informe o número da regra</Form.Label>
            <Form.Control type="text" required value={ruleNumber} readOnly />
          </Form.Group>

          <Form.Group>
            <Form.Label>Selecione a ação da regra</Form.Label>
            <Form.Control
              as="select"
              name="action"
              onChange={e => setAction(e.target.value)}
              required
            >
              <option
                selected={ruleData.action === constants.REFUSED_LABEL}
                value={constants.REFUSED_LABEL}
              >
                Barrar Pedido
              </option>
              <option
                selected={ruleData.action === constants.FRAUD_CHECK_LABEL}
                value={constants.FRAUD_CHECK_LABEL}
              >
                Análise Manual
              </option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Status da regra</Form.Label>
            <Form.Control
              as="select"
              name="activated"
              onChange={e => setActivated(e.target.value)}
              required
            >
              <option selected={ruleData.activated} value="true">
                Ativa
              </option>
              <option selected={!ruleData.activated} value="false">
                Inativa
              </option>
            </Form.Control>
          </Form.Group>

          <div className="container-buttons">
            <Button
              type="button"
              typeOfButton="primary"
              onClick={handleEditRule}
            >
              Salvar
            </Button>
            <Button
              type="button"
              typeOfButton="secondary"
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>
          </div>
        </Form>
      </StyledModal.Body>
    </StyledModal>
  );
}
