import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../components/Button';
import ToastCustom from '../../components/Toast';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';
import BlacklistTable from '../../components/BlacklistPage/BlacklistTable';
import ModalAddProduct from '../../components/BlacklistPage/ModalAddProduct';
import BlacklistFilter from '../../components/BlacklistPage/BlacklistFilter';

import {
  handleGetRequest,
  initialPaginationData,
} from '../../services/GenericMethods';

import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

import { Container } from './styles';

export default function Blacklist({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [isOpenModalAddProduct, setIsOpenModalAddProduct] = useState(false);
  const FULL_URL = window.SERVICES_DOMAIN + window.BLACKLIST_PRODUCTS_URL;
  const options = initialPaginationData('GET', currentPage, keycloak.token);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);

  const getProducts = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.BLACKLIST.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const { content } = response.data;
            setProducts(content);

            setCurrentPage(response.data.number);
            setTotalPages(response.data.totalPages);
            setIsFirstPage(response.data.first);
            setIsLastPage(response.data.last);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setIsFirstPage(false);
            setIsLastPage(false);
            setProducts([]);
          }

          console.warn(`Response listProducts = ${response}`);
        })
        .catch(error => {
          console.error(`Error at listProducts request = ${error}`);

          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const handleFilter = inputParams => {
    options.params = inputParams;
    setCurrentFilter(inputParams);
    getProducts(options);
  };

  const handlePageChange = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getProducts(options);
  };

  const handleShowMdlAddProduct = () => setIsOpenModalAddProduct(true);
  const handleCloseMdlAddProduct = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }

    setIsOpenModalAddProduct(false);
  };

  const handleShowMessageAction = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }
  };

  useEffect(() => {
    getProducts(options);
    setCurrentFilter(options.params);
  }, [toastList]);

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <header>
        <h2>Blocklist</h2>
        {userPermissions.checkUiPermissionsFn(
          'ESFERA.BLACKLIST.CREATE',
          userPermissions.userGroups,
        ) ? (
          <Button typeOfButton="primary" onClick={handleShowMdlAddProduct}>
            Adicionar Produtos
          </Button>
        ) : null}
      </header>

      <BlacklistFilter
        currentFilter={currentFilter}
        handleFilter={handleFilter}
      />

      {userPermissions.checkUiPermissionsFn(
        'ESFERA.BLACKLIST.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {products.length > 0 ? (
            <BlacklistTable
              products={products}
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPages > 0 ? (
        <Pag
          currentPage={currentPage}
          totalPages={totalPages}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          onClickFnPage={handlePageChange}
        />
      ) : null}

      <ModalAddProduct
        isOpenModal={isOpenModalAddProduct}
        handleCloseModal={handleCloseMdlAddProduct}
        userPermissions={userPermissions}
      />
    </Container>
  );
}
