import styled from 'styled-components';

export const WelcomeUserContainer = styled.div`
  padding: 170px 0 50px 0;
  h1 {
    font-family: Poppins;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
  }
  p {
    font-family: Open Sans;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
`;
export const WelcomeMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    font-family: #222222;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }
  p {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
  .iconWelcome {
    margin-right: 10px;
  }
  .iconDescription {
    width: 160px;
    margin-right: 30px;
  }
`;

export const GroupElementsButton = styled.a`
  display: flex;
  color: #000;
  :hover {
    color: #000;
    text-decoration: none;
  }
`;

export const Content = styled.div`
  width: 1080px;
  margin: 0 auto;
`;
