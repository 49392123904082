import React, { useState, useEffect, useMemo } from 'react';
import constants from '../../../../config';
import Accordion from './StatusAccordion';
import { StyledForm, ContainerAccordion } from './styles';

function findCodesByEsferaStatus(status, statusEsfera) {
  return status.reduce((codes, currentCodPartner) => {
    if (currentCodPartner.statusEsfera === statusEsfera) {
      codes.push({
        id: currentCodPartner.id,
        code: currentCodPartner.statusPartnerCode,
      });
    }
    return codes;
  }, []);
}

function formatCode(
  statusEsfera,
  partnerCode,
  partnerDescription,
  statusPartnerCodes,
) {
  return {
    statusEsfera,
    statusDescription: constants.OCC_PHYSICAL_STATUS_VALUE[statusEsfera],
    partnerCode,
    partnerDescription,
    statusPartnerCodes,
  };
}

function groupCodeByEsferaStatus(partner, status) {
  const group = {};
  Object.keys(constants.OCC_PHYSICAL_STATUS_VALUE).forEach(
    currentStatusEsfera => {
      const foundCodesByStatus = findCodesByEsferaStatus(
        status,
        currentStatusEsfera,
      );
      const formattedCode = formatCode(
        currentStatusEsfera,
        partner.code,
        partner.description,
        foundCodesByStatus,
      );
      group[currentStatusEsfera] = formattedCode;
    },
  );
  return group;
}

export default function FromTo({
  isVisible,
  userHasPermission,
  attendentUser,
  statusResponse,
  selectedPartner,
  newPartnerCodes,
  setNewPartnerCodes,
  deletePartnerCodes,
  setDeletePartnerCodes,
}) {
  const [groups, setGroups] = useState({});

  const addPartnerCode = (partnerCode, esferaStatus) => {
    const newPartnerCode = { ...partnerCode, attendentUser };
    setNewPartnerCodes([...newPartnerCodes, newPartnerCode]);
    const updatedGroups = groups;
    updatedGroups[esferaStatus].statusPartnerCodes.push({
      id: newPartnerCode.tempId,
      code: newPartnerCode.statusPartnerCode,
    });
    setGroups(updatedGroups);
  };

  const removePartnerCode = (partnerCode, esferaStatus) => {
    const hasInNewPartnerCodes = !!newPartnerCodes.find(
      newCode => newCode.tempId === partnerCode.id,
    );
    if (hasInNewPartnerCodes) {
      const updatedNewPartnerCodes = newPartnerCodes.filter(
        item => item.tempId !== partnerCode.id,
      );
      setNewPartnerCodes(updatedNewPartnerCodes);
    } else {
      setDeletePartnerCodes([...deletePartnerCodes, partnerCode]);
    }
    const updatedGroups = groups;
    updatedGroups[esferaStatus].statusPartnerCodes = groups[
      esferaStatus
    ].statusPartnerCodes.filter(code => code.id !== partnerCode.id);
    setGroups(updatedGroups);
  };

  useEffect(() => {
    if (!Object.keys(statusResponse).length) return;
    const status = statusResponse.trackingStatus.content;
    const codesGroupedByEsferaStatus = groupCodeByEsferaStatus(
      selectedPartner,
      status,
    );
    setGroups(codesGroupedByEsferaStatus);
  }, [statusResponse]);

  const leftItems = useMemo(() => {
    const codesGroupedArray = Object.values(groups);
    const codesOnLeft = codesGroupedArray.filter(
      (_, index) => index < parseInt(codesGroupedArray.length / 2, 10),
    );
    return codesOnLeft;
  }, [groups]);

  const rightItems = useMemo(() => {
    const codesGroupedArray = Object.values(groups);
    const codesOnRight = codesGroupedArray.filter(
      (_, index) => index >= parseInt(codesGroupedArray.length / 2, 10),
    );
    return codesOnRight;
  }, [groups]);

  return (
    <StyledForm className={!isVisible && 'hidden'}>
      <div>
        {leftItems.map(partner => (
          <ContainerAccordion key={partner.statusEsfera}>
            <Accordion
              userHasPermission={userHasPermission}
              selectedPartner={partner}
              addPartnerCode={addPartnerCode}
              removePartnerCode={removePartnerCode}
            />
          </ContainerAccordion>
        ))}
      </div>
      <div>
        {rightItems.map(partner => (
          <ContainerAccordion key={partner.statusEsfera}>
            <Accordion
              userHasPermission={userHasPermission}
              selectedPartner={partner}
              addPartnerCode={addPartnerCode}
              removePartnerCode={removePartnerCode}
            />
          </ContainerAccordion>
        ))}
      </div>
    </StyledForm>
  );
}
