import moment from 'moment';
import React, { useState } from 'react';
import editIcon from '../../../assets/airCampaign/edit.svg';
import trashIcon from '../../../assets/airCampaign/trash.svg';
import CancelCampaign from '../CancelCampaign';
import EditCampaign from '../EditCampaign';
import * as S from './styles';

export default function FutureCampaignTableBody({
  item,
  userPermissions,
  setIsModifiedFuture,
  setIsRefreshHistory,
  populateToastMessages,
}) {
  const [isOpenModalEditCampaign, setIsOpenModalEditCampaign] = useState(false);
  const [isOpenModalDeleteCampaign, setIsOpenModalDeleteCampaign] =
    useState(false);

  const handleShowMdlEditCampaign = () => setIsOpenModalEditCampaign(true);
  const handleCloseMdlEditCampaign = () => setIsOpenModalEditCampaign(false);

  const handleShowMdlDeleteCampaign = () => setIsOpenModalDeleteCampaign(true);
  const handleCloseMdlDeleteCampaign = () =>
    setIsOpenModalDeleteCampaign(false);

  return (
    <S.StyledTr>
      <td>{item.campaignName}</td>
      {!!item.adminUser && <td>{item.adminUser}</td>}
      {!!item.startDate && (
        <td>{moment(item.startDate).format('DD/MM/YYYY HH:mm')}</td>
      )}
      {!!item.endDate && (
        <td>{moment(item.endDate).format('DD/MM/YYYY HH:mm')}</td>
      )}
      {!!item.status && (
        <td className="status">
          {item.status === 'ACTIVE' && 'Campanha programada'}
        </td>
      )}
      <td>
        <img
          aria-hidden="true"
          onClick={handleShowMdlEditCampaign}
          src={editIcon}
          alt="Editar"
        />
        <img
          aria-hidden="true"
          onClick={handleShowMdlDeleteCampaign}
          src={trashIcon}
          alt="Excluir"
        />
      </td>
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.UPDATE',
        userPermissions.userGroups,
      ) ? (
        <EditCampaign
          campaignId={item.id}
          campaignSeller={item.seller}
          campaignName={item.campaignName}
          campaignStartDate={moment(
            item.startDate,
            'YYYY-MM-DD hh:mm:ss',
          ).format('YYYY-MM-DD')}
          campaignStartTime={moment(
            item.startDate,
            'YYYY-MM-DD hh:mm:ss',
          ).format('HH:mm')}
          campaignEndDate={moment(item.endDate, 'YYYY-MM-DD hh:mm:ss').format(
            'YYYY-MM-DD',
          )}
          campaignEndTime={moment(item.endDate, 'YYYY-MM-DD hh:mm:ss').format(
            'HH:mm',
          )}
          campaingMilesCampaignRules={item.milesCampaignRules}
          isOpenModal={isOpenModalEditCampaign}
          handleCloseModal={handleCloseMdlEditCampaign}
          setIsModifiedFuture={setIsModifiedFuture}
          userPermissions={userPermissions}
          populateToastMessages={populateToastMessages}
        />
      ) : null}
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.DELETE',
        userPermissions.userGroups,
      ) ? (
        <CancelCampaign
          method="delete"
          campaignId={item.id}
          isOpenModal={isOpenModalDeleteCampaign}
          handleCloseModal={handleCloseMdlDeleteCampaign}
          setIsModifiedFuture={setIsModifiedFuture}
          setIsRefreshHistory={setIsRefreshHistory}
          userPermissions={userPermissions}
          populateToastMessages={populateToastMessages}
        />
      ) : null}
    </S.StyledTr>
  );
}
