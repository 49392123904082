import styled, { css } from 'styled-components';

export const Container = styled.thead`
  ${({ theme }) => css`
    overflow: hidden;
    border: 0;
    background: ${theme.colors.white};

    th {
      border: 0;
      border-bottom: none !important;
      font-family: ${theme.fonts.family.poppins};
      font-size: ${theme.fonts.textSize.small};
      font-weight: ${theme.fonts.semibold};
      color: ${theme.colors.darkerGrey};
      line-height: 16px;
      padding: 0.75rem;

      &:nth-child(1) {
       padding-left: 50px;
      }

      &:nth-child(3) {
        padding-left: 20px;
      }

      &:nth-child(4) {
        padding-left: 20px;
      }
    }
  `}
`;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    button {
      margin-left: 1px;
      border: 0;
      background: transparent;
      outline: 0;

      .icoArrow {
        font-size: 14px;
        line-height: 16px;
        &::before {
          color: ${theme.colors.darkGrey};
        }
      }
    }
  `}
`;