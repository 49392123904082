import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ThemeProvider } from 'styled-components';
import theme from './styles/themes/theme';

import keycloak from './keycloak';
import GlobalStyles from './styles/global';
// oldLayout
// import Layout from './components/Layout';

// nucleo Layout
import Layout from './components/Nucleo/Layout';

import { MenuMobileContextProvider } from './context/MenuMobileContext';

export default function App() {
  const options = {
    onLoad: 'login-required',
  };
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={options}
      LoadingComponent={<div>Initializing Keycloak...</div>}
    >
      <MenuMobileContextProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Layout />
          </ThemeProvider>
        </Router>
      </MenuMobileContextProvider>
    </ReactKeycloakProvider>
  );
}
