import React, { useState } from 'react';
import { Container } from './styles';
import Partners from '../../components/TrackingPage/Partners';
import SelectedPartner from '../../components/TrackingPage/SelectedPartner';

export default function Tracking({ userPermissions }) {
  const [selectedPartner, setSelectedPartner] = useState({});

  const userHasPermission = condition => {
    return userPermissions.checkUiPermissionsFn(
      condition,
      userPermissions.userGroups,
    );
  };

  return (
    <Container>
      {userHasPermission('ESFERA.TRACKING.READ') && (
        <>
          <h2>Tracking</h2>
          <hr />
          <Partners
            isVisible={!Object.keys(selectedPartner).length}
            setSelectedPartner={setSelectedPartner}
          />
          {!!Object.keys(selectedPartner).length && (
            <SelectedPartner
              userHasPermission={userHasPermission}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
            />
          )}
        </>
      )}
    </Container>
  );
}
