import axios from 'axios';

export function initialPaginationData (method, actualPage, keycloakToken) {
  const paginationOptionsInitialState = {
    method: method,
    params: {
      pageCount: 10,
      pageNumber: actualPage,
    },
    headers: initialHeadersData(keycloakToken),
  };

  return paginationOptionsInitialState;
}

export function allOrders(method, actualPage, keycloakToken) {
  const paginationOptionsInitialState = {
    method: method,
    params: {
      pageNumber: actualPage,
    },
    headers: initialHeadersData(keycloakToken),
  };

  return paginationOptionsInitialState;
}

export function initialHeadersData (keycloakToken) {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${keycloakToken}`,
  }
}

export function handleGetRequest (url, options) {
  const inputParams = {
    method: 'GET',
    ...options,
  };

  return new Promise((resolve, reject) => {
    axios(url, inputParams)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function handlePostRequest (url, options) {
  const inputParams = {
    method: 'POST',
    ...options,
  };

  return new Promise((resolve, reject) => {
    axios(url, inputParams)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function handlePutRequest (url, options) {
  const inputParams = {
    method: 'PUT',
    ...options,
  };

  return new Promise((resolve, reject) => {
    axios(url, inputParams)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function handlePatchRequest (url, options) {
  const inputParams = {
    method: 'PATCH',
    ...options,
  };

  return new Promise((resolve, reject) => {
    axios(url, inputParams)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function handleDeleteRequest (url, options) {
  const inputParams = {
    method: 'DELETE',
    ...options,
  };

  return new Promise((resolve, reject) => {
    axios(url, inputParams)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}


