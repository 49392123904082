import React from 'react';

import * as S from './styles';

export default function PaginationItem({
  page,
  isActive = false,
  onClickFnPage,
  index,
}) {
  return (
    <S.Container isActive={isActive} onClick={() => onClickFnPage(index)}>
      {page}
    </S.Container>
  );
}
