import React from 'react';

import HeaderCustomTable from './HeaderCustomTable';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableFooter from './TableFooter';

import * as S from './styles';

export default function CustomTable({
  msgDefaultNoData,
  linkData,
  resumeTableData,
  titleTable,
  headTableData,
  withoutHeaderCustomTable = false,
  bodyTableData,
  hasPagination = false,
  userPermissions,
  currentPage,
  totalPages,
  isFirstPage,
  isLastPage,
  onClickFnPage,
  sortFunction,
}) {
  console.log('[CustomTable]', userPermissions);

  return (
    <S.Container withoutHeaderCustomTable={withoutHeaderCustomTable}>
      {!withoutHeaderCustomTable && (
        <HeaderCustomTable
          resumeTableData={resumeTableData}
          titleTable={titleTable}
          linkData={linkData}
        />
      )}

      <S.Table bodyTableDataLength={bodyTableData.length}>
        <TableHead
          headTableData={headTableData}
          sortFunction={sortFunction}
          bodyTableDataLength={bodyTableData.length}
          msgDefaultNoData={msgDefaultNoData}
        />
        <TableBody bodyTableData={bodyTableData} />
        {!!hasPagination && (
          <TableFooter
            totalCellWidth={headTableData.length}
            currentPage={currentPage}
            totalPages={totalPages}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            onClickFnPage={onClickFnPage}
          />
        )}
      </S.Table>
    </S.Container>
  );
}
