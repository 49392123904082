import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../Button';
import Loading from '../../Loading';
import constants from '../../../config';

import { StyledModal, WrapperButtons, Form } from './styles';

import {
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalAddProduct({
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const [productIds, setProductIds] = useState('');
  const [blacklistType, setBlacklistType] = useState('PRODUCT');
  const FULL_URL = window.SERVICES_DOMAIN + window.BLACKLIST_ADD_PRODUCTS_URL;

  const [showLoading, setShowLoading] = useState(false);

  const handleBlacklistType = e => {
    setBlacklistType(e.target.value);
  };

  const handleProductIds = e => {
    setProductIds(e.target.value);
  };

  const handleAddProductToBlacklist = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.BLACKLIST.CREATE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: {
          blackList: productIds.split(',').map(item => ({
            identifier: item,
            type: blacklistType,
          })),
        },
      };

      handlePostRequest(`${FULL_URL}`, reqOptions)
        .then(response => {
          if (response && response.data && response.data.success === false)
            throw new Error('Generic error');
          setProductIds('');
          setShowLoading(false);
          setBlacklistType('PRODUCT');
          handleCloseModal(
            'success',
            'Sucesso!',
            `Produtos adicionados na Blacklist.`,
          );
        })
        .catch(error => {
          setProductIds('');
          setShowLoading(false);
          setBlacklistType('PRODUCT');
          console.error('Error at addProductsToBlacklist request', error);
          console.error(`Error at addProductsToBlacklist request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Adicionar produtos</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Tipo de item: </Form.Label>
            <Form.Control
              as="select"
              value={blacklistType}
              onChange={handleBlacklistType}
            >
              {Object.keys(constants.BLACKLIST_TYPES).map(item => (
                <option value={item} key={item}>
                  {constants.BLACKLIST_TYPES[item]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Produto, Skus ou Sellers</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={productIds}
              onChange={handleProductIds}
              placeholder="Digite os ids dos produtos, skus ou sellers"
            />
          </Form.Group>

          <span>
            * Adicione vários items, utilizando o seguinte formato:
            dfsp7435,e100000564
          </span>
        </Form>

        <WrapperButtons>
          <Button
            typeOfButton="secondary"
            disabled={!productIds}
            onClick={handleAddProductToBlacklist}
          >
            Sim
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Não
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
