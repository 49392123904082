import React, { useState } from 'react';
import moment from 'moment';
import Button from '../../Button';
import { StyledModal, Form, WrapperButtons } from './styles';

export default function ModalApproveitem({
  item,
  isOpenModal,
  handleCloseModal,
  handleSetValueEmailage,
  userPermissions,
}) {
  const [valueConfig, setValueConfig] = useState('');

  const handleValueConfig = event => {
    event.preventDefault();
    const regex = /^[0-9.]+$/;
    if (regex.test(event.target.value) || event.target.value === '')
      setValueConfig(event.target.value);
  };

  const postEmailage = () => {
    handleSetValueEmailage(item, parseInt(valueConfig, 10));
    setValueConfig('');
    handleCloseModal();
  };

  const closeModalEmailage = () => {
    setValueConfig('');
    handleCloseModal();
  };

  return (
    <StyledModal show={isOpenModal} onHide={closeModalEmailage} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>Detalhes da configuração</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div className="modalAssociationClient">
          <span>
            <strong>Parâmetro: </strong>
            {(item.id === 'EmailageScore'
              ? 'Risk Score'
              : 'Confidence Score') || '-'}
          </span>

          <span>
            <strong>Última Alteração: </strong>
            {moment(item.updateAt).format('DD/MM/YYYY HH:mm') || ' - '}
          </span>

          <span>
            <strong>Usuário: </strong>
            {item.userUpdateAt || ' - '}
          </span>

          <Form className="form-inputs">
            <Form.Group className="form-group">
              <Form.Label>Novo valor:</Form.Label>
              <Form.Control
                type="text"
                maxLength={item.id === 'EmailageScore' ? '3' : '2'}
                onChange={handleValueConfig}
                value={valueConfig}
              />
            </Form.Group>
          </Form>
        </div>
        <WrapperButtons>
          {userPermissions.checkUiPermissionsFn(
            'ONBOARDING.RULES.UPDATE',
            userPermissions.userGroups,
          ) ? (
            <Button
              typeOfButton="secondary"
              disabled={!valueConfig}
              onClick={() => postEmailage()}
            >
              Aplicar
            </Button>
          ) : null}
          <Button typeOfButton="secondary" onClick={() => closeModalEmailage()}>
            Cancelar
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
