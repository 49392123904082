import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import HistoryCampaignTable from '../HistoryCampaignTable';
import * as S from './styles';
import {
  handleGetRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export const headTableDataHistoryCampaign = [
  {
    title: 'Histórico',
    hasIcoSort: false,
  },
  {
    title: 'Usuário',
    hasIcoSort: false,
  },
  {
    title: 'Data inicial',
    hasIcoSort: false,
  },
  {
    title: 'Data final',
    hasIcoSort: false,
  },
  {
    title: 'Status',
    hasIcoSort: false,
  },
];

export default function HistoryCampaign({
  sellerId,
  userPermissions,
  setIsRefreshHistory,
  isRefreshHistory,
  populateToastMessages,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = `${
    window.SERVICES_DOMAIN + window.AIRCAMPAIGN_URL
  }/seller/${sellerId}/history`;
  const [historyData, setHistoryData] = useState();

  const getHistory = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };
      handleGetRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data)
            console.log('Response listOrders', response.data);
          setHistoryData(response.data);
        })
        .catch(error => {
          console.log(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  useEffect(() => {
    getHistory();
    setIsRefreshHistory(false);
  }, [setIsRefreshHistory, isRefreshHistory]);

  return (
    <>
      <S.ContainerTr>
        <S.ContainerTd colSpan={6}>
          {historyData && historyData.content.length > 0 && (
            <HistoryCampaignTable
              headTableData={headTableDataHistoryCampaign}
              bodyTableData={historyData.content}
            />
          )}
        </S.ContainerTd>
      </S.ContainerTr>
    </>
  );
}
