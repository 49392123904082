import React from 'react';
import * as S from './styles';

export default function TableBody({ bodyTableData }) {
  return (
    <S.Container>
      {bodyTableData.map(item => (
        <tr key={item.code}>
          <td className="emphasis">{item.code}</td>
          {!!item.document && <td>{item.document}</td>}
          {!!item.userName && <td>{item.userName}</td>}
          {!!item.createdAt && <td>{item.createdAt}</td>}
          {!!item.updatedAt && <td>{item.updatedAt}</td>}
          {!!item.rulesActived && <td>{item.rulesActived}</td>}
          {!!item.idSalesForce && <td>{item.idSalesForce}</td>}
          {!!item.status && (
            <td>
              <div className={`status ${item.statusClassName}`}>
                {item.status}
              </div>
            </td>
          )}
        </tr>
      ))}
    </S.Container>
  );
}
