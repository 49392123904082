import styled from 'styled-components';

import { Form } from 'react-bootstrap';

export const StyledForm = styled(Form)`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &.hidden {
    display: none;
  }

  .form-content {
    display: flex;
    flex-direction: column;
  }
`;

export const PartnersCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 256px);
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 25px;
`;

export const PartnerCard = styled.div`
  width: 256px;
  height: 120px;
  border-radius: 4px;
  background: #f0f0f0;
  box-shadow: 3px 3px 6px -2px rgba(0, 0, 0, 0.15);
  padding: 9px 12px 8px;
  cursor: pointer;

  p {
    color: var(--color-brand-primary-darker-gray, #222);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    padding: 0px;
    margin: 0px;
  }
  img {
    padding: 8px 0px;
    width: 232px;
    height: 68px;
    object-fit: contain;
  }
  hr {
    background: var(--color-brand-primary-dark-gray, #444);
    padding: 0px;
    margin: 0px;
  }
  span {
    color: var(--color-brand-primary-medium-gray, #767676);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 0px;
    margin: 0px;
  }
`;

export const Alert = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 70%;
  padding: 7px;
  background: rgba(51, 102, 255, 0.1);
  border-radius: 4px;
  gap: 0.5rem;
  margin: 8px 0 16px 0;

  p {
    margin-bottom: 0px;
  }

  .ic_filled_circle_attention {
    font-size: 24px;
    color: #3366ff;
    border-radius: 50%;
  }
`;

export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 16px;
`;
