import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { Modal, Form } from 'react-bootstrap';
import moment from 'moment';
import Button from '../../Button';
import Accordion from '../../Accordion';
import { StyledModal, HeaderBody } from './styles';

import FinancialContent from './FinancialContent';
import InformationContent from './InformationContent';
import ValidityContent from './ValidityContent';

import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../../helpers/GlobalFunctions';
import {
  handlePostRequest,
  handlePutRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';

const _ = require('lodash');

export default function ModalCreateCoupoun({
  handleCloseModal,
  isOpenModal,
  userPermissions,
  item,
  getCouponIdState,
  setModalToastList,
}) {
  const payload = item
    ? {
        couponId: null,
        couponName: item.couponName,
        couponDescription: item.couponDescription,
        additionalCashback: item.additionCashback,
        additionalType: item.additionalType || 'FIXED',
        limitCouponCashback: item.limitCouponCashback,
        siteId: item.siteId,
        promoStartDate: item.promoStartDate,
        promoEndDate: item.promoEndDate,
        promoPriority: item.promoPriority,
        endDateRequired: false,
      }
    : {
        couponId: null,
        couponName: '',
        couponDescription: '',
        additionalCashback: 0,
        additionalType: 'FIXED',
        limitCouponCashback: 0,
        siteId: '',
        promoStartDate: '',
        promoEndDate: '',
        promoPriority: 10,
        endDateRequired: false,
      };

  const [validationFields, setValidationFields] = useState({
    couponId: {
      isValid: !!(item && item.couponId),
    },
    couponName: {
      isValid: !!(item && item.couponName),
    },
    couponDescription: {
      isValid: !!(item && item.couponDescription),
    },
    promoPriority: {
      isValid: !!(item && item.promoPriority),
    },
    promoStartDate: {
      isValid: !!(item && item.promoStartDate),
    },
    startTime: {
      isValid: !!(item && item.promoStartDate),
    },
    promoEndDate: {
      isValid: !!(item && item.promoEndDate),
    },
    endTime: {
      isValid: !!(item && item.promoEndDate),
    },
    additionalCashback: {
      isValid: !!(item && item.additionCashback),
    },
    limitCouponCashback: {
      isValid: !!(item && item.limitCouponCashback === ''),
    },
    siteId: {
      isValid: !!(item && item.siteId),
    },
  });
  const [isFieldsValid, setIsFieldsValid] = useState(false);
  const { keycloak } = useKeycloak();
  const [payloadBase, setPayloadBase] = useState({ ...payload });
  const [toastList, setToastList] = useState([]);
  const couponId = getCouponIdState || '';
  const FULL_URL = getCouponIdState
    ? `${window.SERVICES_DOMAIN}${window.COUPONS_URL}/${couponId}/update`
    : `${window.SERVICES_DOMAIN}${window.COUPONS_URL}/create`;

  const reqOptions = {
    headers: initialHeadersData(keycloak.token),
  };

  const populatePayloadRequest = (payloadData, fieldsValidation) => {
    setValidationFields({ ...validationFields, ...fieldsValidation });
    setPayloadBase({ ...payloadBase, ...payloadData });
  };

  useEffect(() => {
    const validateFields = _.values(validationFields).every(
      val => !!(val.isValid || ('isRequired' in val && !val.isRequired)),
    );

    setIsFieldsValid(validateFields);
  }, [validationFields]);

  const handleSubmit = () => {
    handleCloseModal();
  };

  const submitCoupon = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.COUPONS.CREATE',
        userPermissions.userGroups,
      ) ||
      userPermissions.checkUiPermissionsFn(
        'ESFERA.COUPONS.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      // Spected::'DD-MM-YYYY HH:mm:ss'
      payloadBase.promoStartDate = moment(
        `${payloadBase.promoStartDate} ${payloadBase.startTime}`,
        'DD-MM-YYYY HH:mm:ss',
      ).format('DD-MM-YYYY HH:mm:ss');
      delete payloadBase.startTime;

      payloadBase.promoEndDate = moment(
        `${payloadBase.promoEndDate} ${payloadBase.endTime}`,
        'DD-MM-YYYY HH:mm:ss',
      ).format('DD-MM-YYYY HH:mm:ss');
      delete payloadBase.endTime;

      if (payloadBase.endDateRequired) payloadBase.promoEndDate = null;

      delete payloadBase.endDateRequired;

      payloadBase.additionalCashback = parseInt(
        payloadBase.additionalCashback,
        10,
      );

      payloadBase.limitCouponCashback = parseInt(
        payloadBase.limitCouponCashback,
        10,
      );

      payloadBase.promoPriority = parseInt(payloadBase.promoPriority, 10);

      reqOptions.data = { ...reqOptions.data, ...payloadBase };
      (getCouponIdState
        ? handlePutRequest(`${FULL_URL}`, reqOptions)
        : handlePostRequest(`${FULL_URL}`, reqOptions)
      )
        .then(response => {
          const appendToast = populateToastMessagesGlobal(
            'success',
            'Sucesso',
            response.data.messages[0],
            'main',
          );
          setToastList([...toastList, appendToast]);
          setModalToastList([appendToast]);
        })
        .catch(error => {
          console.error(`Error method coupon = ${error}`);
          const errorMessage = formatErrorMessageToToast(error);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setToastList([...toastList, appendToast]);
          setModalToastList([appendToast]);
        });
      handleCloseModal();
    }
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Modal.Header>
        <span
          role="button"
          className="esfera-icon ic_filled_circle_close"
          onClick={handleCloseModal}
          onKeyDown={handleCloseModal}
          tabIndex={0}
          aria-label="space"
        >
          &nbsp;
        </span>
        <Modal.Title>Criar cupom</Modal.Title>
        <Button
          typeOfButton="primary"
          disabled={!isFieldsValid}
          onClick={submitCoupon}
        >
          Salvar e Publicar
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <HeaderBody.Title>Identificador</HeaderBody.Title>
          <HeaderBody.Numbers>
            <h2>{item && item.couponId}</h2>
            <h6>{item && item.couponId}</h6>
          </HeaderBody.Numbers>
        </div>
        <Form onSubmit={handleSubmit} className="container">
          <Accordion
            id="0"
            title="Informações do cupom"
            bodyAccordion={
              <InformationContent
                code={item && item.couponId}
                name={item && item.couponName}
                priority={item && item.promoPriority}
                description={item && item.couponDescription}
                getCouponIdState={getCouponIdState}
                populatePayloadRequest={populatePayloadRequest}
              />
            }
          />
          <Accordion
            id="1"
            title="Vigência"
            iconClassName="ic_channels_calendar"
            bodyAccordion={
              <ValidityContent
                beginDate={item && item.promoStartDate}
                beginHour=""
                endDate={item && item.promoEndDate}
                populatePayloadRequest={populatePayloadRequest}
              />
            }
          />

          <Accordion
            id="2"
            title="Condições financeiras"
            iconClassName="ic_banking_money"
            bodyAccordion={
              <FinancialContent
                additionalCashback={item && item.additionCashback}
                cashbackLimit={item && item.limitCouponCashback}
                channel={item && item.siteId}
                populatePayloadRequest={populatePayloadRequest}
              />
            }
          />
        </Form>
      </Modal.Body>
    </StyledModal>
  );
}
