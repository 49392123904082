import React, { useState } from 'react';

import ToggleButton from '../ToggleButton';
import Button from '../../Button';

import { StyledForm } from './styles';
import Tooltip from '../../Tooltip';

export default function OrdersFilter({
  currentFilter,
  handleFilter,
  exportList,
  isDownload,
}) {
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [status, setStatus] = useState(null);

  const handleStartDate = e => {
    setInitialDate(e.target.value);
  };
  const handleEndDate = e => {
    setFinalDate(e.target.value);
  };
  const handleDocument = e => {
    setCustomerId(e.target.value);
  };
  const handleOrder = e => {
    setOrderId(e.target.value);
  };
  const handleStatus = e => {
    setStatus(e.target.value);
  };

  const clearFilter = () => {
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.initialDate;
    delete filter.finalDate;
    delete filter.orderId;
    delete filter.customerId;
    delete filter.status;

    setInitialDate('');
    setFinalDate('');
    setCustomerId('');
    setOrderId('');
    setStatus('');

    handleFilter(filter);
  };

  const applyFilter = e => {
    e.preventDefault();
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.initialDate;
    delete filter.finalDate;
    delete filter.orderId;
    delete filter.customerId;
    delete filter.status;

    if (initialDate) filter.initialDate = initialDate;
    if (finalDate) filter.finalDate = finalDate;
    if (customerId) filter.customerId = customerId;
    if (orderId) filter.orderId = orderId;
    if (status) filter.status = status;

    handleFilter(filter);
  };

  const isFilterValid = () => {
    const isValid = status || initialDate || finalDate || customerId || orderId;
    return isValid;
  };

  return (
    <StyledForm>
      <div className="form-content">
        <StyledForm.Group className="form-group period-inputs">
          <StyledForm.Label>Período:</StyledForm.Label>
          <StyledForm.Control
            onChange={handleStartDate}
            type="date"
            value={initialDate}
          />
          <StyledForm.Control
            onChange={handleEndDate}
            type="date"
            value={finalDate}
          />
        </StyledForm.Group>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Pedido:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleOrder}
              type="text"
              value={orderId}
            />
          </StyledForm.Group>

          <StyledForm.Group className="form-group">
            <StyledForm.Label>CPF/CNPJ:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleDocument}
              type="text"
              value={customerId}
            />
          </StyledForm.Group>
        </div>

        <StyledForm.Group className="form-group">
          <StyledForm.Label>Status: </StyledForm.Label>
          <ToggleButton
            onChange={handleStatus}
            groupName="status"
            text="Confirmado"
            value="FRAUD_CHECK_APPROVED"
            checked={status === 'FRAUD_CHECK_APPROVED'}
          />
          <ToggleButton
            onChange={handleStatus}
            groupName="status"
            text="Cancelado"
            value="FRAUD_CHECK_REFUSED"
            checked={status === 'FRAUD_CHECK_REFUSED'}
          />
        </StyledForm.Group>
      </div>

      <div className="form-actions">
        <Button
          type="submit"
          typeOfButton="primary"
          onClick={applyFilter}
          disabled={!isFilterValid()}
        >
          Aplicar Filtros
        </Button>
        <button onClick={clearFilter} type="button" className="btn-link">
          Limpar Filtros
        </button>
        <Tooltip text="Você tem a opção de baixar todos os pedidos confirmados e cancelados em um único arquivo Excel.">
          <button onClick={exportList} type="button" className="btn-link">
            {isDownload === true
              ? 'Exportar Listas'
              : 'Download em andamento...'}
          </button>
        </Tooltip>
      </div>
    </StyledForm>
  );
}
