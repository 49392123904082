import styled, { css } from 'styled-components';

export const StyledTr = styled.tr`

  ${({ theme }) => css`

    height: 50px;

    td {
      font-family: ${theme.fonts.family.opensans};
      font-size: ${theme.fonts.textSize.small};
      color: ${theme.colors.darkerGrey};
      background: #F6F6F6;
      line-height: 16px;
      vertical-align: inherit;
      padding: 0.75rem;

      &:nth-child(1) {
        padding-left: 50px;
      }

      &:nth-child(3) {
        padding-left: 20px;
      }

      &:nth-child(4) {
        padding-left: 20px;
      }

      &.partner {
        display: flex;
        align-items: center;
        height: 64px;
      }

      &.status {
        width: fit-content;
        font-size: ${theme.fonts.textSize.small};
        font-weight: ${theme.fonts.semibold};
        color: #BD6402;
      }

      .esfera-icon {
        font-size: 22px;
        font-weight: 700;
        margin-right: 12px;
      }

      img {
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .container-actions {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;

      .iconEdit {
        font-size: 24px;
      }

      .iconMoreInfo {
        font-size: 24px;
      }

      > svg {
        cursor: pointer;
        font-size: 18px;
      }
    }
  `}
`;
