import React from 'react';
import useMenuMobile from '../../../hooks/useMenuMobile';
import NavGroupLink from './NavGroupLink';
import NavLink from './NavLink';

import { Container, StyledNavbar } from './styles';
import Footer from './Footer';

export default function SideMenu({ userPermissions }) {
  const { isOpenMenuMobile } = useMenuMobile();

  return (
    <Container isSideMenuMobileOpen={isOpenMenuMobile}>
      {/* <SiteSelector /> */}

      <StyledNavbar expand="lg">
        {userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.RULES.SEE_MAIN_MENU',
          userPermissions.userGroups,
        ) ||
        userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.ORDERS.SEE_MAIN_MENU',
          userPermissions.userGroups,
        ) ||
        userPermissions.checkUiPermissionsFn(
          'ONBOARDING.RULES.SEE_MAIN_MENU',
          userPermissions.userGroups,
        ) ? (
          <NavGroupLink title="Prevenção" iconClass="FA200">
            {userPermissions.checkUiPermissionsFn(
              'ANTIFRAUD.RULES.SEE_MENU',
              userPermissions.userGroups,
            ) ||
            userPermissions.checkUiPermissionsFn(
              'ANTIFRAUD.ORDERS.SEE_MENU',
              userPermissions.userGroups,
            ) ? (
              <NavLink href="/prevention">Prevenção</NavLink>
            ) : null}
            {userPermissions.checkUiPermissionsFn(
              'ONBOARDING.RULES.SEE_MENU',
              userPermissions.userGroups,
            ) ? (
              <NavLink href="/onboarding-conta">Onboarding Conta</NavLink>
            ) : null}
            {userPermissions.checkUiPermissionsFn(
              'ANTIFRAUD.RULES.SEE_MENU',
              userPermissions.userGroups,
            ) ||
            userPermissions.checkUiPermissionsFn(
              'ANTIFRAUD.ORDERS.SEE_MENU',
              userPermissions.userGroups,
            ) ? (
              <NavLink href="/block">Bloqueio de Clientes</NavLink>
            ) : null}
            {userPermissions.checkUiPermissionsFn(
              'ANTIFRAUD.RULES.SEE_MENU',
              userPermissions.userGroups,
            ) ||
            userPermissions.checkUiPermissionsFn(
              'ANTIFRAUD.ORDERS.SEE_MENU',
              userPermissions.userGroups,
            ) ? (
              <NavLink href="/unblock">Desbloqueio de Clientes</NavLink>
            ) : null}
          </NavGroupLink>
        ) : null}

        <NavGroupLink title="Marketing" iconClass="FA200">
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.COUPONS.SEE_MENU',
            userPermissions.userGroups,
          ) ? (
            <NavLink href="/coupons">Cupons</NavLink>
          ) : null}
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.MILES_CAMPAIGN.SEE_MENU',
            userPermissions.userGroups,
          ) ? (
            <NavLink href="/air-campaign" iconClass="">
              Campanhas aéreas
            </NavLink>
          ) : null}
        </NavGroupLink>
        {userPermissions.checkUiPermissionsFn(
          'ESFERA.MANUAL_ORDERS.SEE_MENU',
          userPermissions.userGroups,
        ) ? (
          <NavLink href="/manual-orders" iconClass="FA200">
            Pedidos Loja Esfera
          </NavLink>
        ) : null}

        {userPermissions.checkUiPermissionsFn(
          'ESFERA.MANUAL_TRANSFER_ORDERS.SEE_MENU',
          userPermissions.userGroups,
        ) ? (
          <NavLink href="/manual-orders-transfer" iconClass="FA200">
            Transferência para Parceiro
          </NavLink>
        ) : null}

        {userPermissions.checkUiPermissionsFn(
          'ESFERA.BLACKLIST.SEE_MENU',
          userPermissions.userGroups,
        ) ? (
          <NavLink href="/blocklist" iconClass="CB110">
            Blocklist
          </NavLink>
        ) : null}

        {userPermissions.checkUiPermissionsFn(
          'ESFERA.TRACKING.SEE_MENU',
          userPermissions.userGroups,
        ) ? (
          <NavLink href="/tracking" iconClass="CB110">
            Tracking
          </NavLink>
        ) : null}

        {userPermissions.checkUiPermissionsFn(
          'ESFERA.INCENTIVE_ENGINE.SEE_MENU',
          userPermissions.userGroups,
        ) ? (
          <NavGroupLink title="Motor de Incentivos" iconClass="FA200">
            <NavLink href="/transactions-to-release" iconClass="">
              Transações a Liberar
            </NavLink>
            <NavLink href="/transactions-pma" iconClass="">
              Transferências bancárias em PMA
            </NavLink>
            <NavLink href="/orders-pma" iconClass="">
              Pedidos em PMA
            </NavLink>
          </NavGroupLink>
        ) : null}

        {userPermissions.checkUiPermissionsFn(
          'AWIN.ORDERS.SEE_MENU',
          userPermissions.userGroups,
        ) ? (
          <NavLink href="/cashback-awin" iconClass="CB110">
            Cashback Awin
          </NavLink>
        ) : null}

        {userPermissions.checkUiPermissionsFn(
          'ESFERA.PARITY_CONTROL.SEE_MENU',
          userPermissions.userGroups,
        ) ? (
          <NavLink
            href="/partners-parity"
            iconClass="ic_banking_exchange_currency"
          >
            Paridade
          </NavLink>
        ) : null}
        <NavGroupLink title="Regras Especiais" iconClass="ic_banking_bank_loan">
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.CPP_RULES.SEE_MENU',
            userPermissions.userGroups,
          ) ? (
            <NavLink href="/cpp-rules">Valor diferenciado de CPP</NavLink>
          ) : null}
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.CASHPLUSPOINT_RULES.SEE_MENU',
            userPermissions.userGroups,
          ) ? (
            <NavLink href="/cash-plus-point-rules" iconClass="">
              Percentual mínimo para C+P
            </NavLink>
          ) : null}
        </NavGroupLink>

        {/*
        <NavLink href="/finances" iconClass="AF020">
          Finanças
        </NavLink>

        <NavLink href="/juridical" iconClass="D0360">
          Jurídico
        </NavLink>

        <NavGroupLink iconClass="AE130" title="Prevenção" navGroupOpen>
          <NavLinkRouterDom to="/" exact activeClassName="active">Análise de pedidos</NavLinkRouterDom>
          <NavLinkRouterDom to="/rules" activeClassName="active">Regras</NavLinkRouterDom>
        </NavGroupLink>

        <NavGroupLink iconClass="FA200" title="Audiência">
          <NavLinkRouterDom to="/campaigns" exact activeClassName="active">Campanhas</NavLinkRouterDom>
          <NavLinkRouterDom to="/cupons" exact activeClassName="active">Cupons</NavLinkRouterDom>
        </NavGroupLink> */}
      </StyledNavbar>
      <Footer />
    </Container>
  );
}
