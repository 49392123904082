import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment/moment';
import constants from '../../config';

// eslint-disable-next-line import/named
import {
  handleGetRequest,
  initialPaginationData,
} from '../../services/GenericMethods';
import ModalRules from '../../components/ModalRules';
import RulesTable from '../../components/RulesTable';
import Button from '../../components/Button';
import ToastCustom from '../../components/Toast';

import { Container } from './styles';
import warningIcon from '../../assets/img/ic-warning.png';
import errorIcon from '../../assets/img/ic-error.png';
import alertIcon from '../../assets/img/ic-alert.png';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

export default function AntifraudRules({ userPermissions }) {
  const retryOnError = true;
  const timesToRetry = 5;
  let initialRetryCounter = 0;

  const { keycloak, initialized } = useKeycloak();

  const FULL_URL = window.SERVICES_DOMAIN + window.ANTIFRAUD_RULES_URL;
  const [isOpenModalRegisterRule, setIsOpenModalRegisterRule] = useState(false);
  const [rulesFormatted, setRulesFormatted] = useState([]);
  const [user, setUser] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };

  const handleShowMdlRegisterRule = () => setIsOpenModalRegisterRule(true);
  const handleCloseMdlRegisterRule = (status, title, message) => {
    if (!!status && !!title && !!message)
      populateToastMessages(status, title, message, 'secundary');

    setIsOpenModalRegisterRule(false);
  };

  const getRules = actualPage => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ANTIFRAUD.RULES.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(
        FULL_URL,
        initialPaginationData('GET', actualPage, keycloak.token),
      )
        .then(response => {
          const responseFormatted = response.data.content.map(res => {
            let ruleImg;
            let ruleConfig;
            if (res.action === 'FRAUD_CHECK') {
              ruleImg = warningIcon;
              ruleConfig = 'fraud';
            } else if (res.action === 'REFUSED') {
              ruleImg = errorIcon;
              ruleConfig = 'barred';
            } else {
              ruleImg = alertIcon;
              ruleConfig = 'inactive';
            }

            res.createDate = moment(res.createDate).format('DD/MM/YYYY');
            res.updateDate = moment(res.updateDate).format('DD/MM/YYYY');

            res.action = constants[res.action];

            return {
              ...res,
              rulesImg: ruleImg,
              ruleConfig,
            };
          });

          setRulesFormatted(responseFormatted);
          setCurrentPage(response.data.number);
          setTotalPages(response.data.totalPages);
          setIsFirstPage(response.data.first);
          setIsLastPage(response.data.last);
        })
        .catch(error => {
          console.error(`Error at listRules request = ${error}`);

          let queueError = 'main';
          if (retryOnError && timesToRetry < initialRetryCounter) {
            initialRetryCounter += 1;
            queueError = 'secundary';
          }

          const errorMessage = formatErrorMessageToToast(error, keycloak);

          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            queueError,
          );
        });
    }
  };

  const handleLogout = () => {
    keycloak.logout();
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak
        .loadUserInfo()
        .then(userInfo => {
          setUser(userInfo);
        })
        .catch(error => {
          console.error(`Error keycloak ${error}`);
          handleLogout();
        });
    }
  }, [keycloak.authenticated, initialized]);

  useEffect(() => {
    getRules(0);
  }, [toastList]);

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <ModalRules
        isOpenModal={isOpenModalRegisterRule}
        handleCloseModal={handleCloseMdlRegisterRule}
        user={user}
      />

      <header>
        <h2>Cadastro de Regras</h2>
        {userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.RULES.CREATE',
          userPermissions.userGroups,
        ) ? (
          <Button typeOfButton="primary" onClick={handleShowMdlRegisterRule}>
            Cadastrar Nova Regra
          </Button>
        ) : null}
      </header>

      <section className="mt-4">
        {userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.RULES.READ',
          userPermissions.userGroups,
        ) ? (
          <RulesTable
            rules={rulesFormatted}
            handleCloseModal={handleCloseMdlRegisterRule}
            userPermissions={userPermissions}
          />
        ) : null}
      </section>

      <Pag
        currentPage={currentPage}
        totalPages={totalPages}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        onClickFnPage={getRules}
      />
    </Container>
  );
}
