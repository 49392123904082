import React, { useEffect, useState } from 'react';

import Header from './Layout/Header';
import Content from './Layout/Content';

import esferaLogo from '../../assets/esfera-logo.png';

export default function Dashboard({ userData }) {
  const [userInfo, setUsername] = useState([]);
  useEffect(() => {
    if (userData) {
      setUsername(userData);
    }
  }, [userData]);
  return (
    <>
      <Header
        iconHeader={esferaLogo}
        text="Gestão de Processos"
        user={userInfo}
      />
      <Content user={userInfo} />
    </>
  );
}
