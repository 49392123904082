import React from 'react';
import { Box, Item } from './styles';

export default function BoxOption({ display, items, setInputName }) {
  return (
    <Box>
      {display === true &&
        items.map(element => {
          return (
            <Item
              onClick={() => {
                setInputName(element);
              }}
              key={element}
            >
              {element}
            </Item>
          );
        })}
    </Box>
  );
}
