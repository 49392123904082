import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import * as S from './styles';
import PartnersParityDoc from '../../components/ListPartnersParityPage/PartnersParityDoc';
import Content from '../../components/Content';
import TextField from '../../components/TextField';
import ToastCustom from '../../components/Toast';
import RegisterParity from '../../components/ParityPartners/RegisterParity';
import CancelCampaign from '../../components/CreateCampaignPage/CancelCampaign';
import Button from '../../components/Button';
import {
  populateToastMessagesGlobal,
  formatErrorMessageToToast,
} from '../../helpers/GlobalFunctions';
import {
  handlePostRequest,
  handleGetRequest,
  initialHeadersData,
  handlePatchRequest,
} from '../../services/GenericMethods';
import Loading from '../../components/Loading';

export default function Campaign({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const { partnerId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const [isEdit, setIsEdit] = useState(false);
  const [isParam, setIsParam] = useState('');
  const [permissionEditCampaign, setPermissionEditCampaign] = useState(true);
  const URL = window.SERVICES_DOMAIN;
  const PARTNER_PARITY_GET = `${URL}${window.PARTNER_PARITY_GET}/${partnerId}?pageNumber=0&pageCount=10`;
  const CAMPAIGN_PARITY_GET = `${URL}${window.CAMPAIGN_PARITY_GET}`;
  const CAMPAIGN_APPROVAL_POST = `${URL}${window.CAMPAIGN_APPROVAL_POST}`;
  const CAMPAIGN_REJECTED_POST = `${URL}${window.CAMPAIGN_REJECTED_POST}`;
  const CAMPAIGN_CANCEL_POST = `${URL}${window.CAMPAIGN_CANCEL_POST}`;
  const [idCampaign, setIdCampaign] = useState('');
  const [nameCampaign, setNameCampaign] = useState('');
  const [statusCampaign, setStatusCampaign] = useState('');
  const [paritys, setParity] = useState([]);
  const [initDate, setInitDate] = useState('');
  const [initHour, setInitHour] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endHour, setEndHour] = useState('');
  const [toastList, setToastList] = useState([]);
  const [partner, setPartner] = useState({});
  const [defaultParity, setDefaultParity] = useState({});
  const [editedDefaultParity, setEditedDefaultParity] = useState(null);
  const [partityEdit, setParityEdit] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [action, setAction] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState([]);

  const getAllSelectedClubs = () => {
    const allSelectedClubs = [];

    if (Array.isArray(paritys)) {
      paritys.forEach(parityItem => {
        if (parityItem && Array.isArray(parityItem.clubs)) {
          parityItem.clubs.forEach(club => {
            if (club && club.selected) {
              allSelectedClubs.push(club.clubName);
            }
          });
        }
      });
    }

    setSelectedClubs(allSelectedClubs);
  };

  useEffect(() => {
    getAllSelectedClubs();
  }, [paritys]);

  const handleOpenDeleteModal = move => {
    setDeleteModalVisible(true);
    setAction(move);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalVisible(false);
    setAction('');
  };
  const selectedCampaignEdit = state?.campaingSelectEdit[0]
    ? state?.campaingSelectEdit[0]
    : null;
  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    setToastList([...toastList, appendToast]);
  };
  const selectedParitiesDefault = selectedCampaignEdit?.parities?.find(
    parity => parity.type === 'DEFAULT',
  );

  const defaultPointsFunction = data => {
    const updateDefaultParityPoints = {
      ...defaultParity,
      points: Number(data),
    };
    setEditedDefaultParity(updateDefaultParityPoints);
    setDefaultParity(updateDefaultParityPoints);
  };
  const defaultMilesFunction = data => {
    const updateDefaultParityMiles = {
      ...defaultParity,
      miles: Number(data),
    };
    setEditedDefaultParity(updateDefaultParityMiles);
    setDefaultParity(updateDefaultParityMiles);
  };
  const getPartnerData = () => {
    setShowLoading(true);
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };

      handleGetRequest(PARTNER_PARITY_GET, reqOptions)
        .then(({ data }) => {
          if (data.code === 200) {
            const defaultPartnerParity = data.body.parities.filter(
              paritie => paritie.type === 'DEFAULT',
            );
            setPartner(data.body);
            if (defaultPartnerParity) {
              if (editedDefaultParity) {
                setDefaultParity(editedDefaultParity);
                setEditedDefaultParity(null);
              } else if (selectedParitiesDefault) {
                setDefaultParity(selectedParitiesDefault);
              } else {
                setDefaultParity(defaultPartnerParity[0]);
              }
            }
          } else {
            populateToastMessages(
              'error',
              'Erro ao consultar parceiro',
              `Erro: ${data.messages}`,
            );
          }
        })
        .catch(error => {
          console.warn(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
        });
    }
  };
  const verifyEdit = async () => {
    try {
      const data = await state?.campaingSelectEdit[0];
      const param = await state?.extraParam;
      if (data !== undefined) {
        setIsEdit(true);
        setIsParam(param);
        if (param !== 'copy') {
          setNameCampaign(data.campaignName);
          setInitDate(
            moment(data.dateInit, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD'),
          );
          setEndDate(
            moment(data.dateEnd, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD'),
          );
          setInitHour(
            moment(data.dateInit, 'YYYY-MM-DD hh:mm:ss').format('HH:mm'),
          );
          setEndHour(
            moment(data.dateEnd, 'YYYY-MM-DD hh:mm:ss').format('HH:mm'),
          );
          const clubParities = data.parities.filter(
            parity => parity.type === 'CLUB',
          );
          setParityEdit(prevState => [...prevState, ...clubParities]);
          setParity([...paritys, ...data.parities]);
          if (data.status === 'ACTIVE' || param === 'view') {
            setPermissionEditCampaign(false);
          }
          setIdCampaign(data.id);
          setStatusCampaign(data.status);
        } else {
          const clubParities = data.parities.filter(
            parity => parity.type === 'CLUB',
          );
          setParityEdit(prevState => [...prevState, ...clubParities]);
          setParity([...paritys, ...data.parities]);
        }
        if (data.status === 'CANCELED' && param === 'view') {
          setEndDate(
            moment(data.dateCancelled, 'YYYY-MM-DD hh:mm:ss').format(
              'YYYY-MM-DD',
            ),
          );
          setEndHour(
            moment(data.dateCancelled, 'YYYY-MM-DD hh:mm:ss').format('HH:mm'),
          );
        }
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error, isEdit);
    }
  };
  const isValidFields = () => {
    if (!nameCampaign) {
      populateToastMessages(
        'error',
        'Erro ao cadastrar campanha',
        `Preencha o nome da campanha`,
      );
      setShowLoading(false);
      return false;
    }
    if (!initDate || !initHour || !endDate || !endHour) {
      populateToastMessages(
        'error',
        'Erro ao cadastrar campanha',
        `Preencha os campos de data e horário`,
      );
      setShowLoading(false);
      return false;
    }

    if (
      moment(moment(`${initDate} ${initHour}`, 'YYYY-MM-DD HH:mm')).isAfter(
        moment(`${endDate} ${endHour}`, 'YYYY-MM-DD HH:mm'),
      )
    ) {
      populateToastMessages(
        'error',
        'Erro ao cadastrar campanha',
        `A data final tem que ser depois da inicial`,
      );
      setShowLoading(false);
      return false;
    }

    return true;
  };
  const redirect = () => {
    const from = state?.from;

    if (isEdit && !(from === 'partner')) {
      history.push({
        pathname: `/campaign-history/${partnerId}`,
        state: { tabLocation: isParam },
      });
    } else {
      history.push(`/partners-parity/${partnerId}`);
    }
  };
  const register = () => {
    setShowLoading(true);
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      if (!isValidFields()) {
        return;
      }
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: {
          campaignName: nameCampaign,
          dateInit: `${initDate}T${initHour}:00.000Z`,
          dateEnd: `${endDate}T${endHour}:00.000Z`,
          createdBy: keycloak.userInfo?.email,
          partner: {
            id: partnerId,
            partnerName: partner.partnerName,
          },
          parities: [defaultParity, ...paritys].reduce((acc, cur) => {
            if (cur) {
              acc.push({
                ...cur,
                id: undefined,
                clubs: cur.clubs.filter(e => e.selected === true),
                type: cur.type === 'DEFAULT' ? 'DEFAULT' : 'CLUB',
              });
            }
            return acc;
          }, []),
        },
      };

      handlePostRequest(CAMPAIGN_PARITY_GET, reqOptions)
        .then(() => {
          populateToastMessages(
            'success',
            'Campanha cadastrada',
            'Campanha cadastrada com sucesso!',
          );
          setTimeout(() => {
            redirect();
          }, 1500);
        })
        .catch(error => {
          console.warn(`Error at post campaign request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };
  const registerCopy = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      if (!isValidFields()) {
        return;
      }
      setShowLoading(true);
      const hasParityDefault = [...paritys].some(parity => {
        if (parity.type === 'DEFAULT') return parity;
        return false;
      });
      let formattedParities = [];
      if (!hasParityDefault) {
        formattedParities = [defaultParity, ...paritys];
      } else {
        formattedParities = [...paritys];
      }
      const paritiesRemovedDefault = [...formattedParities].map(parity => {
        if (parity.type !== 'DEFAULT') return parity;
        const tempParity = parity;
        tempParity.points =
          defaultParity.points || selectedParitiesDefault?.points;
        tempParity.miles =
          defaultParity.miles || selectedParitiesDefault?.miles;
        return tempParity;
      });
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: {
          campaignName: nameCampaign,
          dateInit: `${initDate}T${initHour}:00.000Z`,
          dateEnd: `${endDate}T${endHour}:00.000Z`,
          createdBy: keycloak.userInfo?.email,
          partner: {
            id: partnerId,
            partnerName: partner.partnerName,
          },
          parities: [...paritiesRemovedDefault].reduce((acc, cur) => {
            if (cur) {
              acc.push({
                ...cur,
                id: undefined,
                clubs: cur.clubs.filter(e => e.selected === true),
                type: cur.type === 'DEFAULT' ? 'DEFAULT' : 'CLUB',
              });
            }
            return acc;
          }, []),
        },
      };

      handlePostRequest(CAMPAIGN_PARITY_GET, reqOptions)
        .then(() => {
          populateToastMessages(
            'success',
            'Campanha cadastrada',
            'Campanha cadastrada com sucesso!',
          );

          setTimeout(() => {
            redirect();
          }, 1500);
        })
        .catch(error => {
          console.warn(`Error at post campaign request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };
  const cancelCampaign = campaingId => {
    setShowLoading(true);
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      if (!isValidFields()) {
        return;
      }
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: {
          partnerCampaign: {
            partnerId,
            campaignId: campaingId,
          },
          status: 'CANCELED',
          updatedBy: keycloak.userInfo?.name,
        },
      };
      handlePostRequest(CAMPAIGN_CANCEL_POST, reqOptions)
        .then(() => {
          populateToastMessages(
            'success',
            'Campanha cancelada',
            'Campanha cancelada com sucesso!',
          );
          setTimeout(() => {
            redirect();
          }, 1500);
        })
        .catch(error => {
          console.warn(`Error at patch campaign request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
        });
    }
  };
  const removeCampaign = campaingId => {
    setShowLoading(true);
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      if (!isValidFields()) {
        return;
      }
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: {
          partnerCampaign: {
            partnerId,
            campaignId: campaingId,
          },
          status: 'DELETED',
          updatedBy: keycloak.userInfo?.name,
        },
      };
      handlePostRequest(CAMPAIGN_REJECTED_POST, reqOptions)
        .then(() => {
          populateToastMessages(
            'success',
            'Campanha removida',
            'Campanha removida com sucesso!',
          );
        })
        .catch(error => {
          console.warn(`Error at patch campaign request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
          setTimeout(() => {
            redirect();
          }, 1500);
        });
    }
  };
  const updateCampaign = () => {
    setShowLoading(true);
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      if (!isValidFields()) {
        return;
      }
      const hasParityDefault = [...paritys].some(parity => {
        if (parity.type === 'DEFAULT') return parity;
        return false;
      });
      let formattedParities = [];
      if (!hasParityDefault) {
        formattedParities = [defaultParity, ...paritys];
      } else {
        formattedParities = [...paritys];
      }
      const paritiesRemovedDefault = [...formattedParities].map(parity => {
        if (parity.type !== 'DEFAULT') return parity;
        const tempParity = parity;
        tempParity.points =
          defaultParity.points || selectedParitiesDefault?.points;
        tempParity.miles =
          defaultParity.miles || selectedParitiesDefault?.miles;
        return tempParity;
      });
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: {
          partnerCampaign: {
            partnerId,
            campaignId: idCampaign,
          },
          campaignName: nameCampaign,
          dateInit: `${initDate}T${initHour}:00.000Z`,
          dateEnd: `${endDate}T${endHour}:00.000Z`,
          status: statusCampaign,
          updatedBy: keycloak.userInfo?.email,
          parities: [...paritiesRemovedDefault].reduce((acc, cur) => {
            if (cur) {
              acc.push({
                ...cur,
                id: cur.type ? cur.id : undefined,
                clubs: cur.clubs.filter(e => e.selected === true || e.id),
                type: cur.type === 'DEFAULT' ? 'DEFAULT' : 'CLUB',
              });
            }
            return acc;
          }, []),
        },
      };
      handlePatchRequest(CAMPAIGN_PARITY_GET, reqOptions)
        .then(() => {
          populateToastMessages(
            'success',
            'Campanha atualizada',
            'Campanha atualizada com sucesso!',
          );
          setTimeout(() => {
            redirect();
          }, 1500);
        })
        .catch(error => {
          console.warn(`Error at patch campaign request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
        });
    }
  };
  const approvalCampaign = () => {
    setShowLoading(true);
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      if (!isValidFields()) {
        return;
      }
      const hasParityDefault = [...paritys].some(parity => {
        if (parity.type === 'DEFAULT') return parity;
        return false;
      });
      let formattedParities = [];
      if (!hasParityDefault) {
        formattedParities = [defaultParity, ...paritys];
      } else {
        formattedParities = [...paritys];
      }
      const paritiesRemovedDefault = [...formattedParities].map(parity => {
        if (parity.type !== 'DEFAULT') return parity;
        const tempParity = parity;
        tempParity.points =
          defaultParity.points || selectedParitiesDefault?.points;
        tempParity.miles =
          defaultParity.miles || selectedParitiesDefault?.miles;
        return tempParity;
      });

      const reqOptionsUpdate = {
        headers: initialHeadersData(keycloak.token),
        data: {
          partnerCampaign: {
            partnerId,
            campaignId: idCampaign,
          },
          campaignName: nameCampaign,
          dateInit: `${initDate}T${initHour}:00.000Z`,
          dateEnd: `${endDate}T${endHour}:00.000Z`,
          status: statusCampaign,
          updatedBy: keycloak.userInfo?.name,
          parities: [...paritiesRemovedDefault].reduce((acc, cur) => {
            if (cur) {
              acc.push({
                ...cur,
                id: cur.type ? cur.id : undefined,
                clubs: cur.clubs.filter(e => e.selected === true || e.id),
                type: cur.type === 'DEFAULT' ? 'DEFAULT' : 'CLUB',
              });
            }
            return acc;
          }, []),
        },
      };
      handlePatchRequest(CAMPAIGN_PARITY_GET, reqOptionsUpdate)
        .then(() => {
          const reqOptionsApprove = {
            headers: initialHeadersData(keycloak.token),
            data: {
              partnerCampaign: {
                partnerId,
                campaignId: idCampaign,
              },
              status: 'APPROVED',
              updatedBy: keycloak.userInfo?.name,
            },
          };
          handlePostRequest(CAMPAIGN_APPROVAL_POST, reqOptionsApprove)
            .then(() => {
              populateToastMessages(
                'success',
                'Campanha aprovada',
                'Campanha aprovada com sucesso!',
              );
            })
            .catch(error => {
              console.warn(`Error at patch campaign request = ${error}`);
              const errorMessage = formatErrorMessageToToast(error, keycloak);
              populateToastMessages(
                'error',
                'Erro',
                errorMessage.messagePTBR,
                'main',
              );
            })
            .finally(function () {
              setShowLoading(false);
              setTimeout(() => {
                redirect();
              }, 1500);
            });
        })
        .catch(error => {
          setShowLoading(false);
          console.warn(`Error at patch campaign request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };
  const rejectCampaign = campaingId => {
    setShowLoading(true);
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      if (!isValidFields()) {
        return;
      }
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: {
          partnerCampaign: {
            partnerId,
            campaignId: campaingId,
          },
          status: 'REJECTED',
          updatedBy: keycloak.userInfo?.name,
        },
      };
      handlePostRequest(CAMPAIGN_APPROVAL_POST, reqOptions)
        .then(() => {
          populateToastMessages(
            'success',
            'Campanha rejeitada',
            'Campanha rejeitada com sucesso!',
          );
        })
        .catch(error => {
          console.warn(`Error at patch campaign request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
          setTimeout(() => {
            redirect();
          }, 1500);
        });
    }
  };
  const handleMoveAction = campaingId => {
    if (action === 'disable') {
      cancelCampaign(campaingId);
    } else if (action === 'reject') {
      rejectCampaign(campaingId);
    } else if (action === 'remove') {
      removeCampaign(campaingId);
    }
  };
  const buttonsDisable = (
    <div className="two-buttons">
      <Button
        typeOfButton="secondary"
        disabled={showLoading}
        onClick={() => handleOpenDeleteModal('disable')}
      >
        Desativar campanha
      </Button>
    </div>
  );
  const buttonsApproval = (
    <div className="two-buttons">
      <Button
        typeOfButton="secondary"
        disabled={showLoading}
        onClick={() => handleOpenDeleteModal('reject')}
      >
        Rejeitar
      </Button>
      {isEdit && permissionEditCampaign && (
        <Button
          type="button"
          disabled={showLoading}
          onClick={() => approvalCampaign()}
        >
          Aprovar
        </Button>
      )}
    </div>
  );
  const buttonsEdit = (
    <div className="two-buttons">
      <Button
        typeOfButton="secondary"
        onClick={() => handleOpenDeleteModal('remove')}
      >
        Excluir Campanha
      </Button>
      {isEdit && permissionEditCampaign && (
        <Button
          type="button"
          disabled={showLoading}
          onClick={() => updateCampaign()}
        >
          Salvar Alterações
        </Button>
      )}
    </div>
  );
  useEffect(() => {
    getPartnerData();
    verifyEdit();
  }, []);

  return (
    <>
      <Content>
        <S.Container>
          <S.CreateCampaignHeader>
            <div className="contentmain__header">
              <div className="contentmain__header__title">
                <h2 className="contentmain__header__text">Campanha</h2>
              </div>
              <PartnersParityDoc />
              <div className="contentmain__header__title__2">
                <h2 className="partner-name">{partner?.partnerName}</h2>
              </div>
            </div>
          </S.CreateCampaignHeader>
          <S.CreateCampaignBody>
            <div className="contentmain__body">
              <div className="contentmain__body__field name_campaign">
                <div className="contentmain__body__name">
                  <span className="contentmain__body__text">Nome</span>
                </div>
                <div className="contentmain__body__value">
                  <TextField
                    id="Nome da campanha"
                    idName="additionalCashback"
                    label="Nome da campanha"
                    type="text"
                    value={nameCampaign}
                    disabled={isEdit && !permissionEditCampaign}
                    onChange={e => {
                      setNameCampaign(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="contentmain__body__field term_campaign">
                <div className="contentmain__body__name">
                  <span className="contentmain__body__text">Prazo</span>
                </div>

                <div className="contentmain__body__fields">
                  <div className="contentmain__body__field">
                    <div className="contentmain__body__date">
                      <TextField
                        id="dataInicial"
                        label="Data inicial"
                        type="date"
                        disabled={isEdit && !permissionEditCampaign}
                        value={initDate}
                        onChange={e => {
                          setInitDate(e.target.value);
                        }}
                      />
                    </div>

                    <div className="contentmain__body__time">
                      <TextField
                        id="horaInicial"
                        label="Horário inicial"
                        type="time"
                        disabled={isEdit && !permissionEditCampaign}
                        value={initHour}
                        onChange={e => {
                          setInitHour(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="contentmain__body__field">
                    <div className="contentmain__body__date">
                      <TextField
                        id="dataFinal"
                        label="Data final"
                        type="date"
                        disabled={isEdit && !permissionEditCampaign}
                        value={endDate}
                        onChange={e => {
                          setEndDate(e.target.value);
                        }}
                      />
                    </div>

                    <div className="contentmain__body__time">
                      <TextField
                        id="horaFinal"
                        label="Horário final"
                        type="time"
                        disabled={isEdit && !permissionEditCampaign}
                        value={endHour}
                        onChange={e => {
                          setEndHour(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <RegisterParity
              userPermissions={userPermissions}
              selectedCampaignEdit={selectedCampaignEdit}
              defaultPointsFunction={defaultPointsFunction}
              defaultMilesFunction={defaultMilesFunction}
              setParity={setParity}
              parities={paritys}
              selectedClubs={selectedClubs}
              defaultParity={defaultParity}
              editParity={isEdit ? partityEdit : null}
              permissionEditParity={permissionEditCampaign}
            />

            <div className="container-buttons">
              <Button typeOfButton="secondary" onClick={() => redirect()}>
                Voltar
              </Button>
              {!isEdit &&
                isParam === '' &&
                userPermissions.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  userPermissions.userGroups,
                ) && (
                  <Button
                    type="button"
                    onClick={() => register()}
                    disabled={showLoading}
                  >
                    Cadastrar
                  </Button>
                )}
              {isEdit &&
                isParam === 'copy' &&
                userPermissions.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  userPermissions.userGroups,
                ) && (
                  <Button
                    type="button"
                    onClick={() => registerCopy()}
                    disabled={showLoading}
                  >
                    Cadastrar
                  </Button>
                )}
              {isEdit &&
                isParam === 'disable' &&
                userPermissions.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  userPermissions.userGroups,
                ) &&
                buttonsDisable}

              {isEdit &&
                isParam === 'approved' &&
                userPermissions.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  userPermissions.userGroups,
                ) &&
                buttonsApproval}

              {isEdit &&
                isParam === 'edit' &&
                userPermissions.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  userPermissions.userGroups,
                ) &&
                buttonsEdit}
            </div>
            {deleteModalVisible && (
              <CancelCampaign
                method={action}
                campaingId={idCampaign}
                isOpenModal={deleteModalVisible}
                handleCloseModal={handleCloseDeleteModal}
                handleFunctionCampaign={handleMoveAction}
              />
            )}
          </S.CreateCampaignBody>
          <ToastCustom
            toastList={toastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
        </S.Container>
      </Content>
      <Loading showLoading={showLoading} />
    </>
  );
}
