import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Button from '../../Button';
import Loading from '../../Loading';

import { StyledModal, WrapperButtons } from './styles';
import {
  handleDeleteRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';

import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalRefusedOrder({
  product,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.BLACKLIST_REMOVE_PRODUCT_URL;
  const [showLoading, setShowLoading] = useState(false);

  const handleRemoveProductFromBlacklist = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.BLACKLIST.DELETE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'DELETE',
        headers: initialHeadersData(keycloak.token),
        data: [product.identifier],
      };

      handleDeleteRequest(`${FULL_URL}`, reqOptions)
        .then(() => {
          setShowLoading(false);
          handleCloseModal(
            'success',
            'Sucesso!',
            `Produto removido da Blacklist.`,
          );
        })
        .catch(error => {
          setShowLoading(false);
          console.error(
            'Error at handleRemoveProductFromBlacklist request',
            error,
          );
          console.error(`
            Error at handleRemoveProductFromBlacklist request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
    handleCloseModal();
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Remoção</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div>
          <p>Deseja realmente remover o seguinte produto da Blacklist: </p>
          <span>
            <strong>Produto</strong>: {product.identifier}
          </span>
        </div>

        <WrapperButtons>
          <Button
            typeOfButton="secondary"
            onClick={handleRemoveProductFromBlacklist}
          >
            Sim
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Não
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
