import styled, { css } from 'styled-components';

export const contentMainPartnersParity = styled.div`
  .content__box__partner {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 33px;

    a{
      text-decoration: none;
      color: #262626;
    }
  }
  .content__item__partner {
    display: flex;
    justify-content: space-between;
    height: 68px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
    background-color: unset;
    
    .contentmain__header__icon {
      align-self: center;
      font-size: 25px;
    }
    span {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 700;
    }
    img {
      margin-right: 20px;
      width: 36px;
    }
  }
`;
