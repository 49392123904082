import styled from 'styled-components';

export const LoadingComponent = styled.main`
  position: absolute;
  width: 100%;
  height: 100%;
  visibility:hidden;
  .loading-box-compass {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #eee;
    z-index: 11;
    opacity: .9;
    visibility: visible;
  }

  .loading-content-compass {
    z-index: 12;
    position: relative;
    padding-top: 25%;
    visibility: visible;
  }

`;
