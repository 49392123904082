import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${theme.fonts.family.roboto};
    font-size: ${theme.fonts.textSize.small};
    font-weight: ${theme.fonts.medium};
    color: ${theme.colors.lightGrey};
    background-color: ${theme.colors.darkerGrey};
    border-radius: 50%;
  `}
`;
