import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { formattedNameUser } from '../../../../services/Utils';
import * as S from './styles';

export default function Header({ iconHeader, text, user }) {
  const { keycloak } = useKeycloak();
  const handleLogout = () => {
    keycloak.logout();
  };
  const navDropDownTitle = formattedNameUser({ name: user.name });
  return (
    <S.Container>
      <S.StyledNavbar collapseOnSelect expand="lg">
        <S.StyledBrandHeader>
          <img src={iconHeader} alt="" />
          <span>|</span>
          <span>{text}</span>
        </S.StyledBrandHeader>

        <S.StyledActionsHeader>
          <S.StyledNavDropdown
            title={navDropDownTitle}
            id="collasible-nav-dropdown"
            className="ml-auto"
          >
            <S.StyledNavDropdown.Item onClick={handleLogout}>
              Sair
            </S.StyledNavDropdown.Item>
          </S.StyledNavDropdown>

          <S.StyledNavbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {}}
          />
        </S.StyledActionsHeader>
      </S.StyledNavbar>
    </S.Container>
  );
}
