import React from 'react';
import ReactTooltip from 'react-tooltip';
import Button from '../../Button';
import * as S from './styles';

export default function TransactionsTableHead({ headTableData }) {
  return (
    <S.Container>
      <tr>
        {headTableData.map(item => (
          <th key={item.title}>
            <S.WrapperHeader>
              {item.title}
              {!!item.hasIcoSort && (
                <>
                  <Button data-tip data-for="registerTip">
                    <span
                      aria-hidden="true"
                      className="ic_system_circle_information esfera-icon"
                    />
                  </Button>
                  <ReactTooltip
                    id="registerTip"
                    place="top"
                    effect="solid"
                    className="tooltip-user"
                  >
                    Usuário responsável pelo cadastro ou última atualização, da
                    campanha.
                  </ReactTooltip>
                </>
              )}
            </S.WrapperHeader>
          </th>
        ))}
      </tr>
    </S.Container>
  );
}
