import styled from 'styled-components';

import { Modal } from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 600px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .form_label_delete {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Open Sans';
    line-height: 20px;
  }

  .form_label_code {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Open Sans';
    line-height: 20px;
    padding-left: 5px;
  }

  .modal-header {
    border: 0;

    .modal-title {
      font-family: 'Poppins';
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
  }
  .form_control {
    width: 60%;
  }
  .container-buttons {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    button {
      & + button {
        margin-left: 1rem;
      }
      margin-left: 20px;
      width: 102px;
    }
  }
  .close {
    color: #cc0000;
    font-weight: 100;
    font-size: 38px;
    opacity: 1;
    z-index: 10;
  }
  .loading-content-compass{
    padding-top: 25%;
  }
`;
