import styled from 'styled-components';

import { Form } from 'react-bootstrap';

export const Container = styled.div`
  padding: 0 0 0 16px;
  height: 100vh;

  .contentmain__header {
    width: 100%;
    border-bottom: 1px solid var(--linecolor-gray);
    display: flex;
    min-height: 72px;
  }

  .contentmain__header__text {
    padding: 16px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
  }

  .contentmain__header__text__active {
    font-weight: 600;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  width: fit-content;
  margin-top: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: #f5f9fb;

  font-size: 14px;
  color: #444444;

  i {
    color: #3366ff;
    font-size: 20px;
  }
`;

export const StyledForm = styled(Form)`
  margin-top: 1.5rem;
  margin-right: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .form-group {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;

    @media (max-width: 473px) {
      width: 100%;
      margin-right: 0;
    }
  }

  label {
    font-size: 0.875rem;
    margin: 0px;
    font-weight: 600;
  }

  .input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .input-error {
    color: var(--danger);
    font-size: 12px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 1rem;

  select {
    max-width: 93px;
    min-width: 93px !important;
    height: auto !important;
    align-self: flex-start;
  }

  .form-group {
    flex-wrap: wrap;
    gap: 4px 10px;
  }

  .form-control {
    flex: 1;
    min-width: 220px;
    height: 69px;

    @media (max-width: 473px) {
      width: 100%;
      flex: 1;
    }
  }
`;
