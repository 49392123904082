import styled from 'styled-components';

export const Container = styled.thead`
    overflow: hidden;
    border: 0;
    background: var(--gray-table);

    th {
      border: 0;
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 500;
      color: var(--gray-200);
      line-height: 16px;
    }
`;

export const WrapperHeader = styled.div`
    display: flex;
    align-items: center;

    button {
      margin-left: 1px;
      border: 0;
      background: transparent;
      outline: 0;

      .icoArrow {
        font-size: 14px;
        line-height: 16px;
      }

      .icoArrow::before {
        color: var(--gray-100);
      }
    }
`;
