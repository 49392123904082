import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import Button from '../../Button';
import { StyledModal } from './styles';

export default function CancelCampaign({
  method,
  campaingId,
  handleCloseModal,
  isOpenModal,
  handleFunctionCampaign,
}) {
  const handleSubmit = e => {
    e.preventDefault();
  };

  const handleCampaign = () => {
    handleFunctionCampaign(campaingId);
    handleCloseModal();
  };

  const renderTitle = () => {
    const methodToTitleMap = {
      disable: 'Desativar campanha',
      reject: 'Rejeitar campanha',
      remove: 'Excluir campanha',
    };
    return methodToTitleMap[method] || '';
  };

  const renderText = () => {
    const methodToTextMap = {
      disable: 'Tem certeza que deseja desativar esta campanha?',
      reject: 'Tem certeza que deseja rejeitar esta campanha?',
      remove: 'Tem certeza que deseja excluir esta campanha?',
    };
    return methodToTextMap[method] || '';
  };

  const renderButton = () => {
    const methodToButtonMap = {
      disable: 'Desativar',
      reject: 'Rejeitar',
      remove: 'Excluir',
    };
    return methodToButtonMap[method] || '';
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{renderTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <p>{renderText()}</p>
          <div className="container-buttons">
            <Button typeOfButton="secondary" onClick={handleCloseModal}>
              Voltar
            </Button>
            <Button type="submit" onClick={handleCampaign}>
              {renderButton()}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  );
}
