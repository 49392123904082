import React, { useEffect, useState } from 'react';
import Button from '../../Button';
import { StyledForm } from './styles';

export default function OrdersFilter({
  allPartner,
  currentFilter,
  handleFilter,
}) {
  const [startDate, setStartDate] = useState('');
  const [saleAmount, setSaleAmount] = useState('');
  const [partner, setPartner] = useState('DEFAULT');

  const handleStartDate = e => {
    setStartDate(e.target.value);
  };

  const handleSaleAmount = e => {
    setSaleAmount(e.target.value);
  };

  const handlePartner = e => {
    setPartner(e.target.value);
  };

  const clearFilter = () => {
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.startDate;
    delete filter.partner;
    delete filter.saleAmount;

    setStartDate('');
    setPartner('DEFAULT');
    setSaleAmount('');

    handleFilter(filter);
  };

  const applyFilter = e => {
    e.preventDefault();
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.startDate;
    delete filter.saleAmount;
    delete filter.partner;

    if (startDate) filter.startDate = startDate;
    if (partner !== 'DEFAULT') filter.partner = partner;
    if (saleAmount) filter.saleAmount = saleAmount;

    handleFilter(filter);
  };

  const isFilterValid = () => {
    const isValid = partner !== 'DEFAULT' || startDate || saleAmount;
    return isValid;
  };

  useEffect(() => {
    setStartDate('');
    setSaleAmount('');
    setPartner('DEFAULT');
  }, [currentFilter]);

  return (
    <StyledForm>
      <div className="form-content">
        <StyledForm.Group className="form-group period-inputs">
          <StyledForm.Label>Data:</StyledForm.Label>
          <StyledForm.Control
            onChange={handleStartDate}
            type="date"
            value={startDate}
          />
        </StyledForm.Group>

        <StyledForm.Group>
          <StyledForm.Label>Loja: </StyledForm.Label>
          <StyledForm.Control
            as="select"
            value={partner}
            onChange={handlePartner}
          >
            <option disabled value="DEFAULT">
              Todas as lojas
            </option>
            {allPartner.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </StyledForm.Control>
        </StyledForm.Group>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Valor da Compra (Awin):</StyledForm.Label>
            <StyledForm.Control
              onChange={handleSaleAmount}
              type="text"
              value={saleAmount}
            />
          </StyledForm.Group>
        </div>
      </div>

      <div className="form-actions">
        <Button
          type="submit"
          typeOfButton="primary"
          onClick={applyFilter}
          disabled={!isFilterValid()}
        >
          Aplicar Filtros
        </Button>
        <button
          onClick={clearFilter}
          type="button"
          className="btn-clear-filter"
        >
          Limpar Filtros
        </button>
      </div>
    </StyledForm>
  );
}
