import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as S from './styles';
import constants from '../../../config';
import ModalReprocessTransactionPma from '../ModalReprocessTransactionPma';
import ModalRejectTransactionPma from '../ModalRejectTransactionPma';

export default function TransactionsPmaTableBody({
  item,
  isModifiedTransactionFile,
  userPermissions,
  reloadAfterAction,
}) {
  const closeViewTable = () => {};
  const [isOpenModalReprocess, setIsOpenModalReprocess] = useState(false);
  const [isOpenModalReject, setIsOpenModalReject] = useState(false);

  const handleShowModalReprocess = event => {
    event.stopPropagation();
    setIsOpenModalReprocess(true);
  };
  const handleShowModalReject = event => {
    event.stopPropagation();
    setIsOpenModalReject(true);
  };
  const handleCloseMdlReprocess = () => {
    setIsOpenModalReprocess(false);
  };
  const handleCloseMdlReject = () => {
    setIsOpenModalReject(false);
  };

  useEffect(() => {
    closeViewTable();
  }, [isModifiedTransactionFile]);
  return (
    <>
      <S.StyledTr>
        <td className="partner">
          {item.comarchTransactionId ? item.comarchTransactionId : '---'}
        </td>
        <td>{item.partnerOrderId ? item.partnerOrderId : '---'}</td>
        <td>{item.orderId ? item.orderId : '---'}</td>
        <td>{item.status ? item.status : '---'}</td>
        <td>{item.document ? item.document : '---'}</td>
        <td className="value">
          {item.cashbackValueDouble
            ? Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(Number(item.cashbackValueDouble))
            : 'R$ 0,00'}
        </td>
        <td>{item.campaignName ? item.campaignName : '---'}</td>
        <td>{item.campaignCode ? item.campaignCode : '---'}</td>
        <td className="message-content">
          {item.message ? item.message : '---'}
        </td>

        <td>
          {item.createDate
            ? moment(item.createDate).format('DD/MM/YYYY HH:mm')
            : '---'}
        </td>
        <td>
          {item.updateDate
            ? moment(item.updateDate).format('DD/MM/YYYY HH:mm')
            : '---'}
        </td>
        <td>
          <S.WrapperButton>
            {userPermissions.checkUiPermissionsFn(
              'ESFERA.INCENTIVE_ENGINE.READ',
              userPermissions.userGroups,
            ) ? (
              <>
                {(constants.TRANSACTIONS_PMA_STATUS_REPROCESS
                  .PMA_LINE_CREATION === item.status && (
                  <>
                    <button
                      type="button"
                      className="btn-action"
                      onClick={handleShowModalReprocess}
                    >
                      <span className="confirm">Reprocessar</span>
                    </button>
                    <button
                      type="button"
                      className="btn-action"
                      onClick={handleShowModalReject}
                    >
                      <span className="recused">Rejeitar</span>
                    </button>
                  </>
                )) ||
                  (constants.TRANSACTIONS_PMA_STATUS_REPROCESS.PMA_CREATION ===
                    item.status && (
                    <>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={handleShowModalReprocess}
                      >
                        <span className="confirm">Reprocessar</span>
                      </button>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={handleShowModalReject}
                      >
                        <span className="recused">Rejeitar</span>
                      </button>
                    </>
                  )) ||
                  (constants.TRANSACTIONS_PMA_STATUS_REPROCESS
                    .PMA_REDEMPTION === item.status && (
                    <>
                      <button
                        type="button"
                        className="btn-action"
                        disabled="true"
                      >
                        <span className="confirm">Reprocessar</span>
                      </button>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={handleShowModalReject}
                      >
                        <span className="recused">Rejeitar</span>
                      </button>
                    </>
                  )) || (
                    <>
                      <button
                        type="button"
                        className="btn-action"
                        disabled="true"
                      >
                        <span className="confirm">Reprocessar</span>
                      </button>
                      <button
                        type="button"
                        className="btn-action"
                        disabled="true"
                      >
                        <span className="recused">Rejeitar</span>
                      </button>
                    </>
                  )}
              </>
            ) : null}
          </S.WrapperButton>
        </td>
      </S.StyledTr>
      <ModalReprocessTransactionPma
        item={item}
        isOpenModal={isOpenModalReprocess}
        handleCloseModal={handleCloseMdlReprocess}
        reloadAfterAction={reloadAfterAction}
      />
      <ModalRejectTransactionPma
        item={item}
        isOpenModal={isOpenModalReject}
        handleCloseModal={handleCloseMdlReject}
        reloadAfterAction={reloadAfterAction}
      />
    </>
  );
}
