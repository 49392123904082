/* eslint-disable */
import React, { useState } from 'react';
import moment from 'moment';
import ModalApproveAwin from '../ModalApproveAwin';
import confirmedImg from '../../../assets/img/ic-check.png';
import { StyledTr, WrapperButton } from './styles';

export default function EsferaItem({
  order,
  userPermissions,
  handleShowMessageAction,
}) {
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);

  const handleShowMdlConfirmOrder = event => {
    event.stopPropagation();
    setIsOpenModalConfirm(true);
  };

  const handleCloseMdlConfirmOrder = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalConfirm(false);
  };

  return (
    <>
      <StyledTr>
        <td>{order.awinPostReturn.transactionId || ' - '}</td>

        <td>{order.awinPostReturn.transactionAmount || ' - '}</td>

        <td>{order.awinPostReturn.comissao || ' - '}</td>

        {/* <td>{moment(order.awinPostReturn.transactionDate).format('DD/MM/YYYY HH:mm')}</td> */}
        <td>{moment(order.awinPostReturn.transactionDate).format('DD/MM/YYYY HH:mm')}</td>

        <td>{order.partnerName || ' - '}</td>

        <td>
          {((order.status === 'withClickref') && (
              <WrapperButton>
                <img src={confirmedImg} alt="" />
                <span className="confirm">Já Associado</span>
              </WrapperButton>
            )) || (
              <WrapperButton>
                {userPermissions.checkUiPermissionsFn(
                  'AWIN.ORDERS.UPDATE',
                  userPermissions.userGroups,
                ) ? (
                  <>
                    <button
                      type="button"
                      className="btn-action"
                      onClick={handleShowMdlConfirmOrder}
                    >
                      <span className="confirm">Associar cliente</span>
                    </button>
                  </>
                ) : null}
              </WrapperButton>
            )}
        </td>
      </StyledTr>

      <ModalApproveAwin
        order={order}
        isOpenModal={isOpenModalConfirm}
        handleCloseModal={handleCloseMdlConfirmOrder}
        userPermissions={userPermissions}
      />
    </>
  );
}
