import styled from 'styled-components';

export const Container = styled.main`
  .toast-compasso {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }
  
  .toast-compasso-success {
    background-color: rgba(55,231,145,.8);
  }

  .toast-compasso-error {
    background-color: var(--boston);
  }

  .text-flag {
    display: flex;
    margin-bottom: 25px;
  }

  .contentmain__header__text {
    padding: 16px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
  }

  .subtitle {
    font-size: 19px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 440px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h2 {
      font-size: 22px;
      color: var(--gray-50);
      font-weight: 700;

      @media (max-width: 440px) {
        margin-bottom: 16px;
      }
    }
  }
`;
