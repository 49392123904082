import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import Content from '../../components/Content';
import Loading from '../../components/Loading';
import * as S from './styles';
import RegisterParity from '../../components/ParityPartners/RegisterParity';
import PartnersParityDoc from '../../components/ListPartnersParityPage/PartnersParityDoc';
import ToastCustom from '../../components/Toast';
import { ButtonModal } from '../../components/RegisterParityModal/styles';
import {
  handleGetRequest,
  handlePatchRequest,
  initialHeadersData,
} from '../../services/GenericMethods';
import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';
import {
  IconParidade,
  TextParidade,
} from '../../components/ParityPartners/RegisterParity/styles';

const ParityPartner = ({ userPermissions }) => {
  const history = useHistory();
  const { partnerId } = useParams();
  const { keycloak } = useKeycloak();

  const [parities, setParity] = useState([]);
  const [defaultParity, setDefaultParity] = useState([]);
  const [partner, setPartner] = useState({});
  const [toastList, setToastList] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);
  const [newPartner, setNewPartner] = useState(false);

  const URL = window.SERVICES_DOMAIN;
  const PARTNER_PARITY_GET = `${URL}${window.PARTNER_PARITY_GET}/${partnerId}?pageNumber=0&pageCount=10`;
  const PARTNER_PATCH = `${URL}${window.PARTNER_PARITY_GET}`;

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    setToastList([...toastList, appendToast]);
  };

  const getPartnerData = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };

      setShowLoading(true);
      handleGetRequest(PARTNER_PARITY_GET, reqOptions)
        .then(({ data }) => {
          if (data.code === 200 || data.code === 204) {
            if (
              data.body.createDate === null ||
              data.body.parities.length === 0
            ) {
              populateToastMessages('error', 'Erro', `${data.messages}`);
              setNewPartner(true);
            }
            const defaultPartnerParity = data.body.parities.filter(
              paritie => paritie.type === 'DEFAULT',
            );
            const partnerParity = data.body.parities.filter(
              paritie => paritie.type === 'CLUB',
            );
            setPartner(data.body);
            if (data.body.campaigns?.content.length) {
              setCampaigns(data.body.campaigns?.content);
            }
            if (partnerParity) {
              setParity(partnerParity);
            }
            if (defaultPartnerParity) {
              setDefaultParity(defaultPartnerParity[0]);
            }
            setIsUpdateButtonDisabled(true);
          } else {
            populateToastMessages(
              'error',
              'Erro ao consultar parceiro',
              `Erro: ${data.messages}`,
            );
          }
        })
        .catch(error => {
          console.warn(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const updatePartner = () => {
    let reqOptions = [];
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      if (newPartner === true) {
        reqOptions = {
          method: 'PATCH',
          headers: initialHeadersData(keycloak.token),
          data: {
            id: partnerId,
            partnerName: partner?.partnerName,
            createdBy: keycloak.userInfo.preferred_username,
            parities: [defaultParity].reduce((acc, cur) => {
              if (cur) {
                acc.push({
                  ...cur,
                  id: cur.type ? cur.id : undefined,
                  clubs: [],
                  type: cur.type === 'DEFAULT' ? 'DEFAULT' : 'CLUB',
                });
              }
              return acc;
            }, []),
          },
        };
      } else {
        reqOptions = {
          method: 'PATCH',
          headers: initialHeadersData(keycloak.token),
          data: {
            id: partnerId,
            partnerName: partner?.partnerName,
            createdBy: keycloak.userInfo.preferred_username,
            parities: [defaultParity, ...parities].reduce((acc, cur) => {
              if (cur) {
                acc.push({
                  ...cur,
                  id: cur.type ? cur.id : undefined,
                  clubs: cur.clubs.filter(e => e.selected === true || e.id),
                  type: cur.type === 'DEFAULT' ? 'DEFAULT' : 'CLUB',
                });
              }
              return acc;
            }, []),
          },
        };
      }

      handlePatchRequest(PARTNER_PATCH, reqOptions)
        .then(({ data }) => {
          if (data.code === 200) {
            if (newPartner === true) {
              setNewPartner(false);
            }
            populateToastMessages(
              'success',
              'Sucesso!',
              'Parceiro alterado com sucesso!',
              'main',
            );
          } else {
            populateToastMessages(
              'error',
              'Erro ao alterar parceiro',
              `Erro: ${data.messages}`,
            );
          }
        })
        .catch(error => {
          console.warn(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(() => {
          getPartnerData();
        });
    }
  };

  const handleRegisterClick = () => {
    history.push(`/campaign/${partnerId}`);
  };

  const goBack = () => {
    history.goBack();
  };

  const redirectToCampaign = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.UPDATE',
        userPermissions.userGroups,
      ) &&
      campaigns[0].status === 'SCHEDULED'
    ) {
      history.push({
        pathname: `/campaign/${partnerId}`,
        state: {
          campaingSelectEdit: campaigns,
          extraParam: 'edit',
          from: 'partner',
        },
      });
    } else if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      ) &&
      campaigns[0].status === 'ACTIVE'
    ) {
      history.push({
        pathname: `/campaign/${partnerId}`,
        state: {
          campaingSelectEdit: campaigns,
          extraParam: 'disable',
          from: 'partner',
        },
      });
    } else if (
      !userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.UPDATE',
        userPermissions.userGroups,
      ) ||
      campaigns[0].status !== 'SCHEDULED'
    ) {
      history.push({
        pathname: `/campaign/${partnerId}`,
        state: {
          campaingSelectEdit: campaigns,
          extraParam: 'view',
          from: 'partner',
        },
      });
    }
  };

  useEffect(() => {
    getPartnerData();
  }, []);

  useEffect(() => {
    setIsUpdateButtonDisabled(false);
  }, [parities]);

  const defaultPointsFunction = data => {
    const updateDefaultParityPoints = {
      ...defaultParity,
      points: Number(data),
    };
    setDefaultParity(updateDefaultParityPoints);
    setIsUpdateButtonDisabled(false);
  };
  const defaultMilesFunction = data => {
    const updateDefaultParityMiles = {
      ...defaultParity,
      miles: Number(data),
    };
    setDefaultParity(updateDefaultParityMiles);
    setIsUpdateButtonDisabled(false);
  };

  return (
    <>
      <Content>
        <S.LoadingComponent>
          <Loading showLoading={showLoading} />
        </S.LoadingComponent>
        <S.Container>
          <S.listHeaderPartnersParity>
            <div className="contentmain__header">
              <div className="contentmain__header__title">
                <div>
                  <h2 className="contentmain__header__text">
                    {partner?.partnerName}
                  </h2>
                </div>
              </div>
            </div>
          </S.listHeaderPartnersParity>
          <PartnersParityDoc />
          <S.Campaign>
            <div className="contentmain__campaign">
              <div className="contentmain__campaign__title">Campanhas</div>
              <Link
                className="contentmain__campaign__seeAll"
                to={`/campaign-history/${partnerId}`}
              >
                Ver todas
              </Link>
              <span className="esfera-icon ic_system_arrow_right" />
            </div>
            <div className="container__campaign">
              {campaigns.map(campaign => (
                <button
                  key={campaign.id}
                  type="button"
                  className="button-campaign"
                  onClick={() => redirectToCampaign()}
                >
                  <div>
                    <div className="name__campaign">
                      {campaign.campaignName}
                    </div>
                    <div className="date__campaign">{`De ${moment(
                      campaign.dateInit,
                      'YYYY-MM-DDTHH:mm:ss',
                    ).format('DD/MM/YYYY')} até ${moment(
                      campaign.dateEnd,
                      'YYYY-MM-DDTHH:mm:ss',
                    ).format('DD/MM/YYYY')}`}</div>
                  </div>
                  {!userPermissions.checkUiPermissionsFn(
                    'ESFERA.PARITY_CONTROL.UPDATE',
                    userPermissions.userGroups,
                  ) || campaign.status !== 'SCHEDULED' ? (
                    <span
                      style={{ color: 'red' }}
                      className="icon-update esfera-icon ic_functional_password_show"
                    />
                  ) : (
                    <span
                      style={{ color: 'red' }}
                      className="icon-update  esfera-icon ic_functional_edit"
                    />
                  )}
                </button>
              ))}

              {userPermissions.checkUiPermissionsFn(
                'ESFERA.PARITY_CONTROL.CREATE',
                userPermissions.userGroups,
              ) &&
                newPartner === false && (
                  <S.ButtonAdd onClick={handleRegisterClick}>
                    <IconParidade>
                      <span
                        style={{ color: 'red' }}
                        className="linkIcon esfera-icon ic_system_circle_add"
                      />
                    </IconParidade>
                    <TextParidade>Cadastrar Campanha</TextParidade>
                  </S.ButtonAdd>
                )}
            </div>
          </S.Campaign>
          <RegisterParity
            setParity={setParity}
            parities={parities}
            defaultParity={defaultParity}
            defaultPointsFunction={defaultPointsFunction}
            defaultMilesFunction={defaultMilesFunction}
            userPermissions={userPermissions}
            permissionEditParity
            newPartner={newPartner}
          />
        </S.Container>
        <S.Container
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <ButtonModal variant="cancel" onClick={() => goBack()}>
            Voltar
          </ButtonModal>

          {userPermissions.checkUiPermissionsFn(
            'ESFERA.PARITY_CONTROL.UPDATE',
            userPermissions.userGroups,
          ) ? (
            <ButtonModal
              variant="active"
              onClick={() => updatePartner()}
              disabled={isUpdateButtonDisabled}
            >
              Atualizar
            </ButtonModal>
          ) : null}
        </S.Container>
        <ToastCustom
          toastList={toastList}
          position="bottom-right"
          autoDelete
          dismissTime={5000}
        />
      </Content>
    </>
  );
};

export default ParityPartner;
