import styled from 'styled-components';

import { Modal } from 'react-bootstrap';
import { Table } from 'react-bootstrap';

export const StyledTable = styled(Table)`
  font-size: 0.875rem;

  th {
    border-top: 0;
    vertical-align: center;
  }
`;

export const StyledModal = styled(Modal)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 70%;
      min-height: 60%;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .history-body {
    div {
      min-height: 300px;
    }
  }

  .text-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    border: 0;
    padding-bottom: 0;

    .modal-title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .modal-body {
    span {
      display: block;

      & + span {
        margin-top: 2px;
      }

      > strong {
        font-weight: 600;
      }
    }
  }

  .container-buttons {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: center;

    button {
      width: 100%;

      & + button {
        margin-left: 1rem;
      }
    }
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  justify-content: center;

  button {
    width: 100%;

    & + button {
      margin-left: 1rem;
    }
  }
`;
