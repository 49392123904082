import styled from 'styled-components';
import AccordionBootstrap from 'react-bootstrap/Accordion';

export const Accordion = styled(AccordionBootstrap)`
  margin-top: 20px;

  .collapse {
    padding-bottom: 40px;
  }
`

export const HeaderAccordion = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #CCCCCC;
  padding-top: 18px;
  cursor: pointer;

  span {
    font-size: 32px
  }
  span:first-child {
    padding-right: 16px;
  }
  p {
    flex: 1;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    padding: 0;
    margin: 0;
  }
`