import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    border-bottom: 1px solid var(--gray-300);
    align-items: center;
    justify-content: space-between;
`;

export const CustomHeaderTable = styled.div`
    width: 136px;
    padding: 20px 20px 24px;

    @media (max-width: 576px) {
      flex: 1;
      width: auto;
    }


    .titleCustomHeader {
      display: block;
      font-family: "Roboto";
      font-size: 14px;
      line-height: 16px;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .resultNumberText {
      margin-top: 8px;
      display: block;
      font-family: "Roboto";
      font-size: 24px;
      line-height: 24px;

      @media (max-width: 576px) {
        font-size: 16px;
      }
    }

    &.primaryType {
      background: var(--black);
      color: var(--white);
      border-radius: 24px 0 0;
    }

    &.secondaryType {
      background: var(--gray-100);
      color: var(--white);
    }

    &.tertiaryType {
      color: var(--gray-100);
      border-right: 1px solid var(--gray-300);

      @media (max-width: 576px) {
        border-right: 0;
      }

    }
`;

export const HeaderTableData = styled.div`
    display: flex;
    @media (max-width: 576px) {
      flex: 1;
    }
`;

export const HeaderTableDefault = styled.div`
    height: 68px;
    padding: 20px 14px;

    > h2 {
      font-family: "Poppins";
      font-size: 20px;
      color: var(--gray-50);
    }
`;

export const HeaderTableLink = styled(Link)`
    display: flex;
    margin-right: 40px;
    font-family: "Open Sans";
    color: var(--boston);
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-decoration: none;

    .icoArrow {
      font-size: 20px;
      margin-left: 5px;
    }

    transition: color 0.3s;

    &:hover {
      color: var(--ruby);
      text-decoration: none;
    }
`;
