import React from 'react';
import { DocStyle } from './styles';

export default function PartnersParityDoc() {
  return (
    <DocStyle>
      <div className="contentmain__text-info">
        <p className="contentmain__header__text-info">
          Portal para gerenciamento de paridade sobre transferência para os
          programas parceiros. <br />
          Para mais detalhes sobre a configuração,&nbsp;
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://esferafidelidade.atlassian.net/wiki/spaces/EF/pages/2782593025/Paridade+fracionada+de+Programas+Parceiros+FERA-11"
          >
            acesse a documentação aqui.
          </a>
        </p>
      </div>
    </DocStyle>
  );
}
