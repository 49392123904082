import styled from 'styled-components';

import {
  Modal as ModalBootstrap,
  Form as FormBootstrap,
} from 'react-bootstrap';

export const StyledModal = styled(ModalBootstrap)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 500px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .modal-header {
    border: 0;
    padding-bottom: 0;

    .modal-title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .modal-body {
    span {
      display: block;

      & + span {
        margin-top: 2px;
      }

      > strong {
        font-weight: 600;
      }
    }
  }
  .message-content {
    overflow: auto;
    ::-webkit-scrollbar {
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 8px #d2d2d2;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #767676;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #222222;
    }
  }
`;

export const Form = styled(FormBootstrap)`
  margin-top: 20px;

  label {
    font-weight: 600;
  }

  textarea {
    min-height: 120px;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  justify-content: center;

  button {
    width: 100%;

    & + button {
      margin-left: 1rem;
    }
  }
`;
