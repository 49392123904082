import styled from 'styled-components';

import { Modal as ModalBootstrap } from 'react-bootstrap';

import { Form } from 'react-bootstrap';

export const StyledModal = styled(ModalBootstrap)`
  .close{
    color: #c00;
    font-size: 32px;
    font-weight: 400;
    opacity: 1;
  }
  .modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;
    min-width:600px;
  }
  .modal-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .modal-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #262626;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .modal-body-form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .modal-body-form span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #262626;
  }
  .row {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: initial;
  }
  .ap-text-field {
    position: relative;
    width: 100%;
    display: flex;
}
.ap-text-field-input {
  all: unset;
  border: 1px solid #767676;
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  padding: 20px 16px;
  flex: 1;
  cursor:pointer;
}
.ap-text-field-label {
  all: unset;
  color: #808080;
  padding: 0 4px;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  background: #fff;
  top: -8px;
  left: 16px;
}
.ap-text-field-select {
  all: unset;
  border: 1px solid #767676;
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  padding: 20px 16px;
  flex: 1;
}
.modal-footer {
  border: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ap-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 64px;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.ap-button-secondary {
  border-color: #c00;
  color: #c00;
  background: #fff;
}
.ap-button-primary {
  border-color: #c00;
  color: #fff;
  background: #c00;
}
.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.ap-text-field-select {
  padding-right: 30px; 
  min-width: 480px;
}
.arrow-icon {
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  pointer-events: none;
  color:#c00 !important;
}
`;
export const StyledForm = styled(Form)`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-content {
    display: flex;
    flex-direction: column;
  }
  .form-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 10px;

    @media (max-width: 473px) {
      width: 100%;
      margin-right: 0;
    }

    select {
      width: 248px;
    }

    > label {
    margin: 0 10px 0 0;
    font-size: 0.875rem;
    width: 75px;

    @media (max-width: 600px) {
      width: 65px;
    }
  }

    input[type="date"] {
      width: 170px;
      margin-right: 10px;
      font-size: 0.875rem;

      @media (max-width: 473px) {
        flex: 1;
        margin-right: 0;
        &:nth-child(3) {
          margin-top: 10px;
          margin-left: 75px;
        }
      }
    }

    input[type="text"] {
      width: 170px;
      font-size: 0.875rem;

      @media (max-width: 473px) {
        width: 100%;
        flex: 1;
      }
    }
  }

  .inputs-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .form-actions {
    margin-left: 120px;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 265px;
    min-width: 150px;

    @media (max-width: 1080px) {
      margin-left: 0;
      width: 100%;
    }

    @media (max-width: 473px) {
      max-width: none;
    }

    button[type="submit"] {
      width: 100%;
    }
  }
`;
