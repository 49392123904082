import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import OrdersFilter from '../../components/CashbackAwinPage/OrdersFilter';
import OrdersTable from '../../components/CashbackAwinPage/OrderTable';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';
import {
  handleGetRequest,
  initialPaginationData,
  initialHeadersData,
} from '../../services/GenericMethods';
import { Container } from './styles';
import ToastCustom from '../../components/Toast';
import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

export default function ManualOrders({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const [ordersFormatted, setOrdersFormatted] = useState([]);
  const [ordersFormattedPerPage, setOrdersFormattedPerPage] = useState([]);
  const [partnerName, setPartnerName] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const options = initialPaginationData('GET', 0, keycloak.token);
  const FULL_URL = window.SERVICES_DOMAIN + window.REPORT_AWIN_TYPE_URL;

  const calcTotalPages = orders => {
    const totalOrders = orders.length;
    const totalPagesCalculated = totalOrders / 10;

    if (Number.isInteger(totalPagesCalculated)) return totalPagesCalculated;
    if (totalPagesCalculated <= 1) return 0;

    return Math.ceil(totalPagesCalculated);
  };

  const getAllPartnerNames = orders => {
    const arrayPartner = [];
    orders.forEach(value => {
      const exist = arrayPartner.indexOf(value.partnerName) >= 0;
      if (!exist) arrayPartner.push(value.partnerName);
    });
    return arrayPartner;
  };

  const getOrdersAwin = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'AWIN.ORDERS.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };
      handleGetRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data && response.data.length > 0) {
            const orders = response.data;

            setCurrentPage(0);
            orders.forEach((order, index) => {
              if (
                order.awinPostReturn.clickRef === null ||
                order.awinPostReturn.clickRef === ''
              ) {
                orders[index].status = 'noClickref';
              } else orders[index].status = 'withClickref';
            });

            orders.sort((a, b) => {
              if (a.status < b.status) {
                return -1;
              }
              if (a.status > b.status) {
                return 1;
              }
              return 0;
            });

            setOrdersFormatted(orders);
            setPartnerName(getAllPartnerNames(orders));
            setTotalPages(calcTotalPages(orders));
            setOrdersFormattedPerPage(orders.slice(0, 10));
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setOrdersFormatted([]);
            setOrdersFormattedPerPage([]);
            setPartnerName([]);
          }

          console.warn(`Response listOrders = ${response}`);
        })
        .catch(error => {
          console.error(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const handleFilterAmount = (ordersFilter, amount) => {
    const ordersFilterAmount = [];
    ordersFilter.forEach(value => {
      if (value.awinPostReturn.transactionAmount === amount)
        ordersFilterAmount.push(value);
    });
    return ordersFilterAmount;
  };

  const handleFilterDate = (ordersFilter, date) => {
    const ordersFilterDate = [];
    const dateFilter = date.split('-');
    ordersFilter.forEach(value => {
      const dateOrder = value.awinPostReturn.transactionDate
        .split(' ')[0]
        .split('-');
      if (
        new Date(dateOrder[0], dateOrder[1] - 1, dateOrder[2]).valueOf() ===
        new Date(dateFilter[0], dateFilter[1] - 1, dateFilter[2]).valueOf()
      )
        ordersFilterDate.push(value);
    });
    return ordersFilterDate;
  };

  const handleFilterPartner = (ordersFilter, partner) => {
    const ordersFilterPartner = [];
    ordersFilter.forEach(value => {
      if (value.partnerName === partner) ordersFilterPartner.push(value);
    });
    return ordersFilterPartner;
  };

  const handleFilter = inputParams => {
    let ordersFilter = ordersFormatted;
    setOrdersFormattedPerPage([]);
    if (inputParams.saleAmount) {
      ordersFilter = handleFilterAmount(ordersFilter, inputParams.saleAmount);
    }
    if (inputParams.startDate) {
      ordersFilter = handleFilterDate(ordersFilter, inputParams.startDate);
    }
    if (inputParams.partner) {
      ordersFilter = handleFilterPartner(ordersFilter, inputParams.partner);
    }
    setTotalPages(calcTotalPages(ordersFilter));
    setCurrentFilter(inputParams);
    setOrdersFormattedPerPage(ordersFilter);
  };

  const handlePageChange = page => {
    const offset = page * 10;
    setOrdersFormattedPerPage([]);
    setCurrentPage(page);
    setOrdersFormattedPerPage(ordersFormatted.slice(offset, offset + 10));
  };

  const handleShowMessageAction = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }
  };

  useEffect(() => {
    getOrdersAwin(options);
  }, [toastList]);

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <h2>Análise de Pedidos Awin</h2>
      <OrdersFilter
        allPartner={partnerName}
        currentFilter={currentFilter}
        handleFilter={handleFilter}
      />
      {userPermissions.checkUiPermissionsFn(
        'AWIN.ORDERS.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {ordersFormattedPerPage.length > 0 ? (
            <OrdersTable
              orders={ordersFormattedPerPage}
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPages > 0 ? (
        <Pag
          currentPage={currentPage}
          totalPages={totalPages}
          isFirstPage={false}
          isLastPage={false}
          onClickFnPage={handlePageChange}
        />
      ) : null}
    </Container>
  );
}
