import styled, {
  css
} from 'styled-components';

const buttonTypeVariations = {
  primary: css `
    background: var(--boston);
    color: var(--white);

    &:hover:not([disabled]), &:active:not([disabled]) {
      background: var(--ruby);
    }

    &:focus {
      background: var(--ruby);
    }
  `,

  secondary: css `
    background: transparent;
    color: var(--boston);

    &:hover:not([disabled]), &:active:not([disabled]) {
      background: var(--red-50);
    }

    &:focus {
      background: var(--red-50);
    }
  `
}

export const Container = styled.button `

  padding: 10px 16px;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid var(--boston);
  border-radius: 4px;

  ${props => buttonTypeVariations[props.typeOfButton || 'primary']}

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all .2s linear;
`;