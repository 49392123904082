import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useKeycloak } from '@react-keycloak/web';
import Content from '../../components/Content';
import * as S from './styles';
import ToastCustom from '../../components/Toast';
import {
  populateToastMessagesGlobal,
  formatErrorMessageToToast,
} from '../../helpers/GlobalFunctions';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';
import TransactionsTable from '../../components/TransactionsReleasePage/TransactionsTable';
import {
  handlePostRequest,
  initialHeadersData,
} from '../../services/GenericMethods';
import TransactionsFilter from '../../components/TransactionsReleasePage/TransactionsFilter';

const headTableDataTransactionsRelease = [
  {
    title: 'Nome Arquivo',
    hasIcoSort: false,
  },
  {
    title: 'Data Arquivo',
    hasIcoSort: false,
  },
  {
    title: 'Quantidade de Transações',
    hasIcoSort: false,
  },
  {
    title: 'Subtotal do Arquivo',
    hasIcoSort: false,
  },
  {
    title: 'Total a Processar do Arquivo',
    hasIcoSort: false,
  },
  {
    title: 'Status do Arquivo',
    hasIcoSort: false,
  },
];

export default function TransactionsRelease({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const FULL_URL = `${window.SERVICES_DOMAIN + window.TRANSACTION_FILE_URL}`;
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const [isModifiedTransactionFile, setIsModifiedTransactionFile] =
    useState(false);
  const [bodyTableDataTransactionsFile, setBodyTableDataTransactionsFile] =
    useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(5);
  const currentDate = moment(new Date()).format('YYYY-MM-DDT23:59:59');
  const [currentFilter, setCurrentFilter] = useState({
    pageNumber: 0,
    pageCount: 5,
    startAt: '2023-01-01T13:00:00',
    endAt: currentDate,
  });

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };

  const getTransactionsFile = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.INCENTIVE_ENGINE.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: currentFilter,
      };

      handlePostRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data && response.data.items.length > 0) {
            setBodyTableDataTransactionsFile(response.data.items);
            setCurrentPage(response.data.currentPageNumber);
            setTotalPages(response.data.totalPages);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setBodyTableDataTransactionsFile([]);
          }
        })
        .catch(error => {
          console.warn(`Error at list transactions files request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const handleFilter = inputParams => {
    setIsModifiedTransactionFile(!isModifiedTransactionFile);
    setCurrentFilter(inputParams);
    getTransactionsFile();
  };

  const handlePageChange = page => {
    const filter = currentFilter;

    filter.pageNumber = page;
    setCurrentFilter(filter);
    setIsModifiedTransactionFile(!isModifiedTransactionFile);

    getTransactionsFile();
  };

  useEffect(() => {
    getTransactionsFile();
  }, [isModifiedTransactionFile]);

  return (
    <>
      <Content>
        <S.Container>
          <S.AirCampaignHeaderContentStyle>
            <div className="contentmain__header">
              <div className="contentmain__header__title">
                <text className="contentmain__header__text">
                  Motor de Incentivos
                </text>
                <span className="contentmain__header__icon icoArrow esfera-icon BE070" />
                <text className="contentmain__header__text contentmain__header__text__active">
                  Transações a liberar
                </text>
              </div>
            </div>
          </S.AirCampaignHeaderContentStyle>
          <TransactionsFilter
            currentFilter={currentFilter}
            handleFilter={handleFilter}
          />
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.INCENTIVE_ENGINE.READ',
            userPermissions.userGroups,
          )
            ? bodyTableDataTransactionsFile && (
                <TransactionsTable
                  headTableData={headTableDataTransactionsRelease}
                  bodyTableData={bodyTableDataTransactionsFile}
                  userPermissions={userPermissions}
                  isModifiedTransactionFile={isModifiedTransactionFile}
                  setIsModifiedTransactionFile={setIsModifiedTransactionFile}
                  populateToastMessages={populateToastMessages}
                />
              )
            : null}
          {totalPages > 0 ? (
            <Pag
              currentPage={currentPage}
              totalPages={totalPages}
              onClickFnPage={handlePageChange}
            />
          ) : null}

          <ToastCustom
            toastList={toastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
          <ToastCustom
            toastList={mainToastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
        </S.Container>
      </Content>
    </>
  );
}
