import styled, { css } from 'styled-components';

export const StyledTr = styled.tr`
  ${({ theme, isInactive }) => css`
    height: 64px;
    .message-content {
      max-width: 240px;
      overflow: auto;
    }
    td {
      font-family: ${theme.fonts.family.opensans};
      font-size: 14px;
      color: ${theme.colors.darkerGrey};
      line-height: 16px;
      vertical-align: inherit;

      ::-webkit-scrollbar {
        height: 8px;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 8px #d2d2d2;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #767676;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #222222;
      }
      &.emphasis {
        font-weight: ${theme.fonts.regular};
      }

      &.partner {
        display: flex;
        align-items: center;
        height: 64px;
      }

      &.value {
        width: 140px;
      }

      .status {
        width: fit-content;
        padding: 4px 6px;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: ${theme.fonts.semibold};
      }

      .esfera-icon {
        font-size: 22px;
        font-weight: 700;
        margin-right: 12px;
        cursor: pointer;
        transform: rotate(180deg);

        &.active {
          transform: rotate(0deg);
        }
      }

      .tooltip-campaign {
        position: absolute;
        right: 0 !important;
        top: 0;
        max-width: 272px;
        background: #FEF2E5;
        border-radius: 4px;
        color: #222222;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .__react_component_tooltip.show {
        opacity: 1;
      }

      .place-left::after {
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        right: 20px;
        top: -5%;
        margin-top: -4px;
        border-left-color: #fef2e5;
        border-left-style: solid;
        border-left-width: 10px;
      }
    }
  `}
`;
export const WrapperButton = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 0.8rem;

    &.confirm {
      color: var(--green-50);
    }

    &.recused {
      color: var(--boston);
    }
  }

  > span {
    margin-left: 10px;
  }

  .btn-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    padding: 7px 2px;
    width: 85px;
    background: transparent;
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    transition: background 0.2s;

    &:hover {
      background: var(--gray-500);
    }

    & + .btn-action {
      margin-left: 10px;
    }

    img {
      margin-bottom: 2px;
      width: 20px;
      height: 20px;
    }
  }
`;
