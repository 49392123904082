import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import {
  StyledForm,
  PartnersCards,
  PartnerCard,
  Alert,
  Paragraph,
} from './styles';
import {
  handleGetRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';

const PARTNERS = [
  {
    id: 1,
    code: 'viaVarejo',
    description: 'Via Varejo',
    logoPath:
      'https://www.esfera.com.vc/ccstore/v1/images/?source=/file/v3171445658765057169/general/via-logo-2023.png&height=100&width=300',
  },
  {
    id: 2,
    code: 'dmgl',
    description: 'Magazine Luiza',
    logoPath:
      'https://www.esfera.com.vc/ccstore/v1/images/?source=/file/general/swiper-club-partner-magalu.png&height=100&width=300',
  },
];

export default function Partners({ isVisible, setSelectedPartner }) {
  const STATUS_ENRICHMENT_URL = `${
    window.SERVICES_DOMAIN + window.STATUS_RULES_TRACKING_ENRICHMENT_GET
  }?page=0&size=20`;

  const { keycloak } = useKeycloak();
  const [enrichments, setEnrichments] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const fetchStatusEnrichment = () => {
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: {},
      };

      handleGetRequest(STATUS_ENRICHMENT_URL, reqOptions)
        .then(response => {
          if (!isMounted || !response || !response.data) return;
          setEnrichments(response.data.body.trackingStatus.content);
        })
        .catch(error => {
          console.warn(`Error at request = ${error}`);
        });
    };

    fetchStatusEnrichment();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <StyledForm className={!isVisible && 'hidden'}>
      <div className="form-content">
        <div>
          <PartnersCards>
            {PARTNERS.map(item => {
              return (
                <PartnerCard
                  onClick={() =>
                    setSelectedPartner({
                      code: item.code,
                      description: item.description,
                    })
                  }
                  key={item.id}
                >
                  <p>{item.description}</p>
                  <img alt="logo" src={item.logoPath} />
                  <hr />
                </PartnerCard>
              );
            })}
          </PartnersCards>

          <h4>Alertas</h4>

          <div>
            {enrichments.map(enrichment => (
              <Alert key={enrichment.id}>
                <span className="esfera-icon ic_filled_circle_attention" />
                <Paragraph>
                  Código {enrichment.statusPartnerCode} do parceiro{' '}
                  {enrichment.partnerCode} não está cadastrado. Tentativa de uso
                  em: {moment(enrichment.updateDate).format('DD/MM/YYYY')}
                </Paragraph>
              </Alert>
            ))}
          </div>
        </div>
      </div>
    </StyledForm>
  );
}
