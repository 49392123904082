import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Content from '../../components/Content';
import ToastCustom from '../../components/Toast';
import {
  populateToastMessagesGlobal,
  formatErrorMessageToToast,
} from '../../helpers/GlobalFunctions';
import {
  handleGetRequest,
  initialHeadersData,
} from '../../services/GenericMethods';
import PartnersParityContent from '../../components/ListPartnersParityPage/PartnersParityContent';
import PartnerSearch from '../../components/ListPartnersParityPage/PartnersParitySearch';
import PartnersParityDoc from '../../components/ListPartnersParityPage/PartnersParityDoc';
import PartnerFilter from '../../components/ListPartnersParityPage/PartnersParityFilter';
import Loading from '../../components/Loading';
import * as S from './styles';

export default function PartnersParity({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const FULL_URL = `${window.SERVICES_DOMAIN + window.PARTNERS_PARITY_GET}`;
  const FULL_URL_REFRESH = `${
    window.SERVICES_DOMAIN + window.PARTNERS_PARITY_GET
  }?update=true`;
  const [mainToastList, setMainToastList] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [searchParams, setSearchParams] = useState('');
  const [ordenedListPartners, setOrdenedListPartners] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const onChangeSearchParams = data => {
    setSearchParams(data);
  };
  const sortPartners = data => {
    const sortedData = [...ordenedListPartners];
    if (data === 'az' || data === 'za') {
      sortedData.sort((a, b) => a.partnerName.localeCompare(b.partnerName));
    } else if (data === 'old' || data === 'new') {
      sortedData.sort(
        (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate),
      );
    }
    if (data === 'za' || data === 'new') {
      sortedData.reverse();
    }
    setOrdenedListPartners(sortedData);
  };
  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };
  const getPartnersList = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
      };
      setShowLoading(true);
      handleGetRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data && response.data.partners.length > 0) {
            setOrdenedListPartners(response.data.partners);
          } else {
            setOrdenedListPartners([]);
          }
        })
        .catch(error => {
          console.warn(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };
  const refreshPartnersList = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
      };

      handleGetRequest(FULL_URL_REFRESH, reqOptions)
        .then(response => {
          if (response && response.data && response.data.partners.length > 0) {
            setOrdenedListPartners(response.data.partners);
          } else {
            setOrdenedListPartners([]);
          }
        })
        .catch(error => {
          console.warn(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };
  useEffect(() => {
    getPartnersList();
  }, []);
  return (
    <>
      <Content>
        <S.LoadingComponent>
          <Loading showLoading={showLoading} />
        </S.LoadingComponent>
        <S.Container>
          <S.listHeaderPartnersParity>
            <div className="contentmain__header">
              <div className="contentmain__header__title">
                <div>
                  <h2 className="contentmain__header__text">Paridade</h2>
                </div>
                <button
                  onClick={refreshPartnersList}
                  type="button"
                  className="contentmain__header__update"
                >
                  <span className="contentmain__header__icon esfera-icon BE250" />
                  <text className="contentmain__header__text">Atualizar</text>
                </button>
              </div>
            </div>
            <PartnersParityDoc />
            <div className="content__header__list__partner">
              <div className="content__title_partner">
                <text>Parceiros cadastrados</text>
              </div>
              <PartnerSearch
                label="Buscar parceiro"
                id="searchPartner"
                onChangeSearchParams={onChangeSearchParams}
              />
              <PartnerFilter sortPartners={sortPartners} />
            </div>
          </S.listHeaderPartnersParity>
          <PartnersParityContent
            ordenedListPartners={ordenedListPartners}
            searchParams={searchParams}
          />
          <ToastCustom
            toastList={toastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
          <ToastCustom
            toastList={mainToastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
        </S.Container>
      </Content>
    </>
  );
}
