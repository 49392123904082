import React from 'react';

import OrderItem from '../OrderItem';

import { StyledTable } from './styles';

export default function OrdersTable({
  orders,
  userPermissions,
  handleShowMessageAction,
}) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th>Pedido</th>
          <th>CPF/CNPJ</th>
          <th>Data da Transação</th>
          <th>Regras Ativadas</th>
          <th>Chamado Salesforce</th>
          <th>Site</th>
          <th>Usuário</th>
          <th>Ações</th>
        </tr>
      </thead>

      <tbody>
        {orders.map(order => (
          <OrderItem
            order={order}
            key={Math.random()}
            userPermissions={userPermissions}
            handleShowMessageAction={handleShowMessageAction}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
