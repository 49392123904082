import styled, { css } from 'styled-components';

export const Container = styled.thead`
  ${({ theme }) => css`
    overflow: hidden;
    border: 0;
    background: ${theme.colors.white};

    th {
      border: 0;
      border-bottom: none !important;
      font-family: ${theme.fonts.family.poppins};
      font-size: 14px;
      font-weight: ${theme.fonts.semibold};
      color: ${theme.colors.darkerGrey};
      line-height: 16px;
    }
  `}
`;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    button {
      margin-left: 1px;
      border: 0;
      background: transparent;
      outline: 0;
    }

    .ic_system_circle_information {
      margin-left: 5px;
    }

    .tooltip-user {
      max-width: 270px;
      background: #DEEDF2;
      border-radius: 4px;
      color: #222222;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      left: 640px !important;
      top: 195px !important;
    }

    .place-top::after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: -6px;
      left: 8% !important;
      margin-left: -8px;
      border-top-color: #DEEDF2 !important;
      border-top-style: solid;
      border-top-width: 6px;
    }

    .__react_component_tooltip {
      position: absolute;
    }
  `}
`;