import React, { useState } from 'react';
import moment from 'moment';
import ModalOnboarding from '../ModalOnboarding';

import * as S from './styles';

export default function OnboardingTableBody({
  item,
  userPermissions,
  handleSetValueEmailage,
}) {
  const [isOpenModalEditRule, setIsOpenModalEditRule] = useState(false);

  const handleShowMdlEditRule = () => {
    setIsOpenModalEditRule(true);
  };
  const handleCloseMdlEditRule = () => setIsOpenModalEditRule(false);

  return (
    <S.StyledTr>
      {item.id ? (
        <td>
          {item.id === 'EmailageScore' &&
            'Para aprovação de cadastro Risk Score deve ser menor que'}
          {item.id === 'EmailageFraudRisk' &&
            'Para aprovação de cadastro Confidence Score deve ser maior que'}
          {item.id === 'EmailageFraudRiskWithRestriction' &&
            'Para aprovação de cadastro com a flag RedemptionEnabled o Confidence Score deve ser maior que'}
        </td>
      ) : (
        <td>-</td>
      )}
      {item && item.value && item.value.integer_value ? (
        <td>{item.value.integer_value}</td>
      ) : (
        <td>-</td>
      )}
      {item.updateAt ? (
        <td>{moment(item.updateAt).format('DD/MM/YYYY HH:mm')}</td>
      ) : (
        <td>-</td>
      )}
      {item.userUpdateAt ? <td>{item.userUpdateAt}</td> : <td>-</td>}
      <td>
        {userPermissions.checkUiPermissionsFn(
          'ONBOARDING.RULES.UPDATE',
          userPermissions.userGroups,
        ) ? (
          <div className="container-actions">
            <span
              className="esfera-icon BD270 iconEdit"
              onClick={() => handleShowMdlEditRule()}
              aria-hidden="true"
            />
          </div>
        ) : null}
      </td>

      <ModalOnboarding
        item={item}
        isOpenModal={isOpenModalEditRule}
        handleCloseModal={handleCloseMdlEditRule}
        handleSetValueEmailage={handleSetValueEmailage}
        userPermissions={userPermissions}
      />
    </S.StyledTr>
  );
}
