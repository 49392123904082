import React from 'react';

export const IconBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#EC0000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17 19.5a5.5 5.5 0 1 0 0-11H2.002"
    />
    <path
      stroke="#EC0000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.499 12.497 1.5 8.5 5.499 4.5"
    />
  </svg>
);

export const IconArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m2.502 7.5 9.498 9 9.498-9"
    />
  </svg>
);

export const IconArrowUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#900"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.498 16.5 12 7.5l-9.498 9"
    />
  </svg>
);

export const IconArrowRight = () => (
  <svg
    cursor="pointer"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      stroke="#EC0000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m15 4.5 7.5 7.5-7.5 7.5"
    />
    <path
      fill="#EC0000"
      fillRule="evenodd"
      d="M1.5 12h21-21Z"
      clipRule="evenodd"
    />
    <path
      stroke="#EC0000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.5 12h21"
    />
  </svg>
);

export const IconClose = () => (
  <svg
    cursor="pointer"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={23}
    fill="none"
  >
    <path
      fill="#C00"
      d="m15.024 8.014-2.828 2.828-2.828-2.828a.333.333 0 1 0-.472.471l2.829 2.829-2.829 2.828a.333.333 0 0 0 .472.472l2.828-2.829 2.829 2.829a.333.333 0 0 0 .47-.472l-2.827-2.828 2.828-2.829a.333.333 0 1 0-.472-.471Z"
    />
  </svg>
);
