import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Button from '../../Button';
import Loading from '../../Loading';

import { StyledModal, Form, WrapperButtons } from './styles';
import {
  handlePatchRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';

import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalRefusedOrder({
  order,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.MANUAL_REFUSED_ORDER_URL;
  const [description, setDescription] = useState('');
  const [showLoading, setShowLoading] = useState(false);

  const handleRefusedOrder = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MANUAL_ORDERS.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'PATCH',
        headers: initialHeadersData(keycloak.token),
        data: { description },
      };

      handlePatchRequest(`${FULL_URL}/${order.orderId}`, reqOptions)
        .then(() => {
          setShowLoading(false);
          handleCloseModal(
            'success',
            'Sucesso!',
            `Pedido cancelado com sucesso.`,
          );
        })
        .catch(error => {
          setShowLoading(false);
          console.error('Error at cancelOrder request', error);
          console.error(`Error at cancelOrder request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
    handleCloseModal();
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Recusar Pedido</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div>
          <p>Deseja realmente recusar o pedido abaixo: </p>
          <span>
            <strong>Pedido: </strong>
            {order.orderId}
          </span>
          <span>
            <strong>Documento: </strong>
            {order.profile.document}
          </span>
          <span>
            <strong>Produto: </strong>
            {order.products.map(product => product.name).join(', ')}
          </span>
          <span>
            <strong>Valor Total: </strong>
            {order.amount}
          </span>
        </div>

        <Form>
          <Form.Group>
            <Form.Label>Motivo da recusa</Form.Label>
            <Form.Control
              as="textarea"
              defaultValue={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="Descreva o motivo da recusa desse pedido"
            />
          </Form.Group>
        </Form>

        <WrapperButtons>
          <Button typeOfButton="secondary" onClick={handleRefusedOrder}>
            Sim
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Não
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
