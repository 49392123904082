import styled from 'styled-components';

export const SearchCouponStyle = styled.div`
  .form-container {
    position: relative;
    height: 40px;
    margin-bottom: 16px;
  }
  .form-container svg {
    position: absolute;
    right: 14px;
    top: 10px;
    color: #767676;
    z-index: 9;
    cursor: pointer;
    font-size: 20px;
  }

  .form-input {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0.87rem;
    color: #767676;
    border: 1px solid #767676;
    border-radius: 0.5rem;
    outline: none;
    padding: 1rem;
    background: none;
    z-index: 1;
  }

  .form-label {
    font-family: 'Open Sans';
    position: absolute;
    left: 0.8rem;
    top: 0.5rem;
    padding: 0 0.25rem;
    color: #767676;
    font-size: 0.87rem;
    background-color: #F5F9FB;
    transition: 0.3s;
  }

  .form-input:focus + .form-label {
    top: -0.7rem;
    left: 0.8rem;
    z-index: 10;
    font-weight: 500;
    font-size: 0.87rem;
  }

  .form-input:not(:placeholder-shown) .form-input:not(:focus) + .form-label {
    top: -0.7rem;
    left: 0.8rem;
    z-index: 10;
    font-weight: 500;
    font-size: 0.87rem;
  }

  @media only screen and (min-width: 992px) {
    .form-input:not(:placeholder-shown) .form-input:not(:focus) + .form-label {
      font-size: 0.87rem;
    }
  }

  .form-input:focus {
    border: 1.5px solid #767676;
  }

  .ic_functional_search {
    position: absolute;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    top: 10px;
  }
`