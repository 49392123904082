import styled from 'styled-components';

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.div`
  visibility: hidden;
  position: absolute;
  bottom: -300;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--gray-50);
  color: white;
  padding: 5px;
  width: 284px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;
