import React from 'react';
import { Container, InputIcon, Label, Icon } from '../styles';

export default function TextFieldIcon({
  id,
  label,
  idName,
  placeholder = ' ',
  classIcon,
  defaultValue,
  type,
  className,
  onChange,
  onKeyUp,
}) {
  return (
    <Container>
      <InputIcon
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={idName}
        id={idName}
        type={type}
        className={className}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
      <Label htmlFor={id}>{label}</Label>
      <Icon className={`icon-button esfera-icon ${classIcon}`} />
    </Container>
  );
}
