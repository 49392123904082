import React, { useState } from 'react';
import moment from 'moment';
import { useKeycloak } from '@react-keycloak/web';
import Button from '../../Button';
import Loading from '../../Loading';
import ToastCustom from '../../Toast';

import { StyledModal, WrapperButtons } from './styles';
import {
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../../helpers/GlobalFunctions';

export default function ModalReprocessTransactionPma({
  item,
  isOpenModal,
  handleCloseModal,
  reloadAfterAction,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL =
    window.SERVICES_DOMAIN + window.TRANSACTION_PMA_ITEM_REPROCESS_URL;
  const [showLoading, setShowLoading] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };

  const handleReprocessOrder = () => {
    setShowLoading(true);
    const reqOptions = {
      method: 'POST',
      headers: initialHeadersData(keycloak.token),
      data: {},
    };

    handlePostRequest(`${FULL_URL}/${item.manualOrderId}`, reqOptions)
      .then(() => {
        handleCloseModal(
          'success',
          'Sucesso!',
          `Item reprocessado com sucesso.`,
        );
        populateToastMessages(
          'success',
          'Sucesso!',
          'Item reprocessado com sucesso.',
        );
        reloadAfterAction(true);
      })
      .catch(error => {
        const errorMessage = formatErrorMessageToToast(error, keycloak);
        handleCloseModal('error', 'Erro!', errorMessage.messagePTBR);
        populateToastMessages(
          'error',
          'Erro!',
          errorMessage.messagePTBR,
          'main',
        );
        reloadAfterAction(false);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };
  return (
    <>
      <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
        <Loading showLoading={showLoading} />

        <StyledModal.Header closeButton>
          <StyledModal.Title>Reprocessar</StyledModal.Title>
        </StyledModal.Header>

        <StyledModal.Body>
          <div>
            <p>Deseja realmente reprocessar o item abaixo: </p>
            <span>
              <strong>ID Tran Comarch: </strong>
              {item.comarchTransactionId ? item.comarchTransactionId : '---'}
            </span>
            <span>
              <strong>Pedido parceiro: </strong>
              {item.partnerOrderId ? item.partnerOrderId : '---'}
            </span>
            <span>
              <strong>Pedido de cashback: </strong>
              {item.orderId ? item.orderId : '---'}
            </span>
            <span>
              <strong>Status pedido: </strong>
              {item.status ? item.status : '---'}
            </span>
            <span>
              <strong>Documento cliente: </strong>
              {item.document ? item.document : '---'}
            </span>
            <span>
              <strong>Valor cashback: </strong>
              {item.cashbackValueDouble
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(Number(item.cashbackValueDouble))
                : 'R$ 0,00'}
            </span>
            <span>
              <strong>Nome campanha: </strong>
              {item.campaignName ? item.campaignName : '---'}
            </span>
            <span>
              <strong>Código campanha: </strong>
              {item.campaignCode ? item.campaignCode : '---'}
            </span>
            <span>
              <strong>Data criação: </strong>
              {item.createDate
                ? moment(item.createDate).format('DD/MM/YYYY HH:mm')
                : '---'}
            </span>
            <span>
              <strong>Data atualização: </strong>
              {item.updateDate
                ? moment(item.updateDate).format('DD/MM/YYYY HH:mm')
                : '---'}
            </span>
            <span className="message-content">
              <strong>Mensagem processamento: </strong>
              {item.message ? item.message : '---'}
            </span>
          </div>

          <WrapperButtons>
            <Button typeOfButton="secondary" onClick={handleReprocessOrder}>
              Sim
            </Button>
            <Button typeOfButton="secondary" onClick={handleCloseModal}>
              Não
            </Button>
          </WrapperButtons>
        </StyledModal.Body>
      </StyledModal>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
    </>
  );
}
