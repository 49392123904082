import React from 'react';
import moment from 'moment';
import Button from '../../Button';
import constants from '../../../config';

import { StyledModal, WrapperButtons } from './styles';

export default function ModalOrderDetails({
  order,
  isOpenModal,
  handleCloseModal,
  handleShowMdlConfirm,
  handleShowMdlRefused,
}) {
  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>Detalhes do Pedido</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div>
          <h6>Pedido</h6>
          <span>
            <strong>Pedido: </strong>
            {order.orderId}
          </span>

          <span>
            <strong>Data: </strong>
            {moment(order.submittedDate).format('DD/MM/YYYY HH:mm')}
          </span>

          <span>
            <strong>Status: </strong>
            {constants.OCC_STATUS_VALUE[order.status]}
          </span>

          {order.discounts.length > 0 && (
            <span>
              <strong>Cupom: </strong>
              {order.discounts.join(', ')}
            </span>
          )}

          <span>
            <strong>Valor Total: </strong>
            {order.amount}
          </span>
        </div>

        <div>
          <h6>Produtos</h6>
          {order.products.length > 0 ? (
            <>
              {order.products.map((product, index) => (
                <React.Fragment key={Math.random()}>
                  <span>
                    <strong>Id: </strong>
                    {product.id}
                  </span>
                  <span>
                    <strong>Sku: </strong>
                    {product.skuId}
                  </span>
                  <span>
                    <strong>Nome: </strong>
                    {product.name}
                  </span>
                  <span>
                    <strong>Quantidade: </strong>
                    {product.quantity}
                  </span>
                  <span>
                    <strong>Tipo: </strong>
                    {constants.OCC_PRODUCT_TYPES[product.type]}
                  </span>

                  {index !== order.products.length - 1 && <hr />}
                </React.Fragment>
              ))}
            </>
          ) : (
            `Nenhum produto retornado.`
          )}
        </div>

        <div>
          <h6>Usuário</h6>
          <span>
            <strong>Documento: </strong>
            {order.profile.document}
          </span>

          <span>
            <strong>Nome: </strong>
            {order.profile.firstName
              ? `${order.profile.firstName} ${order.profile.lastName}`
              : `-`}
          </span>

          <span>
            <strong>Email: </strong>
            {order.profile.email ? `${order.profile.email}` : `-`}
          </span>

          <span>
            <strong>Telefone: </strong>
            {order.profile.phoneNumber ? `${order.profile.phoneNumber}` : `-`}
          </span>

          <span>
            <strong>Endereço: </strong>
            {order.profile.address ? `${order.profile.address}` : `-`}
          </span>
        </div>

        {order.status === constants.OCC_STATUS_LABEL.INITIAL && (
          <WrapperButtons>
            <Button typeOfButton="secondary" onClick={handleShowMdlConfirm}>
              Confirmar
            </Button>
            <Button typeOfButton="secondary" onClick={handleShowMdlRefused}>
              Recusar
            </Button>
          </WrapperButtons>
        )}
      </StyledModal.Body>
    </StyledModal>
  );
}
