import styled from "styled-components";

import { Form } from 'react-bootstrap';

export const StyledForm = styled(Form)`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-content {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 10px;

    @media (max-width: 473px) {
      width: 100%;
      margin-right: 0;
    }

    select {
      width: 248px;
    }

    > label {
      margin: 0 10px 0 0;
      font-size: 0.875rem;
      width: 90px;

      @media (max-width: 600px) {
        width: 65px;
      }
    }

    input[type="date"] {
      width: 170px;
      margin-right: 10px;
      font-size: 0.875rem;

      @media (max-width: 473px) {
        flex: 1;
        margin-right: 0;
        &:nth-child(3) {
          margin-top: 10px;
          margin-left: 75px;
        }
      }
    }

    input[type="text"] {
      width: 350px;
      font-size: 0.875rem;

      @media (max-width: 473px) {
        width: 100%;
        flex: 1;
      }
    }
  }

  span {
    margin-bottom: 8px;
    font-size: 0.875rem;
  }

  .inputs-group {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }

  .form-actions {
    margin-left: 120px;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 265px;
    min-width: 150px;

    @media (max-width: 1080px) {
      margin-left: 0;
      width: 100%;
    }

    @media (max-width: 473px) {
      max-width: none;
    }

    .btn-clear-filter {
      border: 0;
      background: transparent;
      outline: 0;
      color: var(--gray-200);
      margin-top: 20px;
      text-decoration: underline;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    button[type="submit"] {
      width: 100%;
    }
  }
`;
