import React from 'react';
import AirCampaignTableHead from '../AirCampaignTableHead';
import AirCampaignTableBody from '../AirCampaignTableBody';

import * as S from './styles';

export default function AirCampaignTable({
  headTableData,
  bodyTableData,
  userPermissions,
  isModifiedAirCampaign,
  setIsModifiedAirCampaign,
  populateToastMessages,
}) {
  return (
    <S.Container>
      <S.Table responsive>
        <AirCampaignTableHead headTableData={headTableData} />
        <tbody>
          {bodyTableData.map(item => (
            <AirCampaignTableBody
              key={item}
              item={item}
              userPermissions={userPermissions}
              isModifiedAirCampaign={isModifiedAirCampaign}
              setIsModifiedAirCampaign={setIsModifiedAirCampaign}
              populateToastMessages={populateToastMessages}
            />
          ))}
        </tbody>
      </S.Table>
    </S.Container>
  );
}
