import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';
import { handlePutRequest } from '../../../services/GenericMethods';
import { StyledSwitch } from '../../RuleItem/styles';
import ModalCreateCoupoun from '../ModalCreateCoupoun';
import * as S from './styles';

export default function CouponsTableBody({
  item,
  userPermissions,
  getCouponIdState,
  setCuponIdState,
  setModalToastList,
  populateToastMessages,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.COUPON_ACTIVE_URL;

  const [isSwitchOn, setIsSwitchOn] = useState(item.enabled);
  const [isOpenModalEditRule, setIsOpenModalEditRule] = useState(false);
  const handleShowMdlEditRule = code => {
    setCuponIdState(code);
    setIsOpenModalEditRule(true);
  };
  const handleCloseMdlEditRule = () => setIsOpenModalEditRule(false);
  const handleChangeSwitch = couponCode => {
    const putOptions = {
      method: 'PUT',
      data: {},
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`,
      },
    };
    handlePutRequest(`${FULL_URL}/${couponCode}`, putOptions)
      .then(() => {
        setIsSwitchOn(!isSwitchOn);
        populateToastMessages(
          'success',
          'Sucesso!',
          'Regra alterada com sucesso!',
        );
      })
      .catch(error => {
        console.error(`Error at handleChangeSwitch request = ${error}`);
        console.error(error);
        const errorMessage = formatErrorMessageToToast(error, keycloak);
        populateToastMessages('error', 'Erro', errorMessage.messagePTBR);
      });
  };

  return (
    <S.StyledTr isInactive={isSwitchOn}>
      {!!item.promoPriority && <td>{item.promoPriority}</td>}
      {!!item.order && <td>{item.order}</td>}
      <td className="emphasis">{item.couponId}</td>
      {!!item.userName && <td>{item.userName}</td>}
      {!!item.couponName && <td>{item.couponName}</td>}
      {!!item.couponDescription && <td>{item.couponDescription}</td>}
      {!!item.registrationDate && <td>{item.registrationDate}</td>}
      <td>
        {userPermissions.checkUiPermissionsFn(
          'ESFERA.COUPONS.UPDATE',
          userPermissions.userGroups,
        ) ? (
          <div className="container-actions">
            <span
              className="esfera-icon BD270 iconEdit"
              onClick={() => {
                handleShowMdlEditRule(item.couponId);
              }}
              aria-hidden="true"
            />
            <StyledSwitch
              type="switch"
              id={`switch${item.couponId}`}
              onChange={() => {
                handleChangeSwitch(item.couponId);
              }}
              checked={isSwitchOn}
            />
          </div>
        ) : null}
      </td>

      <ModalCreateCoupoun
        isOpenModal={isOpenModalEditRule}
        handleCloseModal={handleCloseMdlEditRule}
        item={item}
        userPermissions={userPermissions}
        getCouponIdState={getCouponIdState}
        setModalToastList={setModalToastList}
        // ruleNumber={item.code}
      />
    </S.StyledTr>
  );
}
