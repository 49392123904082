import React, { useMemo } from 'react';
import PaginationItem from './PaginationItem';
import * as S from './styles';

export default function Pag({ currentPage, totalPages, onClickFnPage }) {
  const btnPrev = useMemo(() => {
    return <span className="icoArrow esfera-icon BE150" />;
  }, []);

  const btnNext = useMemo(() => {
    return <span className="icoArrow esfera-icon BE070" />;
  }, []);

  const onClickEmpty = () => {
    console.log('no more pages.');
  };

  const paginate = (actualPage, lastPage, delta = 5) => {
    const initialRangeOfPages = Array(lastPage)
      .fill()
      .map((_, index) => index + 1);

    const reducedPageRange = initialRangeOfPages.reduce((pages, page) => {
      if (page === 1 || page === lastPage) {
        return [...pages, page];
      }

      if (page - delta <= actualPage && page + delta >= actualPage) {
        return [...pages, page];
      }

      if (pages[pages.length - 1] !== '...') {
        return [...pages, '...'];
      }

      return pages;
    }, []);

    const rangeOfPages = [];
    const actualPages = [...reducedPageRange];
    actualPages.forEach((pageNumber, index) => {
      const pageObject = {
        pageIndex: pageNumber - 1,
        page: pageNumber,
      };

      if (index !== 1 && pageNumber === '...') {
        pageObject.pageIndex = actualPages[actualPages.length - 3];
      } else if (index === 1 && pageNumber === '...') {
        pageObject.pageIndex = actualPages[2] - 2;
      }
      rangeOfPages.push(pageObject);
    });

    return rangeOfPages;
  };

  return (
    <S.Container>
      <PaginationItem
        page={btnPrev}
        onClickFnPage={currentPage > 0 ? onClickFnPage : onClickEmpty}
        index={currentPage - 1}
      />
      {paginate(currentPage, totalPages).map(item => (
        <PaginationItem
          page={item.page}
          key={Math.random()}
          isActive={item.pageIndex === currentPage}
          onClickFnPage={onClickFnPage}
          index={item.pageIndex}
        />
      ))}
      <PaginationItem
        page={btnNext}
        isActive={currentPage + 1 < [...Array(totalPages)].length}
        onClickFnPage={
          currentPage + 1 < [...Array(totalPages)].length
            ? onClickFnPage
            : onClickEmpty
        }
        index={currentPage + 1}
      />
    </S.Container>
  );
}
