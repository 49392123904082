import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledLink = styled(NavLink)`
  ${({ theme }) => css`
    width: 100%;
    margin: 15px 0 0 0;
    padding: 8px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    font-family: ${theme.fonts.family.roboto};
    font-size: ${theme.fonts.textSize.small};
    font-weight: ${theme.fonts.medium};
    color: ${theme.colors.darkerGrey};
    line-height: 24px;


    .linkIcon {
      margin-right: 10px;
      font-size: 20px;
      &::before {
        color: ${theme.colors.darkerGrey};
      }
    }

    &.active {
      background: #F0D8D8;
      color: ${theme.colors.boston};
      border-radius: 8px;

      &::before {
        background: ${theme.colors.boston};
      }

      .linkIcon {
        &::before {
          color: ${theme.colors.boston};
        }
      }
    }

    &:hover {
      text-decoration: none;
      background: #F0D8D8;
      border-radius: 8px;
      color: ${theme.colors.boston};

      .linkIcon {
        &::before {
          color: ${theme.colors.boston};
        }
      }
    }

  `}
`;
