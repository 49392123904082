import React, { useState } from 'react';

import Button from '../../Button';
import { CouponsHeaderContentStyle } from './styles';
import SearchCoupon from '../SearchCoupon';
import ModalCreateCoupoun from '../ModalCreateCoupoun';

export default function CouponsHeaderContent({
  title,
  subtitle,
  searchParams,
  onChangeSearchParams,
  userPermissions,
  items,
  getCouponIdState,
  setCuponIdState,
  setModalToastList,
}) {
  const [isOpenModalCreateCoupon, setIsOpenModalCreateCoupon] = useState(false);

  const handleShowMdlCreateCoupon = () => {
    setIsOpenModalCreateCoupon(true);
    setCuponIdState('');
  };
  const handleCloseMdlCreateCoupon = () => setIsOpenModalCreateCoupon(false);

  return (
    <CouponsHeaderContentStyle>
      <div className="contentmain__header">
        <div className="contentmain__header__title">
          <text className="contentmain__header__text">{title}</text>
          <span className="contentmain__header__icon icoArrow esfera-icon BE070" />
          <text className="contentmain__header__text contentmain__header__text__active">
            {subtitle}
          </text>
        </div>
        {userPermissions.checkUiPermissionsFn(
          'ESFERA.COUPONS.CREATE',
          userPermissions.userGroups,
        ) ? (
          <Button
            typeOfButton="primary"
            iconClassName="BD030"
            onClick={() => {
              handleShowMdlCreateCoupon();
            }}
          >
            + Criar cupom
          </Button>
        ) : null}
      </div>
      <div className="contentmain__lastupdate">
        <text className="contentmain__lastupdate__text">
          Última atualização: há minutos
          {/* Última atualização: há {lastGetOrderExecuted} minutos */}
        </text>
        <SearchCoupon
          label="Buscar cupom"
          id=""
          searchParams={searchParams}
          onChangeSearchParams={onChangeSearchParams}
        />
      </div>

      <ModalCreateCoupoun
        isOpenModal={isOpenModalCreateCoupon}
        handleCloseModal={handleCloseMdlCreateCoupon}
        userPermissions={userPermissions}
        items={items}
        getCouponIdState={getCouponIdState}
        setModalToastList={setModalToastList}
      />
    </CouponsHeaderContentStyle>
  );
}
