export const findProp = (obj, prop, defval) => {
  if (typeof defval == 'undefined') defval = null;
  prop = prop.split('.');
  for (var i = 0; i < prop.length; i++) {
    if (typeof obj[prop[i]] == 'undefined') return defval;
    obj = obj[prop[i]];
  }
  return obj;
};

export const populateToastMessagesGlobal = (status, title, message, queue) => {
  let appendToast;
  if (status === 'error') {
    appendToast = {
      id: Math.random(),
      title,
      description: message,
      backgroundColor: '#d9534f',
      icon: 'errorIcon',
    };
  } else if (status === 'success') {
    appendToast = {
      id: Math.random(),
      title,
      description: message,
      backgroundColor: '#5cb85c',
      icon: 'checkIcon',
    };
  } else if (status === 'warning') {
    appendToast = {
      id: Math.random(),
      title,
      description: message,
      backgroundColor: '#f0ad4e',
      icon: 'warningIcon',
    };
  }
  return appendToast;
};

export const formatErrorMessageToToast = (error, keycloak) => {
  let responseErrorMessage;
  if (error.response) {
    let errorMessage;
    if (error.response.data.messages) {
      errorMessage = error.response.data.messages.join('.\n');
    } else if (error.response.data.error) {
      errorMessage = error.response.data.error;
    } else if (
      typeof error.response.data !== 'object' &&
      !Array.isArray(error.response.data) &&
      error.response.data !== null
    ) {
      errorMessage = error.response.data;
    } else {
      errorMessage = 'A requisição feita não obteve uma resposta válida.';
    }
    responseErrorMessage = {
      status: error.response.status,
      messagePTBR: errorMessage,
      messageEN: error.response.data.messages,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    responseErrorMessage = {
      status: '50X',
      messagePTBR: 'A requisição feita não obteve resposta.',
      messageEN: 'The request was made but no response was received',
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    responseErrorMessage = {
      status: '5XX',
      messagePTBR: 'Erro indefinido, favor aguarde e tente novamente.',
      messageEN:
        'Something happened in setting up the request that triggered an Error',
    };
  }

  if (keycloak && responseErrorMessage.status === 401) handleLogout(keycloak);

  return responseErrorMessage;
};

const handleLogout = keycloak => {
  keycloak.logout();
};

export const formatCpfCnpj = doc =>
  doc.length <= 11
    ? doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
    : doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
