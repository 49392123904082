import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Link } from 'react-router-dom';
import useMenuMobile from '../../../hooks/useMenuMobile';
import HeaderLoggedUser from './HeaderLoggedUser';

import {
  Container,
  StyledNavbar,
  StyledNavDropdown,
  StyledBrandHeader,
  StyledActionsHeader,
} from './styles';

export default function Header({ iconHeader, imgHeader, text }) {
  const { keycloak } = useKeycloak();
  const handleLogout = () => {
    keycloak.logout();
  };
  const userName = keycloak?.userInfo?.name || '';
  const navDropDownTitle = <HeaderLoggedUser name={userName} />;
  const { handleToggleMenuMobile } = useMenuMobile();

  return (
    <Container>
      <StyledNavbar collapseOnSelect expand="lg">
        <StyledBrandHeader>
          <img src={iconHeader} alt="" />
          <img src={imgHeader} alt="" />
          <span>{text}</span>
        </StyledBrandHeader>

        <StyledActionsHeader>
          <span className="esfera-action-header esfera-icon D0400" />
          <Link to="/" style={{ textDecoration: 'none', paddingTop: 2 }}>
            <span className="esfera-action-header esfera-icon ic_functional_home" />
          </Link>
          <StyledNavDropdown
            title={navDropDownTitle}
            id="collasible-nav-dropdown"
            className="ml-auto"
          >
            <StyledNavDropdown.Item onClick={handleLogout}>
              Sair
            </StyledNavDropdown.Item>
          </StyledNavDropdown>

          <StyledNavbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleToggleMenuMobile}
          />
        </StyledActionsHeader>
      </StyledNavbar>
    </Container>
  );
}
