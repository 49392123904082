import React, { useState } from 'react';

import ToggleButton from '../../OrdersPage/ToggleButton';
import Button from '../../Button';
import constants from '../../../config';

import { StyledForm } from './styles';

export default function CppRuleFilter({ currentFilter, handleFilter }) {
  const [ruleType, setRuleType] = useState('DEFAULT');
  const [ruleSiteId, setRuleSiteId] = useState('DEFAULT');
  const [ruleId, setRuleId] = useState(null);
  const [status, setStatus] = useState(null);

  const handleRulelistType = e => {
    setRuleType(e.target.value);
  };

  const handleRuleSiteId = e => {
    setRuleSiteId(e.target.value);
  };

  const handleRuleId = e => {
    setRuleId(e.target.value);
  };

  const handleStatus = e => {
    setStatus(e.target.value);
  };

  const clearFilter = () => {
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.ruleType;
    delete filter.siteId;
    delete filter.ruleId;
    delete filter.active;

    setRuleType('DEFAULT');
    setRuleSiteId('DEFAULT');
    setRuleId('');
    setStatus('');

    handleFilter(filter);
  };

  const applyFilter = e => {
    e.preventDefault();
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.ruleType;
    delete filter.siteId;
    delete filter.ruleId;
    delete filter.active;

    if (ruleType !== 'DEFAULT') filter.ruleType = ruleType;
    if (ruleSiteId !== 'DEFAULT') filter.siteId = ruleSiteId;
    if (ruleId) filter.ruleId = ruleId;
    if (status) filter.active = status;

    handleFilter(filter);
  };

  const isFilterValid = () => {
    const isValid =
      status || ruleType !== 'DEFAULT' || ruleSiteId !== 'DEFAULT' || ruleId;
    return isValid;
  };

  return (
    <StyledForm>
      <div className="form-content">
        <StyledForm.Group className="form-group">
          <StyledForm.Label>Site:</StyledForm.Label>
          <StyledForm.Control
            as="select"
            value={ruleSiteId || ''}
            onChange={handleRuleSiteId}
          >
            <option disabled value="DEFAULT">
              Filtre por site
            </option>
            {Object.keys(constants.CPP_SITE_ID).map(item => (
              <option value={item} key={item}>
                {constants.CPP_SITE_ID[item]}
              </option>
            ))}
          </StyledForm.Control>
        </StyledForm.Group>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Tipo:</StyledForm.Label>
            <StyledForm.Control
              type="select"
              as="select"
              value={ruleType || ''}
              onChange={handleRulelistType}
            >
              <option disabled value="DEFAULT">
                Filtre por tipo
              </option>
              {Object.keys(constants.CPP_RULE_TYPES).map(item => (
                <option value={item} key={item}>
                  {constants.CPP_RULE_TYPES[item]}
                </option>
              ))}
            </StyledForm.Control>
          </StyledForm.Group>

          <StyledForm.Group className="form-group">
            <StyledForm.Label>Identificador:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleRuleId}
              type="text"
              value={ruleId || ''}
            />
          </StyledForm.Group>
        </div>

        <StyledForm.Group className="form-group">
          <StyledForm.Label>Status: </StyledForm.Label>
          <ToggleButton
            onChange={handleStatus}
            groupName="status"
            text="Ativa"
            value="true"
            checked={status === 'true'}
          />
          <ToggleButton
            onChange={handleStatus}
            groupName="status"
            text="Inativa"
            value="false"
            checked={status === 'false'}
          />
        </StyledForm.Group>
      </div>

      <div className="form-actions">
        <Button
          type="submit"
          typeOfButton="primary"
          onClick={applyFilter}
          disabled={!isFilterValid()}
        >
          Aplicar Filtros
        </Button>
        <button onClick={clearFilter} type="button" className="btn-link">
          Limpar Filtros
        </button>
      </div>
    </StyledForm>
  );
}
