import React, { useRef } from 'react';

import Button from '../Button';
import { FileName, InputContainer } from './styles';

const FileInput = ({ value, onChangeValue }) => {
  const inputFileRef = useRef();

  const handleInputClick = () => inputFileRef.current.click();

  return (
    <>
      <InputContainer onClick={handleInputClick}>
        <Button onClick={e => e.preventDefault()}>Arquivo</Button>

        <FileName>{value && value.name}</FileName>
      </InputContainer>

      <input
        ref={inputFileRef}
        type="file"
        style={{ display: 'none' }}
        onChange={onChangeValue}
      />
    </>
  );
};

export default FileInput;
