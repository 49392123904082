import React from 'react';
import HistoryCampaignTableBody from '../HistoryCampaignTableBody';
import HistoryCampaignTableHead from '../HistoryCampaignTableHead';

import * as S from './styles';

export default function HistoryCampaignTable({ headTableData, bodyTableData }) {
  return (
    <S.Container>
      <S.Table responsive>
        <HistoryCampaignTableHead headTableData={headTableData} />
        <tbody>
          {bodyTableData.map(item => (
            <HistoryCampaignTableBody item={item} />
          ))}
        </tbody>
      </S.Table>
    </S.Container>
  );
}
