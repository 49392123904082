import styled, { css } from "styled-components";
import { Table as TableBootstrap } from "react-bootstrap";


export const Container = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.lightGrey};
    border-radius: 24px;
    background: ${theme.colors.white};

    .table-responsive {
      border-radius: inherit;
    }
  `}
`;

export const Table = styled(TableBootstrap)`
  margin-bottom: 0;
`;

