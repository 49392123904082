import React from 'react';

import BlacklistItem from '../BlacklistItem';

import { StyledTable } from './styles';

export default function BlacklistTable({
  products,
  userPermissions,
  handleShowMessageAction,
}) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>Tipo</th>
          <th>Data de Inclusão</th>
          <th>Ações</th>
        </tr>
      </thead>

      <tbody>
        {products.map(product => (
          <BlacklistItem
            product={product}
            key={Math.random()}
            userPermissions={userPermissions}
            handleShowMessageAction={handleShowMessageAction}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
