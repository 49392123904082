import React from 'react';
import { FilterStyle } from './styles';

export default function PartnerFilter({ sortPartners }) {
  const handleChange = e => {
    sortPartners(e.target.value);
  };
  return (
    <FilterStyle>
      <div className="content__filter__partners">
        <span>Ordenar:</span>
        <select className="select__filter" onChange={handleChange}>
          <option value="az">Ordem alfabética (A-Z)</option>
          <option value="za">Ordem alfabética (Z-A)</option>
          <option value="old">Data de modificação (Mais antigo)</option>
          <option value="new">Data de modificação (Mais novo)</option>
        </select>
      </div>
      <span className="esfera-icon BE090" />
    </FilterStyle>
  );
}
