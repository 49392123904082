import React from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../Button';
import { StyledModal } from './styles';

export default function ModalCodeAdded({
  isOpenModal,
  handleCloseModal,
  newPartnerCodes,
}) {
  const statusPartnerCodes = [];

  newPartnerCodes.forEach(item => {
    if (item.statusPartnerCode) {
      statusPartnerCodes.push(item.statusPartnerCode);
    }
  });

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>Adição feita com sucesso!</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>
              Você acaba de adicionar os códigos:
              <span className="form_label_code">
                {statusPartnerCodes.join(';')}
              </span>
            </Form.Label>
          </Form.Group>

          <div className="container-buttons">
            <Button
              type="button"
              typeOfButton="primary"
              onClick={handleCloseModal}
            >
              Continuar
            </Button>
          </div>
        </Form>
      </StyledModal.Body>
    </StyledModal>
  );
}
