import styled, { css } from 'styled-components';
import * as Styled from '../../components/Nucleo/Content/CustomTable/styles';
export const Container = styled.div`
height: 100vh;
display: block;
float: right;
width: 85%;
margin-top: 4%;
padding: 2rem 1rem;
${({ theme }) => css`
  padding: 0 16px;
  height: 100%;
  ${Styled.Container} {
    & + div {
      margin-top: 40px;
    }
  }
`}
`;

export const CreateCampaignHeader = styled.div`
  .contentmain__header__title {
    width: 100%;
    min-height: 72px;
    border-bottom: 1px solid #DADADA;
  }
  .contentmain__body .contentmain__header__text {
    width: 100%;
    height: 40px;
    font-size: 24px;
    font-weight: 600;
  }

  .contentmain__header__description {
    width: 100%;

    padding-top: 3%;
    display: grid;

    border-top: 1px solid #cccccc;
  }

  .partner-name {
    font-family: "Poppins";
    font-size: 20px ;
    font-weight: 700;
    align-self: center;
    width: 25%;  
  }

  .contentmain__header__subtitle {
  }

  a.contentmain__header__link {
    color: #cc0000;
  }

  .contentmain__header__text {
    padding: 16px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
  }
`;

export const CreateCampaignBody = styled.div`
  .contentmain__body {
    width: 100%;
    margin-top: 45px;

    input {
      height: 60px !important;
    }
  }
  .name_campaign {
    .contentmain__body__value {
      display: flex;
      gap: 18px;
      margin-top: 16px;
      grid-template-columns: repeat(2, 162px);

      > div {
        width: 42.5%;
      }
    }
  }

  .term_campaign {
    width: 100%;
    margin-top: 30px;

    .contentmain__body__field {
      display: flex;
      width: 50%;
    }

    .contentmain__body__fields {
      display: flex;
      width: 100%;
      margin-top: 1%;
    }

    .contentmain__body__date {
      width: 40%;
    }
    .contentmain__body__time {
      width: 40%;
      margin-left: 5%;
    }
  }
  .container-buttons {
    height: 80px;
    align-items: flex-star;
    display: flex;
    justify-content: space-between;
    margin: 75px 0px 50px 0px;

    button {
      width: 175px;
      height: 56px;
      top: 902px;
      left: 264px;
      padding: 16px, 64px, 16px, 64px;
      border-radius: 4px;
      border: 1px solid;
      gap: 10px;
    }
  }
  .two-buttons {
    display: flex;
    button {
      margin: 10px;
      width:215px;
    }
  }
  .contentmain__body__text {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
  }
  label {
    font-size: 14px !important;
    top: -13px !important;
  }
`;
