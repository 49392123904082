import React, { useState } from 'react';
import { Container, Collapse } from './styles';

export default function NavGroupLink({
  iconClass,
  navGroupOpen = false,
  children,
  title,
}) {
  const [isOpen, setIsOpen] = useState(navGroupOpen);

  return (
    <Container>
      <button type="button" onClick={() => setIsOpen(!isOpen)}>
        <span className={`linkIcon esfera-icon ${iconClass}`} />
        {title}

        {isOpen ? (
          <span className="arrowIcon esfera-icon BE040" />
        ) : (
          <span className="arrowIcon esfera-icon BE120" />
        )}
      </button>

      <Collapse isOpen={isOpen}>{children}</Collapse>
    </Container>
  );
}
