import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { FiEdit } from 'react-icons/fi';
import EditModalRules from '../EditModalRules';

import { StyledSwitch, StyledTr } from './styles';
import {
  handlePutRequest,
  initialHeadersData,
} from '../../services/GenericMethods';
import constants from '../../config';
import { formatErrorMessageToToast } from '../../helpers/GlobalFunctions';

export default function RuleItem({ rule, handleCloseModal, userPermissions }) {
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.ANTIFRAUD_RULES_URL;
  const [isSwitchOn, setIsSwitchOn] = useState(rule.active);
  const [isOpenModalEditRule, setIsOpenModalEditRule] = useState(false);

  const handleShowMdlEditRule = () => setIsOpenModalEditRule(true);
  const handleCloseMdlEditRule = (status, title, message) => {
    handleCloseModal(status, title, message);
    setIsOpenModalEditRule(false);
  };

  const updateRule = (id, action, activated) => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ANTIFRAUD.RULES.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      let actionFormatted = constants.FRAUD_CHECK_LABEL;
      if (action === constants.REFUSED) {
        actionFormatted = constants.REFUSED_LABEL;
      }

      const reqOptions = {
        method: 'PUT',
        data: {
          action: actionFormatted,
          activated,
        },
        headers: initialHeadersData(keycloak.token),
      };

      handlePutRequest(`${FULL_URL}/${id}`, reqOptions)
        .then(response => {
          setIsSwitchOn(!isSwitchOn);
          handleCloseModal(
            'success',
            'Sucesso!',
            `Status da regra ${response.data.id} alterada com sucesso!`,
          );
        })
        .catch(error => {
          console.error(`Error at updateRule request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
  };

  const handleChangeSiwtch = (id, action) => {
    updateRule(id, action, !isSwitchOn);
  };

  useEffect(() => {
    setIsSwitchOn(rule.active);
  }, [rule]);
  const TdAction = () => {
    return (
      <td>
        {userPermissions.checkUiPermissionsFn(
          'ANTIFRAUD.RULES.UPDATE',
          userPermissions.userGroups,
        ) ? (
          <div className="container-actions">
            <FiEdit onClick={handleShowMdlEditRule} />
            <StyledSwitch
              type="switch"
              id={`switch${rule.id}`}
              onChange={() => handleChangeSiwtch(rule.id, rule.action)}
              checked={isSwitchOn}
            />
          </div>
        ) : (
          <div className="container-actions">Nenhuma disponível</div>
        )}
      </td>
    );
  };

  return (
    <StyledTr isInactive={isSwitchOn}>
      <td className="rules-number">
        <img src={rule.rulesImg} alt="" />
        {rule.id}
      </td>
      <td>{rule.user}</td>
      <td>{rule.createDate}</td>
      <td>{rule.updateDate}</td>
      <td className={`status ${rule.ruleConfig}`}>{rule.action}</td>
      <TdAction />

      <EditModalRules
        isOpenModal={isOpenModalEditRule}
        handleCloseModal={handleCloseMdlEditRule}
        ruleNumber={rule.id}
      />
    </StyledTr>
  );
}
