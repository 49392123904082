import styled, { css } from 'styled-components';
import * as Styled from '../../components/Nucleo/Content/CustomTable/styles';
export const Container = styled.div`
  height: 100vh;
  display: block;
  float: right;
  width: 85%;
  margin-top: 4%;
  padding: 2rem 1rem;
  ${({ theme }) => css`
    padding: 0 16px;
    height: 100%;
    ${Styled.Container} {
      & + div {
        margin-top: 40px;
      }
    }
  `}
  .table-content > main > .loading-content-compass {
    padding-right: 12%;
    padding-top: 7%;
  }
  .table-content > main {
    width: 84%;
  }
`;
export const listHeaderPartnersParity = styled.div`
  .content__header__list__partner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
  }
  .contentmain__header {
    width: 100%;
    align-items: center;
    min-height: 72px;
    border-bottom: 1px solid #dadada;
  }
  .contentmain__header__title {
    display: flex;
    justify-content: space-between;
  }
  .contentmain__header__update {
    display: flex;
    grid-gap: 8px;

    .contentmain__header__icon {
      margin: 0 auto;
      align-self: center;
      font-size: 25px;
    }
    .contentmain__header__text {
      color: #cc0000;
      font-size: 18px;
      font-family: Open Sans;
      text-decoration: underline;
    }
  }
  .contentmain__header__text {
    padding: 16px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gray-50);
  }
  .contentmain__text-info {
    margin-top: 15px;
    margin-bottom: 45px;
  }
  button {
    border: none;
    background: none;
  }
  .content__title_partner {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
    align-self: center;
    width: 25%;
  }
`;
export const Campaign = styled.div`
  display: block;
  width: 45%;
  margin-top: 0%;
  height: 100%;

  .contentmain__campaign {
    display: flex;
    padding: 1rem 0rem;
    width: 50%;
  }

  .contentmain__campaign__title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.5px;
    text-align: left;
    padding-right: 130px;
    white-space: nowrap;
  }
  .contentmain__campaign__seeAll {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #cc0000;
    text-decoration: underline;
    padding-right: 10px;
    white-space: nowrap;
  }

  .ic_system_arrow_right {
    color: #cc0000;
    align-items: center;
    display: flex;
  }

  .container__campaign {
    display: inline-flex;
  }
  .button-campaign {
    margin-right: 40px;
    min-width: 358px;
    position: relative;
    width: auto;
    height: 68px;
    cursor: pointer;
    display: flex;
    padding: 12px 5px 0px 16px;
    font-size: 1rem;
    font-weight: 600;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background: var(--white);
    color: var(--black);
    display: flex;
    transition: all 0.2s linear;
  }

  .name__campaign {
    padding-right: 25px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #222222;
    white-space: nowrap;
  }
  .date__campaign {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    color: #444444;
  }
  .icon-update {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 5px;
    margin-top: 10px;
  }
`;
export const ButtonAdd = styled.div`
width: 352px;
height:68px;
cursor: pointer;
display: flex;
padding: 10px 22px;
font-size: 1rem;
font-weight: 600;
border: 1px solid #cccccc;
border-radius: 4px;
background: var(--white);
align-items: center;
transition: all 0.2s linear;
  icon_add_parity {
    width: 10%;
    text-align: center;
    margin-right: 10px;
    border: 1px dashed gray;
    border-radius: 4px;
  }
  }
`;
export const LoadingComponent = styled.main`
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
  .loading-box-compass {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #eee;
    z-index: 11;
    opacity: 0.9;
    visibility: visible;
  }

  .loading-content-compass {
    z-index: 12;
    position: relative;
    padding-top: 25%;
    visibility: visible;
  }
`;
