import styled, { css } from 'styled-components';

import { Table as TableBootstrap } from 'react-bootstrap';

export const Container = styled.main`
  height: 100vh;
  display: block;
  float: right;
  width: 85%;
  margin-top: 4%;
  padding: 2rem 1rem;

  h2 {
    font-size: 22px;
    color: var(--gray-50);
    font-weight: 700;
  }
`;

export const FirstRowContainer = styled.div`
  margin: 32px 0px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  div {
    flex: 1;
    min-width: 200px;
    margin-bottom: 0px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  height: 48px;
  width: 100%;

  select:required:invalid {
    color: #666;
  }
  option[value=''][disabled] {
    display: none;
  }
  option,
  select {
    position: absolute;
    z-index: 2;
  }
`;

export const InputName = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  color: #8f8f8f;
  cursor: default;
  display: flex;
  justify-content: space-between;
  height: 48px;
  border: 1px solid #ccc;
  padding-left: 16px;
  padding-right: 16px;
  user-select: none;
  width: 100%;
  p {
    margin: 0px;
  }
`;

export const TableContainer = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};

    .table-responsive {
      border-radius: inherit;
    }
  `}
`;

export const Table = styled(TableBootstrap)`
  margin-bottom: 0;
`;

export const TableHeadContainer = styled.thead`
  ${({ theme }) => css`
    overflow: hidden;
    border: 0;
    background: ${theme.colors.white};

    th {
      border: 0;
      border-bottom: none !important;
      font-family: ${theme.fonts.family.poppins};
      font-size: ${theme.fonts.titleSize.xsmall};
      font-weight: ${theme.fonts.semibold};
      color: ${theme.colors.darkerGrey};
      line-height: 16px;
    }
  `}
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 1px;
    border: 0;
    background: transparent;
    outline: 0;
  }
`;

export const StyledTr = styled.tr`
  ${({ theme }) => css`
    height: 64px;

    td {
      font-family: ${theme.fonts.family.opensans};
      font-size: ${theme.fonts.textSize.medium};
      color: ${theme.colors.darkerGrey};
      line-height: 16px;
      vertical-align: inherit;
    }
  `}
`;
