import styled from 'styled-components';

export const BackButton = styled.button`
  gap: 8px;
  width: 108px;
  height: 32px;
  border: none;
  cursor: pointer;

  color: var(--color-brand-primary-darker-gray, #222);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.5px;

  border-radius: 4px;
  background: var(--color-brand-primary-lighter-gray, #f0f0f0);

  svg {
    margin-left: 8px;
  }
`;
export const Flex = styled.div`
  display: ${props => props.display || 'flex'};
  justify-content: ${props => props.justifyContent || ''};
  align-items: ${props => props.alignItems || ''};
  align-self: ${props => props.alignSelf || ''};
  max-width: ${props => props.maxW || ''};
  width: ${props => props.w || ''};
  height: ${props => props.h || ''};
  flex-direction: ${props => props.direction || 'row'};
  margin-top: ${props => props.mt || ''};
  margin-bottom: ${props => props.mb || '5px' || ''};
  margin-left: ${props => props.ml || '2px' || ''};
  background: ${props => props.bg || ''};
  border-radius: ${props => props.bRadius || ''};
  padding: ${props => props.p || ''};
  position: ${props => props.position || ''};
  right: ${props => props.right || ''};
  gap: ${props => props.gap || ''};
  flex-wrap: ${props => props.flexWrap || ''};
  text-align: ${props => props.textAlign || ''};
`;


export const SelectedCardTitle = styled.h3`
  color: var(--color-brand-primary-darker-gray, #222);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const SelectedCardSubtitle = styled.h3`
  color: var(--color-brand-primary-medium-gray, #767676);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  strong {
    color: var(--color-brand-primary-darker-gray, #222);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
`;

export const SelectedCardLastChange = styled.h3`
  color: var(--color-brand-primary-medium-gray, #767676);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
