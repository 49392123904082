import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

import checkIcon from '../../assets/check.svg';
import errorIcon from '../../assets/error.svg';
import infoIcon from '../../assets/info.svg';
import warningIcon from '../../assets/warning.svg';

const Toast = props => {
  const { toastList, position, autoDelete, dismissTime } = props;
  const [list, setList] = useState(toastList);

  const getIcon = toastIcon => {
    let newIcon;
    if (toastIcon === 'warningIcon') {
      newIcon = warningIcon;
    } else if (toastIcon === 'errorIcon') {
      newIcon = errorIcon;
    } else if (toastIcon === 'infoIcon') {
      newIcon = infoIcon;
    } else {
      newIcon = checkIcon;
    }

    return newIcon;
  };

  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = toastList.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, dismissTime, list]);

  return (
    <Container>
      <div className={`notification-container ${position}`}>
        {list.map(toast => (
          <div
            key={toast.id}
            className={`notification toast ${position}`}
            style={{ backgroundColor: toast.backgroundColor }}
          >
            <button type="button" onClick={() => deleteToast(toast.id)}>
              X
            </button>
            <div className="notification-image">
              <img src={getIcon(toast.icon)} alt="" />
            </div>
            <div>
              <p className="notification-title">{toast.title}</p>
              <p
                className="notification-message"
                style={{ textWrap: 'pretty' }}
              >
                {toast.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

Toast.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  toastList: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  position: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  autoDelete: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  dismissTime: PropTypes.number,
};

export default Toast;
