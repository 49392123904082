import React, { useState, useEffect } from 'react';
import * as S from './styles';
import icEsfera from '../../assets/ic-esfera.png';
import Checkbox from '../Checkbox';

const RegisterParityModal = ({
  isOpen,
  onClose,
  onSave,
  parity,
  mode,
  userPermissions,
  checkboxDisabled,
  defaultParity,
  parities,
}) => {
  const [numColumns, setNumColumns] = useState(1);
  const [showScroll, setShowScroll] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [miles, setMiles] = useState(0);
  const [points, setPoints] = useState(0);
  const [participants, setParticipants] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);

  const getAllSelectedClubs = () => {
    const allSelectedClubs = [];

    if (Array.isArray(parities)) {
      parities.forEach(parityItem => {
        if (parityItem && Array.isArray(parityItem.clubs)) {
          parityItem.clubs.forEach(club => {
            if (club && club.selected) {
              allSelectedClubs.push(club.clubName);
            }
          });
        }
      });
    }
    return allSelectedClubs;
  };

  useEffect(() => {
    const selectedClub = getAllSelectedClubs();
    setSelectedClubs(selectedClub);
  }, [parities, isOpen]);

  const initNumColumns = plans => {
    const numParticipants = plans.length;
    if (numParticipants > 10) {
      setNumColumns(2);
    } else if (numParticipants === 2) {
      setNumColumns(2);
    } else {
      setNumColumns(1);
    }

    if (numParticipants > 3) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const getParticipants = () => {
    const allSelectedClubs = getAllSelectedClubs();
    const parityData = parity || defaultParity;
    const selectedClubNames = selectedPlans;
    const updatedCheckboxStates = {};
    let initialSelectedClubs = [];

    parityData.clubs.forEach(club => {
      updatedCheckboxStates[club.clubName] = selectedClubNames.includes(
        club.clubName,
      );
    });

    if (allSelectedClubs.length > 0) {
      initialSelectedClubs = allSelectedClubs;
    }

    setCheckboxStates(updatedCheckboxStates);
    setParticipants(
      parityData.clubs.map(e =>
        Object.freeze({
          name: e.clubName,
          checked: selectedClubNames.includes(e.clubName),
          disabled: e.disabled || initialSelectedClubs.includes(e.clubName),
        }),
      ),
    );

    setSelectedClubs(initialSelectedClubs);

    initNumColumns(parityData.clubs);

    setMiles(parityData.miles);
    setPoints(parityData.points);

    if (parity && !defaultParity) {
      setSelectedPlans(
        parityData.clubs.reduce((acc, cur) => {
          if (cur.selected) acc.push(cur.clubName);
          return acc;
        }, []),
      );

      setCheckboxStates(
        parityData.clubs.reduce((acc, cur) => {
          if (cur.selected) {
            acc[cur.clubName] = true;
          }
          return acc;
        }, {}),
      );
    }
  };
  useEffect(() => {
    getParticipants();
  }, []);

  const handleSelectAll = () => {
    const allChecked = participants.every(
      participant => checkboxStates[participant.name] || participant.disabled,
    );

    const updatedCheckboxStates = { ...checkboxStates };
    const updatedSelectedPlans = [...selectedPlans];

    participants.forEach(participant => {
      if (!participant.disabled) {
        // modify the selection state only if it is not disabled
        updatedCheckboxStates[participant.name] = !allChecked;
        if (!allChecked) {
          if (!updatedSelectedPlans.includes(participant.name)) {
            updatedSelectedPlans.push(participant.name);
          }
        } else {
          // if you are deselecting all, it only removes the clubs that were added by this operation
          const index = updatedSelectedPlans.indexOf(participant.name);
          if (index > -1) {
            updatedSelectedPlans.splice(index, 1);
          }
        }
      }
    });

    setCheckboxStates(updatedCheckboxStates);
    setSelectedPlans(updatedSelectedPlans);
  };

  const handleCheckboxChange = id => {
    const updatedCheckboxStates = { ...checkboxStates };
    updatedCheckboxStates[id] = !updatedCheckboxStates[id];

    setCheckboxStates(updatedCheckboxStates);

    if (updatedCheckboxStates[id]) {
      setSelectedPlans([...selectedPlans, id]);
      setSelectedClubs([...selectedClubs, id]);
    } else {
      setSelectedPlans(selectedPlans.filter(planId => planId !== id));
      setSelectedClubs(selectedClubs.filter(clubName => clubName !== id));
    }
  };

  const handleSave = async () => {
    let newParity;

    try {
      if (selectedPlans.length > 0) {
        if (parity) {
          newParity = {
            id: parity?.id || Date.now(),
            miles: Number(miles),
            points: Number(points),
            clubs: parity.clubs.reduce((acc, cur) => {
              acc.push({
                id: cur.id ?? undefined,
                clubName: cur.clubName,
                selected: Boolean(selectedPlans.find(e => e === cur.clubName)),
              });
              return acc;
            }, []),
          };
        } else {
          newParity = {
            id: Date.now(),
            miles: Number(miles),
            points: Number(points),
            clubs: participants.map(e => {
              return {
                clubName: e.name,
                selected: Boolean(selectedPlans.find(el => el === e.name)),
              };
            }),
          };
        }

        await onSave(newParity);
        onClose();
      }
    } catch (error) {
      console.error('Error saving parity:', error);
    }
  };

  return (
    <>
      {isOpen && (
        <S.StyledModalOverlay>
          <S.StyledModalContent showScroll={showScroll}>
            <S.StyledModalHeader>
              <S.StyledModalTitle>{`${
                mode === 'create' ? 'Cadastrar' : 'Editar'
              } Paridade`}</S.StyledModalTitle>
              <button
                aria-label="delete"
                type="button"
                onClick={onClose}
                className="parity__crud__close"
              >
                <span
                  style={{ color: 'red' }}
                  className="icon-delete esfera-icon ic_system_clouse"
                />
              </button>
            </S.StyledModalHeader>
            <S.StyledModalBody>
              <div className="parity-header">
                Escolha a paridade
                <div className="container-buttons-parity">
                  <div className="action-buttons">
                    <div className="points">
                      <div className="icon_parity">
                        <img src={icEsfera} alt="icon-esfera" />
                      </div>
                      <span>Pontos | </span>
                      <div className="parity-value">
                        {(mode === 'create' || mode === 'edit') &&
                          userPermissions.checkUiPermissionsFn(
                            'ESFERA.PARITY_CONTROL.CREATE',
                            userPermissions.userGroups,
                          ) && (
                            <input
                              type="number"
                              className="parity-input"
                              value={points}
                              onChange={e => setPoints(e.target.value)}
                            />
                          )}
                        {mode === 'view' && parity.points}
                      </div>
                    </div>
                  </div>
                  <div className="action-buttons">
                    <div className="milhas">
                      <span>Milhas | </span>
                      <div className="parity-value">
                        {(mode === 'create' || mode === 'edit') &&
                          userPermissions.checkUiPermissionsFn(
                            'ESFERA.PARITY_CONTROL.CREATE',
                            userPermissions.userGroups,
                          ) && (
                            <input
                              type="number"
                              className="parity-input"
                              value={miles}
                              onChange={e => setMiles(e.target.value)}
                            />
                          )}
                        {mode === 'view' && parity.miles}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <S.SelectedPlans>
                <div className="title-header-plans">
                  Selecione os planos participantes
                </div>

                {mode !== 'view' &&
                  userPermissions.checkUiPermissionsFn(
                    'ESFERA.PARITY_CONTROL.CREATE',
                    userPermissions.userGroups,
                  ) && (
                    <button
                      className="selection-participants"
                      type="button"
                      onClick={handleSelectAll}
                    >
                      Selecionar todos
                    </button>
                  )}
              </S.SelectedPlans>
              <S.Container>
                <S.ParticipantsContainer
                  numColumns={numColumns}
                  showScroll={showScroll}
                >
                  {participants.map(participant => (
                    <div key={participant.name}>
                      <Checkbox
                        id={`participant_${participant.name}`}
                        idName={`participant_${participant.name}`}
                        label={participant.name}
                        checked={checkboxStates[`${participant.name}`]}
                        onChange={() =>
                          handleCheckboxChange(`${participant.name}`)
                        }
                        disabled={
                          mode === 'view' ||
                          (selectedClubs.includes(participant.name) &&
                            !selectedPlans.includes(participant.name) &&
                            !participant.checked)
                        }
                      />
                    </div>
                  ))}
                </S.ParticipantsContainer>
              </S.Container>
            </S.StyledModalBody>
            <S.ButtonContainer>
              {!checkboxDisabled ? (
                <>
                  <S.ButtonModal onClick={onClose} variant="cancel">
                    Cancelar
                  </S.ButtonModal>
                  <S.ButtonModal
                    variant={selectedPlans.length > 0 ? 'active' : 'primary'}
                    disabled={
                      !(miles > 0 && points > 0 && selectedPlans.length > 0)
                    }
                    onClick={handleSave}
                  >
                    Salvar
                  </S.ButtonModal>
                </>
              ) : null}
            </S.ButtonContainer>
          </S.StyledModalContent>
        </S.StyledModalOverlay>
      )}
    </>
  );
};

export default RegisterParityModal;
