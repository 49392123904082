import styled from 'styled-components';

import { Modal } from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  .modal-dialog {

    @media (min-width: 576px) {
      max-width: 350px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .modal-header {
    border: 0;
    padding-bottom: 0;

    .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    }
  }

  .modal-body {
    span {
      display: block;

      & + span {
        margin-top: 2px;
      }

      > strong {
        font-weight: 600;
      }
    }
  }

  .container-buttons {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: center;

    button {
      width: 100%;

      & + button {
        margin-left: 1rem;
      }
    }
  }
`;

export const WrapperButtons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: center;

    button {
      width: 100%;

      & + button {
        margin-left: 1rem;
      }
    }
`;
