import { Modal } from 'react-bootstrap';
import styled from 'styled-components';


export const StyledModal = styled(Modal)`

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 642px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .modal-header {
    .close{
      color: #CC0000;
      font-weight: 100;
      font-size: 38px;
      opacity: 1;
    }

    border: 0;
    padding: 2rem 2rem 1rem 2rem;

    .modal-title {
      font-family: "Poppins";
      font-size: 1.50rem;
      font-weight: 600;
    }
  }

  .invalid-input {
    border-color: #F14C6A;
  }

  .invalid-text {
    color: #F14C6A;
    font-size: 12px;
  }

  .modal-body{
    padding: 1rem 2rem 2rem 2rem;

    .form-control{
      border-radius: 8px;

      &::placeholder{
        color: #8F8F8F;
      }
    }

    .container-name{
      position: relative;
      width: 100%;

      select:required:invalid {
        color: #666;
      }
      option[value=""][disabled] {
        display: none;
      }
      option,select{
        position:absolute;
        z-index: 2;
      }
    }

    label{
      margin-bottom: 0.2rem;
    }
    label:not(label:first-child){
      margin-top: 40px;
    }
    select, input{
      height: 64px;
    }
    .group-name{
      display: flex;
      flex-direction: column;
    }

    .container-date-hour{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      label{
        margin-top: 26px;
      }
    }
    .input-date-hour{
      width: 273px;
    }

  }

  .container-buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    button {
      padding: 18px 60px;
      width: 273px;
    }
  }

`;



export const InputName = styled.div`
  align-items: center;
  background-color: #FFF;
  border-radius: 8px;
  color: #8F8F8F;
  cursor: default;
  display: flex;
  justify-content: space-between;
  height: 64px;
  border: 1px solid #CCC;
  padding-left: 16px;
  padding-right: 16px;
  user-select: none;
  width: 100%;
  p{
    margin: 0px
  }
`;

export const DivCheckBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  input {
    height: auto !important;
  }
  label {
    margin: 0px !important;
  }
`
export const AddCupom = styled.button`
  all: unset;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #CC0000;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 32px;
  &:hover {
    color: #EC0000;
  }
`
export const DivCodigosCampanha = styled.ul`
  padding: 16px 16px 0px 16px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  button {
    border: 0;
    background-color: transparent;
  }
`
export const DivCupom = styled.div`
  display: flex;
  flex-direction: column;
  strong {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #767676;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #767676;
  }
`
export const CupomText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
`

