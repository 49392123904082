import styled from 'styled-components';

export const Flex = styled.div`
  display: ${props => props.display || 'flex'};
  justify-content: ${props => props.justifyContent || ''};
  align-items: ${props => props.alignItems || ''};
  align-self: ${props => props.alignSelf || ''};
  max-width: ${props => props.maxW || ''};
  width: ${props => props.w || ''};
  height: ${props => props.h || ''};
  flex-direction: ${props => props.direction || 'row'};
  margin-top: ${props => props.mt || ''};
  margin-bottom: ${props => props.mb || '5px' || ''};
  margin-left: ${props => props.ml || '2px' || ''};
  background: ${props => props.bg || ''};
  border-radius: ${props => props.bRadius || ''};
  padding: ${props => props.p || ''};
  position: ${props => props.position || ''};
  right: ${props => props.right || ''};
  gap: ${props => props.gap || ''};
  flex-wrap: ${props => props.flexWrap || ''};
  text-align: ${props => props.textAlign || ''};
`;

export const ToggleButton = styled.button`
  color: var(--color-brand-primary-darker-gray, #222);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.5px;
  width: 244px;
  height: 48px;
  cursor: pointer;
  border-radius: 24px;
  border: none;

  &.ToggleButtonActive {
    border-radius: 24px;
    background: #222;
    color: white;
  }
`;
