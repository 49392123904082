import React from 'react';
import CouponsTableBody from '../CouponsTableBody';
import TableHead from '../../Nucleo/Content/CustomTable/TableHead';
import TableFooter from '../../Nucleo/Content/CustomTable/TableFooter';

import * as S from './styles';

export default function CouponsTable({
  headTableData,
  bodyTableData,
  userPermissions,
  hasPagination = false,
  setCuponIdState,
  getCouponIdState,
  setModalToastList,
  populateToastMessages,
  sortFunction,
  currentPage,
  totalPages,
  isFirstPage,
  isLastPage,
  onClickFnPage,
}) {
  return (
    <S.Container>
      <S.Table responsive>
        <TableHead
          headTableData={headTableData}
          bodyTableDataLength={bodyTableData.length}
          sortFunction={sortFunction}
        />
        <tbody>
          {bodyTableData.map(item => (
            <CouponsTableBody
              key={item.code}
              item={item}
              userPermissions={userPermissions}
              setCuponIdState={setCuponIdState}
              getCouponIdState={getCouponIdState}
              setModalToastList={setModalToastList}
              populateToastMessages={populateToastMessages}
            />
          ))}
        </tbody>
        {!!hasPagination && (
          <TableFooter
            totalCellWidth={headTableData.length}
            currentPage={currentPage}
            totalPages={totalPages}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            onClickFnPage={onClickFnPage}
          />
        )}
      </S.Table>
    </S.Container>
  );
}
