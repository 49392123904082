import React from 'react';
import FutureCampaignTableBody from '../FutureCampaignTableBody';
import FutureCampaignTableHead from '../FutureCampaignTableHead';

import * as S from './styles';

export default function FutureCampaignTable({
  headTableData,
  bodyTableData,
  userPermissions,
  setIsModifiedFuture,
  setIsRefreshHistory,
  populateToastMessages,
}) {
  return (
    <S.Container>
      <S.Table responsive>
        <FutureCampaignTableHead headTableData={headTableData} />
        <tbody>
          {bodyTableData &&
            bodyTableData.map(item => (
              <FutureCampaignTableBody
                key={item.id}
                item={item}
                userPermissions={userPermissions}
                setIsModifiedFuture={setIsModifiedFuture}
                setIsRefreshHistory={setIsRefreshHistory}
                populateToastMessages={populateToastMessages}
              />
            ))}
        </tbody>
      </S.Table>
    </S.Container>
  );
}
