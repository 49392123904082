import React from 'react';

import CashPlusPointRuleItem from '../CashPlusPointRuleItem';
import { StyledTable } from './styles';

export default function CashPlusPointRuleTable({
  rules,
  userPermissions,
  handleShowMessageAction,
}) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Tipo</th>
          <th>Identificador</th>
          <th>Valor Minimo %</th>
          <th>Posição</th>
          <th>Site</th>
          <th>Usuário</th>
          <th>Atualização</th>
          <th>Histórico</th>
          <th>Ações</th>
        </tr>
      </thead>

      <tbody>
        {rules.map(rule => (
          <CashPlusPointRuleItem
            rule={rule}
            key={Math.random()}
            userPermissions={userPermissions}
            handleShowMessageAction={handleShowMessageAction}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
