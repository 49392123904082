import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Pag from '../../Nucleo/Content/CustomTable/Pag';
import Loading from '../../Loading';
import { StyledModal } from './styles';
import HistoryRuleTable from './HistoryRuleTable';
import {
  handleGetRequest,
  initialPaginationData,
} from '../../../services/GenericMethods';

import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalCashPlusPointRuleHistory({
  ruleId,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const [cppRuleHistory, setCppRuleHistory] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const { keycloak } = useKeycloak();
  const [showLoading, setShowLoading] = useState(false);
  const options = initialPaginationData('GET', currentPage, keycloak.token);
  const FULL_URL = window.SERVICES_DOMAIN + window.CASHPLUSPOINT_RULE_HISTORY;

  const getCashPlusPointRulesHistory = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.CPP_RULES.READ',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      handleGetRequest(`${FULL_URL}/${ruleId}`, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const { content } = response.data;
            setCppRuleHistory(content);

            setCurrentPage(response.data.number);
            setTotalPages(response.data.totalPages);
            setIsFirstPage(response.data.first);
            setIsLastPage(response.data.last);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setIsFirstPage(false);
            setIsLastPage(false);
            setCppRuleHistory([]);
          }
          setShowLoading(false);
        })
        .catch(error => {
          setShowLoading(false);
          console.error(`
            Error at getCashPlusPointRulesHistory request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
  };

  const handlePageChange = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getCashPlusPointRulesHistory(options);
  };

  const setOptionParams = () => {
    if (currentFilter.length !== 0) {
      options.params = currentFilter;
    }
  };

  useEffect(() => {
    setOptionParams();
    getCashPlusPointRulesHistory(options);
    setCurrentFilter(options.params);
  }, [ruleId]);

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Historico de Alteração da Regra</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <section className="mt-2 history-body">
          {cppRuleHistory.length > 0 ? (
            <HistoryRuleTable rules={cppRuleHistory} />
          ) : (
            <div className="text-center">
              Não foi encontrado histórico para esta regra.
            </div>
          )}
        </section>

        {totalPages > 0 ? (
          <Pag
            currentPage={currentPage}
            totalPages={totalPages}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            onClickFnPage={handlePageChange}
          />
        ) : null}
      </StyledModal.Body>
    </StyledModal>
  );
}
