import styled, { css } from 'styled-components';

export const Container = styled.tfoot`
    padding: 15px 20px;
    border-top: 1px solid #DADADA;
`;




