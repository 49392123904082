import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../../Button';
import { StyledModal } from './styles';
import Loading from '../../../Loading';

export default function ModalDelete({
  isOpenModal,
  handleCloseModal,
  showLoading,
  deletePartnerCodes,
  handleDelete,
}) {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!isOpenModal) return;
    setInputValue('');
  }, [isOpenModal]);

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>
          Tem certeza que gostaria de deletar?
        </StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>
              Você está deletando os seguintes códigos:
              <span className="form_label_code">
                {deletePartnerCodes
                  .map(partnerCode => partnerCode.code)
                  .join(', ')}
              </span>
            </Form.Label>
            <Form.Label className="form_label_delete">
              Escreva ”DELETAR” na caixa abaixo para confirmar.
            </Form.Label>

            <div className="container-buttons">
              <Form.Control
                className="form_control"
                type="text"
                name="id"
                value={inputValue}
                onChange={event => setInputValue(event.target.value)}
                required
              />
              <Button
                disabled={inputValue !== 'DELETAR'}
                type="button"
                typeOfButton="primary"
                onClick={handleDelete}
              >
                Enviar
              </Button>
            </div>
          </Form.Group>
        </Form>
      </StyledModal.Body>
    </StyledModal>
  );
}
