import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import Content from '../../components/Content';
import ToastCustom from '../../components/Toast';
import {
  handleGetRequest,
  initialHeadersData,
} from '../../services/GenericMethods';
import {
  populateToastMessagesGlobal,
  formatErrorMessageToToast,
} from '../../helpers/GlobalFunctions';
import * as S from './styles';
import PartnersParityDoc from '../../components/ListPartnersParityPage/PartnersParityDoc';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';
import ModalCampaignDetails from '../../components/CampaignHistoryPage/ModalHistoryFilter';
import CampaingHistoryContent from '../../components/CampaignHistoryPage/CampaingHistoryContent';
import Loading from '../../components/Loading';

export default function CampaignHistory({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const { partnerId } = useParams();
  const location = useLocation();
  const { state } = location;
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const CAMPAIGN_ACTIVE_URL = `${
    window.SERVICES_DOMAIN + window.HISTORY_CAMPAIGN_ACTIVE_GET
  }`;
  const CAMPAIGN_PENDING_URL = `${
    window.SERVICES_DOMAIN + window.HISTORY_CAMPAIGN_PENDING_GET
  }`;
  const CAMPAIGN_SCHEDULED_URL = `${
    window.SERVICES_DOMAIN + window.HISTORY_CAMPAIGN_SCHEDULED_GET
  }`;
  const CAMPAIGN_FINISHED_URL = `${
    window.SERVICES_DOMAIN + window.HISTORY_CAMPAIGN_FINISHED_GET
  }`;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showPeriod, setShowPeriod] = useState(false);
  const handleOpenModalHistory = () => setIsOpenModal(true);
  const handleCloseModalHistory = () => setIsOpenModal(false);
  const [currentFilter, setCurrentFilter] = useState({
    number: 0,
    pageCount: 0,
    dateInit: '',
    dateEnd: '',
    clubName: '',
  });
  const [filterPage, setFilterPage] = useState({ pageNumber: 0 });
  const [campaignListActive, setCampaignListActive] = useState([]);
  const [campaignListPending, setCampaignListPending] = useState([]);
  const [campaignListScheduled, setCampaignListScheduled] = useState([]);
  const [campaignListFinished, setCampaignListFinished] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const [activeTab, setActiveTab] = useState('Em andamento');
  const [showLoading, setShowLoading] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };
  const handleFilter = inputParams => {
    setCurrentFilter(inputParams);
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
      };
      let urlWithParams = '';
      if (activeTab === 'Pendentes') {
        urlWithParams = `${CAMPAIGN_PENDING_URL}?partners=${partnerId}&pageNumber=${currentFilter.pageNumber}&pageCount=${currentFilter.pageCount}&dateInit=${currentFilter.dateInit}&dateEnd=${currentFilter.dateEnd}&club=${currentFilter.clubName}`;
      }
      if (activeTab === 'Agendadas') {
        urlWithParams = `${CAMPAIGN_SCHEDULED_URL}?partners=${partnerId}&pageNumber=${currentFilter.pageNumber}&pageCount=${currentFilter.pageCount}&dateInit=${currentFilter.dateInit}&dateEnd=${currentFilter.dateEnd}&club=${currentFilter.clubName}`;
      }
      if (activeTab === 'Expiradas') {
        urlWithParams = `${CAMPAIGN_FINISHED_URL}?partners=${partnerId}&pageNumber=${currentFilter.pageNumber}&pageCount=${currentFilter.pageCount}&dateInit=${currentFilter.dateInit}&dateEnd=${currentFilter.dateEnd}&club=${currentFilter.clubName}`;
      }
      setShowLoading(true);

      handleGetRequest(urlWithParams, reqOptions)
        .then(response => {
          if (response && response.data.body && response.data.success) {
            if (activeTab === 'Pendentes') {
              setCampaignListPending(response.data.body.campaigns);
            }
            if (activeTab === 'Agendadas') {
              setCampaignListScheduled(response.data.body.campaigns);
            }
            if (activeTab === 'Expiradas') {
              setCampaignListFinished(response.data.body.campaigns);
            }
          } else {
            setCampaignListFinished([]);
            setCampaignListPending([]);
            setCampaignListScheduled([]);
          }
        })
        .catch(error => {
          console.info(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
        });
    }
    setShowPeriod(true);
  };

  const getCampaignActiveList = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
      };
      setShowLoading(true);
      const urlWithParams = `${CAMPAIGN_ACTIVE_URL}?partners=${partnerId}`;
      handleGetRequest(urlWithParams, reqOptions)
        .then(response => {
          if (response && response.data.body && response.data.success) {
            setCampaignListActive(response.data.body.campaigns);
          } else {
            setCampaignListActive([]);
          }
          if (response && response.data.body.approvalCount) {
            setPendingCount(response.data.body.approvalCount);
          }
        })
        .catch(error => {
          console.info(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
        });
    }
  };
  const getCampaignPendingList = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
      };
      setShowLoading(true);
      const urlWithParams = `${CAMPAIGN_PENDING_URL}?partners=${partnerId}&pageNumber=${filterPage.pageNumber}`;
      handleGetRequest(urlWithParams, reqOptions)
        .then(response => {
          if (response && response.data.body && response.data.success) {
            setCampaignListPending(response.data.body.campaigns);
            setCurrentPage(response.data.body.campaigns.number);
            setTotalPages(response.data.body.campaigns.totalPages);
          } else {
            setCampaignListPending([]);
            setCurrentPage(0);
            setTotalPages(0);
          }
        })
        .catch(error => {
          console.info(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
        });
    }
  };
  const getCampaignScheduledList = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.CREATE',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
      };
      setShowLoading(true);
      const urlWithParams = `${CAMPAIGN_SCHEDULED_URL}?partners=${partnerId}&pageNumber=${filterPage.pageNumber}`;
      handleGetRequest(urlWithParams, reqOptions)
        .then(response => {
          if (response && response.data.body && response.data.success) {
            setCampaignListScheduled(response.data.body.campaigns);
            setCurrentPage(response.data.body.campaigns.number);
            setTotalPages(response.data.body.campaigns.totalPages);
          } else {
            setCampaignListScheduled([]);
            setCurrentPage(0);
            setTotalPages(0);
          }
        })
        .catch(error => {
          console.info(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
        });
    }
  };
  const getCampaignFinishedList = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PARITY_CONTROL.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
      };
      setShowLoading(true);
      const urlWithParams = `${CAMPAIGN_FINISHED_URL}?partners=${partnerId}&pageNumber=${filterPage.pageNumber}`;
      handleGetRequest(urlWithParams, reqOptions)
        .then(response => {
          if (response && response.data.body && response.data.success) {
            setCampaignListFinished(response.data.body.campaigns);
            setCurrentPage(response.data.body.campaigns.number);
            setTotalPages(response.data.body.campaigns.totalPages);
          } else {
            setCampaignListFinished([]);
            setCurrentPage(0);
            setTotalPages(0);
          }
        })
        .catch(error => {
          console.info(`Error at get partners parity request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        })
        .finally(function () {
          setShowLoading(false);
        });
    }
  };
  const handlePageChange = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    setFilterPage(filter);
    if (activeTab === 'Pendentes') {
      getCampaignPendingList();
    }
    if (activeTab === 'Agendadas') {
      getCampaignScheduledList();
    }
    if (activeTab === 'Expiradas') {
      getCampaignFinishedList();
    }
  };
  const clearFilter = () => {
    const filter = currentFilter;
    filter.pageNumber = 0;
    filter.pageCount = 15;
    filter.dateInit = '';
    filter.dateEnd = '';
    filter.clubName = '';
    setCurrentFilter(filter);
    setShowPeriod(false);
    if (activeTab === 'Pendentes') {
      getCampaignPendingList();
    }
    if (activeTab === 'Agendadas') {
      getCampaignScheduledList();
    }
    if (activeTab === 'Expiradas') {
      getCampaignFinishedList();
    }
  };
  const handleTabClick = tab => {
    setActiveTab(tab);
    clearFilter();
    if (tab === 'Em andamento') {
      getCampaignActiveList();
    }
    if (tab === 'Pendentes') {
      getCampaignPendingList();
    }
    if (tab === 'Agendadas') {
      getCampaignScheduledList();
    }
    if (tab === 'Expiradas') {
      getCampaignFinishedList();
    }
  };
  const handleGoBack = () => {
    history.push(`/partners-parity/${partnerId}`);
  };

  useEffect(() => {
    if (
      state?.tabLocation === 'pending' ||
      state?.tabLocation === 'approved' ||
      state?.tabLocation === 'copy'
    ) {
      handleTabClick('Pendentes');
    }
    if (state?.tabLocation === 'edit') {
      handleTabClick('Agendadas');
    }
    if (state?.tabLocation === 'expired' || state?.tabLocation === 'view') {
      handleTabClick('Expiradas');
    }
    getCampaignActiveList();
    return () => {};
  }, []);

  return (
    <>
      <Content>
        <S.Container>
          <S.listCampaignHeader>
            <div className="contentmain__header">
              <div className="contentmain__header__title">
                <div>
                  <h2 className="contentmain__header__text">
                    Histórico de campanhas
                  </h2>
                </div>
              </div>
            </div>
            <PartnersParityDoc />
          </S.listCampaignHeader>
          <S.listFilterNavigationHeader>
            <div className="ap-content-section">
              <div className="ap-tab">
                {userPermissions.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  userPermissions.userGroups,
                ) && (
                  <div className="ap-tab-content">
                    {(() => {
                      const options = [
                        'Em andamento',
                        'Agendadas',
                        'Expiradas',
                      ];

                      if (
                        userPermissions.checkUiPermissionsFn(
                          'ESFERA.PARITY_CONTROL.APPROVER',
                          userPermissions.userGroups,
                        )
                      ) {
                        options.splice(
                          1,
                          0,
                          <span>
                            Pendentes{' '}
                            <span className="ap-tab-count">{pendingCount}</span>
                          </span>,
                        );
                      }

                      return options;
                    })().map(tab => {
                      let call;
                      if (
                        typeof tab === 'object' &&
                        tab.props &&
                        tab.props.children
                      ) {
                        const isPendentesElement =
                          tab.props.children.includes('Pendentes');
                        if (isPendentesElement) {
                          [call] = tab.props.children.filter(
                            child => typeof child === 'string',
                          );
                        }
                      } else {
                        call = tab;
                      }
                      return (
                        <span
                          key={tab}
                          className={`ap-tab-item ${
                            activeTab === call ? 'active' : ''
                          }`}
                          onKeyDown={event => {
                            if (event.key === 'Enter') {
                              handleTabClick(call);
                            }
                          }}
                          onClick={() => handleTabClick(call)}
                          tabIndex={0}
                          role="button"
                        >
                          {tab}
                        </span>
                      );
                    })}
                  </div>
                )}
                {!userPermissions.checkUiPermissionsFn(
                  'ESFERA.PARITY_CONTROL.CREATE',
                  userPermissions.userGroups,
                ) && (
                  <div className="ap-tab-content">
                    {['Em andamento', 'Expiradas'].map(tab => (
                      <span
                        key={tab}
                        className={`ap-tab-item ${
                          activeTab === tab ? 'active' : ''
                        }`}
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            handleTabClick(tab);
                          }
                        }}
                        onClick={() => handleTabClick(tab)}
                        tabIndex={0}
                        role="button"
                      >
                        {tab}
                      </span>
                    ))}
                  </div>
                )}
                <div className="ap-tab-filter">
                  <div className="ap-tab-apply">
                    {showPeriod && (
                      <span className="ap-tab-apply-filtered">
                        Período:{' '}
                        {`${moment(currentFilter.dateInit).format(
                          'DD/MM/YYYY',
                        )}`}{' '}
                        a{' '}
                        {`${moment(currentFilter.dateEnd).format(
                          'DD/MM/YYYY',
                        )}`}
                      </span>
                    )}
                    {showPeriod && (
                      <span className="ap-tab-apply-filtered">
                        Clube:{currentFilter.clubName}
                      </span>
                    )}
                  </div>
                  <div className="ap-tab-actions">
                    {showPeriod && (
                      <span
                        className="ap-tab-actions-btn"
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            clearFilter();
                          }
                        }}
                        onClick={clearFilter}
                        role="button"
                        tabIndex="0"
                      >
                        <u>Limpar filtro</u>
                        <span className="ic_system_circle_clouse esfera-icon" />
                      </span>
                    )}
                    {activeTab !== 'Em andamento' && (
                      <span
                        className="ap-tab-actions-btn"
                        data-toggle="modal"
                        data-target="#filterPeriod"
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            handleOpenModalHistory();
                          }
                        }}
                        onClick={() => handleOpenModalHistory()}
                        role="button"
                        tabIndex="0"
                      >
                        <u>Filtrar</u>
                        <span className="BD390 esfera-icon" />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {userPermissions.checkUiPermissionsFn(
                'ESFERA.PARITY_CONTROL.READ',
                userPermissions.userGroups,
              ) && (
                <S.listFilterNavigationBody>
                  {activeTab === 'Em andamento' &&
                    (campaignListActive?.content?.length > 0 ? (
                      <CampaingHistoryContent
                        listCampaing={campaignListActive}
                        permission={userPermissions}
                      />
                    ) : (
                      <div className="message-alert">
                        <span className="ic_functional_alert esfera-icon" />
                        <p>Não há campanhas em andamento no momento.</p>
                      </div>
                    ))}
                  {activeTab === 'Pendentes' &&
                    (campaignListPending?.content?.length > 0 ? (
                      <CampaingHistoryContent
                        listCampaing={campaignListPending}
                        permission={userPermissions}
                      />
                    ) : (
                      <div className="message-alert">
                        <span className="ic_functional_alert esfera-icon" />
                        <p>Não há campanhas pendente no momento.</p>
                      </div>
                    ))}

                  {activeTab === 'Agendadas' &&
                    (campaignListScheduled?.content?.length > 0 ? (
                      <CampaingHistoryContent
                        listCampaing={campaignListScheduled}
                        permission={userPermissions}
                      />
                    ) : (
                      <div className="message-alert">
                        <span className="ic_functional_alert esfera-icon" />
                        <p>Não há campanhas agendadas no momento.</p>
                      </div>
                    ))}
                  {activeTab === 'Expiradas' &&
                    (campaignListFinished?.content?.length > 0 ? (
                      <CampaingHistoryContent
                        listCampaing={campaignListFinished}
                        permission={userPermissions}
                      />
                    ) : (
                      <div className="message-alert">
                        <span className="ic_functional_alert esfera-icon" />
                        <p>Não há campanhas expiradas.</p>
                      </div>
                    ))}
                </S.listFilterNavigationBody>
              )}
            </div>
          </S.listFilterNavigationHeader>
          <S.listFilterNavigationFooter>
            <div className="ap-footer-content">
              <button
                type="button"
                className="ap-button ap-button-secondary"
                onClick={() => handleGoBack()}
              >
                Voltar
              </button>
              {activeTab !== 'Em andamento' && (
                <nav className="ap-pagination">
                  {totalPages > 1 ? (
                    <Pag
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onClickFnPage={handlePageChange}
                    />
                  ) : null}
                </nav>
              )}
              <button
                type="button"
                className="ap-button ap-button-primary invisible"
              >
                Atualizar
              </button>
            </div>
          </S.listFilterNavigationFooter>
          <ToastCustom
            toastList={toastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
          <ToastCustom
            toastList={mainToastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
          <ModalCampaignDetails
            isOpenModal={isOpenModal}
            handleCloseModal={handleCloseModalHistory}
            handleFilter={handleFilter}
            currentFilter={currentFilter}
          />
        </S.Container>
      </Content>
      <Loading showLoading={showLoading} />
    </>
  );
}
