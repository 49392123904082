import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import OrdersFilter from '../../components/ManualOrdersTransferPage/OrdersFilter';
import OrdersTable from '../../components/ManualOrdersTransferPage/OrderTable';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';

import {
  handleGetRequest,
  initialPaginationData,
} from '../../services/GenericMethods';
import { Container } from './styles';
import ToastCustom from '../../components/Toast';

import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

export default function ManualOrdersTransfer({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const [ordersFormatted, setOrdersFormatted] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentFilter, setCurrentFilter] = useState([]);
  const FULL_URL =
    window.SERVICES_DOMAIN + window.MANUAL_TRANSFER_LIST_ORDER_URL;
  const options = initialPaginationData('GET', currentPage, keycloak.token);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);

  const getOrders = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MANUAL_TRANSFER_ORDERS.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const orders = response.data.content;
            setOrdersFormatted(orders);

            setCurrentPage(response.data.number);
            setTotalPages(response.data.totalPages);
            setIsFirstPage(response.data.first);
            setIsLastPage(response.data.last);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setIsFirstPage(false);
            setIsLastPage(false);
            setOrdersFormatted([]);
          }

          console.warn(`Response listOrders = ${response}`);
        })
        .catch(error => {
          console.error(`Error at listOrders request = ${error}`);

          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const handleFilter = inputParams => {
    options.params = inputParams;
    setCurrentFilter(inputParams);
    getOrders(options);
  };

  const handlePageChange = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getOrders(options);
  };

  const handleShowMessageAction = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }
  };

  useEffect(() => {
    getOrders(options);
    setCurrentFilter(options.params);
  }, [toastList]);

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.MANUAL_TRANSFER_ORDERS.READ',
        userPermissions.userGroups,
      ) ? (
        <>
          <h2>Análise de Pedidos Transferência para Parceiro</h2>
          <OrdersFilter
            currentFilter={currentFilter}
            handleFilter={handleFilter}
          />
          <section className="mt-2">
            {ordersFormatted.length > 0 ? (
              <OrdersTable
                orders={ordersFormatted}
                userPermissions={userPermissions}
                handleShowMessageAction={handleShowMessageAction}
              />
            ) : (
              <div>Nenhum resultado encontrado para esta pesquisa</div>
            )}
          </section>
        </>
      ) : null}

      {totalPages > 0 ? (
        <Pag
          currentPage={currentPage}
          totalPages={totalPages}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          onClickFnPage={handlePageChange}
        />
      ) : null}
    </Container>
  );
}
