import React from 'react';

import * as S from './styles';

export default function HeaderCustomTable({
  resumeTableData,
  titleTable,
  linkData,
}) {
  return (
    <S.Container>
      {resumeTableData ? (
        <S.HeaderTableData>
          {resumeTableData.map(item => (
            <S.CustomHeaderTable key={item.title} className={item.classStyle}>
              <span className="titleCustomHeader">{item.title}</span>
              <span className="resultNumberText">{item.resultNumber}</span>
            </S.CustomHeaderTable>
          ))}
        </S.HeaderTableData>
      ) : (
        <S.HeaderTableDefault>
          {!!titleTable && <h2>{titleTable}</h2>}
        </S.HeaderTableDefault>
      )}

      {!!linkData && (
        <S.HeaderTableLink to={linkData.link}>
          {linkData.title}
          <span className="icoArrow esfera-icon BE070" />
        </S.HeaderTableLink>
      )}
    </S.Container>
  );
}
