import styled from 'styled-components';

export const ParityFracion = styled.div`
  height: 100px;
  display: flex;
  width: 100%;
  background-color: #f5f9fb;
  position: relative;
  .icons-crud {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  .icon-update,
  .icon-delete {
    color: #cc0000;
    font-size: 30px;
    font-family: Open Sans;
    margin-right: 10px;
  }
  .parity__crud__edit,
  .parity__crud__delete {
    background: transparent;
    border: transparent;
    padding-bottom: 35px;
  }
`;

export const Parity = styled.div`
  width: 184px;
  height: 56px;
  margin-left: 3%;
  background-color: #deebf2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;

  .icon_parity {
    text-align: right;
    width: 20%;
    img {
      width: 25px;
      border-radius: 30px;
    }
  }
  .title-parity-default {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 20px;
    text-align: left;
    color: #444444;
  }
`;
export const TypeParity = styled.div`
  height: 56px;
  width: 280px;
  background-color: #deebf2;
  border-radius: 8px;
  align-self: center;
  display: flex;
  padding-left: 20px;
  margin-left: 1%;
  align-items: center;

  .title-parity-fracion {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #444444;
  }
`;
