import React from 'react';

// import useForm from './../../../Hooks/useForm'

import { Container, Input, Label, Error } from './styles';

export default function TextField({
  id,
  label,
  placeholder,
  type,
  idName,
  MensagemError = '',
  value,
  defaultValue,
  readOnly,
  disabled,
  error,
  required,
  onBlur,
  onChange,
  onFocus,
  onKeyUp,
  className,
}) {
  return (
    <Container>
      <Input
        placeholder={placeholder}
        name={idName}
        id={idName}
        type={type}
        value={value}
        defaultValue={defaultValue}
        readOnly={readOnly}
        disabled={disabled}
        error={error}
        required={required}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        className={className}
      />
      <Label htmlFor={id}>{label}</Label>
      <Error>{MensagemError}</Error>
    </Container>
  );
}
