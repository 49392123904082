import styled, { css } from 'styled-components';
import * as Styled from '../../components/Nucleo/Content/CustomTable/styles'

export const Container = styled.div`
    height: 100vh;
    display: block;
    float: right;
    width: 85%;
    margin-top: 4%;
    padding: 2rem 1rem;
  ${({ theme }) => css`
    padding: 0 16px;
    height: 100%;

  ${Styled.Container} {
    & + div {
      margin-top: 40px;
    }
  }

  `}
`;
