import React from 'react';
import TransactionsTableHead from '../../TransactionsReleasePage/TransactionsTableHead';
import TransactionsPmaTableBody from '../TransactionsPmaTableBody';

import * as S from './styles';

export default function TransactionsPmaTable({
  headTableData,
  bodyTableData,
  userPermissions,
  isModifiedTransactionFile,
  setIsModifiedTransactionFile,
  populateToastMessages,
  reloadAfterAction,
}) {
  return (
    <S.Container>
      <S.Table responsive>
        <TransactionsTableHead headTableData={headTableData} />
        <tbody>
          {bodyTableData.map(item => (
            <TransactionsPmaTableBody
              key={item.fileId}
              item={item}
              userPermissions={userPermissions}
              isModifiedTransactionFile={isModifiedTransactionFile}
              setIsModifiedTransactionFile={setIsModifiedTransactionFile}
              populateToastMessages={populateToastMessages}
              reloadAfterAction={reloadAfterAction}
            />
          ))}
        </tbody>
      </S.Table>
    </S.Container>
  );
}
