import styled from 'styled-components';

export const Container = styled.div`

  margin-right: 10px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  input[type=radio] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;

    &:checked + label {
      background-color: var(--boston) !important;
      border: solid 1px var(--boston) !important;
      color: #FFF!important
    }
  }

  .btn-radio {
    min-width: 112px;
    text-align: center;
    background-color: white;
    border: solid 1px var(--boston);
    border-radius: 4px;
    color: var(--boston);
    padding: 8px 16px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color 0.2s, color 0.2s;


    &:hover, &.active, &.active:hover, &.active:focus {
      background-color: var(--boston) !important;
      border: solid 1px var(--boston) !important;
      color: #FFF!important;
    }
}
`;
