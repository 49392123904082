import React from 'react';
import TransactionsTableHead from '../TransactionsTableHead';
import TransactionsTableBody from '../TransactionsTableBody';

import * as S from './styles';

export default function TransactionsTable({
  headTableData,
  bodyTableData,
  userPermissions,
  isModifiedTransactionFile,
  setIsModifiedTransactionFile,
  populateToastMessages,
}) {
  return (
    <S.Container>
      <S.Table responsive>
        <TransactionsTableHead headTableData={headTableData} />
        <tbody>
          {bodyTableData.map(item => (
            <TransactionsTableBody
              key={item.fileId}
              item={item}
              userPermissions={userPermissions}
              isModifiedTransactionFile={isModifiedTransactionFile}
              setIsModifiedTransactionFile={setIsModifiedTransactionFile}
              populateToastMessages={populateToastMessages}
            />
          ))}
        </tbody>
      </S.Table>
    </S.Container>
  );
}
