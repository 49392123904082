import styled from 'styled-components';

import { Modal as ModalBootstrap, Form as FormBootstrap } from 'react-bootstrap';

export const StyledModal = styled(ModalBootstrap)`
  .modal-dialog {

    @media (min-width: 576px) {
      max-width: 500px;
    }

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .modal-header {
    border: 0;
    padding-bottom: 0;

    .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    }
  }

  .modal-body {
    span {
      display: block;

      & + span {
        margin-top: 2px;
      }

      > strong {
        font-weight: 600;
      }
    }
  }

  .modalAssociationClient {
    text-align: center;
  }

  .form-group {
    display: flex;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    margin-right: 10px;

    @media (max-width: 473px) {
      width: 100%;
      margin-right: 0;
    }

    select {
      width: 248px;
    }

    > label {
    margin: 0 10px 0 0;
    font-size: 0.875rem;

    @media (max-width: 600px) {
      width: 65px;
    }
  }

    input[type="text"] {
      width: 170px;
      font-size: 0.875rem;

      @media (max-width: 473px) {
        width: 100%;
        flex: 1;
      }
    }
  }

  .form-inputs {
    display: flex;
    justify-content: center;
  }
`;

export const Form = styled(FormBootstrap)`
  margin-top: 20px;

  label {
    font-weight: 600;
  }

  textarea {
    min-height: 120px;
  }
`;

export const WrapperButtons = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: center;

    button {
      width: 100%;

      & + button {
        margin-left: 1rem;
      }
    }
`;
