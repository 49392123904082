import React, { useEffect, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import {
  handleGetRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';
import FutureCampaignTable from '../FutureCampaignTable';
import * as S from './styles';

const headTableDataFutureCampaign = [
  {
    title: 'Campanhas futuras',
    hasIcoSort: false,
  },
  {
    title: 'Usuário',
    hasIcoSort: false,
  },
  {
    title: 'Data inicial',
    hasIcoSort: false,
  },
  {
    title: 'Data final',
    hasIcoSort: false,
  },
  {
    title: 'Status',
    hasIcoSort: false,
  },
  {
    title: '',
    hasIcoSort: false,
  },
];

export default function FutureCampaign({
  sellerId,
  userPermissions,
  isModifiedAirCampaign,
  setIsModifiedAirCampaign,
  setIsModifiedFuture,
  isModifiedFuture,
  setIsRefreshHistory,
  populateToastMessages,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL = `${
    window.SERVICES_DOMAIN + window.AIRCAMPAIGN_URL
  }/seller/${sellerId}/coming`;

  const [bodyTableDataAirCampaign, setBodyTableDataAirCampaign] = useState([]);

  const getCampaigns = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MILES_CAMPAIGN.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };
      handleGetRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data)
            setBodyTableDataAirCampaign(response.data);
        })
        .catch(error => {
          console.warn(`Error at listOrders request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  useEffect(() => {
    if (sellerId) getCampaigns();
    setIsModifiedAirCampaign(false);
    setIsModifiedFuture(false);
  }, [isModifiedAirCampaign, isModifiedFuture]);

  return (
    <>
      <S.ContainerTr>
        <S.ContainerTd colSpan={6}>
          {bodyTableDataAirCampaign && bodyTableDataAirCampaign.length > 0 && (
            <FutureCampaignTable
              headTableData={headTableDataFutureCampaign}
              bodyTableData={bodyTableDataAirCampaign}
              userPermissions={userPermissions}
              setIsModifiedFuture={setIsModifiedFuture}
              setIsRefreshHistory={setIsRefreshHistory}
              populateToastMessages={populateToastMessages}
            />
          )}
        </S.ContainerTd>
      </S.ContainerTr>
    </>
  );
}
