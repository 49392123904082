import styled from 'styled-components';

export const DocStyle = styled.div`
.contentmain__text-info {
  margin-top: 15px;
  margin-bottom: 45px;
}
.contentmain__header__text-info {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 16px
  text-align: left;

  a {
    color: #CC0000;
    text-decoration: underline;
  }
}
`