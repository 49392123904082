import React from 'react';

import RuleItem from '../RuleItem';
import { StyledTable } from './styles';

export default function RulesTable({
  rules,
  handleCloseModal,
  userPermissions,
}) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th>Número da Regra</th>
          <th>Usuário</th>
          <th>Data Cadastro</th>
          <th>Data Atualização</th>
          <th>Configuração da Regra</th>
          <th>Ações</th>
        </tr>
      </thead>

      <tbody>
        {rules.map(rule => (
          <RuleItem
            rule={rule}
            key={rule.id}
            handleCloseModal={handleCloseModal}
            userPermissions={userPermissions}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
