import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  z-index: 9;
  position: relative;
`;
