import styled, { css } from "styled-components";
import { Table as TableBootstrap } from "react-bootstrap";

export const Container = styled.div`
  border: 1px solid var(--gray-300);
  border-radius: 24px;
  background: var(--white);
  margin: 64px 80px 64px 0;

  @media (max-width: 992px) {
    margin: 64px 16px 64px 0;
  }
`;

export const Table = styled(TableBootstrap)`
  margin-bottom: 0;

  ${({ theme, bodyTableDataLength }) => css`
    ${bodyTableDataLength <= 0 && css`
      border-collapse: separate;
      thead > tr:last-child td:first-child {
        border-bottom-left-radius: 24px;
      }
      thead > tr:last-child td:last-child {
        border-bottom-right-radius: 24px;
      }
    `}
  `}
`;
