import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import ToastCustom from '../../components/Toast';
import CouponsTable from '../../components/CouponPage/CouponsTable';
import Content from '../../components/Content';
import CouponsHeaderContent from '../../components/CouponPage/CouponsHeaderContent';

import * as S from './styles';
import {
  handleGetRequest,
  initialPaginationData,
} from '../../services/GenericMethods';

import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

export default function Coupons({ userPermissions }) {
  const [toastList, setToastList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [sortCoupons, setSortCoupons] = useState({ field: null, order: 'asc' });
  const [modalToastList, setMdlToastList] = useState([]);
  const [searchParams, setSearchParams] = useState('');
  const [couponId, setCouponId] = useState('');

  // mock header coupon
  const headTableDataCoupons = [
    {
      title: 'Prioridade',
      code: 'promoPriority',
      hasIcoSort: true,
    },
    {
      title: 'Código',
      code: 'couponId',
      hasIcoSort: false,
    },
    {
      title: 'Usuário',
      code: 'attendantUser',
      hasIcoSort: true,
    },
    {
      title: 'Nome do cupom',
      code: 'couponName',
      hasIcoSort: false,
    },
    {
      title: 'Descrição do cupom',
      code: 'couponDescription',
      hasIcoSort: false,
    },
    {
      title: 'Cadastro',
      code: 'createDate',
      hasIcoSort: true,
    },
    {
      title: '',
      hasIcoSort: false,
    },
  ];

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    setToastList([...toastList, appendToast]);
  };

  const onChangeSearchParams = data => {
    setSearchParams(data);
  };

  const setModalToastList = data => {
    setMdlToastList(data);
  };

  const setCuponIdState = couponType => {
    setCouponId(couponType);
  };

  const { keycloak, initialized } = useKeycloak();
  const [user, setUser] = useState(false);
  console.log(user);

  const FULL_URL = `${window.SERVICES_DOMAIN}${window.COUPONS_URL}/search`;
  const options = initialPaginationData('GET', 0, keycloak.token);
  const [couponsFormatted, setCouponsFormatted] = useState([]);

  const handleLogout = () => {
    keycloak.logout();
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak
        .loadUserInfo()
        .then(userInfo => {
          setUser(userInfo);
        })
        .catch(error => {
          console.error(`Error keycloak ${error}`);
          handleLogout();
        });
    }
  }, [keycloak.authenticated, initialized]);

  const getCoupons = (actualPage, sortParams) => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.COUPONS.READ',
        userPermissions.userGroups,
      )
    ) {
      const inputParams = initialPaginationData(
        'GET',
        actualPage,
        keycloak.token,
      );
      const params = inputParams;
      params.params.pageCount = 10;
      params.params.couponName = searchParams || null;
      if (sortParams) params.params.sort = sortParams;

      setCouponsFormatted([]);
      handleGetRequest(FULL_URL, params)
        .then(response => {
          if (
            response &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const coupons = response.data.content;
            setCurrentPage(response.data.number);
            setTotalPages(response.data.totalPages);
            setIsFirstPage(response.data.first);
            setIsLastPage(response.data.last);
            setCouponsFormatted(
              coupons.map(coupon => {
                return {
                  enabled: coupon.enabled ? coupon.enabled : false,
                  promoPriority: coupon.promoPriority
                    ? coupon.promoPriority
                    : '-',
                  couponId: coupon.couponId ? coupon.couponId : '-',
                  userName: coupon.attendentUser ? coupon.attendentUser : '-',
                  couponName: coupon.couponName ? coupon.couponName : '-',
                  couponDescription: coupon.couponDescription
                    ? coupon.couponDescription
                    : '-',
                  registrationDate: moment(coupon.createDate).format(
                    'DD/MM/YYYY',
                  ),
                  additionCashback: coupon.additionalCashback
                    ? coupon.additionalCashback
                    : '-',
                  limitCouponCashback: coupon.limitCouponCashback
                    ? coupon.limitCouponCashback
                    : '-',
                  promoStartDate: coupon.promoStartDate
                    ? coupon.promoStartDate
                    : '-',
                  promoEndDate: coupon.promoEndDate ? coupon.promoEndDate : '-',
                  siteId: coupon.siteId ? coupon.siteId : '-',
                };
              }),
            );
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setIsFirstPage(false);
            setIsLastPage(false);
            setCouponsFormatted([]);
          }
        })
        .catch(error => {
          console.error(`Error at listCoupons request = ${error}`);

          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  useEffect(() => {
    getCoupons(0);
  }, [searchParams, modalToastList]);

  const setSortCoupon = sortParams => {
    if (!sortParams) return;
    let sortProp = false;
    if (sortCoupons.field === null || sortCoupons.field !== sortParams.code) {
      setSortCoupons({ field: sortParams.code, order: sortCoupons.order });
      sortProp = `${sortParams.code},${sortCoupons.order}`;
    } else if (sortCoupons.field === sortParams.code) {
      if (sortCoupons.order === 'asc') {
        setSortCoupons({ field: sortParams.code, order: 'desc' });
        sortProp = `${sortParams.code},desc`;
      } else {
        setSortCoupons({ field: sortParams.code, order: 'asc' });
        sortProp = `${sortParams.code},asc`;
      }
    }
    options.params.sort = sortProp;
    getCoupons(0, sortProp);
  };

  return (
    <>
      <Content>
        <S.Container>
          <ToastCustom
            toastList={toastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
          <ToastCustom
            toastList={modalToastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
          <CouponsHeaderContent
            title="Marketing"
            subtitle="Cupons"
            userPermissions={userPermissions}
            searchParams={searchParams}
            onChangeSearchParams={onChangeSearchParams}
            items={couponsFormatted}
            getCouponIdState={couponId}
            setCuponIdState={setCuponIdState}
            setModalToastList={setModalToastList}
          />

          <CouponsTable
            headTableData={headTableDataCoupons}
            withoutHeaderCustomTable
            userPermissions={userPermissions}
            bodyTableData={couponsFormatted}
            hasPagination
            setCuponIdState={setCuponIdState}
            getCouponIdState={couponId}
            setModalToastList={setModalToastList}
            populateToastMessages={populateToastMessages}
            sortFunction={setSortCoupon}
            currentPage={currentPage}
            totalPages={totalPages}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            onClickFnPage={getCoupons}
          />
        </S.Container>
      </Content>
    </>
  );
}
