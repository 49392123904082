import React from 'react';
import { LoadingComponent } from './styles';

export default function Loading({ showLoading }) {
  return (
    <LoadingComponent>
      {showLoading ? (
        <>
          <div className="loading-box-compass" />
          <div className="text-center loading-content-compass">
            <div className="spinner-border text-danger" role="status" />
            <div className="font-weight-bold">Carregando... Aguarde</div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LoadingComponent>
  );
}
