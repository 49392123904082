import React from 'react';
import icEsfera from '../../../../assets/ic-esfera.png';
import icAtlas from '../../../../assets/ic-atlas.png';
import icNucleo from '../../../../assets/ic-nucleo.png';
import icOrbita from '../../../../assets/ic-orbita.png';

import * as S from './styles';

export default function Content({ user }) {
  const isStaging = window.location.href.includes('stage');

  return (
    <>
      <S.Content>
        <S.WelcomeUserContainer>
          <Username name={user.name} />
          <p>Escolha o caminho por onde deseja começar:</p>
        </S.WelcomeUserContainer>
        <S.WelcomeMenuContainer>
          <S.GroupElementsButton href="/nucleo">
            <div className="iconWelcome">
              <img src={icNucleo} alt="" />
            </div>
            <div className="iconDescription">
              <ButtonTitle
                title="Núcleo"
                description="Gerencie regras de negócio e campanhas"
              />
            </div>
          </S.GroupElementsButton>
          <S.GroupElementsButton
            href={
              isStaging
                ? 'https://stage-atlas.servicosesfera.com.br/'
                : 'https://atlas.servicosesfera.com.br/'
            }
          >
            <div className="iconWelcome">
              <img src={icAtlas} alt="" />
            </div>
            <div className="iconDescription">
              <ButtonTitle
                title="Atlas"
                description="Consulte informações para atender os clientes"
              />
            </div>
          </S.GroupElementsButton>
          <S.GroupElementsButton href="http://cms.servicosesfera.com.br/">
            <div className="iconWelcome">
              <img src={icEsfera} alt="" />
            </div>
            <div className="iconDescription">
              <ButtonTitle
                title="APP Admin"
                description="Administre conteúdos no App Esfera"
              />
            </div>
          </S.GroupElementsButton>
          <S.GroupElementsButton href="https://painel.servicosesfera.com.br/">
            <div className="iconWelcome">
              <img src={icOrbita} alt="" />
            </div>
            <div className="iconDescription">
              <ButtonTitle
                title="Redash"
                description="Acompanhe os gráficos da nossa evolução."
              />
            </div>
          </S.GroupElementsButton>
        </S.WelcomeMenuContainer>
      </S.Content>
    </>
  );
}

const Username = ({ name }) => {
  if (name) {
    const prepositions = ['da', 'de', 'das', 'do', 'dos', 'e', 'di'];
    const partsOfName = name.split(' ');
    const nameCapitalized = partsOfName.map(part => {
      if (prepositions.includes(part)) {
        return part;
      }
      return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
    });
    return <h1>Olá, {nameCapitalized.join(' ')}!</h1>;
  }
  return null;
};

const ButtonTitle = ({ title, description }) => {
  return (
    <>
      <h3>{title}</h3>
      <p>{description}</p>
    </>
  );
};
