import styled from 'styled-components';

export const Box = styled.div`
background: #FFFFFF;
border-radius: 0px 0px 8px 8px;
box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.25);
padding: 0px 16px 0px 16px;
position: absolute;
width:100%;
top: 64px;
z-index: 99999999999;

p{ 
  border-bottom: 1px solid #CCCCCC;
  margin: 0;
  padding: 16px 0px;
}
`

export const Item = styled.p`
color: #767676;
cursor: pointer;
`