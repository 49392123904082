import styled from 'styled-components';

import { Modal } from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  .fade {
    padding-left: 0px;
    padding-right: 0px !important;
  }
  
  .modal-dialog {
    max-width: 100%;
    margin: 0px;

    @media (max-width: 475px) {
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }

  }

  .modal-dialog-centered {
    min-height: 100%;
    align-items: normal;
  }

  .modal-header {
    border: 0;
    background: #F6F6F6;
    align-items: center;

    .modal-title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .modal-title {
    font-family: "Poppins";
  }

  .ic_filled_circle_close {
    cursor: pointer;
    font-size: 22px;
    
    @media(min-width: 992px) {
      margin-right: 123px;
    }
  }

  .container-buttons {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    button {

      & + button {
        margin-left: 1rem;
      }

      width: 102px;
    }
  }
  
  .container {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
  }
`;


export const HeaderBody = {

  Title: styled.p`
    font-size: 14px;
    margin: 0
  `,

  Numbers: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: 'Poppins';
    font-weight: 600;
  `
}