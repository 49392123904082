import React from 'react';

import * as S from './styles';
import { formatCpfCnpj } from '../../helpers/GlobalFunctions';

const BlockedDocumentTable = ({ data, showInformation }) => (
  <S.TableContainer>
    <S.Table responsive>
      <S.TableHeadContainer>
        <tr>
          <th>
            <S.WrapperHeader>CPF/CNPJ do Cliente</S.WrapperHeader>
          </th>
          {showInformation && (
            <>
              <th>
                <S.WrapperHeader>Código do bloqueio</S.WrapperHeader>
              </th>
              <th>
                <S.WrapperHeader>Motivo do bloqueio</S.WrapperHeader>
              </th>
            </>
          )}
          <th>
            <S.WrapperHeader>Retorno do bloqueio</S.WrapperHeader>
          </th>
        </tr>
      </S.TableHeadContainer>
      <tbody>
        {data.map(item => (
          <S.StyledTr key={item}>
            <td>{formatCpfCnpj(item.identifier)}</td>
            {showInformation && (
              <>
                <td>{item.blockCode}</td>
                <td>{item.reason}</td>
              </>
            )}
            <td
              style={{
                color: item.success ? 'var(--success)' : 'var(--danger)',
              }}
            >
              {item.success ? 'Operação sucedida' : 'Operação falhou'}
            </td>
          </S.StyledTr>
        ))}
      </tbody>
    </S.Table>
  </S.TableContainer>
);

export default BlockedDocumentTable;
