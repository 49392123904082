import React, { createContext, useState } from 'react';

export const MenuMobileContext = createContext();

export function MenuMobileContextProvider({ children }) {
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);

  const handleToggleMenuMobile = () => {
    setIsOpenMenuMobile(!isOpenMenuMobile);
  };

  return (
    <MenuMobileContext.Provider
      value={{ handleToggleMenuMobile, isOpenMenuMobile }}
    >
      {children}
    </MenuMobileContext.Provider>
  );
}
