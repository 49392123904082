import styled from 'styled-components';

export const Container = styled.div``;

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;

  .parity-header {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.5px;
    text-align: left;
  }
  .icon_parity {
    margin-left: -5px;
    margin-right: 5px;
    img {
      width: 25px;
      border-radius: 30px;
    }
  }
  .container-buttons-parity {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .action-buttons {
      align-items: center;
      margin-right: 20px;

      &.selected {
        border-radius: 8px;
        border: 1px solid #cc0000;
      }

      .parity-input {
        border: transparent;
        width: 100%;
      }

      .points {
        .parity-value {
          width: 40%;
        }
      }

      .milhas {
        .parity-value {
          width: 50%;
        }
      }

      .points,
      .milhas {
        background-color: transparent;
        color: #000;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        width: 184px;
        height: 56px;
        padding: 16px;
        border-radius: 8px;
        margin-right: 10px;
        text-align: left;

        span {
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          color: #808080;
          width: 50%;
        }

        input {
          width: 100%;
          border: transparent;
        }

        .value-parity {
          width: 50%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledModalContent = styled.div`
  height: 100%;
  max-width: 85%;
  max-height: 85%;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: ${({ showScroll }) => (showScroll ? 'auto' : 'hidden')};
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: solid 1px #cccccc;

  .icon-delete {
    color: #cc0000;
    font-size: 24px !important;
    font-family: Open Sans;
    margin-right: 10px;
  }

  .parity__crud__close {
    opacity: 0px;
    background: transparent;
    border: transparent;
    padding-bottom: 35px;
  }
`;

export const StyledModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #262626;
  padding-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

export const SelectedPlans = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .title-header-plans {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    white-space: nowrap;
  }

  .selection-participants {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: #cc0000;
    cursor: pointer;
    text-decoration: underline;
    border: none;
    background: none;
  }
`;

export const ParticipantsContainer = styled.div`
  margin-top: 30px;
  columns: ${({ numColumns }) =>
    numColumns === 2 ? '2' : '1'};
  width: 100%;
  padding-left: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  input:not(:checked) + label {
    position: unset;
  }
  input:checked + label {
    position: unset;
  }
  margin-bottom: 20px;
  div {
    margin-bottom: 20px
  }
`;

export const ButtonModal = styled.button`
  width: 196px;
  height: 56px;
  justify-content: space-between;
  padding: 16px 64px;
  border-radius: 4px;
  gap: 20px;
  border: 2px solid
    ${({ variant }) => (variant === 'primary' ? '#ccc' : '#cc0000')};
  color: ${({ variant }) => (variant === 'primary' ? '#808080' : '#cc0000')};
  background-color: ${({ variant }) =>
    variant === 'primary'
      ? '#ccc'
      : variant === 'active'
      ? '#cc0000'
      : 'transparent'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #808080;
    cursor: not-allowed;
  }

  ${({ variant }) =>
    variant === 'active' &&
    `
    color: white;
  `}

  ${({ variant }) =>
    variant === 'active' &&
    `
    background-color: #cc0000;
    border-color: #cc0000;
    color: white;
  `}
`;
