import styled, { css } from 'styled-components';
import { Navbar, NavDropdown } from 'react-bootstrap';

export const Container = styled.header`
    height: 56px;
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #FFFFFF;
`;

export const StyledNavbar = styled(Navbar)`
    width: 1080px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 56px;
    border-bottom: 1px solid #dadada;

    @media (max-width: 992px) {
      left: 0;
    }

    .title {
      margin-left: 58px;
      font-family: 'Poppins';
      font-size: 12px;
      font-weight: 600;
      color: #000000;
      line-height: 24px;
      letter-spacing: -0.5px;

      @media (max-width: 992px) {
        margin-left: 0px;
      }
    }

    .dropdown-toggle {
      color: #767676;
    }

    .dropdown-menu {
      left: -140%;
    }
`;

export const StyledNavDropdown = styled(NavDropdown)`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: bold;
    background-color: #222222;
    align-items: center;
  a{
    
  }
  .dropdown-toggle::after {
    display: none;
  }
`;

export const StyledBrandHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    > span {
      position: relative;
      top: 1px;
      display: block;
      padding: 0.5px 0px;
      font-size: 16px;
      font-weight: 300;
      font-family: 'Poppins';
      border-radius: 100px;
    }
`;

export const StyledActionsHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    .esfera-action-header {
      font-size: 24px;

      &::before {
        color: #222222; 
      }
    }
`;