import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import TableHead from '../../Nucleo/Content/CustomTable/TableHead';
import OnboardingTableBody from '../OnboardingTableBody';
import {
  handleGetRequest,
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

import * as S from './styles';

const headTableData = [
  {
    title: 'Parâmetro',
    code: 'params',
    hasIcoSort: false,
  },
  {
    title: 'Valor Atual',
    code: 'currentValue',
    hasIcoSort: false,
  },
  {
    title: 'Última alteração',
    code: 'lastChange',
    hasIcoSort: false,
  },
  {
    title: 'Usuário',
    code: 'user',
    hasIcoSort: false,
  },
  {
    title: '',
    code: '',
  },
];

export default function OnboardingConfiguration({
  userPermissions,
  populateToastMessages,
}) {
  const { keycloak } = useKeycloak();
  const [emailageScore, setEmailageScore] = useState();
  const [emailageFraudRisk, setEmailageFraudRisk] = useState();
  const [fraudRiskWithRestriction, setFraudRiskWithRestriction] = useState();
  const [updateEmailageTable, setUpdateEmailageTable] = useState(true);
  const FULL_URL_GET_DIGITAL_IDENTITY = `${
    window.SERVICES_DOMAIN + window.FLAG_REDEMPTION_URL
  }/name/EmailageScore`;
  const FULL_URL_GET_FRAUD_RISK = `${
    window.SERVICES_DOMAIN + window.FLAG_REDEMPTION_URL
  }/name/EmailageFraudRisk`;
  const FULL_URL_GET_FRAUD_RISK_WITH_RESTRICTION = `${
    window.SERVICES_DOMAIN + window.FLAG_REDEMPTION_URL
  }/name/EmailageFraudRiskWithRestriction`;
  const FULL_URL_POST = window.SERVICES_DOMAIN + window.FLAG_REDEMPTION_URL;
  const generalPermissionsRead = userPermissions.checkUiPermissionsFn(
    'ONBOARDING.RULES.READ',
    userPermissions.userGroups,
  );
  const generalPermissionsUpdate = userPermissions.checkUiPermissionsFn(
    'ONBOARDING.RULES.UPDATE',
    userPermissions.userGroups,
  );

  const getDigitalIdentity = () => {
    if (generalPermissionsRead) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {
          name: 'EmailageScore',
        },
      };

      handleGetRequest(FULL_URL_GET_DIGITAL_IDENTITY, reqOptions)
        .then(response => {
          if (response && response.data) setEmailageScore(response.data);
        })
        .catch(error => {
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const getFraudRisk = () => {
    if (generalPermissionsRead) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {
          name: 'EmailageFraudRisk',
        },
      };

      handleGetRequest(FULL_URL_GET_FRAUD_RISK, reqOptions)
        .then(response => {
          if (response && response.data) setEmailageFraudRisk(response.data);
        })
        .catch(error => {
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'secundary',
          );
        });
    }
  };

  const getFraudRiskWithRestriction = () => {
    if (generalPermissionsRead) {
      const reqOptions = {
        method: 'GET',
        headers: initialHeadersData(keycloak.token),
        data: {
          name: 'EmailageFraudRiskWithRestriction',
        },
      };

      handleGetRequest(FULL_URL_GET_FRAUD_RISK_WITH_RESTRICTION, reqOptions)
        .then(response => {
          if (response && response.data)
            setFraudRiskWithRestriction(response.data);
        })
        .catch(error => {
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'secundary',
          );
        });
    }
  };

  const handleSetValueEmailage = (item, valueConfig) => {
    if (generalPermissionsUpdate) {
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: {
          name: item.id,
          value: {
            integer_value: valueConfig,
          },
          user: '',
        },
      };

      handlePostRequest(FULL_URL_POST, reqOptions)
        .then(() => {
          setUpdateEmailageTable(true);
          populateToastMessages(
            'success',
            'Sucesso!',
            `Valor do ${item.id} alterado com sucesso!`,
          );
        })
        .catch(() => {
          setUpdateEmailageTable(true);
          populateToastMessages(
            'error',
            'Erro',
            `Erro na alteração ${item.id}`,
            'main',
          );
        });
    }
  };

  useEffect(() => {
    if (updateEmailageTable) {
      getDigitalIdentity();
      getFraudRisk();
      getFraudRiskWithRestriction();
      setUpdateEmailageTable(false);
    }
  }, [updateEmailageTable]);

  return (
    <>
      <S.Container>
        <div className="contentmain__header border_emailage">
          <text className="contentmain__header__text subtitle">
            Configuração Emailage :
          </text>
        </div>
      </S.Container>
      <S.tableTest>
        {generalPermissionsRead ? (
          <S.Table responsive>
            <TableHead headTableData={headTableData} bodyTableDataLength={4} />
            <tbody>
              {emailageScore ? (
                <OnboardingTableBody
                  key={1}
                  item={emailageScore}
                  userPermissions={userPermissions}
                  handleSetValueEmailage={handleSetValueEmailage}
                />
              ) : null}
              {emailageFraudRisk ? (
                <OnboardingTableBody
                  key={2}
                  item={emailageFraudRisk}
                  userPermissions={userPermissions}
                  handleSetValueEmailage={handleSetValueEmailage}
                />
              ) : null}
              {fraudRiskWithRestriction ? (
                <OnboardingTableBody
                  key={3}
                  item={fraudRiskWithRestriction}
                  userPermissions={userPermissions}
                  handleSetValueEmailage={handleSetValueEmailage}
                />
              ) : null}
            </tbody>
          </S.Table>
        ) : null}
      </S.tableTest>
    </>
  );
}
