import React, { useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import * as S from '../../components/Nucleo/Layout/styles';

import Content from '../../components/Nucleo/Content';

import { FormRow, InfoContainer, StyledForm } from './styles';

import Button from '../../components/Button';
import BlockedDocumentTable from '../../components/BlockedDocumentTable';
import FileInput from '../../components/FileInput';
import Toast from '../../components/Toast';
import {
  handlePostRequest,
  initialHeadersData,
} from '../../services/GenericMethods';
import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

const FULL_URL = window.SERVICES_DOMAIN + window.PROFILE_URL;

const BlockClient = () => {
  const { keycloak } = useKeycloak();

  const [isLoading, setIsLoading] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [blockedDocs, setBlockedDocs] = useState([]);

  const [file, setFile] = useState();

  const [blockCode, setBlockCode] = useState();
  const [reason, setReason] = useState();

  const [fileError, setFileError] = useState(false);
  const [blockCodeError, setBlockCodeError] = useState(false);
  const [reasonError, setReasonError] = useState(false);

  const handleBlockClient = e => {
    e.preventDefault();
    setBlockCodeError();
    setReasonError();
    setFileError();

    let hasError = false;

    if (!file) {
      setFileError(true);
      hasError = true;
    }
    if (!blockCode) {
      setBlockCodeError(true);
      hasError = true;
    }
    if (!reason) {
      setReasonError(true);
      hasError = true;
    }

    if (hasError) return;

    setIsLoading(true);

    const data = new FormData();
    data.append('file', file);
    data.append('reason', reason);
    data.append('blockCode', blockCode);

    const reqOptions = {
      headers: initialHeadersData(keycloak.token),
      data,
    };

    handlePostRequest(`${FULL_URL}/block`, reqOptions)
      .then(returnedData => {
        setBlockedDocs(returnedData.data);

        setFile();
        setReason('');
        setBlockCode('');
      })
      .catch(err => {
        const errorMessage = formatErrorMessageToToast(err, keycloak);
        const newToast = populateToastMessagesGlobal(
          'error',
          'Erro',
          errorMessage.messagePTBR,
          'main',
        );
        setToastList(toasts => [...toasts, newToast]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Content>
      <S.Container>
        <Toast
          toastList={toastList}
          position="bottom-right"
          autoDelete
          dismissTime={5000}
        />

        <div className="contentmain__header">
          <text className="contentmain__header__text contentmain__header__text__active">
            Bloqueio de Clientes
          </text>
        </div>

        <InfoContainer>
          <i className="ic_filled_circle_information esfera-icon" />
          Inserir arquivo de texto com um CPF/CNPJ por linha, sempre com uma
          vírgula no final.
        </InfoContainer>

        <StyledForm>
          <FormRow>
            <div className="input-group">
              <FileInput
                value={file}
                onChangeValue={e =>
                  e.target.files && setFile(e.target.files[0])
                }
              />
              {fileError && (
                <span className="input-error">
                  Insira um Arquivo para continuar
                </span>
              )}
            </div>

            <div className="input-group">
              <StyledForm.Group>
                <StyledForm.Label>Código de bloqueio: </StyledForm.Label>
                <StyledForm.Control
                  value={blockCode}
                  onChange={e => setBlockCode(e.target.value)}
                />
              </StyledForm.Group>
              {blockCodeError && (
                <span className="input-error">
                  Insira o Código de bloqueio para continuar
                </span>
              )}
            </div>
          </FormRow>

          <FormRow>
            <div className="input-group">
              <StyledForm.Group>
                <StyledForm.Label>Motivo do bloqueio: </StyledForm.Label>
                <StyledForm.Control
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </StyledForm.Group>
              {reasonError && (
                <span className="input-error">
                  Insira o Motivo do bloqueio para continuar
                </span>
              )}
            </div>

            <div style={{ display: 'flex', height: 38 }}>
              <Button disabled={isLoading} onClick={handleBlockClient}>
                Bloquear
              </Button>
            </div>
          </FormRow>
        </StyledForm>

        {blockedDocs && blockedDocs.length > 0 && (
          <>
            <div className="contentmain__header">
              <text className="contentmain__header__text contentmain__header__text__active">
                CPF/CNPJ Bloqueado
              </text>
            </div>

            {!isLoading && (
              <BlockedDocumentTable data={blockedDocs} showInformation />
            )}
          </>
        )}
      </S.Container>
    </Content>
  );
};

export default BlockClient;
