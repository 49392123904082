import React from 'react';
import { StyledLink } from './styles';

function NavLink({ iconClass, children, href }) {
  return (
    <StyledLink to={href} exact>
      <span className={`linkIcon esfera-icon ${iconClass}`} />
      {children}
    </StyledLink>
  );
}

export default NavLink;
