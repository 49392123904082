import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';
import * as XLSX from 'xlsx';

import OrdersFilter from '../../components/OrdersPage/OrdersFilter';
import OrdersTable from '../../components/OrdersPage/OrdersTable';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';

import {
  handleGetRequest,
  initialPaginationData,
  allOrders,
} from '../../services/GenericMethods';

import { Container } from './styles';
import ToastCustom from '../../components/Toast';

import constants from '../../config';

import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

export default function Orders({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const [ordersFormatted, setOrdersFormatted] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [approved, setApproved] = useState([]);
  const [refused, setRefused] = useState([]);
  const FULL_URL = window.SERVICES_DOMAIN + window.LIST_ORDERS_URL;
  const options = initialPaginationData('GET', currentPage, keycloak.token);
  const optionsAllOrders = allOrders('GET', currentPage, keycloak.token);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const [isDownload, setIsDownload] = useState(true);

  const handleShowMessageAction = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }
  };

  const approvedRefusedList = () => {
    const getListAll = [];
    getListAll.push(...approved, ...refused);
    return getListAll;
  };

  const exportFile = () => {
    const newList = approvedRefusedList().map(order => {
      const { sentinelResponse } = order;
      return {
        pedido: order.orderId,
        cpf_cnpj: order.customerId ? order.customerId : '-',
        data_da_transacao: moment(order.createDate).format(
          'DD/MM/YYYY - hh:mm:ss ',
        ),
        regras_ativadas:
          sentinelResponse.rules.length > 0
            ? sentinelResponse.rules.toString()
            : '-',
        chamado_salesforce: order.salesforceNumber
          ? order.salesforceNumber
          : '-',
        site: order.siteId ? order.siteId : '-',
        usuario: order.username ? order.username : '-',
        status:
          order.status === 'FRAUD_CHECK_APPROVED' ? 'confirmado' : 'cancelado',
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(newList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Planilha1');
    XLSX.writeFile(workbook, 'Planilha.xlsx');
    setIsDownload(true);
    handleShowMessageAction('success', 'Download concluido', 'Sucesso');
  };

  useEffect(() => {
    if (approved.length && refused.length) {
      approvedRefusedList();
      setTimeout(() => {
        exportFile();
      }, 3000);
    }
  }, [approved, refused]);

  const getAllOrders = (inputParams, fraudCheck) => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ANTIFRAUD.ORDERS.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            setApproved(
              fraudCheck === 'FRAUD_CHECK_APPROVED' && response.data.content,
            );
            setRefused(
              fraudCheck === 'FRAUD_CHECK_REFUSED' && response.data.content,
            );
          }
        })
        .catch(error => {
          console.error(`Error at listOrders request = ${error}`);

          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const getOrders = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ANTIFRAUD.ORDERS.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const orders = response.data.content;
            setOrdersFormatted(
              orders.map(order => {
                const { sentinelResponse } = order;
                return {
                  orderId: order.orderId,
                  rules:
                    sentinelResponse.rules.length > 0
                      ? sentinelResponse.rules.toString()
                      : '-',
                  exceptions:
                    sentinelResponse.exception &&
                    sentinelResponse.exception.exception
                      ? sentinelResponse.rules.toString()
                      : '-',
                  status: order.status,
                  user:
                    order.status !== constants.FRAUD_CHECK_LABEL &&
                    order.attendentUser
                      ? order.attendentUser.user
                      : '-',
                  document: order.customerId ? order.customerId : '-',
                  date: moment(order.createDate).format(
                    'DD/MM/YYYY - hh:mm:ss ',
                  ),
                  siteId: order.siteId ? order.siteId : '-',
                  salesforceNumber: order.salesforceNumber
                    ? order.salesforceNumber
                    : '-',
                };
              }),
            );

            setCurrentPage(response.data.number);
            setTotalPages(response.data.totalPages);
            setIsFirstPage(response.data.first);
            setIsLastPage(response.data.last);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setIsFirstPage(false);
            setIsLastPage(false);
            setOrdersFormatted([]);
          }
          console.warn(`Response listOrders = ${response}`);
        })
        .catch(error => {
          console.error(`Error at listOrders request = ${error}`);

          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const filterApprovedRefused = element => {
    const filter = {
      pageCount: 1000,
      pageNumber: 0,
      sort: 'createDate,desc',
      status:
        element === 'FRAUD_CHECK_APPROVED'
          ? 'FRAUD_CHECK_APPROVED'
          : 'FRAUD_CHECK_REFUSED',
    };
    return filter;
  };

  const handleFilterAll = () => {
    optionsAllOrders.params = filterApprovedRefused('FRAUD_CHECK_APPROVED');
    getAllOrders(optionsAllOrders, 'FRAUD_CHECK_APPROVED');
    optionsAllOrders.params = filterApprovedRefused('FRAUD_CHECK_REFUSED');
    getAllOrders(optionsAllOrders, 'FRAUD_CHECK_REFUSED');
  };

  const exportApprovedRefused = () => {
    handleFilterAll();
    setIsDownload(false);
  };

  const handleFilter = inputParams => {
    options.params = inputParams;
    setCurrentFilter(inputParams);
    getOrders(options);
  };

  const handlePageChange = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getOrders(options);
  };

  useEffect(() => {
    options.params.sort = 'createDate,desc';
    getOrders(options);
    setCurrentFilter(options.params);
  }, [toastList]);

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />

      <h2>Análise de Pedidos</h2>
      <OrdersFilter
        currentFilter={currentFilter}
        handleFilter={handleFilter}
        exportList={exportApprovedRefused}
        isDownload={isDownload}
      />
      {userPermissions.checkUiPermissionsFn(
        'ANTIFRAUD.ORDERS.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {ordersFormatted.length > 0 ? (
            <OrdersTable
              orders={ordersFormatted}
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPages > 0 ? (
        <Pag
          currentPage={currentPage}
          totalPages={totalPages}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          onClickFnPage={handlePageChange}
        />
      ) : null}
    </Container>
  );
}
