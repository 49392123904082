import styled, { css } from 'styled-components';

export const StyledTr = styled.tr`
  ${({ theme, isInactive }) => css`

    height: 64px;

    td {
      font-family: ${theme.fonts.family.opensans};
      font-size: ${theme.fonts.textSize.medium};
      color: ${theme.colors.darkerGrey};
      line-height: 16px;
      vertical-align: inherit;

      &.emphasis {
        font-weight: ${theme.fonts.regular};
      }

      &.partner {
        display: flex;
        align-items: center;
        height: 64px;
      }

      .status {
        width: fit-content;
        padding: 4px 6px;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: ${theme.fonts.textSize.xsmall};
        font-weight: ${theme.fonts.semibold};
      }

      .esfera-icon {
        font-size: 22px;
        font-weight: 700;
        margin-right: 12px;
        cursor: pointer;
        transform: rotate(180deg);

        &.active {
          transform: rotate(0deg);
        }
      }

      .tooltip-campaign {
        position: absolute;
        right: 0 !important;
        top: 0;
        max-width: 272px;
        background: #FEF2E5;
        border-radius: 4px;
        color: #222222;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .__react_component_tooltip.show {
        opacity: 1;
      }

      .place-left::after {
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        right: 20px;
        top: -5%;
        margin-top: -4px;
        border-left-color: #FEF2E5 ;
        border-left-style: solid;
        border-left-width: 10px;
      }
    }
  `}
`;
