import React, { useState } from 'react';
import ToastCustom from '../../components/Toast';
import { populateToastMessagesGlobal } from '../../helpers/GlobalFunctions';
import OnboardingConfiguration from '../../components/OnboardingContaPage/OnboardingConfiguration';
import EmailageConfigurations from '../../components/OnboardingContaPage/EmailageConfiguration';
import Content from '../../components/Content';

import * as S from './styles';

export default function OnboardingConta({ userPermissions }) {
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };

  return (
    <>
      <Content>
        <S.Container>
          <S.OnboardingHeaderContentStyle>
            <div className="contentmain__header">
              <div className="contentmain__header__title">
                <text className="contentmain__header__text">Prevenção</text>
                <span className="contentmain__header__icon icoArrow esfera-icon BE070" />
                <text className="contentmain__header__text contentmain__header__text__active">
                  Onboarding Conta
                </text>
              </div>
            </div>
          </S.OnboardingHeaderContentStyle>

          {userPermissions.checkUiPermissionsFn(
            'ONBOARDING.RULES.READ',
            userPermissions.userGroups,
          ) ? (
            <div>
              <OnboardingConfiguration
                userPermissions={userPermissions}
                populateToastMessages={populateToastMessages}
              />
              <EmailageConfigurations
                userPermissions={userPermissions}
                populateToastMessages={populateToastMessages}
              />
            </div>
          ) : null}

          <ToastCustom
            toastList={toastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />

          <ToastCustom
            toastList={mainToastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
        </S.Container>
      </Content>
    </>
  );
}
