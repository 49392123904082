import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useKeycloak } from '@react-keycloak/web';
import Content from '../../components/Content';
import * as S from './styles';
import ToastCustom from '../../components/Toast';
import {
  populateToastMessagesGlobal,
  formatErrorMessageToToast,
} from '../../helpers/GlobalFunctions';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';
import OrdersPmaTable from '../../components/OrdersPma/OrdersPmaTable';
import {
  handlePostRequest,
  initialHeadersData,
} from '../../services/GenericMethods';
import OrdersPmaFilterDate from '../../components/OrdersPma/OrdersPmaFilterDate';

const headTableDataOrdersPma = [
  {
    title: 'ID Tran Comarch',
    hasIcoSort: false,
  },
  {
    title: 'Pedido parceiro',
    hasIcoSort: false,
  },
  {
    title: 'Pedido cashback',
    hasIcoSort: false,
  },
  {
    title: 'Status pedido',
    hasIcoSort: false,
  },
  {
    title: 'Documento cliente',
    hasIcoSort: false,
  },
  {
    title: 'Valor cashback',
    hasIcoSort: false,
  },
  {
    title: 'Nome campanha',
    hasIcoSort: false,
  },
  {
    title: 'Código campanha',
    hasIcoSort: false,
  },
  {
    title: 'Mensagem processamento',
    hasIcoSort: false,
  },
  {
    title: 'Data criação',
    hasIcoSort: false,
  },
  {
    title: 'Data atualização',
    hasIcoSort: false,
  },
  {
    title: 'Status',
    hasIcoSort: false,
  },
];

export default function OrdersPma({ userPermissions }) {
  const { keycloak } = useKeycloak();
  const FULL_URL = `${window.SERVICES_DOMAIN + window.ORDERS_PMA_ITEM_URL}`;
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const [isModifiedOrder, setisModifiedOrder] = useState(false);
  const [bodyTableDataOrders, setbodyTableDataOrders] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(5);
  const currentDate = moment(new Date()).format('YYYY-MM-DDT23:59:59');
  const [currentFilter, setCurrentFilter] = useState({
    pageNumber: 0,
    pageCount: 20,
    startAt: '2023-01-01T13:00:00',
    endAt: currentDate,
  });

  const populateToastMessages = (status, title, message, queue) => {
    const appendToast = populateToastMessagesGlobal(
      status,
      title,
      message,
      queue,
    );

    if (queue === 'secundary') {
      setToastList([...toastList, appendToast]);
    } else {
      setMainToastList([...mainToastList, appendToast]);
    }
  };

  const getTransactionsPma = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.INCENTIVE_ENGINE.READ',
        userPermissions.userGroups,
      )
    ) {
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: currentFilter,
      };

      handlePostRequest(FULL_URL, reqOptions)
        .then(response => {
          if (response && response.data && response.data.items.length > 0) {
            setbodyTableDataOrders(response.data.items);
            setCurrentPage(response.data.currentPageNumber);
            setTotalPages(response.data.totalPages);
          } else {
            setCurrentPage(0);
            setTotalPages(0);
            setbodyTableDataOrders([]);
          }
        })
        .catch(error => {
          console.warn(`Error at list transactions files request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          populateToastMessages(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
        });
    }
  };

  const handleFilter = inputParams => {
    setisModifiedOrder(!isModifiedOrder);
    setCurrentFilter(inputParams);
    getTransactionsPma();
  };

  const handlePageChange = page => {
    const filter = currentFilter;

    filter.pageNumber = page;
    setCurrentFilter(filter);
    setisModifiedOrder(!isModifiedOrder);

    getTransactionsPma();
  };

  const reloadAfterAction = data => {
    if (data === true) {
      getTransactionsPma();
    }
  };

  useEffect(() => {
    getTransactionsPma();
  }, [isModifiedOrder]);

  return (
    <>
      <Content>
        <S.Container>
          <S.AirCampaignHeaderContentStyle>
            <div className="contentmain__header">
              <div className="contentmain__header__title">
                <text className="contentmain__header__text">
                  Motor de Incentivos
                </text>
                <span className="contentmain__header__icon icoArrow esfera-icon BE070" />
                <text className="contentmain__header__text contentmain__header__text__active">
                  Pedidos em PMA
                </text>
              </div>
            </div>
          </S.AirCampaignHeaderContentStyle>
          <OrdersPmaFilterDate
            currentFilter={currentFilter}
            handleFilter={handleFilter}
          />
          {userPermissions.checkUiPermissionsFn(
            'ESFERA.INCENTIVE_ENGINE.READ',
            userPermissions.userGroups,
          )
            ? bodyTableDataOrders && (
                <OrdersPmaTable
                  headTableData={headTableDataOrdersPma}
                  bodyTableData={bodyTableDataOrders}
                  userPermissions={userPermissions}
                  isModifiedOrder={isModifiedOrder}
                  setisModifiedOrder={setisModifiedOrder}
                  populateToastMessages={populateToastMessages}
                  reloadAfterAction={reloadAfterAction}
                />
              )
            : null}
          {totalPages > 0 ? (
            <Pag
              currentPage={currentPage}
              totalPages={totalPages}
              onClickFnPage={handlePageChange}
            />
          ) : null}

          <ToastCustom
            toastList={toastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
          <ToastCustom
            toastList={mainToastList}
            position="bottom-right"
            autoDelete
            dismissTime={5000}
          />
        </S.Container>
      </Content>
    </>
  );
}
