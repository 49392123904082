import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 10px 0px 0px;
    width: 100%;

    & + div {
      margin-top: 15px;
    }

    button {
      width: 100%;
      margin: 0;
      padding: 0 0 0 10px;
      border: 0;
      outline: none;

      font-family: ${theme.fonts.family.roboto};
      font-weight: ${theme.fonts.medium};
      font-size: ${theme.fonts.textSize.small};
      line-height: 24px;
      color: ${theme.colors.darkGrey};
      background: transparent;

      display: flex;
      align-self: flex-start;
      align-items: center;

      .linkIcon {
        margin-right: 10px;
        font-size: 20px;
        &::before {
          color: ${theme.colors.darkGrey};
        }
      }

      .arrowIcon {
        margin-left: auto;
        font-size: 24px;

        &::before {
          color: ${theme.colors.darkGrey};
        }
      }
    }
  `}
`;

export const Collapse = styled.div`
  ${({ theme, isOpen }) => css`
    ${isOpen ? '' : 'max-height: 0;'}
    transition: max-height 0.3s ease-in;
    overflow: hidden;

    a {
      margin-top: 8px;
      display: block;
      font-family: ${theme.fonts.family.roboto};
      font-size: ${theme.fonts.textSize.small};
      font-weight: ${theme.fonts.medium};
      color: ${theme.colors.darkerGrey};
      padding: 8px 16px;
      background: transparent;
      transition: 0.3s all;
      text-decoration: none;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: ${theme.colors.lightGrey};
        display: inline-block;
        border-radius: 50%;
        margin-right: 16px;
      }

      &.active {
        background: #f0d8d8;
        border-radius: 8px;

        &::before {
          background: ${theme.colors.boston};
        }
      }

      &:hover {
        background: #f0d8d8;
        border-radius: 8px;

        &::before {
          background: ${theme.colors.boston};
        }
      }
    }
  `}
`;
