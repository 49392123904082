import moment from 'moment';
import React from 'react';
import * as S from './styles';

export default function TransactionsItemTableBody({ item }) {
  return (
    <S.StyledTr>
      {item.partnerOrderId ? <td>{item.partnerOrderId}</td> : <td>---</td>}

      {item.campaignName ? <td>{item.campaignName}</td> : <td>---</td>}

      {item.commerceItemId ? <td>{item.commerceItemId}</td> : <td>---</td>}

      {item.document ? <td>{item.document}</td> : <td>---</td>}

      {item.customerName ? <td>{item.customerName}</td> : <td>---</td>}

      {item.bankAccount ? <td>{item.bankAccount}</td> : <td>---</td>}

      {item.accountDigit ? <td>{item.accountDigit}</td> : <td>---</td>}

      {item.createDate ? (
        <td>{moment(item.createDate).format('DD/MM/YYYY HH:mm')}</td>
      ) : (
        <td>---</td>
      )}

      {item.updateDate ? (
        <td>{moment(item.updateDate).format('DD/MM/YYYY HH:mm')}</td>
      ) : (
        <td>---</td>
      )}
      <td>
        {item.cashbackValueDouble
          ? `${Number(item.cashbackValueDouble)
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`
          : '0,00'}
      </td>

      {item.status ? <td>{item.status}</td> : <td>---</td>}
    </S.StyledTr>
  );
}
