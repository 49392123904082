import styled from 'styled-components';

export const Header = styled.div`
    position: relative;
    padding: 14px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--darkerGrey);
    border: 1px solid var(--mediumSky);
    background: var(--white);
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin: 16px 0;
    background: var(--white);
    box-sizing: border-box;
    cursor: pointer;

    img {
      margin-right: 12px;
      width: 16px;
      line-height: 24px;
    }

    .arrow-icon {
      position: absolute;
      right: 8px;
      font-size: 16px;
      &::before {
        color: var(--darkerGrey);
      }
    }
`;

export const ListContainer = styled.div``;

export const List = styled.ul`
    margin-top: 8px;
    background: var(--white);
    border: 1px solid var(--lighterGrey);
    border-radius: 8px;

    &:first-child {
      padding-top: 2px;
    }
`;
export const Container = styled.div `

`;

export const ListItem = styled.li`
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: var(--darkerGrey);
    padding: 14px;


  .container-label {
    display: flex;

    > span {
      line-height: 16px;
    }

    > img {
      margin-right: 12px;
      width: 16px;
      line-height: 24px;
    }
  }
  &:hover {
    background: var(--sky);
  }
`;
