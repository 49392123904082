import React, { useState, useEffect } from 'react';

import TextField from '../../../TextField';
import { DivInput } from './styles';

export default function InformationContent({
  code,
  name,
  priority,
  description,
  populatePayloadRequest,
  getCouponIdState,
}) {
  const [newInformationValues, setNewInformationValues] = useState({
    couponId: getCouponIdState ? code : null,
    couponName: name,
    couponDescription: description,
    promoPriority: priority ? priority.toString() : '',
  });
  const [validationFields, setValidationFields] = useState({
    couponId: {
      isValid: !!(
        newInformationValues.couponId &&
        newInformationValues.couponId.length >= 3
      ),
      isRequired: true,
      minLength: 3,
    },
    couponName: {
      isValid: !!(
        newInformationValues.couponName &&
        newInformationValues.couponName.length >= 3
      ),
      isRequired: true,
      minLength: 3,
    },
    couponDescription: {
      isValid: !!(
        newInformationValues.couponDescription &&
        newInformationValues.couponDescription.length >= 3
      ),
      isRequired: true,
      minLength: 3,
    },
    promoPriority: {
      isValid: !!(
        newInformationValues.promoPriority &&
        newInformationValues.promoPriority.length >= 1
      ),
      isRequired: true,
      minLength: 1,
    },
  });
  const setIsUntouchable = e => {
    const { target } = e;
    const { value, id } = target;

    const validationField = {};
    validationField[id] = {
      ...validationFields[id],
      isValid: !!(value && value.length >= validationFields[id].minLength),
    };

    setValidationFields({ ...validationFields, ...validationField });
  };

  const populateInformationFields = e => {
    const { target } = e;
    const { value, id } = target;
    const key = target.name;
    const newObject = {};
    newObject[key] = value;

    const validationField = {};
    validationField[id] = {
      ...validationFields[id],
      isValid: !!(value && value.length >= validationFields[id].minLength),
    };
    setValidationFields({ ...validationFields, ...validationField });
    setNewInformationValues({ ...newInformationValues, ...newObject });
  };

  useEffect(() => {
    populatePayloadRequest(newInformationValues, validationFields);
  }, [newInformationValues]);

  return (
    <>
      <DivInput>
        <TextField
          id="Código"
          label="Código"
          idName="couponId"
          defaultValue={code}
          disabled={getCouponIdState}
          className={
            validationFields.couponId.isValid ||
            !validationFields.couponId.isRequired
              ? ''
              : 'invalid-input'
          }
          onKeyUp={e => {
            setIsUntouchable(e);
          }}
          onBlur={e => {
            populateInformationFields(!getCouponIdState ? e : null);
          }}
        />
        <TextField
          id="Nome"
          label="Nome"
          idName="couponName"
          defaultValue={name}
          disabled={getCouponIdState}
          className={
            validationFields.couponName.isValid ||
            !validationFields.couponName.isRequired
              ? ''
              : 'invalid-input'
          }
          onKeyUp={e => {
            setIsUntouchable(e);
          }}
          onBlur={e => populateInformationFields(e)}
        />
        <TextField
          id="Prioridade"
          label="Prioridade"
          idName="promoPriority"
          defaultValue={priority}
          type="number"
          className={
            validationFields.promoPriority.isValid ||
            !validationFields.promoPriority.isRequired
              ? ''
              : 'invalid-input'
          }
          onKeyUp={e => {
            setIsUntouchable(e);
          }}
          onBlur={e => {
            populateInformationFields(e);
          }}
        />
        <TextField
          id="Descrição"
          label="Descrição"
          idName="couponDescription"
          defaultValue={description}
          className={
            validationFields.couponDescription.isValid ||
            !validationFields.couponDescription.isRequired
              ? ''
              : 'invalid-input'
          }
          onKeyUp={e => {
            setIsUntouchable(e);
          }}
          onBlur={e => {
            populateInformationFields(e);
          }}
        />
      </DivInput>
    </>
  );
}
