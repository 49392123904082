import React, { useState } from 'react';
import moment from 'moment';

import constants from '../../../config';
import ModalRemoveProduct from '../ModalRemoveProduct';

import { StyledTr, WrapperButton } from './styles';

export default function BlacklistItem({
  product,
  userPermissions,
  handleShowMessageAction,
}) {
  const [isOpenModalRemove, setIsOpenModalRemove] = useState(false);

  const handleShowMdlRemoveProduct = event => {
    event.stopPropagation();
    setIsOpenModalRemove(true);
  };

  const handleCloseMdlRemoveProduct = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalRemove(false);
  };

  return (
    <>
      <StyledTr>
        <td>{product.identifier}</td>
        <td>{constants.BLACKLIST_TYPES[product.type]}</td>
        <td>{moment(product.createAt).format('DD/MM/YYYY HH:mm')}</td>
        <td>
          <WrapperButton>
            {userPermissions.checkUiPermissionsFn(
              'ESFERA.BLACKLIST.DELETE',
              userPermissions.userGroups,
            ) ? (
              <button
                type="button"
                className="btn-action"
                onClick={handleShowMdlRemoveProduct}
              >
                <span className="recused">Remover</span>
              </button>
            ) : null}
          </WrapperButton>
        </td>
      </StyledTr>

      <ModalRemoveProduct
        product={product}
        isOpenModal={isOpenModalRemove}
        handleCloseModal={handleCloseMdlRemoveProduct}
        userPermissions={userPermissions}
      />
    </>
  );
}
