import React from 'react';
import moment from 'moment';

import { StyledModal } from './styles';

export default function ModalCampaignDetails({
  item,
  isOpenModal,
  handleCloseModal,
}) {
  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>Detalhes da Campanha</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div>
          <h6>Campanha</h6>
          <span>
            <strong>Parceiro: </strong>
            {item.seller}
          </span>

          <span>
            <strong>Nome da Campanha: </strong>
            {item.campaignName}
          </span>

          <span>
            <strong>Data Inicial: </strong>
            {moment(item.startDate).format('DD/MM/YYYY HH:mm')}
          </span>

          <span>
            <strong>Data Final: </strong>
            {moment(item.endDate).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>

        <div>
          <span>
            <strong>Códigos Cadastrados: </strong>
            <br />

            <ul>
              {item.milesCampaignRules.map(data => (
                <>
                  <li>
                    <strong>Promotion Code: </strong>
                    {data.promotionCode}
                  </li>
                  <li>
                    <strong>Clubes Contemplados: </strong>
                    {data.participatingClubs}
                  </li>
                  <br />
                </>
              ))}
            </ul>
          </span>
        </div>
      </StyledModal.Body>
    </StyledModal>
  );
}
