import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Button from '../../Button';
import Loading from '../../Loading';
import { StyledModal, Form, WrapperButtons } from './styles';
import {
  handlePatchRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';
// import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalApproveOrder({
  order,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const [showLoading, setShowLoading] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const FULL_URL = window.SERVICES_DOMAIN + window.REPORT_AWIN_ASSOCIATE_URL;

  const formatDocumentId = () => {
    const documentIdFormated = documentId;
    return documentIdFormated.replace(/[^0-9]/g, '');
  };

  const handleConfirmOrder = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'AWIN.ORDERS.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        method: 'POST',
        headers: initialHeadersData(keycloak.token),
        data: {},
      };

      handlePatchRequest(
        `${FULL_URL}/${
          order.awinPostReturn.transactionId
        }/${formatDocumentId()}`,
        reqOptions,
      )
        .then(() => {
          setShowLoading(false);
          handleCloseModal(
            'success',
            'Sucesso!',
            `Cliente associado com sucesso.`,
          );
        })
        .catch(error => {
          setShowLoading(false);
          console.error(`Error at associate request = ${error}`);
          console.error('O CPF/CNPJ não pertence a essa transação.');
          const errorMessage = 'O CPF/CNPJ não pertence a essa transação';
          handleCloseModal('error', 'Erro', errorMessage);
        });
    }
    setDocumentId('');
  };

  const cpfMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const cnpjMask = value => {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const handleDocumentId = e => {
    if (e.target.value.length <= 14) setDocumentId(cpfMask(e.target.value));
    else setDocumentId(cnpjMask(e.target.value));
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Detalhes do Pedido</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div className="modalAssociationClient">
          <span>
            <strong>ID Transação: </strong>
            {order.awinPostReturn.transactionId || ' - '}
          </span>

          <span>
            <strong>Valor do Pedido: </strong>
            {order.awinPostReturn.transactionAmount || ' - '}
          </span>

          <span>
            <strong>Valor da Comissão: </strong>
            {order.awinPostReturn.comissao || ' - '}
          </span>

          <span>
            <strong>Data do Pedido: </strong>
            {order.awinPostReturn.transactionDate}
          </span>

          <span>
            <strong>Loja: </strong>
            {order.partnerName || ' - '}
          </span>

          <Form className="form-inputs">
            <Form.Group className="form-group">
              <Form.Label>CPF/CNPJ:</Form.Label>
              <Form.Control
                type="text"
                onChange={handleDocumentId}
                value={documentId}
              />
            </Form.Group>
          </Form>
        </div>
        <WrapperButtons>
          <Button typeOfButton="secondary" onClick={handleConfirmOrder}>
            Associar
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
