import React from 'react';
import TransactionsItemTableBody from '../TransactionsItemTableBody';
import TransactionsItemTableHead from '../TransactionsItemTableHead';

import * as S from './styles';

export default function TransactionsItemTable({
  headTableData,
  bodyTableData,
}) {
  return (
    <S.Container>
      <S.Table responsive>
        <TransactionsItemTableHead headTableData={headTableData} />
        <tbody>
          {bodyTableData.map(item => (
            <TransactionsItemTableBody item={item} key={item.manualOrderId} />
          ))}
        </tbody>
      </S.Table>
    </S.Container>
  );
}
