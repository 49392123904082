import React from 'react';
import Button from '../../../Button';
import { Flex, ToggleButton } from './styles';

export default function Navbar({
  isToDisableSaveButton,
  userHasPermission,
  menu,
  selectedMenuItem,
  setSelectedMenuItem,
  handleSaveUpdate,
}) {
  return (
    <Flex maxW="880px" justifyContent="space-between">
      <Flex
        w="440px"
        h="48px"
        bg="#F0F0F0"
        bRadius="24px"
        justifyContent="space-between"
        alignItems="center"
      >
        {Object.values(menu).map(menuItem => (
          <ToggleButton
            key={menuItem.id}
            onClick={() => setSelectedMenuItem(menuItem.id)}
            className={selectedMenuItem === menuItem.id && `ToggleButtonActive`}
          >
            {menuItem.value}
          </ToggleButton>
        ))}
      </Flex>

      {userHasPermission('ESFERA.TRACKING.UPDATE') && (
        <Button
          type="submit"
          disabled={isToDisableSaveButton}
          onClick={() => handleSaveUpdate()}
        >
          Salvar Atualização
        </Button>
      )}
    </Flex>
  );
}
