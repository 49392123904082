import styled from 'styled-components';

export const StyledForm = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &.hidden {
    display: none;
  }
`;

export const Flex = styled.div`
  display: ${props => props.display || 'flex'};
  justify-content: ${props => props.justifyContent || ''};
  align-items: ${props => props.alignItems || ''};
  align-self: ${props => props.alignSelf || ''};
  max-width: ${props => props.maxW || ''};
  width: ${props => props.w || ''};
  height: ${props => props.h || ''};
  flex-direction: ${props => props.direction || 'row'};
  margin-top: ${props => props.mt || ''};
  margin-bottom: ${props => props.mb || '5px' || ''};
  margin-left: ${props => props.ml || '2px' || ''};
  background: ${props => props.bg || ''};
  border-radius: ${props => props.bRadius || ''};
  padding: ${props => props.p || ''};
  position: ${props => props.position || ''};
  right: ${props => props.right || ''};
  gap: ${props => props.gap || ''};
  flex-wrap: ${props => props.flexWrap || ''};
  text-align: ${props => props.textAlign || ''};
`;

export const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 432px);
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 25px;
  margin-top: 21px;
`;
export const Status = styled.p`
  color: #000;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;
