import React, { useEffect, useState } from 'react';

import Button from '../../Button';
import constants from '../../../config';

import { StyledForm } from './styles';

export default function OrdersFilter({ currentFilter, handleFilter }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [documentId, setDocumentId] = useState('');
  const [status, setStatus] = useState('DEFAULT');

  const handleStartDate = e => {
    setStartDate(e.target.value);
  };

  const handleEndDate = e => {
    setEndDate(e.target.value);
  };

  const handleDocument = e => {
    setDocumentId(e.target.value);
  };

  const handleStatus = e => {
    setStatus(e.target.value);
  };

  const clearFilter = () => {
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.startDate;
    delete filter.endDate;
    delete filter.documentId;
    delete filter.status;

    setStartDate('');
    setEndDate('');
    setDocumentId('');
    setStatus('DEFAULT');

    handleFilter(filter);
  };

  const applyFilter = e => {
    e.preventDefault();
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.startDate;
    delete filter.endDate;
    delete filter.documentId;
    delete filter.status;

    if (startDate) filter.startDate = startDate;
    if (endDate) filter.endDate = endDate;
    if (documentId) filter.documentId = documentId;
    if (status !== 'DEFAULT') filter.status = status;

    handleFilter(filter);
  };

  const isFilterValid = () => {
    const isValid = status !== 'DEFAULT' || startDate || endDate || documentId;
    return isValid;
  };

  useEffect(() => {
    setStartDate('');
    setEndDate('');
    setDocumentId('');
    setStatus('DEFAULT');
  }, [currentFilter]);

  return (
    <StyledForm>
      <div className="form-content">
        <StyledForm.Group className="form-group period-inputs">
          <StyledForm.Label>Período de Campanha:</StyledForm.Label>
          <StyledForm.Control
            onChange={handleStartDate}
            type="date"
            value={startDate}
          />
          <StyledForm.Control
            onChange={handleEndDate}
            type="date"
            value={endDate}
          />
        </StyledForm.Group>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>CPF/CNPJ:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleDocument}
              type="text"
              value={documentId}
            />
          </StyledForm.Group>
        </div>

        <StyledForm.Group>
          <StyledForm.Label>Status: </StyledForm.Label>
          <StyledForm.Control
            as="select"
            value={status}
            onChange={handleStatus}
          >
            <option disabled value="DEFAULT">
              Filtre por um status
            </option>
            {Object.keys(constants.OCC_TRANSFER_STATUS_VALUE).map(item => (
              <option value={item} key={item}>
                {constants.OCC_TRANSFER_STATUS_VALUE[item]}
              </option>
            ))}
          </StyledForm.Control>
        </StyledForm.Group>
      </div>

      <div className="form-actions">
        <Button
          type="submit"
          typeOfButton="primary"
          onClick={applyFilter}
          disabled={!isFilterValid()}
        >
          Aplicar Filtros
        </Button>
        <button
          onClick={clearFilter}
          type="button"
          className="btn-clear-filter"
        >
          Limpar Filtros
        </button>
      </div>
    </StyledForm>
  );
}
