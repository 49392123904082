import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, isSideMenuMobileOpen }) => css`
    height: auto;
    min-height: 100vh;
    display: block;
    float: right;
    width: 85%;
    flex: 1;
    padding-top: 72px;
    margin-left: -250px;
    background: white;

    @media (min-width: 992px) {
      margin-left: 0;
      transition: margin 0.15s ease-in-out;
    }

    @media (max-width: 992px) {
      width: 100%;
    }

    ${isSideMenuMobileOpen &&
    css`
    &::before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${theme.colors.black};
      z-index: ${theme.layers.base};
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    `}
  `}
`;
