import styled from 'styled-components';

export const Container = styled.div`
  .CustomCard {
    border-radius: 8px;
    background-color: #f0f0f0;
    box-shadow: 3px 3px 6px -2px rgba(0, 0, 0, 0.15);
    border: none;
    .CustomCard__Header {
      height: 47px;
      background-color: #f0f0f0;
      border: none;
    }
    .CustomCard__Body {
      padding-top: 0px;
    }
  }
`;

export const CollapseText = styled.h4`
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

export const Flex = styled.div`
  display: ${props => props.display || 'flex'};
  justify-content: ${props => props.justifyContent || ''};
  align-items: ${props => props.alignItems || ''};
  align-self: ${props => props.alignSelf || ''};
  max-width: ${props => props.maxW || ''};
  width: ${props => props.w || ''};
  height: ${props => props.h || ''};
  flex-direction: ${props => props.direction || 'row'};
  margin-top: ${props => props.mt || ''};
  margin-bottom: ${props => props.mb || '5px' || ''};
  margin-left: ${props => props.ml || '2px' || ''};
  background: ${props => props.bg || ''};
  border-radius: ${props => props.bRadius || ''};
  padding: ${props => props.p || ''};
  position: ${props => props.position || ''};
  right: ${props => props.right || ''};
  gap: ${props => props.gap || ''};
  flex-wrap: ${props => props.flexWrap || ''};
  text-align: ${props => props.textAlign || ''};
`;

export const InputCode = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  height: 26px;

  color: var(--color-brand-primary-darker-gray, #222);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

export const CodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 12.5px;
  background: #d9d9d9;
  box-shadow: 3px 3px 6px -2px rgba(0, 0, 0, 0.15);
  padding: 1px 8px;
  width: 74px;
  height: 28px;

  span {
    color: var(--color-brand-primary-darker-gray, #222);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
`;
