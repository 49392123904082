import React, { useEffect, useState } from 'react';
import Checkbox from '../../../Checkbox';
import { StyledForm, Flex, CheckboxGrid, Status } from './styles';
import constants from '../../../../config';

const ACTIONS = [
  { id: 'EMAIL', name: 'SEND_EMAIL', label: 'E-mail' },
  { id: 'PUSH', name: 'SEND_PUSH', label: 'Push' },
  { id: 'SMS', name: 'SEND_SMS', label: 'SMS' },
];

export default function Ruler({
  isVisible,
  attendentUser,
  cachedRules,
  modifiedRules,
  setModifiedRules,
}) {
  const [rules, setRules] = useState([]);

  useEffect(() => {
    setRules(cachedRules);
  }, [cachedRules]);

  const updateRule = (rule, actionToFind) => {
    let updatedActions = [];
    const hasAction = !!rule.actions.find(action => action === actionToFind);
    if (hasAction) {
      updatedActions = rule.actions.filter(action => action !== actionToFind);
    } else {
      updatedActions = [...rule.actions, actionToFind];
    }
    return {
      ...rule,
      actions: updatedActions,
      attendentUser,
    };
  };

  const updateRules = (updatedRule, ruleIdToFind) => {
    return rules.map(rule => (rule.id === ruleIdToFind ? updatedRule : rule));
  };

  const isTheSameCachedRule = (updatedRule, ruleIdToFind) => {
    const cachedRule = cachedRules.find(item => item.id === ruleIdToFind);
    if (!cachedRule) return false;
    const cachedActions = cachedRule.actions.slice().sort();
    const updatedActions = updatedRule.actions.slice().sort();
    if (
      cachedActions.length === updatedActions.length &&
      cachedActions.every((action, index) => action === updatedActions[index])
    ) {
      return true;
    }
    return false;
  };

  const updateModifiedRules = (updatedRule, ruleIdToFind) => {
    if (!modifiedRules.length) return [updatedRule];
    if (isTheSameCachedRule(updatedRule, ruleIdToFind)) {
      return modifiedRules.filter(rule => rule.id !== ruleIdToFind);
    }
    const isNewRuleModifiedAlready = !!modifiedRules.find(
      item => item.id === ruleIdToFind,
    );
    if (isNewRuleModifiedAlready) {
      return modifiedRules.map(rule =>
        rule.id === ruleIdToFind ? updatedRule : rule,
      );
    }
    return [...modifiedRules, updatedRule];
  };

  const handleCheckboxChange = (rule, actionName) => {
    const updatedRule = updateRule(rule, actionName);
    const newModifiedRules = updateModifiedRules(updatedRule, rule.id);
    setModifiedRules(newModifiedRules);
    const updatedRules = updateRules(updatedRule, rule.id);
    setRules(updatedRules);
  };

  return (
    <StyledForm className={!isVisible && 'hidden'}>
      <CheckboxGrid>
        {rules.map(rule => (
          <Flex
            key={rule.id}
            direction="column"
            bg="#F0F0F0"
            bRadius="8px"
            p="10px 96px 10px 36px"
          >
            <Status>
              Pedido {constants.OCC_PHYSICAL_STATUS_VALUE[rule.statusEsfera]}
            </Status>
            <Flex w="100%" justifyContent="space-between">
              {ACTIONS.map(action => (
                <Checkbox
                  key={action.id}
                  id={`CHECKBOX_${rule.id}_${action.id}`}
                  name={action.name}
                  label={action.label}
                  onChange={() => handleCheckboxChange(rule, action.name)}
                  checked={rule.actions.includes(action.name)}
                />
              ))}
            </Flex>
          </Flex>
        ))}
      </CheckboxGrid>
    </StyledForm>
  );
}
