import styled, { css } from 'styled-components';
import { Navbar, NavDropdown } from 'react-bootstrap';

export const Container = styled.header`
  ${({ theme }) => css`
    height: 56px;
    z-index: ${theme.layers.menu};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: ${theme.colors.white};
  `}
`;

export const StyledNavbar = styled(Navbar)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* justify-content: flex-start; */
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 56px;
    border-bottom: 1px solid #dadada;

    @media (max-width: 992px) {
      left: 0;
    }

    .title {
      margin-left: 58px;
      font-family: ${theme.fonts.family.poppins};
      font-size: ${theme.fonts.titleSize.xsmall};
      font-weight: ${theme.fonts.semibold};
      color: ${theme.colors.black};
      line-height: 24px;
      letter-spacing: -0.5px;

      @media (max-width: 992px) {
        margin-left: 0px;
      }
    }

    .dropdown-toggle {
      color: ${theme.colors.mediumGrey};
    }

    .dropdown-menu {
      left: -140%;
    }
  `}

`;

export const StyledNavDropdown = styled(NavDropdown)`

  .dropdown-toggle::after {
    display: none;
  }
`;

export const StyledBrandHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;

    > span {
      position: relative;
      top: 1px;
      display: block;
      padding: 0.5px 6px;
      font-size: 10px;
      background-color: ${theme.colors.darkerGrey};
      font-weight: ${theme.fonts.semibold};
      font-family: ${theme.fonts.family.roboto};
      color: ${theme.colors.white};
      border-radius: 100px;
    }
  `}
`;

export const StyledActionsHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    .esfera-action-header {
      font-size: 24px;

      &::before {
        color: ${theme.colors.darkerGrey}; 
      }
    }
  `}
`;