import React from 'react';

import Pag from '../Pag';
import * as S from './styles';

export default function TableFooter({
  totalCellWidth,
  currentPage,
  totalPages,
  isFirstPage,
  isLastPage,
  onClickFnPage,
}) {
  return (
    <S.Container>
      <tr>
        <td aria-label="pagination" colSpan={totalCellWidth}>
          <Pag
            currentPage={currentPage}
            totalPages={totalPages}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            onClickFnPage={onClickFnPage}
          />
        </td>
      </tr>
    </S.Container>
  );
}
