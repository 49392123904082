import React, { useEffect, useState } from 'react';
import {
  Alert,
  DivCheckBox,
  Paragraph,
  SFinancialContent,
  Subtitle,
  DivInput,
} from './styles';
import Checkbox from '../../../Checkbox';
import TextFieldIcon from '../../../TextField/TextFieldIcon';

export default function FinancialContent({
  additionalCashback,
  cashbackLimit,
  channel,
  populatePayloadRequest,
}) {
  const [newFinancialValues, setNewFinancialValues] = useState({
    additionalCashback: additionalCashback ? additionalCashback.toString() : '',
    limitCouponCashback: cashbackLimit ? cashbackLimit.toString() : '',
    siteId: channel,
  });
  const [validationFields, setValidationFields] = useState({
    additionalCashback: {
      isValid: !!(
        newFinancialValues.additionalCashback &&
        newFinancialValues.additionalCashback.length >= 1
      ),
      isRequired: true,
      minLength: 1,
    },
    limitCouponCashback: {
      isValid: !!(
        newFinancialValues.limitCouponCashback &&
        newFinancialValues.limitCouponCashback.length >= 0
      ),
      isRequired: false,
      minLength: 0,
    },
    siteId: {
      isValid: !!(
        newFinancialValues.siteId && newFinancialValues.siteId.length >= 1
      ),
      isRequired: true,
      minLength: 1,
    },
  });

  const [siteIdProp, setSiteIdProp] = useState(channel);

  const setIsUntouchable = e => {
    const { target } = e;
    const { value, id, name } = target;
    let newId = id;
    if (id === 'esferaCashback' || id === 'app') {
      newId = name;
    }
    const validationField = {};
    validationField[newId] = {
      ...validationFields[newId],
      isValid: !!(value && value.length >= validationFields[newId].minLength),
    };

    setValidationFields({ ...validationFields, ...validationField });
  };

  const populateFinancialFields = e => {
    if (e.target.type === 'checkbox') {
      setSiteIdProp(e.target.id);
      setNewFinancialValues({
        ...newFinancialValues,
        siteId: e.target.id,
      });
    } else {
      const key = e.target.name;
      const { value } = e.target;
      const newObject = {};
      newObject[key] = value;
      setNewFinancialValues({ ...newFinancialValues, ...newObject });
    }
  };

  useEffect(() => {
    populatePayloadRequest(newFinancialValues, validationFields);
  }, [newFinancialValues]);

  return (
    <SFinancialContent>
      <>
        <Subtitle>Cashback</Subtitle>
        <DivInput>
          <TextFieldIcon
            id="Valor"
            idName="additionalCashback"
            label="Valor"
            type="number"
            classIcon="ic_banking_percent"
            defaultValue={additionalCashback}
            className={
              validationFields.additionalCashback.isValid ||
              !validationFields.additionalCashback.isRequired
                ? ''
                : 'invalid-input'
            }
            onKeyUp={e => {
              setIsUntouchable(e);
              populateFinancialFields(e);
            }}
          />
          <TextFieldIcon
            id="Limitador"
            idName="limitCouponCashback"
            label="Limitador"
            type="number"
            classIcon="ic_banking_exchange_currency"
            defaultValue={cashbackLimit}
            className={validationFields.limitCouponCashback}
            onKeyUp={e => {
              setIsUntouchable(e);
              populateFinancialFields(e);
            }}
          />
        </DivInput>
        <Alert>
          <span className="esfera-icon ic_filled_circle_attention" />
          <Paragraph>
            O cupom se torna inativo caso o cliente alcance o limite de cashback
            diário ou mensal.
          </Paragraph>
        </Alert>
      </>
      <>
        <Subtitle>Escolha o canal</Subtitle>
        <Paragraph>
          A campanha será apresentada apenas no canal selecionado.
        </Paragraph>
        <DivCheckBox>
          <Checkbox
            id="esferaCashback"
            idName="siteId"
            checked={siteIdProp === 'esferaCashback'}
            label="Site"
            className={
              validationFields.siteId.isValid ||
              !validationFields.siteId.isRequired
                ? 'checkbox'
                : 'checkbox invalid-input'
            }
            onChange={e => {
              setIsUntouchable(e);
              populateFinancialFields(e);
            }}
            iconClassName="ic_banking_internet_banking"
          />
          <Checkbox
            id="app"
            idName="siteId"
            checked={siteIdProp === 'app'}
            label="Aplicativo"
            className={
              validationFields.siteId.isValid ||
              !validationFields.siteId.isRequired
                ? 'checkbox'
                : 'checkbox invalid-input'
            }
            onChange={e => {
              setIsUntouchable(e);
              populateFinancialFields(e);
            }}
            iconClassName="ic_banking_mobile"
          />
        </DivCheckBox>
      </>
    </SFinancialContent>
  );
}
