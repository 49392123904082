import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../Button';
// eslint-disable-next-line import/named
import { handlePostRequest } from '../../services/GenericMethods';
import constants from '../../config';

import { StyledModal } from './styles';
import { formatErrorMessageToToast } from '../../helpers/GlobalFunctions';

export default function ModalRules({ isOpenModal, handleCloseModal, user }) {
  const { keycloak } = useKeycloak();
  const FULL_URL = window.SERVICES_DOMAIN + window.ANTIFRAUD_RULES_URL;

  const [id, setId] = useState('');
  const [action, setAction] = useState('');
  const [activated, setActivated] = useState('');

  const handleAddRule = () => {
    if (!id || !action || !activated) console.log('error to createRule!');

    let activatedPost = false;
    if (activated === 'true') {
      activatedPost = true;
    }
    const createPostOption = {
      method: 'POST',
      data: {
        id: parseInt(id, 10),
        action,
        activated: activatedPost,
        user: user.preferred_username,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`,
      },
    };
    handlePostRequest(FULL_URL, createPostOption)
      .then(response => {
        handleCloseModal(
          'success',
          'Sucesso!',
          `Regra número ${response.data.id} cadastrada com sucesso!`,
        );
      })
      .catch(error => {
        console.error(`Error at createRule request = ${error}`);
        const errorMessage = formatErrorMessageToToast(error, keycloak);
        handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
      });
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <StyledModal.Header closeButton>
        <StyledModal.Title>Cadastrar Nova Regra</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Informe o número da regra</Form.Label>
            <Form.Control
              type="number"
              name="id"
              onChange={e => setId(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Selecione a ação da regra</Form.Label>
            <Form.Control
              as="select"
              name="action"
              onChange={e => setAction(e.target.value)}
              required
            >
              <option disabled selected>
                Selecione uma ação
              </option>
              <option value={constants.REFUSED_LABEL}>Barrar Pedido</option>
              <option value={constants.FRAUD_CHECK_LABEL}>
                Análise Manual
              </option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Status da regra</Form.Label>
            <Form.Control
              as="select"
              name="activated"
              onChange={e => setActivated(e.target.value)}
              required
            >
              <option disabled selected>
                Selecione um status
              </option>
              <option value="true">Ativa</option>
              <option value="false">Inativa</option>
            </Form.Control>
          </Form.Group>

          <div className="container-buttons">
            <Button
              type="button"
              typeOfButton="primary"
              onClick={handleAddRule}
            >
              Salvar
            </Button>
            <Button
              type="button"
              typeOfButton="secondary"
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>
          </div>
        </Form>
      </StyledModal.Body>
    </StyledModal>
  );
}
