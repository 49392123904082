import React from 'react';
import { TooltipContainer, TooltipText } from './styles';

export default function Tooltip({ text, children }) {
  return (
    <TooltipContainer>
      {children}
      <TooltipText>{text}</TooltipText>
    </TooltipContainer>
  );
}
