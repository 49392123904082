import React from 'react';
import { Container } from './styles';

export default function Content({ children, isSideMenuMobileOpen }) {
  return (
    <Container isSideMenuMobileOpen={isSideMenuMobileOpen}>
      {children}
    </Container>
  );
}
