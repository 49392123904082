import React, { useState } from 'react';
import * as S from './styles';

export default function Accordion({ id, title, bodyAccordion, iconClassName }) {
  const [isActive, setIsActive] = useState(false);
  const iconClass = `icon-button esfera-icon ${iconClassName}`;

  return (
    <S.Accordion defaultActiveKey={id}>
      <S.Accordion.Toggle
        as={S.HeaderAccordion}
        eventKey={id}
        onClick={() => setIsActive(!isActive)}
      >
        {!!iconClassName && <span className={iconClass} />}
        <p>{title}</p>
        {isActive ? (
          <span className="icon-button esfera-icon ic_system_arrow_down" />
        ) : (
          <span className="icon-button esfera-icon ic_system_arrow_top" />
        )}
      </S.Accordion.Toggle>
      <S.Accordion.Collapse eventKey={id}>{bodyAccordion}</S.Accordion.Collapse>
    </S.Accordion>
  );
}
