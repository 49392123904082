import React from 'react';

import OrderItem from '../OrderItem';

import { StyledTable } from './styles';

export default function EsferaTable({
  orders,
  userPermissions,
  handleShowMessageAction,
}) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <th>Pedido</th>
          <th>Documento</th>
          <th>Data do Pedido</th>
          <th>Produto</th>
          <th>ID Sku</th>
          <th>Valor Total</th>
          <th>Status</th>
        </tr>
      </thead>

      <tbody>
        {orders.map(order => (
          <OrderItem
            order={order}
            key={Math.random()}
            userPermissions={userPermissions}
            handleShowMessageAction={handleShowMessageAction}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
