import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Button from '../../Button';
import Loading from '../../Loading';

import { StyledModal, WrapperButtons } from './styles';
import {
  handlePostRequest,
  initialHeadersData,
} from '../../../services/GenericMethods';

import { formatErrorMessageToToast } from '../../../helpers/GlobalFunctions';

export default function ModalRefusedOrder({
  order,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const { keycloak } = useKeycloak();
  const FULL_URL =
    window.SERVICES_DOMAIN + window.MANUAL_TRANSFER_UPDATE_ORDER_URL;
  const [showLoading, setShowLoading] = useState(false);

  const handleRefusedOrder = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.MANUAL_TRANSFER_ORDERS.UPDATE',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      const reqOptions = {
        headers: initialHeadersData(keycloak.token),
        data: {
          orderId: order.orderId,
          commerceItemId: order.commerceItemId,
          status: 'REFUSED',
        },
      };

      handlePostRequest(FULL_URL, reqOptions)
        .then(() => {
          setShowLoading(false);
          handleCloseModal(
            'success',
            'Sucesso!',
            `Pedido recusado com sucesso.`,
          );
        })
        .catch(error => {
          setShowLoading(false);
          console.error(`Error at cancelOrder request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          handleCloseModal('error', 'Erro', errorMessage.messagePTBR);
        });
    }
    handleCloseModal();
  };

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        <StyledModal.Title>Recusar Pedido</StyledModal.Title>
      </StyledModal.Header>

      <StyledModal.Body>
        <div>
          <p>Deseja realmente recusar o pedido abaixo: </p>
          <span>
            <strong>Pedido: </strong>
            {order.orderId}
          </span>
          <span>
            <strong>Documento: </strong>
            {order.documentId}
          </span>
          <span>
            <strong>Produto: </strong>
            {order.product}
          </span>
          <span>
            <strong>Valor Total: </strong>
            {order.value}
          </span>
        </div>

        <WrapperButtons>
          <Button typeOfButton="secondary" onClick={handleRefusedOrder}>
            Sim
          </Button>
          <Button typeOfButton="secondary" onClick={handleCloseModal}>
            Não
          </Button>
        </WrapperButtons>
      </StyledModal.Body>
    </StyledModal>
  );
}
