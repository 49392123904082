import moment from 'moment';
import React, { useState } from 'react';
import * as S from './styles';
import ModalCampaignDetails from '../ModalCampaignDetails';

export default function HistoryCampaignTableBody({ item }) {
  const [isOpenModalDetails, setIsOpenCampaignModalDetails] = useState(false);

  const handleShowMdlCampaignDetails = () =>
    setIsOpenCampaignModalDetails(true);
  const handleCloseMdlCampaignDetails = () =>
    setIsOpenCampaignModalDetails(false);

  return (
    <>
      <S.StyledTr onClick={handleShowMdlCampaignDetails}>
        <td>{item.campaignName}</td>
        {!!item.adminUser && <td>{item.adminUser}</td>}
        {!!item.startDate && (
          <td>{moment(item.startDate).format('DD/MM/YYYY HH:mm')}</td>
        )}
        {!!item.endDate && (
          <td>{moment(item.endDate).format('DD/MM/YYYY HH:mm')}</td>
        )}
        {!!item.registrationDate && (
          <td>{moment(item.registrationDate).format('DD/MM/YYYY HH:mm')}</td>
        )}
        {!!item.status && (
          <td className="status">
            {item.status === 'DISABLED' ? 'Desativada' : 'Excluída'}
          </td>
        )}
      </S.StyledTr>

      <ModalCampaignDetails
        item={item}
        isOpenModal={isOpenModalDetails}
        handleCloseModal={handleCloseMdlCampaignDetails}
      />
    </>
  );
}
