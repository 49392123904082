import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 48px;
    margin-bottom: 16px;

    svg {
      position: absolute;
      right: 14px;
      top: 10px;
      color: ${theme.colors.mediumGrey};
      z-index: 9;
      cursor: pointer;
      font-size: 20px;
    }
  `}
`;

export const Input = styled.input`
  ${({ theme, isError }) => css`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: ${theme.colors.darkerGrey};
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.colors.lightGrey};
    border-radius: 8px;
    outline: none;
    padding: 12px 16px;
    background: none;
    z-index: 1;

    ${isError && css`
      border-color: ${theme.colors.error};
    `}

    @media only screen and (min-width: 992px) {
      font-size: 16px;
    }

    &:hover {
      border: 1px solid ${theme.colors.darkSky};
    }

    &:focus {
      border: 1.5px solid ${theme.colors.darkerGrey};
      ${!!isError && css`
        border-color: ${theme.colors.error};
      `}
    }

    &:focus + label {
      top: -10px;
      left: 0.8rem;
      z-index: 10;
      font-weight: 500; 
      font-size: 12px;
    
      @media only screen and (min-width: 992px) {
        font-size: 16px;
        top: -14px;
      }
    }

    &:not(:placeholder-shown)&:not(:focus) + label {
      top: -10px;
      left: 0.8rem;
      z-index: 10;
      font-weight: 500;
      font-size: 12px;
    
      @media only screen and (min-width: 992px) {
        font-size: 16px;
        top: -14px;
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    position: absolute;
    left: 1rem;
    top: 12px;
    padding: 0 0.25rem;
    color: ${theme.colors.mediumGrey};
    font-size: 12px;
    background-color: ${theme.colors.white};
    transition: 0.3s;

    @media only screen and (min-width: 992px) {
      font-size: 16px;
      top: 12px;
    }
  `}
`;

export const Error = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    line-height: 16px;
    color: ${theme.colors.error};
  `}
`;

export const InputIcon = styled(Input)`
  width: calc(100% - 47px);
  border-end-end-radius: 0;
  border-start-end-radius: 0;

  &:focus ~ span {
    border: 1.5px solid #222222;
    border-left-width: 1px;
  }
`

export const Icon = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  background: #F0F0F0;
  height: 48px;
  width: 48px;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border: 1px solid #CCCCCC;
`